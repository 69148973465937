import { FacebookLogo, GlobeHemisphereWest, InstagramLogo, MapPin } from 'phosphor-react';
import { ChangeEvent, FormEvent, HTMLAttributes, useState } from 'react';
import { useMutation } from 'react-query';
import { fetchContactUs } from '../../api/contact';
import { IContactUs } from '../../interfaces/contact';
import AlertMessages from '../AlertMessages';
import Button from '../Button';
import Label from '../Label';
import Loader from '../Loader';
import Paragraph from '../Paragraph';
import Textarea from '../Textarea';
import Textfield from '../Textfield';
import Title from '../Title';

const emptyState: IContactUs = {
  name: '',
  email: '',
  message: '',
};

function Container({ children }: HTMLAttributes<HTMLDivElement>) {
  return <div className="inline-flex items-center mb-4 md:mb-8 last:mb-0">{children}</div>;
}

function ContactForm() {
  const [state, setState] = useState<IContactUs>(emptyState);

  const { mutate, isLoading, error, data } = useMutation(fetchContactUs, {
    onSettled: () => {
      setState(emptyState);
    },
  });

  const onHandleChange = ({ target }: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setState((prevState) => ({
      ...prevState,
      [target.name]: target.value,
    }));
  };

  const onHandleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    mutate(state);
  };

  return (
    <div className="w-full">
      {isLoading && <Loader fullScreen />}

      <Title>
        <strong className="font-semibold">Fale com o KidsBuffets</strong>
      </Title>

      <Paragraph>
        Utilize o formulário para tirar todas as suas dúvidas. Responderemos o seu contato em até 72
        horas úteis.
      </Paragraph>

      <AlertMessages show={!!data?.message} type="success" message={data?.message} />
      <AlertMessages show={!!error} type="error" message={JSON.stringify(error)} />

      <div className="flex flex-wrap flex-col md:flex-row md:flex-nowrap w-full mt-8 md:mb-12">
        <div className="flex flex-col w-full md:w-96 md:mr-20 mb-10 md:mb-0">
          <Container>
            <GlobeHemisphereWest size={28} weight="fill" className="text-blue-450 mr-4" />
            <Paragraph>
              <a href="mailto:contato@kidsbuffets.com.br" className="no-underline hover:underline">
                contato@kidsbuffets.com.br
              </a>
            </Paragraph>
          </Container>

          <Container>
            <MapPin size={28} weight="fill" className="text-blue-450 mr-4" />
            <Paragraph>São Paulo - SP</Paragraph>
          </Container>

          <h2 className="md:mb-8 mb-6 mt-4 text-xl">Mídias Sociais</h2>

          <Container>
            <FacebookLogo size={28} weight="fill" className="text-facebook mr-4" />
            <Paragraph>
              <a
                href="https://facebook.com/kidsbuffets"
                target="_blank"
                rel="noopener noreferrer"
                className="no-underline hover:underline"
              >
                Facebook
              </a>
            </Paragraph>
          </Container>

          <Container>
            <InstagramLogo size={28} weight="fill" className="text-instagram mr-4" />
            <Paragraph>
              <a
                className="no-underline hover:underline"
                href="https://instagram.com/kidsbuffets"
                target="_blank"
                rel="noopener noreferrer"
              >
                Instagram
              </a>
            </Paragraph>
          </Container>
        </div>

        <form className="w-full grid grid-cols-1 gap-6" onSubmit={onHandleSubmit}>
          <fieldset>
            <Label className="pl-6" htmlFor="txtName">
              Nome:*
            </Label>
            <Textfield
              id="txtName"
              type="text"
              placeholder="Digite seu nome..."
              name="name"
              minLength={3}
              maxLength={60}
              onChange={onHandleChange}
              autoComplete="off"
              value={state.name}
              required
            />
          </fieldset>

          <fieldset>
            <Label className="pl-6" htmlFor="txtEmail">
              E-mail:*
            </Label>
            <Textfield
              id="txtEmail"
              type="email"
              placeholder="Digite seu email..."
              name="email"
              value={state.email}
              onChange={onHandleChange}
              required
            />
          </fieldset>

          <fieldset>
            <Label className="pl-6" htmlFor="txtMessage">
              Mensagem:*
            </Label>
            <Textarea
              id="txtMessage"
              placeholder="Digite sua mensagem..."
              rows={3}
              minLength={3}
              name="message"
              value={state.message}
              onChange={onHandleChange}
              required
            />
          </fieldset>

          <div className="flex justify-end">
            <Button type="submit" size="large" theme="purple" disabled={isLoading}>
              Enviar mensagem
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default ContactForm;
