import { ChangeEvent, FormEvent, useState } from 'react';
import { useMutation } from 'react-query';
import { fetchSendComment } from '../../api/comments';
import { ICommentsForm } from '../../interfaces/comments';
import AlertMessages from '../AlertMessages';
import Button from '../Button';
import Label from '../Label';
import Loader from '../Loader';
import Rating from '../Rating';
import Textarea from '../Textarea';
import Textfield from '../Textfield';

interface IProps {
  buffetId: string;
}

const emptyState: ICommentsForm = {
  name: '',
  email: '',
  message: '',
  rating: 3,
  buffetId: '',
};

function CommentsForm({ buffetId }: IProps) {
  const [state, setState] = useState<ICommentsForm>(emptyState);

  const { mutate, isLoading, error, data } = useMutation(fetchSendComment, {
    onSettled: () => {
      setState(emptyState);
    },
  });

  const onHandleChange = ({ target }: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setState((prevState) => ({
      ...prevState,
      [target.name]: target.value,
    }));
  };

  const onHandleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    mutate({
      ...state,
      buffetId,
    });
  };

  if (isLoading) return <Loader fullScreen={false} />;

  if (data?.message) return <AlertMessages type="success" message={data?.message} />;

  return (
    <>
      <AlertMessages
        show={!!error}
        type="error"
        message={typeof error === 'string' ? error : JSON.stringify(error)}
      />

      <form onSubmit={onHandleSubmit} className="w-full grid grid-cols-2 gap-4 max-w-xl box-border">
        <fieldset className="col-span-2">
          <Label>Escolha sua avaliação:*</Label>
          <Rating
            count={5}
            value={state.rating}
            size={40}
            onClick={(val: number) =>
              setState((prevState) => ({
                ...prevState,
                rating: val,
              }))
            }
          />
        </fieldset>

        <fieldset>
          <Label className="pl-6" htmlFor="txtName">
            Nome:*
          </Label>
          <Textfield
            type="text"
            placeholder="digite seu nome..."
            autoComplete="off"
            name="name"
            id="txtName"
            onChange={onHandleChange}
            value={state.name}
            maxLength={60}
            minLength={3}
            required
          />
        </fieldset>

        <fieldset>
          <Label className="pl-6" htmlFor="txtEmail">
            E-mail:*
          </Label>
          <Textfield
            type="email"
            autoComplete="off"
            placeholder="digite seu e-mail..."
            name="email"
            id="txtEmail"
            onChange={onHandleChange}
            value={state.email}
            maxLength={60}
            minLength={3}
            required
          />
        </fieldset>

        <fieldset className="col-span-2">
          <Label className="pl-6" htmlFor="txtMessage">
            Mensagem:*
          </Label>
          <Textarea
            rows={3}
            minLength={3}
            autoComplete="off"
            name="message"
            id="txtMessage"
            onChange={onHandleChange}
            value={state.message}
            placeholder="digite sua mensage..."
            required
          />
        </fieldset>

        <fieldset className="col-span-2">
          <Button type="submit" theme="purple">
            Enviar comentário
          </Button>
        </fieldset>
      </form>
    </>
  );
}

export default CommentsForm;
