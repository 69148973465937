import { HTMLAttributes } from 'react';

function Subtitle({ children }: HTMLAttributes<HTMLHeadingElement>) {
  return (
    <h3 className="w-full box-border text-2xl text-text md:font-normal mb-4 sm:mb-6">
      {children}
    </h3>
  );
}

export default Subtitle;
