import { FacebookLogo, GlobeHemisphereWest, InstagramLogo } from 'phosphor-react';
import { Link } from 'react-router-dom';
import Wrapper from '../Wrapper';
import IMG_Logo from '../../assets/images/kidsbuffets-logo-white.png';
import SocialMedia from './socialmedia';

function Footer() {
  return (
    <footer className="w-full bg-black pt-12">
      <Wrapper>
        <div className="flex justify-center mb-8">
          <img src={IMG_Logo} alt="" className="grayscale-0" />
        </div>

        <p className="text-white text-center text-sm font-light md:font-light">
          KidsBuffets te ajuda a encontrar o melhor Buffet Infantil para realizar a festa do seu
          filho ou de sua filha. <br />
          São milhares de oportunidades para realizar a festa que você sempre sonhou e tornar esse
          dia mágico e inesquecível.
        </p>

        <hr className="border-white my-8" />

        <div className="flex flex-wrap flex-col sm:flex-row sm:flex-nowrap items-center justify-center sm:justify-between">
          <div className="mb-2 sm:mb-0">
            <SocialMedia
              icon={<GlobeHemisphereWest size={18} weight="fill" className="text-orange-450" />}
              text="contato@wesleyamaro.com"
              url="mailto:contato@kidsbuffets.com.br"
            />
          </div>

          <div>
            <SocialMedia
              icon={<FacebookLogo size={18} weight="fill" className="text-orange-450" />}
              text="facebook"
              url="https://facebook.com/kidsbuffets"
              className="mr-6"
            />

            <SocialMedia
              icon={<InstagramLogo size={18} weight="fill" className="text-orange-450" />}
              text="instagram"
              url="https://instagram.com/kidsbuffets"
            />
          </div>
        </div>
      </Wrapper>

      <div className="w-full bg-gray-950 mt-4 py-5">
        <Wrapper>
          <div className="flex flex-col text-white font-light text-xs justify-center items-center">
            <span>CNPJ 37.833.975/0001-00</span>

            <span className="inline-flex my-2">
              {'KidsBuffets © '}
              {new Date().getFullYear()}
              {'. Todos os direitos reservados. '}
            </span>

            <span>
              <Link to="/termos-de-uso" className="hover:underline">
                Termos de Uso
              </Link>
              {' | '}
              <Link to="/politica-de-privacidade" className="hover:underline">
                Política de Privacidade
              </Link>
            </span>
          </div>
        </Wrapper>
      </div>
    </footer>
  );
}

export default Footer;
