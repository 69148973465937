import { useEffect, useState } from 'react';

export function useWindowSize() {
  const [size, setSize] = useState([window.innerWidth, window.innerHeight]);

  useEffect(() => {
    const onResizeScreen = () => setSize([window.innerWidth, window.innerHeight]);

    window.addEventListener('resize', onResizeScreen);

    return () => window.removeEventListener('resize', onResizeScreen);
  }, []);

  return size;
}

export default { useWindowSize };
