import { v4 as uuidv4 } from 'uuid';
import { Database, DeviceMobile, ShieldCheck } from 'phosphor-react';
import Wrapper from '../../../components/Wrapper';

const list = [
  {
    title: 'Servidores Rápidos',
    description:
      'Nossos serviços estão localizados em servidores de alto desempenho, confiáveis e estão disponíveis 24/7',
    icon: <Database size={54} weight="fill" className="text-blue-450" />,
  },
  {
    title: 'Proteção de dados',
    description:
      'Utilizamos tecnologias avançadas de segurança para proteger os dados da sua empresa, para que você possa ter certeza de que está em boas mãos.',
    icon: <ShieldCheck size={54} weight="fill" className="text-blue-450" />,
  },
  {
    title: 'Praticidade',
    description:
      'Todos os nossos serviços são 100% responsivos com um editor móvel fácil de usar. Atualize e acompanhe seu anúncio em qualquer lugar.',
    icon: <DeviceMobile size={54} weight="fill" className="text-blue-450" />,
  },
];

function BeneficiosSection() {
  return (
    <section className="w-full bg-gray-250 py-10 my-10">
      <Wrapper>
        <ul className="w-full grid grid-cols-1 md:grid-cols-3 gap-8 md:gap-6">
          {list.map((item) => (
            <li key={uuidv4()} className="flex items-start content-start justify-start">
              <div className="mr-4">{item.icon}</div>

              <div>
                <h3 className="text-lg font-semibold mb-1">{item.title}</h3>
                <p className="text-base text-black font-light md:font-light">{item.description}</p>
              </div>
            </li>
          ))}
        </ul>
      </Wrapper>
    </section>
  );
}

export default BeneficiosSection;
