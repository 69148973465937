import { CaretDown, CaretRight } from 'phosphor-react';
import { useState, useRef } from 'react';
import Paragraph from '../Paragraph';

interface IProps {
  item: any;
  theme?: 'dark' | 'light';
}

function Questions({ item, theme }: IProps) {
  const refContainer = useRef(null);
  const [isOpened, setIsOpened] = useState(false);

  const arrowStyle =
    'inline-flex mr-2 left-4 absolute top-1/2 -translate-y-1/2 z-10';

  return (
    <div className="w-full" ref={refContainer} itemScope>
      <div
        className={`w-full font-normal mb-2 rounded-3xl py-2 pr-5 pl-11 box-border cursor-pointer transition-all flex items-center content-center relative ${
          theme === 'light' ? 'bg-white' : 'bg-gray-250'
        } hover:bg-orange-450 hover:text-white`}
        itemType="Question"
        itemProp="Question"
        role="button"
        tabIndex={0}
        onClick={() => setIsOpened(!isOpened)}
        onKeyPress={() => setIsOpened(!isOpened)}
      >
        {isOpened ? (
          <CaretDown size={18} weight="bold" className={arrowStyle} />
        ) : (
          <CaretRight size={18} weight="bold" className={arrowStyle} />
        )}

        <span className="text-lg leading-snug">{item.question}</span>
      </div>

      <div
        className={`box-border transition-all px-5 pt-1 pb-8 ${
          !isOpened ? 'hidden' : 'flex'
        }`}
        itemType="Answer"
        itemProp="Answer"
      >
        <Paragraph>{item.answer}</Paragraph>
      </div>
    </div>
  );
}

Questions.defaultProps = {
  theme: 'dark',
};

export default Questions;
