type TRequiredFields = {
  name: string;
  value: string | number | null | undefined | any[];
};

type TOrderBy = {
  propName: string;
  order: 'ASC' | 'DESC';
  arr: any[];
};

type TObj = {
  [key: string]: string;
};

type TSearchURL = {
  params: TObj;
  district: string | null | undefined;
  city: string | null | undefined;
  locationSearch: string | null | undefined;
};

export const reactQueryConfig = {
  refetchOnWindowFocus: false,
  retries: 1,
};

export function axiosErrorMessage(err: any) {
  if (err) {
    if (err?.response?.data?.message) throw err.response.data.message;
    if (err?.message) throw err.message;
  }

  throw new Error('Ops! Alguma coisa deu errado. Tente novamente mais tarde.');
}

export function validateRequiredFields(arr: TRequiredFields[]) {
  function throwError(index: number) {
    throw new Error(`The field ${arr[index].name} is required`);
  }

  for (let i = 0; i < arr.length; i += 1) {
    const isArr: boolean = Array.isArray(arr[i].value);

    if (isArr) {
      const val: any = arr[i].value;
      if (val.length === 0) throwError(i);
    }

    if (!arr[i].value) {
      throwError(i);
    }
  }
}

export const normalizeString = (_str: string) => {
  if (typeof _str !== 'undefined') {
    return _str
      .trim()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .replace(/\s\s+/, ' ')
      .toLowerCase();
  }

  return false;
};

export const orderBy = ({ propName, order, arr = [] }: TOrderBy) => {
  if (arr?.length > 0) {
    return arr.slice(0).sort((a, b) => {
      const varA = typeof a[propName] === 'string' ? normalizeString(a[propName]) : a[propName];
      const varB = typeof b[propName] === 'string' ? normalizeString(b[propName]) : b[propName];
      let orderDesc = 1;
      let orderAsc = -1;

      if (order === 'DESC') {
        orderDesc = -1;
        orderAsc = 1;
      }

      if (varA > varB) return orderDesc;
      if (varA < varB) return orderAsc;
      return 0;
    });
  }

  return arr;
};

export const buildSearchParams = (obj: TObj, locationSearch: string | undefined | null = '') => {
  const searchParams = new URLSearchParams(locationSearch ?? '');

  const entries = Object.entries(obj);

  if (entries.length) {
    for (let i = 0; i < entries.length; i += 1) {
      const [key, value] = entries[i];

      if (!searchParams.has(key)) {
        searchParams.append(key, value);
      } else {
        searchParams.set(key, value);
      }
    }
  }

  return `?${searchParams.toString()}`;
};

export const buildSearchURL = ({ params, city, district, locationSearch }: TSearchURL) => {
  let url = '/buffets-infantis';
  const q = params ? buildSearchParams(params, locationSearch) : '';

  if (city) {
    url += `/${city}`;
  }

  if (district) {
    url += `/${district}`;
  }

  const result = url + q;

  return result;
};

export const validEmailAddress = (email: string) => {
  const regex = /^(\D)+(\w)*((\.(\w)+)?)+@(\D)+(\w)*((\.(\D)+(\w)*)+)?(\.)[a-z]{2,}$/;

  return {
    regexAsString: regex.source,
    result: regex.test(email),
  };
};

export default {
  reactQueryConfig,
  normalizeString,
  orderBy,
  axiosErrorMessage,
  validateRequiredFields,
  buildSearchParams,
  buildSearchURL,
  validEmailAddress,
};
