import { CaretLeft, CaretRight } from 'phosphor-react';
import { v4 as uuidv4 } from 'uuid';
import { useState, useRef, ReactElement } from 'react';

interface IProps {
  children: ReactElement | ReactElement[];
  height?: number;
  controllers?: boolean;
  className?: string;
}

let startMoving: any = null;
let endMoving: any = null;

function Carousel({ children, height, controllers, className }: IProps) {
  const refContainer = useRef(null);
  const [active, setActive] = useState(0);

  const onHandleNext = () => {
    if (children && Array.isArray(children) && children.length > 1) {
      setActive(active !== children.length - 1 ? active + 1 : 0);
    }
  };

  const onHandlePrev = () => {
    if (children && Array.isArray(children) && children.length > 1) {
      setActive(active !== 0 ? active - 1 : children.length - 1);
    }
  };

  const onTouchMove = (e: any) => {
    if (e.touches[0]) {
      startMoving = !startMoving ? e.touches[0].pageX : startMoving;
      endMoving = e.touches[0].pageX;
    }
  };

  const onTouchEnd = () => {
    const result = startMoving - endMoving;

    if (result > 0 && result > 40) {
      // SWIPE TO LEFT
      onHandleNext();
    }

    if (result < 0 && result * -1 > 40) {
      // SWIPE TO RIGHT
      onHandlePrev();
    }

    startMoving = null;
    endMoving = null;
  };

  const controlsStyle =
    'w-10 h-10 absolute top-1/2 z-10 bg-gray-250 rounded-full box-border overflow-hidden cursor-pointer transition-all outline-none border-none -translate-y-1/2 flex justify-center items-center content-center opacity-60 hover:opacity-100';

  const child = Array.isArray(children) ? children : new Array(children);

  return (
    <div
      className={`w-full overflow-hidden flex flex-wrap mb-4 relative rounded-lg lg:max-w-225 max-h-125 ${className}`}
      ref={refContainer}
      style={{ height }}
    >
      {child.map((item: ReactElement, index: number) => (
        <div
          key={item?.key ?? uuidv4()}
          style={{ height }}
          className={`w-full inline-flex opacity-0 absolute top-0 left-0 z-0 transition-all carousel-items-${index} ${
            index === active ? 'opacity-100' : ''
          } ${className}`}
          onTouchMove={onTouchMove}
          onTouchEnd={onTouchEnd}
        >
          {item}
        </div>
      ))}

      {child.length > 1 && (
        <>
          {controllers && (
            <div className="w-full absolute top-1/2 left-0 z-10 -translate-y-1/2 transition-all">
              <span
                role="button"
                className={`${controlsStyle} right-2`}
                onClick={() => onHandleNext()}
                onKeyPress={() => onHandleNext()}
                tabIndex={0}
              >
                <CaretRight size={24} weight="bold" />
              </span>

              <span
                role="button"
                className={`${controlsStyle} left-2`}
                onKeyPress={() => onHandlePrev()}
                onClick={() => onHandlePrev()}
                tabIndex={0}
              >
                <CaretLeft size={24} weight="bold" />
              </span>
            </div>
          )}

          <div className="absolute bg-text right-2 top-2 z-10 px-3 py-1 box-border text-sm text-white transition-all rounded-lg">
            {active + 1}/{child.length}
          </div>

          <div className="w-full absolute bottom-3 left-0 flex z-20 flex-wrap justify-center items-center content-center">
            {child.map((_, i: number) => (
              <span
                key={uuidv4()}
                className={`w-2 h-2 mr-1 last:mr-0 rounded-full bg-white border border-gray-250 ${
                  i === active ? 'w-3 h-3 bg-blue-400' : ''
                }`}
              />
            ))}
          </div>
        </>
      )}
    </div>
  );
}

Carousel.defaultProps = {
  height: 500,
  controllers: true,
  className: '',
};

export default Carousel;
