import Button from '../Button';

interface IProps {
  title: string;
  price?: string;
  description?: string;
  buttonText?: string;
  details?: string[];
  priceCurrency?: string;
  onClick?(): void;
}

function PlanBox({
  title,
  price,
  priceCurrency,
  description,
  details,
  buttonText,
  onClick,
}: IProps) {
  return (
    <div
      itemProp="offers"
      itemScope
      itemType="http://schema.org/Offer"
      className="box-border p-7 inline-flex flex-wrap flex-col items-center content-center bg-white border-2 border-gray-250 rounded-3xl transition-all hover:border-orange-450"
    >
      {title && (
        <h3
          className="text-text w-full font-semibold text-xl uppercase mb-4 pb-4 text-center border-b border-gray-250"
          itemProp="name"
        >
          {title}
        </h3>
      )}

      {priceCurrency && (
        <h3 className="text-text font-semibold text-4xl text-center">
          <span itemProp="priceCurrency">R$ {priceCurrency}</span>
        </h3>
      )}

      {price && (
        <h3
          className="text-orange-450 font-semibold text-5xl m-0"
          itemProp="price"
        >
          {price}
        </h3>
      )}

      {description && (
        <p className="text-text font-light text-base mt-1 text-center">
          {description}
        </p>
      )}

      {details && details.length > 0 && (
        <ul className="w-full mt-5 pt-5 border-t border-gray-250 list-disc list-inside">
          {details.map((item) => (
            <li
              key={item}
              className="text-base font-light text-text mb-2 last:mb-0"
            >
              {item}
            </li>
          ))}
        </ul>
      )}

      {buttonText && onClick && (
        <Button
          type="button"
          size="large"
          theme="orange"
          className="mt-8"
          onClick={onClick}
        >
          {buttonText}
        </Button>
      )}
    </div>
  );
}

PlanBox.defaultProps = {
  priceCurrency: '',
  price: '',
  description: '',
  details: [],
  buttonText: '',
  onClick: undefined,
};

export default PlanBox;
