import { useContext } from 'react';
import Wrapper from '../../components/Wrapper';
import MasterPage from '../../components/MasterPage';
import Partners from '../../components/Partners';
import Title from '../../components/Title';
import CardsList from '../../components/CardsList';
import AboutUs from '../../components/AboutUs';
import ContactForm from '../../components/ContactForm';
import Newsletter from '../../components/Newsletter';
import AppContext from '../../context';
import SeeMore from '../../components/SeeMore';

function Home() {
  const { isLoadingFeaturedBuffets, isLoadingPopularBuffets, popularBuffets, featuredBuffets } =
    useContext(AppContext);

  return (
    <MasterPage>
      <Wrapper>
        <section>
          <Title>
            Veja os <strong className="font-semibold">Buffets Infantis</strong> em destaque
          </Title>

          <CardsList buffets={featuredBuffets} isLoading={isLoadingFeaturedBuffets} />

          <SeeMore to="/buffets-infantis?orderby=reviews" />
        </section>
      </Wrapper>

      <Partners className="my-10" />

      <Wrapper>
        <section>
          <Title>
            Separamos alguns dos <strong className="font-semibold">Buffets Infantis</strong>
            <br /> mais populares para você
          </Title>

          <CardsList buffets={popularBuffets} isLoading={isLoadingPopularBuffets} />

          <SeeMore to="/buffets-infantis?orderby=popularity" />
        </section>
      </Wrapper>

      <AboutUs className="my-10" />

      <Wrapper>
        <section>
          <ContactForm />
        </section>
      </Wrapper>

      <Newsletter className="mt-10" />
    </MasterPage>
  );
}

export default Home;
