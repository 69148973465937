import { ReactNode } from 'react';

interface IProps {
  children: ReactNode;
  className?: string;
}

function Wrapper({ children, className }: IProps) {
  return (
    <div
      className={`w-full lg:mx-auto lg:max-w-screen-xl px-3 lg:px-4 box-border ${className}`}
    >
      {children}
    </div>
  );
}

Wrapper.defaultProps = {
  className: '',
};

export default Wrapper;
