import axios from 'axios';
import { axiosErrorMessage, validateRequiredFields } from '../utils';

export async function fetchAccountConfirmation(email: string, token: string) {
  validateRequiredFields([
    { name: 'email', value: email },
    { name: 'token', value: token },
  ]);

  const { REACT_APP_API_URL, REACT_APP_ORIGIN_URL } = process.env;

  return axios
    .get(`${REACT_APP_API_URL}/account-confirmation`, {
      headers: {
        'Access-Control-Allow-Origin': REACT_APP_ORIGIN_URL ?? '',
      },
      params: {
        email,
        token,
      },
    })
    .then(({ data }) => data.message)
    .catch(axiosErrorMessage);
}

export default { fetchAccountConfirmation };
