import { useNavigate } from 'react-router-dom';
import Button from '../Button';
import Wrapper from '../Wrapper';

interface IProps {
  className?: string;
}

function AboutUs({ className }: IProps) {
  const navigate = useNavigate();

  return (
    <div
      className={`w-full py-8 bg-center md:min-h-119 box-border bg-blue-100 md:bg-aboutUs ${className}`}
    >
      <Wrapper>
        <div className="flex justify-end">
          <div className="w-full md:w-7/12 lg:w-6/12 xl:w-5/12 md:mt-12">
            <span className="text-center md:text-left block text-2xl md:text-3xl md:font-light font-light leading-8 md:leading-9">
              <strong className="font-semibold">Aumente</strong> a visibilidade do seu{' '}
              <strong className="font-semibold">Buffet Infantil</strong> e transforme visitas em{' '}
              <strong className="font-semibold">futuros clientes</strong>
            </span>

            <Button
              type="button"
              size="large"
              theme="purple"
              className="w-full mt-5"
              onClick={() => navigate('/quem-somos')}
            >
              Saiba mais
            </Button>
          </div>
        </div>
      </Wrapper>
    </div>
  );
}

AboutUs.defaultProps = {
  className: '',
};

export default AboutUs;
