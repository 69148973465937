import axios from 'axios';
import { axiosErrorMessage, validateRequiredFields } from '../utils';

export async function fetchZipCode(zipCode: string) {
  validateRequiredFields([{ name: 'zipCode', value: zipCode }]);

  if (zipCode.length === 9 && zipCode.indexOf('_') === -1) {
    return axios
      .get(`https://viacep.com.br/ws/${zipCode.replace('-', '')}/json/`)
      .then(({ data }) => {
        if (data.erro) {
          throw new Error('CEP Inválido.');
        }

        return data;
      })
      .catch(axiosErrorMessage);
  }

  throw new Error('CEP Inválido');
}

export default {
  fetchZipCode,
};
