import { CaretDoubleLeft, CaretDoubleRight } from 'phosphor-react';
import { Link } from 'react-router-dom';

interface IProps {
  to: string;
}

function SeeMore({ to }: IProps) {
  return (
    <div className="w-full flex justify-center mt-8">
      <Link
        to={to}
        className="inline-flex justify-center items-center w-full sm:w-auto px-6 sm:px-12 p-1 rounded-xl bg-gray-100 text-sm shadow hover:underline no-underline text-text"
      >
        <CaretDoubleLeft size={14} className="text-text" />
        <span className="inline-flex mx-2">Ver mais</span>
        <CaretDoubleRight size={14} className="text-text" />
      </Link>
    </div>
  );
}

export default SeeMore;
