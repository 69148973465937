import { HTMLAttributes } from 'react';
import MasterPage from '../../components/MasterPage';
import Paragraph from '../../components/Paragraph';
import Subtitle from '../../components/Subtitle';
import Wrapper from '../../components/Wrapper';

function Container({ children }: HTMLAttributes<HTMLDivElement>) {
  return <div className="w-full mt-10">{children}</div>;
}

function PoliticaPrivacidade() {
  return (
    <MasterPage
      title="Política de Privacidade"
      description="A presente Política de Privacidade regulamenta o acesso e uso dos serviços
    contidos no site pelos anunciantes como titulares de dados suscetíveis
    de proteção, de acordo com as normas vigentes sobre proteção
    de dados pessoais."
    >
      <Wrapper>
        <Paragraph>
          A presente Política de Privacidade regulamenta o acesso e uso dos serviços contidos no
          site pelos anunciantes como titulares de dados suscetíveis de proteção, de acordo com as
          normas vigentes sobre proteção de dados pessoais.
        </Paragraph>

        <Container>
          <Subtitle>1. Responsável pelos dados coletados</Subtitle>
          <Paragraph>
            O anunciante será sempre responsável pelas imagens publicadas e pelos conteúdos
            cadastrados. Sem prejuízo disso, o site Kidsbuffets.com.br poderá retirar algum conteúdo
            que julgue ser ilegal.
          </Paragraph>
        </Container>

        <Container>
          <Subtitle>2. Uso de informações e dados pessoais</Subtitle>
          <Paragraph>
            Os uso de dados pessoais serão os mesmos dados que o anunciante tenha fornecido ao
            KidsBuffets.com.br ao preencher o formulário de cadastro online, através de redes
            sociais, do serviço de atendimento ao cliente ou de qualquer outro meio (chat, telefone,
            correio eletrônico).
          </Paragraph>
        </Container>

        <Container>
          <Subtitle>3. Veracidade dos dados</Subtitle>
          <Paragraph>
            Os campos assinalados com um asterisco (*) no formulário de cadastro são estritamente
            necessários para responder ao seu pedido, sendo voluntária a inclusão de dados nos
            campos restantes. O anunciante garante que os dados pessoais fornecidos ao
            KidsBuffets.com.br são verdadeiros e estão atualizados, tornando-se responsável por
            comunicar qualquer alteração dos mesmos, sendo o que anunciante é o único responsável
            pela inexatidão ou falsidade dos dados fornecidos e pelos prejuízos que possa causar ao
            KidsBuffets.com.br ou a terceiros em consequência da utilização dos serviços oferecidos
            pelo KidsBuffets.com.br. Do mesmo modo, o KidsBuffets.com.br declara que os dados
            coletados são os adequados, pertinentes e não excessivos em relação ao âmbito, às
            finalidades e aos serviços determinados.
          </Paragraph>
        </Container>

        <Container>
          <Subtitle>4. Segurança</Subtitle>
          <Paragraph>
            KidsBuffets.com.br utiliza uma camada de proteção na transmissão de dados entre seu
            computador e o servidor onde a comunicação é criptografada, aumentando
            significativamente a segurança dos dados.
          </Paragraph>

          <Paragraph>
            KidsBuffets.com.br informa aos usuários/anunciantes que tomem medidas técnicas e
            organizacionais estabelecidas por lei, que garantem a segurança dos dados pessoais,
            perda, tratamento ou acesso não autorizado, considerando o estado da tecnologia, a
            natureza dos dados armazenados e os riscos a que se encontram expostos.
          </Paragraph>
        </Container>

        <Container>
          <Subtitle>5. Cookies e IPs</Subtitle>
          <Paragraph>
            O usuário/anunciante aceita o uso de cookies e o acompanhamento de IPs.
          </Paragraph>

          <Paragraph>
            Nosso analisador de tráfico do site utiliza cookies e acompanhamentos de IPs que nos
            permitem coletar dados para fins estatísticos, tais como: data da primeira visita,
            número de vezes que foi visitado, data da última visita, URL e domínio da qual provêm,
            explorador utilizado e resolução da tela. No entanto, o usuário, se assim o desejar,
            poderá desativar e/ou eliminar estes cookies seguindo as instruções do seu navegador da
            internet.
          </Paragraph>
        </Container>
      </Wrapper>
    </MasterPage>
  );
}

export default PoliticaPrivacidade;
