/* eslint-disable no-underscore-dangle */
import TimeAgo from 'react-timeago';
import TimeAgoPTBR from 'react-timeago/lib/language-strings/pt';
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter';
import { UserCircle } from 'phosphor-react';
import { IComments } from '../../interfaces/comments';
import Paragraph from '../Paragraph';
import Rating from '../Rating';

interface IProps {
  comments: IComments[];
}

function Comments({ comments }: IProps) {
  if (comments.length > 0) {
    const formatter = buildFormatter(TimeAgoPTBR);

    return (
      <ul className="w-full flex flex-col">
        {comments.map((item: IComments) => (
          <li
            className="flex items-start last:mb-0 last:border-none mb-6 pb-6 border-b border-gray-250"
            key={item._id}
          >
            <UserCircle size={60} weight="fill" className="text-gray-500" />

            <div className="ml-3">
              <Rating count={5} value={item.rating} readonly />

              <div className="flex items-center mt-1">
                <span className="inline-flex text-text text-lg">{item.name}</span>

                <span className="inline-flex ml-2 text-gray-500 font-light text-base">
                  (<TimeAgo date={item.created_at} formatter={formatter} />)
                </span>
              </div>

              <Paragraph>{item.message}</Paragraph>
            </div>
          </li>
        ))}
      </ul>
    );
  }

  return <> </>;
}

export default Comments;
