import { useNavigate } from 'react-router-dom';
import PlanBox from '../../../components/PlanBox';
import Title from '../../../components/Title';
import Wrapper from '../../../components/Wrapper';

const plans = [
  {
    id: 1,
    active: true,
    title: 'Kids',
    price: 'GRÁTIS',
    priceCurrency: '',
    description: 'por tempo limitado',
    details: [
      'Painel administrativo',
      '100% Responsivo',
      '3 fotos por anúncio',
      'Mapa de localização',
      'Contador de visitas',
      'Publicação nas redes sociais',
      'Ferramenta de orçamento online',
      'Gestão de comentários recebidos',
      'Gestão de orçamentos recebidos',
      'Notificação de comentários recebidos',
      'Notificação de orçamentos recebidos',
    ],
    buttonText: 'Anuncie já!',
    buttonLink: '/anuncie-seu-buffet-infantil',
  },
];

function PlansSection() {
  const navigate = useNavigate();

  return (
    <section className="w-full bg-gray-250 py-10 my-10">
      <Wrapper>
        <Title>
          <strong className="font-semibold">Nossos Planos</strong>
        </Title>

        <div className="flex justify-center">
          {plans
            .filter((item) => item.active)
            .map((item) => (
              <PlanBox
                key={item.id}
                title={item.title}
                price={item.price}
                priceCurrency={item.priceCurrency}
                description={item.description}
                details={item.details}
                buttonText={item.buttonText}
                onClick={() => navigate(item.buttonLink)}
              />
            ))}
        </div>
      </Wrapper>
    </section>
  );
}

export default PlansSection;
