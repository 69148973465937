import MasterPage from '../../components/MasterPage';
import QuestionsContainer from '../../components/QuestionsContainer';
import Title from '../../components/Title';
import Wrapper from '../../components/Wrapper';

function FAQ() {
  return (
    <MasterPage
      title="FAQ"
      description="Tire todas as suas dúvidas sobre os planos e contratos do KidsBuffets e conheça todos os benefícios em anunciar conosco."
    >
      <Wrapper>
        <Title>
          <strong className="font-semibold">Perguntas Frequentes</strong>
        </Title>

        <QuestionsContainer />
      </Wrapper>
    </MasterPage>
  );
}

export default FAQ;
