import { ReactNode } from 'react';

interface IProps {
  children: ReactNode;
  className?: string;
}

function Paragraph({ children, className }: IProps) {
  return (
    <p className={`font-light md:font-light text-lg mb-6 last:mb-0 ${className || 'text-text'}`}>
      {children}
    </p>
  );
}

Paragraph.defaultProps = {
  className: '',
};

export default Paragraph;
