import { Star } from 'phosphor-react';
import { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

interface IProps {
  count: number;
  value: number;
  size?: number;
  readonly?: boolean;
  onClick?(index: number): void;
}

function Rating({ count, size, value, readonly, onClick }: IProps) {
  const [hover, setHover] = useState(0);

  return (
    <div className="flex w-full">
      {[...Array(count)].map((_, index) => {
        const i = index + 1;
        const starColor = `${i <= (hover || value) ? 'text-yellow-350' : 'text-gray-200'}`;

        if (readonly) {
          return <Star key={uuidv4()} size={size} weight="fill" className={starColor} />;
        }

        return (
          <button
            onClick={() => {
              if (onClick) onClick(i);
            }}
            onMouseEnter={() => setHover(i)}
            onMouseLeave={() => setHover(value)}
            key={uuidv4()}
          >
            <Star size={size} weight="fill" className={starColor} />
          </button>
        );
      })}
    </div>
  );
}

Rating.defaultProps = {
  size: 24,
  readonly: false,
  onClick: undefined,
};

export default Rating;
