import { ChangeEvent, FormEvent, useContext, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import queryString from 'query-string';
import AppContext from '../../context';
import { ICities } from '../../interfaces/cities';
import { ISearchQuery } from '../../interfaces/search';
import Button from '../Button';
import Label from '../Label';
import Select from '../Select';
import { buildSearchURL, orderBy } from '../../utils';

const emptyState: ISearchQuery = {
  city: '',
  district: '',
  orderby: 'popularity',
};

function Searchbar() {
  const { city: cityParam, district: districtParam } = useParams();

  const navigate = useNavigate();
  const location = useLocation();

  const q: any = location.search ? queryString.parse(location.search) : null;

  const { cities, isLoadingCities } = useContext(AppContext);

  const [state, setState] = useState<ISearchQuery>(emptyState);

  const listOfDistricts = useMemo(() => {
    let districts: any[] = [];

    if (cities.length) {
      const listOfCities = state.city ? cities.filter((c) => c.value === state.city) : cities;

      for (let i = 0; i < listOfCities.length; i += 1) {
        if (listOfCities[i].items && listOfCities[i].items.length) {
          districts = districts.concat(listOfCities[i].items);
        }
      }

      if (districts.length > 1) {
        return orderBy({ propName: 'label', order: 'ASC', arr: districts });
      }
    }

    return districts;
  }, [state.city, cities]);

  const onHandleChange = ({ target }: ChangeEvent<HTMLSelectElement>) => {
    setState((prevState) => ({
      ...prevState,
      district: target.name === 'city' ? '' : prevState.district,
      [target.name]: target.value,
    }));
  };

  const onHandleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const res = buildSearchURL({
      params: {
        orderby: state.orderby,
        page: '1',
      },
      city: state.city,
      district: state.district,
      locationSearch: location.search,
    });

    navigate(res);
  };

  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      city: cityParam ?? prevState.city,
      orderby: q?.orderby ?? prevState.orderby,
      district: districtParam ?? prevState.district,
    }));
  }, [cityParam, districtParam, q?.orderby]);

  return (
    <form
      className="w-full pb-6 border-b border-gray-250 mb-6 flex flex-wrap md:flex-nowrap"
      onSubmit={onHandleSubmit}
    >
      <fieldset className="w-full mb-2 md:mr-6 md:mb-0 md:w-auto">
        <Label className="pl-6" htmlFor="txtOrderby">
          Ordenar por:
        </Label>
        <Select name="orderby" id="txtOrderby" onChange={onHandleChange} value={state.orderby}>
          <option value="popularity">Popularidade</option>
          <option value="reviews">Recomendações</option>
          <option value="name">Nome [A-Z]</option>
        </Select>
      </fieldset>

      <fieldset className="w-full mb-2 md:mr-6 md:mb-0 md:w-auto">
        <Label className="pl-6" htmlFor="txtCity">
          Cidade:
        </Label>
        <Select name="city" id="txtCity" onChange={onHandleChange} value={state.city}>
          <option value="">Todas as cidades</option>
          {!isLoadingCities &&
            cities.length > 0 &&
            cities.map((item: ICities) => (
              <option key={item.value} value={item.value}>
                {item.label}
              </option>
            ))}
        </Select>
      </fieldset>

      <fieldset className="w-full md:mr-6 md:mb-0 md:w-auto">
        <Label className="pl-6" htmlFor="txtLocation">
          Região:
        </Label>

        <Select name="district" id="txtLocation" onChange={onHandleChange} value={state.district}>
          <option value="">Todas as regiões</option>
          {!isLoadingCities &&
            listOfDistricts.length > 1 &&
            listOfDistricts.map((item: ICities) => (
              <option key={item.value} value={item.value}>
                {item.label}
              </option>
            ))}
        </Select>
      </fieldset>

      <fieldset>
        <Label>{/* button */}</Label>
        <Button type="submit" size="large">
          Buscar
        </Button>
      </fieldset>
    </form>
  );
}

export default Searchbar;
