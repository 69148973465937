import { Ear, Handshake, Suitcase } from 'phosphor-react';
import { v4 as uuidv4 } from 'uuid';
import Title from '../../../components/Title';
import Wrapper from '../../../components/Wrapper';
import IMG_Mobile from '../../../assets/images/website-mobile.jpg';

const list = [
  {
    title: 'Eficiência',
    description:
      'Somos eficientes, confiáveis e asseguramos que você esteja preparado para o sucesso antes que nosso trabalho seja concluído.',
    icon: <Handshake size={54} weight="fill" className="text-blue-450" />,
  },
  {
    title: 'Flexibilidade',
    description:
      'Por sermos uma pequena empresa, também somos responsivos, flexíveis, ágeis e orientado para o cliente.',
    icon: <Ear size={54} weight="fill" className="text-blue-450" />,
  },
  {
    title: 'Experiência',
    description:
      'Somos profissionais experientes que não apenas atenderão, como excederão as suas expectativas.',
    icon: <Suitcase size={54} weight="fill" className="text-blue-450" />,
  },
];

function PorqueKidsBuffets() {
  return (
    <section>
      <Wrapper>
        <Title>
          Por que escolher o <strong className="font-semibold">KidsBuffets</strong>?
        </Title>

        <div className="flex flex-col-reverse flex-wrap md:grid md:grid-cols-2 md:gap-6">
          <ul className="grid grid-cols-1 gap-8 md:gap-6">
            {list.map((item) => (
              <li key={uuidv4()} className="flex items-start content-start justify-start">
                <div className="mr-4">{item.icon}</div>

                <div>
                  <h3 className="text-lg font-semibold mb-1">{item.title}</h3>
                  <p className="text-base text-black font-light md:font-light">
                    {item.description}
                  </p>
                </div>
              </li>
            ))}
          </ul>

          <figure className="mb-8 md:mb-0">
            <img src={IMG_Mobile} alt="" className="w-full object-contain" />
          </figure>
        </div>
      </Wrapper>
    </section>
  );
}

export default PorqueKidsBuffets;
