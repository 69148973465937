/* eslint-disable no-global-assign */
import React from 'react';
import { render, hydrate } from 'react-dom';
import reportWebVitals from './reportWebVitals';
import App from './app';

// EXTERNAL CSS
import 'react-lazy-load-image-component/src/effects/blur.css';

const root = document.getElementById('root');

if (process.env.REACT_APP_ENVIROMENT === 'production') {
  console = {
    ...console,
    warn: () => {},
    error: () => {},
    log: () => {},
  };
}

if (root?.hasChildNodes()) {
  if (process.env.NODE_ENV === 'development') {
    hydrate(<App />, root);
  } else {
    hydrate(
      <React.StrictMode>
        <App />
      </React.StrictMode>,
      root
    );
  }
} else if (process.env.NODE_ENV === 'development') {
  render(<App />, root);
} else {
  render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    root
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
