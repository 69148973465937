import Wrapper from '../Wrapper';

interface IProps {
  title: string;
}

function PageBanner({ title }: IProps) {
  return (
    <div className="bg-pageBanner box-border py-10">
      <Wrapper>
        <h1 className="font-light md:font-light text-3xl text-white">{title}</h1>
      </Wrapper>
    </div>
  );
}

export default PageBanner;
