import { useContext, useLayoutEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import queryString from 'query-string';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { fetchBuffets } from '../../api/buffets';
import AlertMessages from '../../components/AlertMessages';
import MasterPage from '../../components/MasterPage';
import Searchbar from '../../components/Searchbar';
import Wrapper from '../../components/Wrapper';
import CardsList from '../../components/CardsList';
import { IBuffets } from '../../interfaces/buffets';
import { buildSearchURL, reactQueryConfig } from '../../utils';
import { ISearchPageDetails } from '../../interfaces/search';
import Paginator from '../../components/Paginator';
import AppContext from '../../context';
import { ICities } from '../../interfaces/cities';

const emptySearchPageDetails: ISearchPageDetails = {
  page: 1,
  totalItems: 1,
  showing: 1,
  city: '',
  district: '',
  orderby: 'popularity',
  items: 50,
};

function BuffetsInfantis() {
  const { city: cityParam, district: districtParam } = useParams();

  const navigate = useNavigate();

  const location = useLocation();
  const q = useMemo(
    () => (location?.search ? queryString.parse(location.search) : {}),
    [location.search]
  );

  const { cities, isLoadingCities } = useContext(AppContext);

  const [pageTitle, setPageTitle] = useState('Buffets Infantis');
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [results, setResults] = useState<IBuffets[]>([]);
  const [searchPageDetails, setSearchPageDetails] =
    useState<ISearchPageDetails>(emptySearchPageDetails);

  const { isLoading, error } = useQuery(
    [
      'buffets',
      searchPageDetails.page,
      searchPageDetails.city,
      searchPageDetails.district,
      searchPageDetails.orderby,
    ],
    () => fetchBuffets(searchPageDetails),
    {
      ...reactQueryConfig,
      enabled: !isPageLoading,
      onSuccess(res) {
        setSearchPageDetails((prevState) => ({
          ...prevState,
          showing: res.showing ?? prevState.showing,
          totalItems: res.totalItems ?? prevState.totalItems,
        }));

        setResults(res.data);
      },
    }
  );

  const onHandlePageChange = (page: number) => {
    const res = buildSearchURL({
      params: {
        orderby: searchPageDetails.orderby,
        page: String(page),
      },
      city: searchPageDetails.city,
      district: searchPageDetails.district,
      locationSearch: location.search,
    });

    navigate(res);
  };

  useLayoutEffect(() => {
    const params = {
      ...q,
      city: cityParam ?? '',
      page: q.page ? Number(q.page) : 1,
      district: districtParam ?? '',
    };

    setSearchPageDetails((prevState) => ({
      ...prevState,
      ...params,
    }));

    setIsPageLoading(false);
  }, [cityParam, districtParam, q]);

  useLayoutEffect(() => {
    if (cityParam && !isLoadingCities && cities.length) {
      const title = cities.find((item: ICities) => item.value === cityParam);

      if (Object.entries(title).length) {
        setPageTitle(`Buffets Infantis em ${title.label}`);
      }
    }
  }, [cityParam, isLoadingCities, cities]);

  return (
    <MasterPage
      title={pageTitle}
      description={`Encontre os melhores ${pageTitle} para realizar a festa do seu filho (a) e solicite orçamentos online sem compromisso!`}
    >
      <Wrapper>
        <Searchbar />

        <AlertMessages
          show={!!error}
          type="error"
          message={typeof error === 'string' ? error : JSON.stringify(error)}
        />

        <CardsList isLoading={isPageLoading || isLoading} buffets={results} />

        {(!isPageLoading || !isLoading) && (
          <Paginator
            currentPage={searchPageDetails.page}
            showing={searchPageDetails.showing}
            totalItems={searchPageDetails.totalItems}
            itemsBeingShowed={searchPageDetails.items}
            onPageChange={onHandlePageChange}
          />
        )}
      </Wrapper>
    </MasterPage>
  );
}

export default BuffetsInfantis;
