/* eslint-disable no-underscore-dangle */
import { PhoneCall } from 'phosphor-react';
import { useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useQuery } from 'react-query';
import { useLocation, useParams } from 'react-router-dom';
import { fetchBuffetDetails } from '../../api/buffets';
import { fetchComments } from '../../api/comments';
import BudgetForm from '../../components/BudgetForm';
import Button from '../../components/Button';
import Carousel from '../../components/Carousel';
import Comments from '../../components/Comments';
import CommentsForm from '../../components/CommentsForm';
import GoogleMaps from '../../components/GoogleMaps';
import Lightbox from '../../components/Lightbox';
import Loader from '../../components/Loader';
import MasterPage from '../../components/MasterPage';
import Paragraph from '../../components/Paragraph';
import Rating from '../../components/Rating';
import SharePage from '../../components/SharePage';
import Subtitle from '../../components/Subtitle';
import Wrapper from '../../components/Wrapper';
import { IBuffetsDetail, IBuffetsPhotos } from '../../interfaces/buffets';
import { IComments } from '../../interfaces/comments';
import { reactQueryConfig } from '../../utils';
import NotFound from '../NotFound';

function DetalheBuffet() {
  const params = useParams();
  const location = useLocation();

  const { REACT_APP_CLOUDINARY_UPLOADS, REACT_APP_ORIGIN_URL } = process.env;

  const [showPhone, setShowPhone] = useState(false);

  const {
    data,
    isLoading: loading,
    error: detailsError,
  } = useQuery(['details', params.slug], () => fetchBuffetDetails(params.slug ?? ''), {
    ...reactQueryConfig,
    enabled: !!params?.slug,
  });

  const { data: commentsData = [], isLoading: isLoadingComments } = useQuery(
    ['comments', data?._id],
    () => fetchComments(data._id),
    {
      enabled: !!data && !!data?.commentsApproved,
      ...reactQueryConfig,
    }
  );

  if (loading) return <Loader fullScreen />;

  if (!params.slug || detailsError || !data) return <NotFound />;

  const details: IBuffetsDetail = data;
  const comments: IComments[] = commentsData;

  return (
    <MasterPage
      title={`Buffet Infantil ${details.name}`}
      description={details.description}
      ogImage={
        details.photos && details.photos[0].url
          ? `${REACT_APP_CLOUDINARY_UPLOADS}/${details.photos[0].url}`
          : `${REACT_APP_ORIGIN_URL}/favicon.png`
      }
    >
      <Wrapper>
        <div className="flex w-full flex-wrap flex-col lg:flex-row lg:flex-nowrap">
          <section className="w-full inline-flex flex-col">
            <Carousel>
              {details.photos.map((item: IBuffetsPhotos) => (
                <LazyLoadImage
                  width="100%"
                  effect="blur"
                  alt={`Buffet Infantil ${details.name}`}
                  src={`${REACT_APP_CLOUDINARY_UPLOADS}/${item.url}`}
                  wrapperClassName="lazyload"
                  className="lazyload-img max-h-125"
                  key={item.public_id}
                />
              ))}
            </Carousel>

            <div className="mt-0 flex flex-col">
              <Rating count={5} value={details.rating} readonly />

              <address className="text-text font-light md:font-light mt-2">
                {details.address}, {details.number} - {details.zipCode} - {details.district} -{' '}
                {details.city} - {details.state}
              </address>

              {details.website && (
                <div className="mt-1">
                  <a href={details.website} target="_blank" rel="noreferrer" className="link">
                    {details.website}
                  </a>
                </div>
              )}

              <div className="mt-4 flex flex-col sm:flex-row">
                <SharePage buffetName={details.name} path={location.pathname} />

                <Button
                  type="button"
                  theme="blue"
                  className="sm:ml-4 mt-4 sm:mt-0"
                  onClick={() => setShowPhone(true)}
                >
                  <PhoneCall size={20} className="text-white mr-2" weight="fill" />
                  Ver telefone
                </Button>
              </div>
            </div>
          </section>

          <section className="w-full lg:w-auto inline-flex lg:pl-8 box-border mt-8 lg:mt-0">
            <BudgetForm buffetId={details._id} />
          </section>
        </div>

        {showPhone && (
          <Lightbox
            title={`Buffet Infantil ${details.name}`}
            onClose={() => setShowPhone(false)}
            height={180}
            width={580}
          >
            <Paragraph>
              <strong>Telefone:</strong> {details.phone}
            </Paragraph>

            <Paragraph>
              <strong>WhatsApp:</strong>{' '}
              <a
                href={`https://api.whatsapp.com/send?phone=55${details.whatsapp.replace(
                  /[^+\d]+/g,
                  ''
                )}`}
                target="_blank"
                rel="noopener noreferrer"
                className="link"
              >
                {details.whatsapp}
              </a>
            </Paragraph>
          </Lightbox>
        )}

        {details.customerService && (
          <blockquote className="bg-yellow-100 box-border p-6 mt-8 rounded-lg">
            <Subtitle>Atendimento</Subtitle>
            <Paragraph>{details.customerService}</Paragraph>
          </blockquote>
        )}

        <article className="flex flex-col w-full border border-gray-250 box-border p-6 rounded-lg my-8">
          <Paragraph className="mb-6 upp">
            <strong>Capacidade Máxima: {details.capacity} pessoas</strong>
          </Paragraph>

          <Paragraph>{details.description}</Paragraph>
        </article>
      </Wrapper>

      <div className="w-full bg-gray-250 py-6 mb-8">
        <Wrapper>
          <div className="w-full overflow-hidden rounded-lg">
            <GoogleMaps address={`${details.address.split(' ').join('+')}+n+${details?.number}`} />
          </div>
        </Wrapper>
      </div>

      <Wrapper>
        <div className="w-full flex flex-col">
          <Subtitle>
            Comentários {details.commentsApproved ? `(${details.commentsApproved})` : ''}
          </Subtitle>

          {!details.commentsApproved && (
            <Paragraph className="mb-6">Seja o primeiro a comentar</Paragraph>
          )}

          <CommentsForm buffetId={details._id} />

          {!!details.commentsApproved && !isLoadingComments && (
            <div className="mt-12">
              <Comments comments={comments} />
            </div>
          )}
        </div>
      </Wrapper>
    </MasterPage>
  );
}

export default DetalheBuffet;
