export interface IFaqQuestions {
  question: string;
  answer: string;
}

export const faqQuestions = [
  {
    question: 'Quanto tempo levará para ativação do meu anúncio?',
    answer:
      'Após realizar o cadastro, você receberá um e-mail de confirmação e já terá acesso ao nosso painel administrativo para completar todas as informações do seu anúncio que terá ativação IMEDIATA.',
  },
  {
    question: 'Depois que eu anunciar, estarei preso a algum contrato?',
    answer:
      'Não! Você estará anunciando GRATUITAMENTE por tempo limitado e poderá ativar e desativar seu anúncio em tempo real pelo nosso painel administrativo. ',
  },
  {
    question:
      'Meu anúncio não está aparecendo na página inicial na seção destaques, por que?',
    answer:
      'Os buffets infantís na seção destaque da página inicial são exibidos aleatoriamente a cada refresh/acesso. Como isso funciona? Todos os nossos anunciantes possuem o plano grátis e todos devem ter a mesma oportunidade de exibição.',
  },
  {
    question:
      'Tenho mais de 1 unidade, devo criar um cadastro para cada unidade?',
    answer:
      'Não! Você deverá criar apenas um cadastro como sua unidade principal e através do nosso painel administrativo você poderá adicionar as outras unidades. Lembrando que se você deseja receber pedidos de orçamentos em e-mails diferentes, isso também é possível na tela de criação/edição do anúncio.',
  },
  {
    question:
      'Tenho mais de 1 unidade, terei apenas 1 anúncio com todas as minhas unidades ou terei um anúncio diferente para cada unidade?',
    answer: 'Você terá um anúncio diferente para cada unidade.',
  },
];

export default { faqQuestions };
