import Pagination from 'react-js-pagination';

interface IProps {
  onPageChange(page: number, itemsBeingShowed: number): void;
  currentPage?: number;
  showing: number;
  totalItems: number;
  itemsBeingShowed: number;
}

function Paginator({
  onPageChange,
  currentPage = 1,
  showing,
  totalItems,
  itemsBeingShowed,
}: IProps) {
  const showingLimit = (currentPage - 1) * itemsBeingShowed + showing;

  return (
    <div className="w-full mt-14 grid grid-cols-1 sm:grid-cols-2 gap-6 sm:gap-8">
      <div className="w-full flex">
        <div className="inline-flex content-center items-center">
          <span className="text-text text-sm">
            {'Exibindo '}
            <strong>{`${(currentPage - 1) * itemsBeingShowed + 1}`}</strong>
            {' - '}
            <strong>{showingLimit > totalItems ? totalItems : showingLimit}</strong>
          </span>
        </div>

        <div className="inline-flex content-center items-center ml-4">
          <span className="text-text text-sm">
            {'Total de '}
            <strong>{totalItems}</strong>
          </span>
        </div>
      </div>

      <div className="w-full flex justify-start sm:justify-end">
        <Pagination
          innerClass="flex justify-end list-none"
          itemClass="rounded-full overflow-hidden"
          linkClass="w-9 h-9 flex justify-center items-center content-center transition-all hover:bg-yellow-300 text-text no-underline hover:no-underline"
          activeClass="bg-yellow-300 text-text hover:no-underline"
          activePage={currentPage}
          itemsCountPerPage={itemsBeingShowed}
          totalItemsCount={totalItems}
          pageRangeDisplayed={3}
          onChange={(page: number) => onPageChange(page, itemsBeingShowed)}
          hideDisabled
        />
      </div>
    </div>
  );
}

Paginator.defaultProps = {
  currentPage: 1,
};

export default Paginator;
