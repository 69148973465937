import { ReactNode, useEffect } from 'react';
import ReactGA from 'react-ga';
import { useLocation } from 'react-router-dom';
import MainBanner from '../Banner/main';
import PageBanner from '../Banner/page';
import Footer from '../Footer';
import Head from '../Head';
import Header from '../Header';

interface IProps {
  children: ReactNode;
  title?: string;
  description?: string;
  ogImage?: string;
  showBanner?: boolean;
}

function MasterPage({ children, description, ogImage, title, showBanner }: IProps) {
  const location = useLocation();

  const { NODE_ENV } = process.env;

  useEffect(() => {
    if (NODE_ENV === 'production') {
      ReactGA.initialize('G-RVHZYJ961S');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });

    if (!!location && NODE_ENV === 'production') {
      ReactGA.pageview(location.pathname + location.search ?? '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, location.search]);

  return (
    <>
      <Head
        title={`${title ? `${title} | KidsBuffets` : 'Página Inicial | KidsBuffets'}`}
        description={description}
        ogImage={ogImage}
      />

      <div className="w-full h-full flex flex-col justify-between">
        <div className="w-full">
          <Header />

          {showBanner && (
            <div className="w-full">{title ? <PageBanner title={title} /> : <MainBanner />}</div>
          )}

          <main className={`bg-white ${title ? 'py-6' : 'pt-6'}`}>{children}</main>
        </div>

        <Footer />
      </div>
    </>
  );
}

MasterPage.defaultProps = {
  title: '',
  showBanner: true,
  description: undefined,
  ogImage: undefined,
};

export default MasterPage;
