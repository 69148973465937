import { ChangeEvent, FormEvent, HTMLAttributes, useState } from 'react';
import { useMutation } from 'react-query';
import Button from '../Button';
import Textfield from '../Textfield';
import Wrapper from '../Wrapper';
import IMG_Newsletter from '../../assets/images/newsletter-img.png';
import { fetchRegisterNewsletter } from '../../api/newsletter';
import AlertMessages from '../AlertMessages';
import Loader from '../Loader';

function Newsletter({ className }: HTMLAttributes<HTMLDivElement>) {
  const [email, setEmail] = useState('');

  const { mutate, isLoading, error, data } = useMutation(fetchRegisterNewsletter, {
    onSettled() {
      setEmail('');
    },
  });

  const onHandleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    mutate(email);
  };

  return (
    <div className={`w-full py-8 bg-blue-450 bg-newsletter ${className}`}>
      {isLoading && <Loader fullScreen />}

      <Wrapper>
        <form className="w-full flex justify-center" onSubmit={onHandleSubmit}>
          <div className="hidden sm:inline-flex mr-10">
            <img src={IMG_Newsletter} alt="" className="md:w-48 w-36 object-contain" />
          </div>

          <div className="inline-flex flex-col">
            <div className="flex flex-col mb-2 md:mb-4">
              <span className="text-3xl md:text-4xl text-white font-semibold">
                Assine nossa newsletter grátis
              </span>

              <span className="text-lg md:text-xl text-white font-light md:font-light block mt-1">
                Mantenha-se informado com atualizações e novidades!
              </span>
            </div>

            <AlertMessages show={!!data?.message} type="success" message={data?.message} />
            <AlertMessages show={!!error} type="error" message={JSON.stringify(error)} />

            <Textfield
              type="email"
              name="email"
              placeholder="digite seu e-mail"
              value={email}
              onChange={({ target }: ChangeEvent<HTMLInputElement>) => setEmail(target.value)}
              required
            />

            <div className="mt-4">
              <Button
                type="submit"
                theme="white"
                size="large"
                className="text-white"
                disabled={isLoading}
              >
                Assinar newsletter
              </Button>
            </div>
          </div>
        </form>
      </Wrapper>
    </div>
  );
}

export default Newsletter;
