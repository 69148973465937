import { TextareaHTMLAttributes } from 'react';

interface IProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  className?: string;
}

function Textarea({ className, ...rest }: IProps) {
  return (
    <textarea
      className={`w-full inline-flex text-lg font-light md:font-light box-border text-text outline-none rounded-3xl border-2 border-gray-250 bg-white transition-all hover:border-blue-450 disabled:bg-disabled py-3 px-6 placeholder:italic placeholder:lowercase resize-y min-h-14 ${className}`}
      {...rest}
    />
  );
}

Textarea.defaultProps = {
  className: '',
};

export default Textarea;
