import { Link } from 'react-router-dom';
import Wrapper from '../Wrapper';
import IMG_Logo from '../../assets/images/kidsbuffets-logo.svg';
import Nav from '../Nav';

function Header() {
  return (
    <header className="w-full bg-white relative shadow-outer">
      <Wrapper>
        <div className="w-full flex flex-wrap items-center content-center justify-center lg:justify-between relative">
          <Link to="/" className="inline-flex my-3 lg:my-0">
            <img
              src={IMG_Logo}
              alt="KidsBuffet Logotipo"
              className="lg:h-8 h-7"
            />
          </Link>

          <Nav />
        </div>
      </Wrapper>
    </header>
  );
}

export default Header;
