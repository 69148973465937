import MasterPage from '../../components/MasterPage';
import FAQSection from './sections/faq';
import PlansSection from './sections/plans';
import PorqueKidsBuffets from './sections/porqueKidsBuffets';
import BeneficiosSection from './sections/beneficiosCloud';
import VamosComecarSection from './sections/vamosComecar';

function PlanosInvestimentos() {
  return (
    <MasterPage
      title="Planos e Investimentos"
      description="Milhares de pessoas buscam por Buffets Infantís diariamente. Expanda seus negócios do jeito que você nunca imaginou."
    >
      <VamosComecarSection />
      <BeneficiosSection />
      <PorqueKidsBuffets />
      <PlansSection />
      <FAQSection />
    </MasterPage>
  );
}

export default PlanosInvestimentos;
