import { CaretDown } from 'phosphor-react';
import { SelectHTMLAttributes } from 'react';

interface IProps extends SelectHTMLAttributes<HTMLSelectElement> {
  containerClassName?: string;
  className?: string;
}

function Select({ children, className, containerClassName, ...rest }: IProps) {
  return (
    <div
      className={`w-full flex relative bg-white rounded-3xl overflow-hidden transition-all hover:border-blue-450 box-border border-2 min-h-14 items-center content-center border-gray-250 ${containerClassName}`}
    >
      <CaretDown size={24} className="absolute right-4 top-1/2 -translate-y-1/2 z-10" />

      <select
        className={`w-full relative inline-flex py-3 pl-7 pr-14 text-base font-light md:font-light bg-transparent appearance-none box-border cursor-pointer outline-none border-none transition-all duration-300 z-20  ${className}`}
        {...rest}
      >
        {children}
      </select>
    </div>
  );
}

Select.defaultProps = {
  className: '',
  containerClassName: '',
};

export default Select;
