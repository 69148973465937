import { useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link } from 'react-router-dom';
import { IBuffets } from '../../interfaces/buffets';
import Rating from '../Rating';

interface IProps {
  buffet: IBuffets;
}

function Card({ buffet }: IProps) {
  const { REACT_APP_CLOUDINARY_UPLOADS } = process.env;

  const [hover, setHover] = useState(false);

  const altText = `Buffet Infantil ${buffet.name}`;

  return (
    <Link
      className="relative cursor-pointer"
      to={`/buffet-infantil/${buffet.slug}`}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <div className="relative w-full rounded-xl overflow-hidden">
        <LazyLoadImage
          width="100%"
          effect="blur"
          alt={altText}
          src={`${REACT_APP_CLOUDINARY_UPLOADS}/c_fill,g_faces,h_260/v1/${buffet.photos[0].public_id}`}
          wrapperClassName="lazyload max-h-65 min-h-65 sm:max-h-47.5 sm:min-h-47.5"
          className="lazyload-img"
        />

        {hover && (
          <div className="w-full h-full absolute z-10 top-0 left-0 bg-black bg-opacity-90 flex justify-center items-center">
            <span className="inline-flex text-white uppercase">Ver detalhes</span>
          </div>
        )}
      </div>

      <div className="flex flex-col mt-2">
        <span className="text-base font-semibold line-clamp-1">{buffet.name}</span>
        <span className="font-light md:font-light text-sm text-gray-700 my-px line-clamp-1">
          {buffet.district} - {buffet.city} - {buffet.state}
        </span>
        <span className="mt-0.5">
          <Rating count={5} value={buffet.rating} size={24} readonly />
        </span>
      </div>
    </Link>
  );
}

export default Card;
