import { useNavigate } from 'react-router-dom';
import Button from '../../../components/Button';
import Paragraph from '../../../components/Paragraph';
import Title from '../../../components/Title';
import Wrapper from '../../../components/Wrapper';
import IMG_Investiment from '../../../assets/images/investment.jpg';

function VamosComecarSection() {
  const navigate = useNavigate();

  return (
    <Wrapper>
      <Title>
        <strong className="font-semibold">Vamos começar um negócio juntos?</strong>
      </Title>

      <div className="w-full flex flex-col-reverse md:flex-row flex-wrap md:flex-nowrap">
        <div className="inline-flex justify-center md:justify-start flex-wrap">
          <Paragraph>
            <strong className="font-semibold">Anuncie</strong> seu Buffet Infantil{' '}
            <strong className="font-semibold">GRÁTIS</strong> por tempo limitado no KidsBuffets e
            tenha seu Buffet Infantil disponível 24 horas por dia! <br />
            <br /> Receba mais visitas, pedidos de orçamentos e tenha seu Buffet Infantil
            visualizado por milhares de pessoas.
          </Paragraph>

          <div>
            <Button
              type="button"
              size="large"
              theme="orange"
              className="mt-2 w-auto h-auto"
              onClick={() => navigate('/anuncie-seu-buffet-infantil')}
            >
              Começar agora
            </Button>
          </div>
        </div>

        <figure className="md:ml-8 mb-8 md:mb-0">
          <img src={IMG_Investiment} alt="" className="object-contain w-full" />
        </figure>
      </div>
    </Wrapper>
  );
}

export default VamosComecarSection;
