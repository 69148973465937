import { X } from 'phosphor-react';
import { ReactNode, RefObject, useCallback, useEffect, useRef } from 'react';
import { useWindowSize } from '../../hooks/useWindowSize';

export interface ILightbox {
  title: string;
  height?: number | string;
  width?: number | string;
  onClose?: () => void | undefined;
  /**
   * Lightbox contents
   */
  children: ReactNode;
}

function Lightbox({ children, title, height, width, onClose }: ILightbox) {
  const [windowWidth] = useWindowSize();

  const refCloseButton: RefObject<HTMLButtonElement> = useRef(null);

  const onHandleEscape = useCallback(
    (event: KeyboardEvent) => {
      if (!!onClose && (event.key === '27' || event.key === 'Escape'))
        refCloseButton.current?.click();
    },
    [onClose]
  );

  useEffect(() => {
    document.body.classList.add('overflow-hidden');
    document.body.addEventListener('keydown', onHandleEscape);

    return () => {
      document.body.classList.remove('overflow-hidden');
      document.body.removeEventListener('keydown', onHandleEscape);
    };
  }, [onHandleEscape]);

  let objStyle = {
    maxHeight: height,
    maxWidth: width,
  };

  if (windowWidth < 640) {
    objStyle = {
      maxHeight: 'none',
      maxWidth: 'none',
    };
  }

  let onCloseProps = {};

  if (onClose) {
    onCloseProps = {
      onClick: onClose,
      onKeyDown: onClose,
      role: 'presentation',
    };
  }

  return (
    <div id="lightbox" className="h-screen w-screen fixed top-0 left-0 z-50">
      <div
        className="w-full h-full bg-black bg-opacity-80 absolute top-0 left-0 z-5"
        {...onCloseProps}
      />

      <div
        className="h-full w-full absolute z-10 bg-white sm:rounded-lg box-border px-6 pb-6 left-0 overflow-x-hidden overflow-y-auto sm:left-1/2 top-0 sm:top-1/2 sm:-translate-y-1/2 sm:-translate-x-1/2"
        style={objStyle}
        data-testid="lightbox-content"
      >
        <div className="w-full sticky z-50 pb-6 pt-6 top-0 bg-white">
          <div className="w-full relative">
            <h1 className="text-text text-xl font-semibold box-border pr-8 sm:pr-0">{title}</h1>

            {!!onClose && (
              <button
                type="button"
                ref={refCloseButton}
                onClick={onClose}
                data-testid="closeButton"
                className="absolute -right-2 sm:-top-2 -top-1 bg-white w-8 h-8 sm:w-6 sm:h-6 box-border justify-center items-center content-center rounded-full border-2 sm:border border-gray-650 text-gray-650 z-20 cursor-pointer font-medium uppercase hover:font-black hover:border-black transition-all duration-300 sm:inline-flex"
              >
                <X size={16} weight="bold" className="inline-flex" />
              </button>
            )}
          </div>
        </div>

        <div className="w-full">
          <div className="w-full h-full overflow-x-hidden overflow-y-auto">{children}</div>
        </div>
      </div>
    </div>
  );
}

Lightbox.defaultProps = {
  height: 600,
  width: 800,
  onClose: undefined,
};

export default Lightbox;
