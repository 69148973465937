import axios from 'axios';
import { IContactUs } from '../interfaces/contact';
import { axiosErrorMessage, validateRequiredFields } from '../utils';

export async function fetchContactUs({ name, email, message }: IContactUs) {
  validateRequiredFields([
    { name: 'name', value: name },
    { name: 'email', value: email },
    { name: 'message', value: message },
  ]);

  const { REACT_APP_API_URL, REACT_APP_ORIGIN_URL } = process.env;

  return axios
    .post(
      `${REACT_APP_API_URL}/contact`,
      {
        name,
        email,
        message,
      },
      {
        headers: {
          'Access-Control-Allow-Origin': REACT_APP_ORIGIN_URL ?? '',
        },
      }
    )
    .then(({ data }) => data)
    .catch(axiosErrorMessage);
}

export default {
  fetchContactUs,
};
