/* eslint-disable no-underscore-dangle */
import { IBuffets } from '../../interfaces/buffets';
import Card from '../Card';
import Loader from '../Loader';

interface IProps {
  isLoading: boolean;
  buffets: IBuffets[];
}

function CardsList({ buffets, isLoading }: IProps) {
  if (isLoading) return <Loader fullScreen={false} />;

  return (
    <ul className="w-full gap-8 sm:gap-6 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
      {buffets?.length > 0 &&
        buffets.map((item: IBuffets) => (
          <li key={item._id}>
            <Card buffet={item} />
          </li>
        ))}
    </ul>
  );
}

export default CardsList;
