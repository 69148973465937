import { createContext, HTMLAttributes, useMemo } from 'react';
import { useQuery } from 'react-query';
import { fetchFeaturedBuffets, fetchPopularBuffets } from '../api/buffets';
import { fetchCities } from '../api/cities';
import { reactQueryConfig } from '../utils';

interface IDefaultValues {
  cities: any[];
  featuredBuffets: any[];
  popularBuffets: any[];
  isLoadingCities: boolean;
  isLoadingFeaturedBuffets: boolean;
  isLoadingPopularBuffets: boolean;
}

const defaultValues: IDefaultValues = {
  cities: [],
  featuredBuffets: [],
  popularBuffets: [],
  isLoadingCities: true,
  isLoadingFeaturedBuffets: true,
  isLoadingPopularBuffets: true,
};

const AppContext = createContext(defaultValues);

export function AppProvider({ children }: HTMLAttributes<HTMLDivElement>) {
  const { data: cities = [], isLoading: isLoadingCities }: any = useQuery(
    'cities',
    fetchCities,
    reactQueryConfig
  );

  const { data: featuredBuffets = [], isLoading: isLoadingFeaturedBuffets }: any = useQuery(
    'featuredBuffets',
    fetchFeaturedBuffets,
    reactQueryConfig
  );

  const { data: popularBuffets = [], isLoading: isLoadingPopularBuffets }: any = useQuery(
    'popularBuffets',
    fetchPopularBuffets,
    reactQueryConfig
  );

  const values = useMemo(
    () => ({
      isLoadingCities,
      isLoadingFeaturedBuffets,
      isLoadingPopularBuffets,
      cities,
      popularBuffets,
      featuredBuffets,
    }),
    [
      isLoadingCities,
      isLoadingFeaturedBuffets,
      isLoadingPopularBuffets,
      cities,
      popularBuffets,
      featuredBuffets,
    ]
  );

  return <AppContext.Provider value={values}>{children}</AppContext.Provider>;
}

export const AppConsumer = AppContext.Consumer;

export default AppContext;
