import axios from 'axios';
import { axiosErrorMessage, validateRequiredFields } from '../utils';

export async function fetchRegisterNewsletter(email: string) {
  validateRequiredFields([{ name: 'email', value: email }]);

  const { REACT_APP_API_URL, REACT_APP_ORIGIN_URL } = process.env;

  return axios
    .post(
      `${REACT_APP_API_URL}/newsletter`,
      {
        email,
      },
      {
        headers: {
          'Access-Control-Allow-Origin': REACT_APP_ORIGIN_URL ?? '',
        },
      }
    )
    .then(({ data }) => data)
    .catch(axiosErrorMessage);
}

export default {
  fetchRegisterNewsletter,
};
