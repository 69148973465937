import { ReactNode } from 'react';
import Helmet from 'react-helmet';
import { useLocation } from 'react-router-dom';

interface IProps {
  title?: string;
  description?: string;
  ogImage?: string;
  children?: ReactNode;
}

function Head({ title, description, ogImage, children }: IProps) {
  const { REACT_APP_ORIGIN_URL } = process.env;

  const location = useLocation();

  const url = REACT_APP_ORIGIN_URL + location.pathname;
  const img = ogImage ?? `${REACT_APP_ORIGIN_URL}/favicon-512x512.png`;

  return (
    <Helmet>
      <meta charSet="UTF-8" />

      <title>{title}</title>
      <link rel="canonical" href={url} />

      <meta name="robots" content="index, follow" />
      <meta name="description" content={description} />
      <meta name="theme-color" content="#04a7fa" />
      <meta name="author" content="KidsBuffet" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />

      <link rel="icon" sizes="192x192" href="/favicon-192x192.png" />
      <link rel="apple-touch-icon" href="/favicon.png" />
      <link rel="mask-icon" href="/favicon.png" color="#49B882" />
      <link rel="icon" href="/favicon.ico" />
      <link rel="manifest" href="/manifest.json" />

      {/* <meta property="fb:app_id" content="" /> */}

      <meta property="og:url" content={url} />
      <meta property="og:title" content={title} />
      <meta property="og:type" content="website" />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={img} />

      <meta name="twitter:site" content={url} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:image" content={img} />

      {children && children}
    </Helmet>
  );
}

Head.defaultProps = {
  title: 'KidsBuffets',
  description:
    'Encontre os melhores Buffets Infantis para realizar a festa do seu filho (a) e solicite orçamentos online sem compromisso!',
  ogImage: undefined,
  children: undefined,
};

export default Head;
