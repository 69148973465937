import { useNavigate } from 'react-router-dom';
import MasterPage from '../../components/MasterPage';
import Wrapper from '../../components/Wrapper';
import IMG_404 from '../../assets/images/404.jpg';
import Paragraph from '../../components/Paragraph';
import Button from '../../components/Button';

function NotFound() {
  const navigate = useNavigate();

  return (
    <MasterPage
      showBanner={false}
      title="404 Página não encontrada"
      description="Talvez o conteúdo que você está tentando acessar pode ter sido removido ou substituído."
    >
      <Wrapper>
        <div className="w-full grid grid-cols-1 gap-10 sm:grid-cols-2 sm:gap-8 md:gap-12 my-6 sm:my-10">
          <figure className="w-full justify-center">
            <img
              src={IMG_404}
              alt=""
              className="w-full max-h-40 sm:max-h-64 md:max-h-max object-contain"
            />
          </figure>

          <div className="flex flex-col justify-center">
            <h1 className="text-text text-4xl mt-0 p-0 mb-4 font-semibold">OOOPS... Não chore!</h1>

            <Paragraph>
              É apenas uma página não encontrada. <br />
              Talvez o conteúdo que você está tentando acessar pode ter sido removido ou
              substituído.
            </Paragraph>

            <div className="flex sm:justify-start justify-center">
              <Button type="button" theme="purple" onClick={() => navigate('/home')}>
                Voltar para a home
              </Button>
            </div>
          </div>
        </div>
      </Wrapper>
    </MasterPage>
  );
}

export default NotFound;
