export interface IListOfDistricts {
  ibge: string;
  name: string;
}

export const listOfDistricts: IListOfDistricts[] = [
  { ibge: '110001505', name: "Alta Floresta D'Oeste" },
  { ibge: '110001515', name: "Filadélfia d'Oeste" },
  { ibge: '110001520', name: 'Izidolândia' },
  { ibge: '110001525', name: "Nova Gease d'Oeste" },
  { ibge: '110001530', name: 'Rolim de Moura do Guaporé' },
  { ibge: '110001535', name: "Santo Antônio d'Oeste" },
  { ibge: '110002305', name: 'Ariquemes' },
  { ibge: '110003105', name: 'Cabixi' },
  { ibge: '110004905', name: 'Cacoal' },
  { ibge: '110004910', name: 'Riozinho' },
  { ibge: '110005605', name: 'Cerejeiras' },
  { ibge: '110006405', name: 'Colorado do Oeste' },
  { ibge: '110007205', name: 'Corumbiara' },
  { ibge: '110008005', name: 'Costa Marques' },
  { ibge: '110008015', name: 'Príncipe da Beira' },
  { ibge: '110009805', name: "Espigão D'Oeste" },
  { ibge: '110009810', name: 'Boa Vista do Pacarana' },
  { ibge: '110009812', name: 'Novo Paraíso' },
  { ibge: '110009815', name: 'Flor da Serra' },
  { ibge: '110009818', name: 'Nova Esperança' },
  { ibge: '110010605', name: 'Guajará-Mirim' },
  { ibge: '110010612', name: 'Iata' },
  { ibge: '110010622', name: 'Surpresa' },
  { ibge: '110011405', name: 'Jaru' },
  { ibge: '110011410', name: 'Bom Jesus' },
  { ibge: '110011420', name: 'Santa Cruz da Serra' },
  { ibge: '110011425', name: 'Tarilândia' },
  { ibge: '110012205', name: 'Ji-Paraná' },
  { ibge: '110012220', name: 'Nova Colina' },
  { ibge: '110012225', name: 'Nova Londrina' },
  { ibge: '110013005', name: "Machadinho D'Oeste" },
  { ibge: '110013015', name: 'Oriente Novo' },
  { ibge: '110013020', name: 'Quinto BEC' },
  { ibge: '110013025', name: 'Tabajara' },
  { ibge: '110014805', name: "Nova Brasilândia D'Oeste" },
  { ibge: '110015505', name: 'Ouro Preto do Oeste' },
  { ibge: '110015525', name: 'Rondominas' },
  { ibge: '110018905', name: 'Pimenta Bueno' },
  { ibge: '110018915', name: 'Marco Rondon' },
  { ibge: '110020505', name: 'Porto Velho' },
  { ibge: '110020510', name: 'Abunã' },
  { ibge: '110020515', name: 'Calama' },
  { ibge: '110020516', name: 'Demarcação' },
  { ibge: '110020517', name: 'Extrema' },
  { ibge: '110020518', name: 'Fortaleza do Abunã' },
  { ibge: '110020520', name: 'Jaci Paraná' },
  { ibge: '110020525', name: 'Mutum Paraná' },
  { ibge: '110020530', name: 'Nazaré' },
  { ibge: '110020535', name: 'Nova Califórnia' },
  { ibge: '110020540', name: 'São Carlos' },
  { ibge: '110020545', name: 'Vista Alegre do Abunã' },
  { ibge: '110025405', name: 'Presidente Médici' },
  { ibge: '110025415', name: 'Estrela de Rondônia' },
  { ibge: '110025420', name: 'Novo Riachuelo' },
  { ibge: '110025425', name: 'Vila Bandeira Branca' },
  { ibge: '110025430', name: 'Vila Camargo' },
  { ibge: '110026205', name: 'Rio Crespo' },
  { ibge: '110028805', name: 'Rolim de Moura' },
  { ibge: '110029605', name: "Santa Luzia D'Oeste" },
  { ibge: '110030405', name: 'Vilhena' },
  { ibge: '110032005', name: 'São Miguel do Guaporé' },
  { ibge: '110032020', name: 'Santana do Guaporé' },
  { ibge: '110033805', name: 'Nova Mamoré' },
  { ibge: '110033810', name: 'Araras' },
  { ibge: '110033812', name: 'Jacynópolis' },
  { ibge: '110033815', name: 'Nova Dimensão' },
  { ibge: '110033820', name: 'Palmeiras' },
  { ibge: '110034605', name: "Alvorada D'Oeste" },
  { ibge: '110034625', name: 'Tancredópolis' },
  { ibge: '110034630', name: 'Terra Boa' },
  { ibge: '110037905', name: 'Alto Alegre dos Parecis' },
  { ibge: '110040305', name: 'Alto Paraíso' },
  { ibge: '110045205', name: 'Buritis' },
  { ibge: '110050205', name: 'Novo Horizonte do Oeste' },
  { ibge: '110060105', name: 'Cacaulândia' },
  { ibge: '110070005', name: 'Campo Novo de Rondônia' },
  { ibge: '110080905', name: 'Candeias do Jamari' },
  { ibge: '110080920', name: 'Rio Preto do Candeias' },
  { ibge: '110090805', name: 'Castanheiras' },
  { ibge: '110092405', name: 'Chupinguaia' },
  { ibge: '110094005', name: 'Cujubim' },
  { ibge: '110100505', name: 'Governador Jorge Teixeira' },
  { ibge: '110100515', name: 'Colina Verde' },
  { ibge: '110110405', name: 'Itapuã do Oeste' },
  { ibge: '110120305', name: 'Ministro Andreazza' },
  { ibge: '110130205', name: 'Mirante da Serra' },
  { ibge: '110140105', name: 'Monte Negro' },
  { ibge: '110143505', name: 'Nova União' },
  { ibge: '110145005', name: 'Parecis' },
  { ibge: '110146805', name: 'Pimenteiras do Oeste' },
  { ibge: '110147605', name: 'Primavera de Rondônia' },
  { ibge: '110148405', name: "São Felipe D'Oeste" },
  { ibge: '110149205', name: 'São Francisco do Guaporé' },
  { ibge: '110150005', name: 'Seringueiras' },
  { ibge: '110155905', name: 'Teixeirópolis' },
  { ibge: '110160905', name: 'Theobroma' },
  { ibge: '110170805', name: 'Urupá' },
  { ibge: '110175705', name: 'Vale do Anari' },
  { ibge: '110180705', name: 'Vale do Paraíso' },
  { ibge: '120001305', name: 'Acrelândia' },
  { ibge: '120005405', name: 'Assis Brasil' },
  { ibge: '120010405', name: 'Brasiléia' },
  { ibge: '120013805', name: 'Bujari' },
  { ibge: '120017905', name: 'Capixaba' },
  { ibge: '120020305', name: 'Cruzeiro do Sul' },
  { ibge: '120025205', name: 'Epitaciolândia' },
  { ibge: '120030205', name: 'Feijó' },
  { ibge: '120032805', name: 'Jordão' },
  { ibge: '120033605', name: 'Mâncio Lima' },
  { ibge: '120034405', name: 'Manoel Urbano' },
  { ibge: '120035105', name: 'Marechal Thaumaturgo' },
  { ibge: '120038505', name: 'Plácido de Castro' },
  { ibge: '120038510', name: 'Campinas' },
  { ibge: '120039305', name: 'Porto Walter' },
  { ibge: '120040105', name: 'Rio Branco' },
  { ibge: '120042705', name: 'Rodrigues Alves' },
  { ibge: '120043505', name: 'Santa Rosa do Purus' },
  { ibge: '120045005', name: 'Senador Guiomard' },
  { ibge: '120050005', name: 'Sena Madureira' },
  { ibge: '120060905', name: 'Tarauacá' },
  { ibge: '120070805', name: 'Xapuri' },
  { ibge: '120080705', name: 'Porto Acre' },
  { ibge: '120080720', name: 'Vila do Caquetá' },
  { ibge: '120080723', name: 'Vila do Incra' },
  { ibge: '120080725', name: 'Vila do V' },
  { ibge: '130002905', name: 'Alvarães' },
  { ibge: '130006005', name: 'Amaturá' },
  { ibge: '130008605', name: 'Anamã' },
  { ibge: '130010205', name: 'Anori' },
  { ibge: '130014405', name: 'Apuí' },
  { ibge: '130020105', name: 'Atalaia do Norte' },
  { ibge: '130030005', name: 'Autazes' },
  { ibge: '130030010', name: 'Murutinga' },
  { ibge: '130040905', name: 'Barcelos' },
  { ibge: '130050805', name: 'Barreirinha' },
  { ibge: '130050810', name: 'Ariaú' },
  { ibge: '130050812', name: 'Cametá' },
  { ibge: '130050815', name: 'Freguesia do Andirá' },
  { ibge: '130050820', name: 'Pedras' },
  { ibge: '130060705', name: 'Benjamin Constant' },
  { ibge: '130063105', name: 'Beruri' },
  { ibge: '130068005', name: 'Boa Vista do Ramos' },
  { ibge: '130068010', name: 'Lago Preto' },
  { ibge: '130068015', name: 'Massauari' },
  { ibge: '130070605', name: 'Boca do Acre' },
  { ibge: '130070610', name: 'Floriano Peixoto' },
  { ibge: '130080505', name: 'Borba' },
  { ibge: '130083905', name: 'Caapiranga' },
  { ibge: '130090405', name: 'Canutama' },
  { ibge: '130100105', name: 'Carauari' },
  { ibge: '130110005', name: 'Careiro' },
  { ibge: '130115905', name: 'Careiro da Várzea' },
  { ibge: '130120905', name: 'Coari' },
  { ibge: '130130805', name: 'Codajás' },
  { ibge: '130130810', name: 'Badajos' },
  { ibge: '130140705', name: 'Eirunepé' },
  { ibge: '130150605', name: 'Envira' },
  { ibge: '130160505', name: 'Fonte Boa' },
  { ibge: '130165405', name: 'Guajará' },
  { ibge: '130170405', name: 'Humaitá' },
  { ibge: '130180305', name: 'Ipixuna' },
  { ibge: '130185205', name: 'Iranduba' },
  { ibge: '130185210', name: 'Ariaú' },
  { ibge: '130185215', name: 'Cacau Pirêra' },
  { ibge: '130185220', name: 'Lago do Limão' },
  { ibge: '130185225', name: 'Paricatuba' },
  { ibge: '130190205', name: 'Itacoatiara' },
  { ibge: '130190210', name: 'Amatari' },
  { ibge: '130195105', name: 'Itamarati' },
  { ibge: '130200905', name: 'Itapiranga' },
  { ibge: '130210805', name: 'Japurá' },
  { ibge: '130220705', name: 'Juruá' },
  { ibge: '130230605', name: 'Jutaí' },
  { ibge: '130240505', name: 'Lábrea' },
  { ibge: '130250405', name: 'Manacapuru' },
  { ibge: '130255305', name: 'Manaquiri' },
  { ibge: '130260305', name: 'Manaus' },
  { ibge: '130270205', name: 'Manicoré' },
  { ibge: '130280105', name: 'Maraã' },
  { ibge: '130290005', name: 'Maués' },
  { ibge: '130290010', name: 'Osório da Fonseca' },
  { ibge: '130290015', name: 'Repartimento' },
  { ibge: '130300705', name: 'Nhamundá' },
  { ibge: '130310605', name: 'Nova Olinda do Norte' },
  { ibge: '130320505', name: 'Novo Airão' },
  { ibge: '130330405', name: 'Novo Aripuanã' },
  { ibge: '130340305', name: 'Parintins' },
  { ibge: '130340310', name: 'Mocambo' },
  { ibge: '130350205', name: 'Pauini' },
  { ibge: '130353605', name: 'Presidente Figueiredo' },
  { ibge: '130353610', name: 'Balbina' },
  { ibge: '130356905', name: 'Rio Preto da Eva' },
  { ibge: '130360105', name: 'Santa Isabel do Rio Negro' },
  { ibge: '130370005', name: 'Santo Antônio do Içá' },
  { ibge: '130380905', name: 'São Gabriel da Cachoeira' },
  { ibge: '130380912', name: 'Cucui' },
  { ibge: '130380915', name: 'Içana' },
  { ibge: '130380920', name: 'São Felipe' },
  { ibge: '130390805', name: 'São Paulo de Olivença' },
  { ibge: '130390815', name: 'Santa Rita' },
  { ibge: '130395705', name: 'São Sebastião do Uatumã' },
  { ibge: '130400505', name: 'Silves' },
  { ibge: '130406205', name: 'Tabatinga' },
  { ibge: '130410405', name: 'Tapauá' },
  { ibge: '130420305', name: 'Tefé' },
  { ibge: '130423705', name: 'Tonantins' },
  { ibge: '130426005', name: 'Uarini' },
  { ibge: '130430205', name: 'Urucará' },
  { ibge: '130440105', name: 'Urucurituba' },
  { ibge: '130440110', name: 'Augusto Montenegro' },
  { ibge: '140002705', name: 'Amajari' },
  { ibge: '140005005', name: 'Alto Alegre' },
  { ibge: '140010005', name: 'Boa Vista' },
  { ibge: '140015905', name: 'Bonfim' },
  { ibge: '140017505', name: 'Cantá' },
  { ibge: '140020905', name: 'Caracaraí' },
  { ibge: '140023305', name: 'Caroebe' },
  { ibge: '140028205', name: 'Iracema' },
  { ibge: '140030805', name: 'Mucajaí' },
  { ibge: '140040705', name: 'Normandia' },
  { ibge: '140045605', name: 'Pacaraima' },
  { ibge: '140047205', name: 'Rorainópolis' },
  { ibge: '140050605', name: 'São João da Baliza' },
  { ibge: '140060505', name: 'São Luiz' },
  { ibge: '140070405', name: 'Uiramutã' },
  { ibge: '150010705', name: 'Abaetetuba' },
  { ibge: '150010710', name: 'Beja' },
  { ibge: '150013105', name: 'Abel Figueiredo' },
  { ibge: '150020605', name: 'Acará' },
  { ibge: '150020610', name: 'Guajará-Miri' },
  { ibge: '150020615', name: 'Jaguarari' },
  { ibge: '150030505', name: 'Afuá' },
  { ibge: '150034705', name: 'Água Azul do Norte' },
  { ibge: '150040405', name: 'Alenquer' },
  { ibge: '150050305', name: 'Almeirim' },
  { ibge: '150050310', name: 'Arumanduba' },
  { ibge: '150050315', name: 'Monte Dourado' },
  { ibge: '150060205', name: 'Altamira' },
  { ibge: '150060207', name: 'Castelo dos Sonhos' },
  { ibge: '150070105', name: 'Anajás' },
  { ibge: '150080005', name: 'Ananindeua' },
  { ibge: '150085905', name: 'Anapu' },
  { ibge: '150090905', name: 'Augusto Corrêa' },
  { ibge: '150090910', name: 'Aturiaí' },
  { ibge: '150090915', name: 'Emboraí' },
  { ibge: '150090920', name: 'Itapixuna' },
  { ibge: '150095805', name: 'Aurora do Pará' },
  { ibge: '150100605', name: 'Aveiro' },
  { ibge: '150100610', name: 'Brasília Legal' },
  { ibge: '150100615', name: 'Pinhel' },
  { ibge: '150110505', name: 'Bagre' },
  { ibge: '150110510', name: 'Pedreira' },
  { ibge: '150120405', name: 'Baião' },
  { ibge: '150120410', name: 'Joana Peres' },
  { ibge: '150120420', name: 'São Joaquim do Ituquara' },
  { ibge: '150120430', name: 'Umarizal do Tocantins' },
  { ibge: '150125305', name: 'Bannach' },
  { ibge: '150130305', name: 'Barcarena' },
  { ibge: '150130310', name: 'Murucupi' },
  { ibge: '150140205', name: 'Belém' },
  { ibge: '150140245', name: 'Bengui' },
  { ibge: '150140250', name: 'Entroncamento' },
  { ibge: '150140255', name: 'Guamá' },
  { ibge: '150140260', name: 'Icoaraci' },
  { ibge: '150140265', name: 'Mosqueiro' },
  { ibge: '150140267', name: 'Outeiro' },
  { ibge: '150140275', name: 'Sacramenta' },
  { ibge: '150145105', name: 'Belterra' },
  { ibge: '150150105', name: 'Benevides' },
  { ibge: '150150110', name: 'Benfica' },
  { ibge: '150157605', name: 'Bom Jesus do Tocantins' },
  { ibge: '150160005', name: 'Bonito' },
  { ibge: '150170905', name: 'Bragança' },
  { ibge: '150170910', name: 'Almoço' },
  { ibge: '150170915', name: 'Caratateua' },
  { ibge: '150170920', name: 'Nova Mocajuba' },
  { ibge: '150170930', name: 'Tijoca' },
  { ibge: '150170940', name: 'Vila do Treme' },
  { ibge: '150172505', name: 'Brasil Novo' },
  { ibge: '150175805', name: 'Brejo Grande do Araguaia' },
  { ibge: '150175815', name: 'São Raimundo do Araguaia' },
  { ibge: '150178205', name: 'Breu Branco' },
  { ibge: '150180805', name: 'Breves' },
  { ibge: '150180810', name: 'Antônio Lemos' },
  { ibge: '150180815', name: 'Curumu' },
  { ibge: '150180820', name: 'São Miguel dos Macacos' },
  { ibge: '150190705', name: 'Bujaru' },
  { ibge: '150190710', name: 'Guajará-Açu' },
  { ibge: '150195605', name: 'Cachoeira do Piriá' },
  { ibge: '150200405', name: 'Cachoeira do Arari' },
  { ibge: '150210305', name: 'Cametá' },
  { ibge: '150210307', name: 'Areião' },
  { ibge: '150210310', name: 'Carapajó' },
  { ibge: '150210315', name: 'Curuçambaba' },
  { ibge: '150210320', name: 'Januacoeli' },
  { ibge: '150210325', name: 'Juaba' },
  { ibge: '150210330', name: 'Moiraba' },
  { ibge: '150210336', name: 'Torres do Cupijó' },
  { ibge: '150210340', name: 'Vila do Carmo do Tocantins' },
  { ibge: '150215205', name: 'Canaã dos Carajás' },
  { ibge: '150220205', name: 'Capanema' },
  { ibge: '150220210', name: 'Mirasselvas' },
  { ibge: '150220215', name: 'Tauari' },
  { ibge: '150230105', name: 'Capitão Poço' },
  { ibge: '150240005', name: 'Castanhal' },
  { ibge: '150240010', name: 'Apeú' },
  { ibge: '150250905', name: 'Chaves' },
  { ibge: '150250910', name: 'São Sebastião de Viçosa' },
  { ibge: '150260805', name: 'Colares' },
  { ibge: '150270705', name: 'Conceição do Araguaia' },
  { ibge: '150275605', name: 'Concórdia do Pará' },
  { ibge: '150276405', name: 'Cumaru do Norte' },
  { ibge: '150277205', name: 'Curionópolis' },
  { ibge: '150280605', name: 'Curralinho' },
  { ibge: '150280610', name: 'Piriá' },
  { ibge: '150285505', name: 'Curuá' },
  { ibge: '150290505', name: 'Curuçá' },
  { ibge: '150290520', name: 'Lauro Sodré' },
  { ibge: '150290525', name: 'Murajá' },
  { ibge: '150290540', name: 'Ponta de Ramos' },
  { ibge: '150293905', name: 'Dom Eliseu' },
  { ibge: '150295405', name: 'Eldorado dos Carajás' },
  { ibge: '150300205', name: 'Faro' },
  { ibge: '150300208', name: 'Nova Maracanã' },
  { ibge: '150304405', name: 'Floresta do Araguaia' },
  { ibge: '150307705', name: 'Garrafão do Norte' },
  { ibge: '150309305', name: 'Goianésia do Pará' },
  { ibge: '150310105', name: 'Gurupá' },
  { ibge: '150310110', name: 'Carrazedo' },
  { ibge: '150310115', name: 'Itatupã' },
  { ibge: '150320005', name: 'Igarapé-Açu' },
  { ibge: '150320010', name: 'Caripi' },
  { ibge: '150330905', name: 'Igarapé-Miri' },
  { ibge: '150330910', name: 'Maiauatá' },
  { ibge: '150340805', name: 'Inhangapi' },
  { ibge: '150345705', name: 'Ipixuna do Pará' },
  { ibge: '150350705', name: 'Irituia' },
  { ibge: '150360605', name: 'Itaituba' },
  { ibge: '150370505', name: 'Itupiranga' },
  { ibge: '150375405', name: 'Jacareacanga' },
  { ibge: '150375420', name: 'Porto Rico' },
  { ibge: '150380405', name: 'Jacundá' },
  { ibge: '150390305', name: 'Juruti' },
  { ibge: '150400005', name: 'Limoeiro do Ajuru' },
  { ibge: '150405905', name: 'Mãe do Rio' },
  { ibge: '150410905', name: 'Magalhães Barata' },
  { ibge: '150410910', name: 'Cafezal' },
  { ibge: '150420805', name: 'Marabá' },
  { ibge: '150430705', name: 'Maracanã' },
  { ibge: '150430715', name: 'Boa Esperança' },
  { ibge: '150430740', name: 'São Roberto' },
  { ibge: '150440605', name: 'Marapanim' },
  { ibge: '150440610', name: 'Marudá' },
  { ibge: '150440615', name: 'Matapiquara' },
  { ibge: '150440620', name: 'Monte Alegre do Maú' },
  { ibge: '150440622', name: 'Praia de Marudá' },
  { ibge: '150442205', name: 'Marituba' },
  { ibge: '150445505', name: 'Medicilândia' },
  { ibge: '150445535', name: 'União da Floresta' },
  { ibge: '150450505', name: 'Melgaço' },
  { ibge: '150450510', name: 'Areias' },
  { ibge: '150460405', name: 'Mocajuba' },
  { ibge: '150460415', name: 'São Pedro de Viseu' },
  { ibge: '150470305', name: 'Moju' },
  { ibge: '150470310', name: 'Cairari' },
  { ibge: '150475205', name: 'Mojuí dos Campos' },
  { ibge: '150480205', name: 'Monte Alegre' },
  { ibge: '150490105', name: 'Muaná' },
  { ibge: '150490110', name: 'São Francisco da Jararaca' },
  { ibge: '150490115', name: 'São Miguel do Pracuúba' },
  { ibge: '150495005', name: 'Nova Esperança do Piriá' },
  { ibge: '150497605', name: 'Nova Ipixuna' },
  { ibge: '150500705', name: 'Nova Timboteua' },
  { ibge: '150500710', name: 'Timboteua' },
  { ibge: '150503105', name: 'Novo Progresso' },
  { ibge: '150506405', name: 'Novo Repartimento' },
  { ibge: '150506410', name: 'Belo Monte do Pará' },
  { ibge: '150506420', name: 'Maracajá' },
  { ibge: '150506440', name: 'Vitória da Conquista' },
  { ibge: '150510605', name: 'Óbidos' },
  { ibge: '150520505', name: 'Oeiras do Pará' },
  { ibge: '150530405', name: 'Oriximiná' },
  { ibge: '150540305', name: 'Ourém' },
  { ibge: '150543705', name: 'Ourilândia do Norte' },
  { ibge: '150548605', name: 'Pacajá' },
  { ibge: '150549405', name: 'Palestina do Pará' },
  { ibge: '150550205', name: 'Paragominas' },
  { ibge: '150553605', name: 'Parauapebas' },
  { ibge: '150555105', name: "Pau D'Arco" },
  { ibge: '150560105', name: 'Peixe-Boi' },
  { ibge: '150560110', name: 'Tauarizinho' },
  { ibge: '150563505', name: 'Piçarra' },
  { ibge: '150565005', name: 'Placas' },
  { ibge: '150570005', name: 'Ponta de Pedras' },
  { ibge: '150580905', name: 'Portel' },
  { ibge: '150590805', name: 'Porto de Moz' },
  { ibge: '150590810', name: 'Veiros' },
  { ibge: '150590815', name: 'Vilarinho do Monte' },
  { ibge: '150600505', name: 'Prainha' },
  { ibge: '150600510', name: 'Pacoval' },
  { ibge: '150610405', name: 'Primavera' },
  { ibge: '150611205', name: 'Quatipuru' },
  { ibge: '150613805', name: 'Redenção' },
  { ibge: '150616105', name: 'Rio Maria' },
  { ibge: '150618705', name: 'Rondon do Pará' },
  { ibge: '150619505', name: 'Rurópolis' },
  { ibge: '150619515', name: 'Divinópolis' },
  { ibge: '150620305', name: 'Salinópolis' },
  { ibge: '150630205', name: 'Salvaterra' },
  { ibge: '150630210', name: 'Condeixa' },
  { ibge: '150630215', name: 'Joanes' },
  { ibge: '150630220', name: 'Jubim' },
  { ibge: '150630225', name: 'Monsarás' },
  { ibge: '150635105', name: 'Santa Bárbara do Pará' },
  { ibge: '150640105', name: 'Santa Cruz do Arari' },
  { ibge: '150650005', name: 'Santa Isabel do Pará' },
  { ibge: '150650010', name: 'Americano' },
  { ibge: '150650015', name: 'Caraparu' },
  { ibge: '150655905', name: 'Santa Luzia do Pará' },
  { ibge: '150658305', name: 'Santa Maria das Barreiras' },
  { ibge: '150658310', name: 'Casa de Tábua' },
  { ibge: '150658315', name: 'Nova Esperança' },
  { ibge: '150660905', name: 'Santa Maria do Pará' },
  { ibge: '150670805', name: 'Santana do Araguaia' },
  { ibge: '150680705', name: 'Santarém' },
  { ibge: '150680710', name: 'Alter do Chão' },
  { ibge: '150680725', name: 'Boim' },
  { ibge: '150680730', name: 'Curuai' },
  { ibge: '150690605', name: 'Santarém Novo' },
  { ibge: '150700305', name: 'Santo Antônio do Tauá' },
  { ibge: '150700310', name: 'Espírito Santo do Tauá' },
  { ibge: '150700315', name: 'São Raimundo de Borralhos' },
  { ibge: '150710205', name: 'São Caetano de Odivelas' },
  { ibge: '150710210', name: 'Perseverança' },
  { ibge: '150715105', name: 'São Domingos do Araguaia' },
  { ibge: '150720105', name: 'São Domingos do Capim' },
  { ibge: '150730005', name: 'São Félix do Xingu' },
  { ibge: '150730025', name: 'Taboca' },
  { ibge: '150730030', name: 'Vila Ladeira Vermelha' },
  { ibge: '150730035', name: 'Vila Lindoeste' },
  { ibge: '150730040', name: 'Vila Nereu' },
  { ibge: '150740905', name: 'São Francisco do Pará' },
  { ibge: '150745805', name: 'São Geraldo do Araguaia' },
  { ibge: '150746605', name: 'São João da Ponta' },
  { ibge: '150747405', name: 'São João de Pirabas' },
  { ibge: '150747410', name: 'Japerica' },
  { ibge: '150750805', name: 'São João do Araguaia' },
  { ibge: '150750810', name: 'Apinagés' },
  { ibge: '150760705', name: 'São Miguel do Guamá' },
  { ibge: '150760710', name: 'Caju' },
  { ibge: '150760715', name: 'Urucuri' },
  { ibge: '150760720', name: 'Urucuriteua' },
  { ibge: '150770605', name: 'São Sebastião da Boa Vista' },
  { ibge: '150775505', name: 'Sapucaia' },
  { ibge: '150780505', name: 'Senador José Porfírio' },
  { ibge: '150790405', name: 'Soure' },
  { ibge: '150795305', name: 'Tailândia' },
  { ibge: '150796105', name: 'Terra Alta' },
  { ibge: '150797905', name: 'Terra Santa' },
  { ibge: '150800105', name: 'Tomé-Açu' },
  { ibge: '150803505', name: 'Tracuateua' },
  { ibge: '150805005', name: 'Trairão' },
  { ibge: '150808405', name: 'Tucumã' },
  { ibge: '150810005', name: 'Tucuruí' },
  { ibge: '150812605', name: 'Ulianópolis' },
  { ibge: '150815905', name: 'Uruará' },
  { ibge: '150815910', name: 'Alvorada' },
  { ibge: '150820905', name: 'Vigia' },
  { ibge: '150820915', name: 'Porto Salvo' },
  { ibge: '150830805', name: 'Viseu' },
  { ibge: '150830815', name: 'Fernandes Belo' },
  { ibge: '150830820', name: 'São José do Gurupi' },
  { ibge: '150830825', name: 'São José do Piriá' },
  { ibge: '150835705', name: 'Vitória do Xingu' },
  { ibge: '150840705', name: 'Xinguara' },
  { ibge: '160005505', name: 'Serra do Navio' },
  { ibge: '160005510', name: 'Cachaço' },
  { ibge: '160010505', name: 'Amapá' },
  { ibge: '160010515', name: 'Sucuriju' },
  { ibge: '160015405', name: 'Pedra Branca do Amapari' },
  { ibge: '160020405', name: 'Calçoene' },
  { ibge: '160020410', name: 'Cunani' },
  { ibge: '160020415', name: 'Lourenço' },
  { ibge: '160021205', name: 'Cutias' },
  { ibge: '160023805', name: 'Ferreira Gomes' },
  { ibge: '160025305', name: 'Itaubal' },
  { ibge: '160027905', name: 'Laranjal do Jari' },
  { ibge: '160030305', name: 'Macapá' },
  { ibge: '160030310', name: 'Bailique' },
  { ibge: '160030311', name: 'Carapanantuba' },
  { ibge: '160030312', name: 'Fazendinha' },
  { ibge: '160030324', name: 'São Joaquim do Pacuí' },
  { ibge: '160040205', name: 'Mazagão' },
  { ibge: '160040208', name: 'Carvão' },
  { ibge: '160040215', name: 'Mazagão Velho' },
  { ibge: '160050105', name: 'Oiapoque' },
  { ibge: '160050110', name: 'Clevelândia do Norte' },
  { ibge: '160050115', name: 'Vila Velha' },
  { ibge: '160053505', name: 'Porto Grande' },
  { ibge: '160055005', name: 'Pracuúba' },
  { ibge: '160060005', name: 'Santana' },
  { ibge: '160060007', name: 'Anauerapucu' },
  { ibge: '160060010', name: 'Igarapé do Lago' },
  { ibge: '160060015', name: 'Ilha de Santana' },
  { ibge: '160060020', name: 'Piaçacá' },
  { ibge: '160060023', name: 'Pirativa' },
  { ibge: '160070905', name: 'Tartarugalzinho' },
  { ibge: '160080805', name: 'Vitória do Jari' },
  { ibge: '170025105', name: 'Abreulândia' },
  { ibge: '170030105', name: 'Aguiarnópolis' },
  { ibge: '170035005', name: 'Aliança do Tocantins' },
  { ibge: '170040005', name: 'Almas' },
  { ibge: '170070705', name: 'Alvorada' },
  { ibge: '170100205', name: 'Ananás' },
  { ibge: '170105105', name: 'Angico' },
  { ibge: '170110105', name: 'Aparecida do Rio Negro' },
  { ibge: '170130905', name: 'Aragominas' },
  { ibge: '170190305', name: 'Araguacema' },
  { ibge: '170200005', name: 'Araguaçu' },
  { ibge: '170210905', name: 'Araguaína' },
  { ibge: '170215805', name: 'Araguanã' },
  { ibge: '170220805', name: 'Araguatins' },
  { ibge: '170220810', name: 'Natal' },
  { ibge: '170230705', name: 'Arapoema' },
  { ibge: '170240605', name: 'Arraias' },
  { ibge: '170240610', name: 'Cana Brava' },
  { ibge: '170255405', name: 'Augustinópolis' },
  { ibge: '170270305', name: 'Aurora do Tocantins' },
  { ibge: '170290105', name: 'Axixá do Tocantins' },
  { ibge: '170300805', name: 'Babaçulândia' },
  { ibge: '170305705', name: 'Bandeirantes do Tocantins' },
  { ibge: '170307305', name: 'Barra do Ouro' },
  { ibge: '170310705', name: 'Barrolândia' },
  { ibge: '170320605', name: 'Bernardo Sayão' },
  { ibge: '170330505', name: 'Bom Jesus do Tocantins' },
  { ibge: '170360205', name: 'Brasilândia do Tocantins' },
  { ibge: '170370105', name: 'Brejinho de Nazaré' },
  { ibge: '170380005', name: 'Buriti do Tocantins' },
  { ibge: '170382605', name: 'Cachoeirinha' },
  { ibge: '170384205', name: 'Campos Lindos' },
  { ibge: '170386705', name: 'Cariri do Tocantins' },
  { ibge: '170388305', name: 'Carmolândia' },
  { ibge: '170389105', name: 'Carrasco Bonito' },
  { ibge: '170390905', name: 'Caseara' },
  { ibge: '170410505', name: 'Centenário' },
  { ibge: '170460005', name: 'Chapada de Areia' },
  { ibge: '170510205', name: 'Chapada da Natividade' },
  { ibge: '170550805', name: 'Colinas do Tocantins' },
  { ibge: '170555705', name: 'Combinado' },
  { ibge: '170560705', name: 'Conceição do Tocantins' },
  { ibge: '170600105', name: 'Couto Magalhães' },
  { ibge: '170610005', name: 'Cristalândia' },
  { ibge: '170625805', name: 'Crixás do Tocantins' },
  { ibge: '170650605', name: 'Darcinópolis' },
  { ibge: '170700905', name: 'Dianópolis' },
  { ibge: '170710805', name: 'Divinópolis do Tocantins' },
  { ibge: '170720705', name: 'Dois Irmãos do Tocantins' },
  { ibge: '170730605', name: 'Dueré' },
  { ibge: '170740505', name: 'Esperantina' },
  { ibge: '170755305', name: 'Fátima' },
  { ibge: '170765205', name: 'Figueirópolis' },
  { ibge: '170770205', name: 'Filadélfia' },
  { ibge: '170770210', name: 'Bielândia' },
  { ibge: '170820505', name: 'Formoso do Araguaia' },
  { ibge: '170825405', name: 'Fortaleza do Tabocão' },
  { ibge: '170830405', name: 'Goianorte' },
  { ibge: '170900505', name: 'Goiatins' },
  { ibge: '170900510', name: 'Cartucho' },
  { ibge: '170900515', name: 'Craolândia' },
  { ibge: '170930205', name: 'Guaraí' },
  { ibge: '170930210', name: 'Mirandópolis' },
  { ibge: '170950005', name: 'Gurupi' },
  { ibge: '170980705', name: 'Ipueiras' },
  { ibge: '171050805', name: 'Itacajá' },
  { ibge: '171070605', name: 'Itaguatins' },
  { ibge: '171090405', name: 'Itapiratins' },
  { ibge: '171110005', name: 'Itaporã do Tocantins' },
  { ibge: '171150605', name: 'Jaú do Tocantins' },
  { ibge: '171180305', name: 'Juarina' },
  { ibge: '171190205', name: 'Lagoa da Confusão' },
  { ibge: '171195105', name: 'Lagoa do Tocantins' },
  { ibge: '171200905', name: 'Lajeado' },
  { ibge: '171215705', name: 'Lavandeira' },
  { ibge: '171240505', name: 'Lizarda' },
  { ibge: '171240510', name: 'Alto Bonito' },
  { ibge: '171245405', name: 'Luzinópolis' },
  { ibge: '171250405', name: 'Marianópolis do Tocantins' },
  { ibge: '171270205', name: 'Mateiros' },
  { ibge: '171280105', name: 'Maurilândia do Tocantins' },
  { ibge: '171320505', name: 'Miracema do Tocantins' },
  { ibge: '171330405', name: 'Miranorte' },
  { ibge: '171360105', name: 'Monte do Carmo' },
  { ibge: '171370005', name: 'Monte Santo do Tocantins' },
  { ibge: '171380905', name: 'Palmeiras do Tocantins' },
  { ibge: '171395705', name: 'Muricilândia' },
  { ibge: '171420305', name: 'Natividade' },
  { ibge: '171420320', name: 'Príncipe' },
  { ibge: '171430205', name: 'Nazaré' },
  { ibge: '171488005', name: 'Nova Olinda' },
  { ibge: '171500205', name: 'Nova Rosalândia' },
  { ibge: '171510105', name: 'Novo Acordo' },
  { ibge: '171515005', name: 'Novo Alegre' },
  { ibge: '171525905', name: 'Novo Jardim' },
  { ibge: '171550705', name: 'Oliveira de Fátima' },
  { ibge: '171570505', name: 'Palmeirante' },
  { ibge: '171575405', name: 'Palmeirópolis' },
  { ibge: '171610905', name: 'Paraíso do Tocantins' },
  { ibge: '171620805', name: 'Paranã' },
  { ibge: '171630705', name: "Pau D'Arco" },
  { ibge: '171650505', name: 'Pedro Afonso' },
  { ibge: '171650510', name: 'Porto Real' },
  { ibge: '171660405', name: 'Peixe' },
  { ibge: '171665305', name: 'Pequizeiro' },
  { ibge: '171670305', name: 'Colméia' },
  { ibge: '171700805', name: 'Pindorama do Tocantins' },
  { ibge: '171720605', name: 'Piraquê' },
  { ibge: '171750305', name: 'Pium' },
  { ibge: '171780005', name: 'Ponte Alta do Bom Jesus' },
  { ibge: '171790905', name: 'Ponte Alta do Tocantins' },
  { ibge: '171800605', name: 'Porto Alegre do Tocantins' },
  { ibge: '171820405', name: 'Porto Nacional' },
  { ibge: '171820425', name: 'Luzimangues' },
  { ibge: '171830305', name: 'Praia Norte' },
  { ibge: '171840205', name: 'Presidente Kennedy' },
  { ibge: '171845105', name: 'Pugmil' },
  { ibge: '171850105', name: 'Recursolândia' },
  { ibge: '171855005', name: 'Riachinho' },
  { ibge: '171865905', name: 'Rio da Conceição' },
  { ibge: '171870905', name: 'Rio dos Bois' },
  { ibge: '171875805', name: 'Rio Sono' },
  { ibge: '171880805', name: 'Sampaio' },
  { ibge: '171884005', name: 'Sandolândia' },
  { ibge: '171886505', name: 'Santa Fé do Araguaia' },
  { ibge: '171888105', name: 'Santa Maria do Tocantins' },
  { ibge: '171889905', name: 'Santa Rita do Tocantins' },
  { ibge: '171890705', name: 'Santa Rosa do Tocantins' },
  { ibge: '171890710', name: 'Cangas' },
  { ibge: '171890715', name: 'Morro de São João' },
  { ibge: '171900405', name: 'Santa Tereza do Tocantins' },
  { ibge: '172000205', name: 'Santa Terezinha do Tocantins' },
  { ibge: '172010105', name: 'São Bento do Tocantins' },
  { ibge: '172015005', name: 'São Félix do Tocantins' },
  { ibge: '172020005', name: 'São Miguel do Tocantins' },
  { ibge: '172025905', name: 'São Salvador do Tocantins' },
  { ibge: '172030905', name: 'São Sebastião do Tocantins' },
  { ibge: '172049905', name: 'São Valério' },
  { ibge: '172065505', name: 'Silvanópolis' },
  { ibge: '172080405', name: 'Sítio Novo do Tocantins' },
  { ibge: '172085305', name: 'Sucupira' },
  { ibge: '172090305', name: 'Taguatinga' },
  { ibge: '172093705', name: 'Taipas do Tocantins' },
  { ibge: '172097805', name: 'Talismã' },
  { ibge: '172100005', name: 'Palmas' },
  { ibge: '172100008', name: 'Buritirana' },
  { ibge: '172100015', name: 'Taquaruçu' },
  { ibge: '172110905', name: 'Tocantínia' },
  { ibge: '172120805', name: 'Tocantinópolis' },
  { ibge: '172125705', name: 'Tupirama' },
  { ibge: '172130705', name: 'Tupiratins' },
  { ibge: '172208105', name: 'Wanderlândia' },
  { ibge: '172210705', name: 'Xambioá' },
  { ibge: '210005505', name: 'Açailândia' },
  { ibge: '210010505', name: 'Afonso Cunha' },
  { ibge: '210015405', name: 'Água Doce do Maranhão' },
  { ibge: '210020405', name: 'Alcântara' },
  { ibge: '210020410', name: 'São João de Cortes' },
  { ibge: '210030305', name: 'Aldeias Altas' },
  { ibge: '210040205', name: 'Altamira do Maranhão' },
  { ibge: '210043605', name: 'Alto Alegre do Maranhão' },
  { ibge: '210047705', name: 'Alto Alegre do Pindaré' },
  { ibge: '210050105', name: 'Alto Parnaíba' },
  { ibge: '210050110', name: 'Curupá' },
  { ibge: '210055005', name: 'Amapá do Maranhão' },
  { ibge: '210060005', name: 'Amarante do Maranhão' },
  { ibge: '210070905', name: 'Anajatuba' },
  { ibge: '210070910', name: 'Porto das Gabarras' },
  { ibge: '210080805', name: 'Anapurus' },
  { ibge: '210083205', name: 'Apicum-Açu' },
  { ibge: '210087305', name: 'Araguanã' },
  { ibge: '210090705', name: 'Araioses' },
  { ibge: '210095605', name: 'Arame' },
  { ibge: '210100405', name: 'Arari' },
  { ibge: '210100410', name: 'Bonfim do Arari' },
  { ibge: '210110305', name: 'Axixá' },
  { ibge: '210120205', name: 'Bacabal' },
  { ibge: '210125105', name: 'Bacabeira' },
  { ibge: '210130105', name: 'Bacuri' },
  { ibge: '210135005', name: 'Bacurituba' },
  { ibge: '210140005', name: 'Balsas' },
  { ibge: '210150905', name: 'Barão de Grajaú' },
  { ibge: '210160805', name: 'Barra do Corda' },
  { ibge: '210170705', name: 'Barreirinhas' },
  { ibge: '210173105', name: 'Belágua' },
  { ibge: '210177205', name: 'Bela Vista do Maranhão' },
  { ibge: '210180605', name: 'Benedito Leite' },
  { ibge: '210190505', name: 'Bequimão' },
  { ibge: '210193905', name: 'Bernardo do Mearim' },
  { ibge: '210197005', name: 'Boa Vista do Gurupi' },
  { ibge: '210200205', name: 'Bom Jardim' },
  { ibge: '210203605', name: 'Bom Jesus das Selvas' },
  { ibge: '210207705', name: 'Bom Lugar' },
  { ibge: '210210105', name: 'Brejo' },
  { ibge: '210215005', name: 'Brejo de Areia' },
  { ibge: '210220005', name: 'Buriti' },
  { ibge: '210230905', name: 'Buriti Bravo' },
  { ibge: '210230910', name: 'Bacatuba' },
  { ibge: '210232505', name: 'Buriticupu' },
  { ibge: '210235805', name: 'Buritirana' },
  { ibge: '210237405', name: 'Cachoeira Grande' },
  { ibge: '210240805', name: 'Cajapió' },
  { ibge: '210250705', name: 'Cajari' },
  { ibge: '210250710', name: 'Boa Vista do Pindaré' },
  { ibge: '210255605', name: 'Campestre do Maranhão' },
  { ibge: '210260605', name: 'Cândido Mendes' },
  { ibge: '210260610', name: 'Barão de Tromaí' },
  { ibge: '210260615', name: 'Estandarte' },
  { ibge: '210270505', name: 'Cantanhede' },
  { ibge: '210275405', name: 'Capinzal do Norte' },
  { ibge: '210280405', name: 'Carolina' },
  { ibge: '210290305', name: 'Carutapera' },
  { ibge: '210300005', name: 'Caxias' },
  { ibge: '210310905', name: 'Cedral' },
  { ibge: '210312505', name: 'Central do Maranhão' },
  { ibge: '210315805', name: 'Centro do Guilherme' },
  { ibge: '210317405', name: 'Centro Novo do Maranhão' },
  { ibge: '210320805', name: 'Chapadinha' },
  { ibge: '210325705', name: 'Cidelândia' },
  { ibge: '210330705', name: 'Codó' },
  { ibge: '210330710', name: 'Codozinho' },
  { ibge: '210330715', name: 'São Raimundo de Codó' },
  { ibge: '210340605', name: 'Coelho Neto' },
  { ibge: '210350505', name: 'Colinas' },
  { ibge: '210355405', name: 'Conceição do Lago-Açu' },
  { ibge: '210360405', name: 'Coroatá' },
  { ibge: '210370305', name: 'Cururupu' },
  { ibge: '210375205', name: 'Davinópolis' },
  { ibge: '210380205', name: 'Dom Pedro' },
  { ibge: '210390105', name: 'Duque Bacelar' },
  { ibge: '210400805', name: 'Esperantinópolis' },
  { ibge: '210405705', name: 'Estreito' },
  { ibge: '210407305', name: 'Feira Nova do Maranhão' },
  { ibge: '210408105', name: 'Fernando Falcão' },
  { ibge: '210409905', name: 'Formosa da Serra Negra' },
  { ibge: '210410705', name: 'Fortaleza dos Nogueiras' },
  { ibge: '210420605', name: 'Fortuna' },
  { ibge: '210430505', name: 'Godofredo Viana' },
  { ibge: '210430510', name: 'Aurizona' },
  { ibge: '210440405', name: 'Gonçalves Dias' },
  { ibge: '210450305', name: 'Governador Archer' },
  { ibge: '210455205', name: 'Governador Edison Lobão' },
  { ibge: '210460205', name: 'Governador Eugênio Barros' },
  { ibge: '210462805', name: 'Governador Luiz Rocha' },
  { ibge: '210465105', name: 'Governador Newton Bello' },
  { ibge: '210467705', name: 'Governador Nunes Freire' },
  { ibge: '210470105', name: 'Graça Aranha' },
  { ibge: '210480005', name: 'Grajaú' },
  { ibge: '210490905', name: 'Guimarães' },
  { ibge: '210500505', name: 'Humberto de Campos' },
  { ibge: '210510405', name: 'Icatu' },
  { ibge: '210510410', name: 'Itapera' },
  { ibge: '210515305', name: 'Igarapé do Meio' },
  { ibge: '210520305', name: 'Igarapé Grande' },
  { ibge: '210530205', name: 'Imperatriz' },
  { ibge: '210535105', name: 'Itaipava do Grajaú' },
  { ibge: '210540105', name: 'Itapecuru Mirim' },
  { ibge: '210542705', name: 'Itinga do Maranhão' },
  { ibge: '210545005', name: 'Jatobá' },
  { ibge: '210547605', name: 'Jenipapo dos Vieiras' },
  { ibge: '210550005', name: 'João Lisboa' },
  { ibge: '210560905', name: 'Joselândia' },
  { ibge: '210565805', name: 'Junco do Maranhão' },
  { ibge: '210570805', name: 'Lago da Pedra' },
  { ibge: '210580705', name: 'Lago do Junco' },
  { ibge: '210590605', name: 'Lago Verde' },
  { ibge: '210592205', name: 'Lagoa do Mato' },
  { ibge: '210594805', name: 'Lago dos Rodrigues' },
  { ibge: '210596305', name: 'Lagoa Grande do Maranhão' },
  { ibge: '210598905', name: 'Lajeado Novo' },
  { ibge: '210600305', name: 'Lima Campos' },
  { ibge: '210610205', name: 'Loreto' },
  { ibge: '210620105', name: 'Luís Domingues' },
  { ibge: '210630005', name: 'Magalhães de Almeida' },
  { ibge: '210630010', name: 'Custódio Lima' },
  { ibge: '210632605', name: 'Maracaçumé' },
  { ibge: '210635905', name: 'Marajá do Sena' },
  { ibge: '210637505', name: 'Maranhãozinho' },
  { ibge: '210640905', name: 'Mata Roma' },
  { ibge: '210650805', name: 'Matinha' },
  { ibge: '210660705', name: 'Matões' },
  { ibge: '210663105', name: 'Matões do Norte' },
  { ibge: '210667205', name: 'Milagres do Maranhão' },
  { ibge: '210670605', name: 'Mirador' },
  { ibge: '210670610', name: 'Ibipira' },
  { ibge: '210675505', name: 'Miranda do Norte' },
  { ibge: '210680505', name: 'Mirinzal' },
  { ibge: '210690405', name: 'Monção' },
  { ibge: '210700105', name: 'Montes Altos' },
  { ibge: '210710005', name: 'Morros' },
  { ibge: '210720905', name: 'Nina Rodrigues' },
  { ibge: '210725805', name: 'Nova Colinas' },
  { ibge: '210730805', name: 'Nova Iorque' },
  { ibge: '210735705', name: 'Nova Olinda do Maranhão' },
  { ibge: '210740705', name: "Olho d'Água das Cunhãs" },
  { ibge: '210745605', name: 'Olinda Nova do Maranhão' },
  { ibge: '210750605', name: 'Paço do Lumiar' },
  { ibge: '210760505', name: 'Palmeirândia' },
  { ibge: '210770405', name: 'Paraibano' },
  { ibge: '210780305', name: 'Parnarama' },
  { ibge: '210780310', name: 'Brejo de São Félix' },
  { ibge: '210790205', name: 'Passagem Franca' },
  { ibge: '210800905', name: 'Pastos Bons' },
  { ibge: '210800910', name: 'Roçado' },
  { ibge: '210805805', name: 'Paulino Neves' },
  { ibge: '210810805', name: 'Paulo Ramos' },
  { ibge: '210820705', name: 'Pedreiras' },
  { ibge: '210820710', name: 'Marianópolis' },
  { ibge: '210825605', name: 'Pedro do Rosário' },
  { ibge: '210830605', name: 'Penalva' },
  { ibge: '210840505', name: 'Peri Mirim' },
  { ibge: '210845405', name: 'Peritoró' },
  { ibge: '210850405', name: 'Pindaré-Mirim' },
  { ibge: '210850410', name: 'Pimentel' },
  { ibge: '210860305', name: 'Pinheiro' },
  { ibge: '210860310', name: 'Roque' },
  { ibge: '210870205', name: 'Pio XII' },
  { ibge: '210880105', name: 'Pirapemas' },
  { ibge: '210890005', name: 'Poção de Pedras' },
  { ibge: '210900705', name: 'Porto Franco' },
  { ibge: '210905605', name: 'Porto Rico do Maranhão' },
  { ibge: '210910605', name: 'Presidente Dutra' },
  { ibge: '210920505', name: 'Presidente Juscelino' },
  { ibge: '210923905', name: 'Presidente Médici' },
  { ibge: '210927005', name: 'Presidente Sarney' },
  { ibge: '210930405', name: 'Presidente Vargas' },
  { ibge: '210940305', name: 'Primeira Cruz' },
  { ibge: '210945205', name: 'Raposa' },
  { ibge: '210950205', name: 'Riachão' },
  { ibge: '210955105', name: 'Ribamar Fiquene' },
  { ibge: '210960105', name: 'Rosário' },
  { ibge: '210970005', name: 'Sambaíba' },
  { ibge: '210975905', name: 'Santa Filomena do Maranhão' },
  { ibge: '210980905', name: 'Santa Helena' },
  { ibge: '210980910', name: 'Curva Grande' },
  { ibge: '210990805', name: 'Santa Inês' },
  { ibge: '211000505', name: 'Santa Luzia' },
  { ibge: '211003905', name: 'Santa Luzia do Paruá' },
  { ibge: '211010405', name: 'Santa Quitéria do Maranhão' },
  { ibge: '211020305', name: 'Santa Rita' },
  { ibge: '211023705', name: 'Santana do Maranhão' },
  { ibge: '211027805', name: 'Santo Amaro do Maranhão' },
  { ibge: '211030205', name: 'Santo Antônio dos Lopes' },
  { ibge: '211040105', name: 'São Benedito do Rio Preto' },
  { ibge: '211050005', name: 'São Bento' },
  { ibge: '211060905', name: 'São Bernardo' },
  { ibge: '211065805', name: 'São Domingos do Azeitão' },
  { ibge: '211070805', name: 'São Domingos do Maranhão' },
  { ibge: '211080705', name: 'São Félix de Balsas' },
  { ibge: '211085605', name: 'São Francisco do Brejão' },
  { ibge: '211090605', name: 'São Francisco do Maranhão' },
  { ibge: '211090610', name: 'Caraíba do Norte' },
  { ibge: '211090615', name: 'Ribeirão Azul' },
  { ibge: '211100305', name: 'São João Batista' },
  { ibge: '211102905', name: 'São João do Carú' },
  { ibge: '211105205', name: 'São João do Paraíso' },
  { ibge: '211107805', name: 'São João do Soter' },
  { ibge: '211110205', name: 'São João dos Patos' },
  { ibge: '211120105', name: 'São José de Ribamar' },
  { ibge: '211120110', name: 'Mata' },
  { ibge: '211125005', name: 'São José dos Basílios' },
  { ibge: '211130005', name: 'São Luís' },
  { ibge: '211140905', name: 'São Luís Gonzaga do Maranhão' },
  { ibge: '211150805', name: 'São Mateus do Maranhão' },
  { ibge: '211153205', name: 'São Pedro da Água Branca' },
  { ibge: '211157305', name: 'São Pedro dos Crentes' },
  { ibge: '211160705', name: 'São Raimundo das Mangabeiras' },
  { ibge: '211163105', name: 'São Raimundo do Doca Bezerra' },
  { ibge: '211167205', name: 'São Roberto' },
  { ibge: '211170605', name: 'São Vicente Ferrer' },
  { ibge: '211172205', name: 'Satubinha' },
  { ibge: '211174805', name: 'Senador Alexandre Costa' },
  { ibge: '211176305', name: 'Senador La Rocque' },
  { ibge: '211178905', name: 'Serrano do Maranhão' },
  { ibge: '211180505', name: 'Sítio Novo' },
  { ibge: '211190405', name: 'Sucupira do Norte' },
  { ibge: '211195305', name: 'Sucupira do Riachão' },
  { ibge: '211200105', name: 'Tasso Fragoso' },
  { ibge: '211210005', name: 'Timbiras' },
  { ibge: '211220905', name: 'Timon' },
  { ibge: '211220910', name: 'Buriti Cortado' },
  { ibge: '211223305', name: 'Trizidela do Vale' },
  { ibge: '211227405', name: 'Tufilândia' },
  { ibge: '211230805', name: 'Tuntum' },
  { ibge: '211230810', name: 'São Joaquim dos Melos' },
  { ibge: '211240705', name: 'Turiaçu' },
  { ibge: '211245605', name: 'Turilândia' },
  { ibge: '211250605', name: 'Tutóia' },
  { ibge: '211250610', name: 'Barro Duro' },
  { ibge: '211260505', name: 'Urbano Santos' },
  { ibge: '211270405', name: 'Vargem Grande' },
  { ibge: '211280305', name: 'Viana' },
  { ibge: '211285205', name: 'Vila Nova dos Martírios' },
  { ibge: '211290205', name: 'Vitória do Mearim' },
  { ibge: '211300905', name: 'Vitorino Freire' },
  { ibge: '211400705', name: 'Zé Doca' },
  { ibge: '220005305', name: 'Acauã' },
  { ibge: '220010305', name: 'Agricolândia' },
  { ibge: '220020205', name: 'Água Branca' },
  { ibge: '220025105', name: 'Alagoinha do Piauí' },
  { ibge: '220027705', name: 'Alegrete do Piauí' },
  { ibge: '220030105', name: 'Alto Longá' },
  { ibge: '220040005', name: 'Altos' },
  { ibge: '220045905', name: 'Alvorada do Gurguéia' },
  { ibge: '220050905', name: 'Amarante' },
  { ibge: '220060805', name: 'Angical do Piauí' },
  { ibge: '220070705', name: 'Anísio de Abreu' },
  { ibge: '220080605', name: 'Antônio Almeida' },
  { ibge: '220090505', name: 'Aroazes' },
  { ibge: '220095405', name: 'Aroeiras do Itaim' },
  { ibge: '220100205', name: 'Arraial' },
  { ibge: '220105105', name: 'Assunção do Piauí' },
  { ibge: '220110105', name: 'Avelino Lopes' },
  { ibge: '220115005', name: 'Baixa Grande do Ribeiro' },
  { ibge: '220117605', name: "Barra D'Alcântara" },
  { ibge: '220120005', name: 'Barras' },
  { ibge: '220130905', name: 'Barreiras do Piauí' },
  { ibge: '220140805', name: 'Barro Duro' },
  { ibge: '220150705', name: 'Batalha' },
  { ibge: '220155605', name: 'Bela Vista do Piauí' },
  { ibge: '220157205', name: 'Belém do Piauí' },
  { ibge: '220160605', name: 'Beneditinos' },
  { ibge: '220170505', name: 'Bertolínia' },
  { ibge: '220173905', name: 'Betânia do Piauí' },
  { ibge: '220177005', name: 'Boa Hora' },
  { ibge: '220180405', name: 'Bocaina' },
  { ibge: '220190305', name: 'Bom Jesus' },
  { ibge: '220191905', name: 'Bom Princípio do Piauí' },
  { ibge: '220192905', name: 'Bonfim do Piauí' },
  { ibge: '220194505', name: 'Boqueirão do Piauí' },
  { ibge: '220196005', name: 'Brasileira' },
  { ibge: '220198805', name: 'Brejo do Piauí' },
  { ibge: '220200005', name: 'Buriti dos Lopes' },
  { ibge: '220202605', name: 'Buriti dos Montes' },
  { ibge: '220205905', name: 'Cabeceiras do Piauí' },
  { ibge: '220207505', name: 'Cajazeiras do Piauí' },
  { ibge: '220208305', name: 'Cajueiro da Praia' },
  { ibge: '220209105', name: 'Caldeirão Grande do Piauí' },
  { ibge: '220210905', name: 'Campinas do Piauí' },
  { ibge: '220211705', name: 'Campo Alegre do Fidalgo' },
  { ibge: '220213305', name: 'Campo Grande do Piauí' },
  { ibge: '220217405', name: 'Campo Largo do Piauí' },
  { ibge: '220220805', name: 'Campo Maior' },
  { ibge: '220225105', name: 'Canavieira' },
  { ibge: '220230705', name: 'Canto do Buriti' },
  { ibge: '220240605', name: 'Capitão de Campos' },
  { ibge: '220245505', name: 'Capitão Gervásio Oliveira' },
  { ibge: '220250505', name: 'Caracol' },
  { ibge: '220253905', name: 'Caraúbas do Piauí' },
  { ibge: '220255405', name: 'Caridade do Piauí' },
  { ibge: '220260405', name: 'Castelo do Piauí' },
  { ibge: '220265305', name: 'Caxingó' },
  { ibge: '220270305', name: 'Cocal' },
  { ibge: '220271105', name: 'Cocal de Telha' },
  { ibge: '220272905', name: 'Cocal dos Alves' },
  { ibge: '220273705', name: 'Coivaras' },
  { ibge: '220275205', name: 'Colônia do Gurguéia' },
  { ibge: '220277805', name: 'Colônia do Piauí' },
  { ibge: '220280205', name: 'Conceição do Canindé' },
  { ibge: '220285105', name: 'Coronel José Dias' },
  { ibge: '220290105', name: 'Corrente' },
  { ibge: '220300805', name: 'Cristalândia do Piauí' },
  { ibge: '220310705', name: 'Cristino Castro' },
  { ibge: '220320605', name: 'Curimatá' },
  { ibge: '220323005', name: 'Currais' },
  { ibge: '220325505', name: 'Curralinhos' },
  { ibge: '220327105', name: 'Curral Novo do Piauí' },
  { ibge: '220330505', name: 'Demerval Lobão' },
  { ibge: '220335405', name: 'Dirceu Arcoverde' },
  { ibge: '220340405', name: 'Dom Expedito Lopes' },
  { ibge: '220342005', name: 'Domingos Mourão' },
  { ibge: '220345305', name: 'Dom Inocêncio' },
  { ibge: '220350305', name: 'Elesbão Veloso' },
  { ibge: '220360205', name: 'Eliseu Martins' },
  { ibge: '220370105', name: 'Esperantina' },
  { ibge: '220375005', name: 'Fartura do Piauí' },
  { ibge: '220380005', name: 'Flores do Piauí' },
  { ibge: '220385905', name: 'Floresta do Piauí' },
  { ibge: '220390905', name: 'Floriano' },
  { ibge: '220400605', name: 'Francinópolis' },
  { ibge: '220410505', name: 'Francisco Ayres' },
  { ibge: '220415405', name: 'Francisco Macedo' },
  { ibge: '220420405', name: 'Francisco Santos' },
  { ibge: '220430305', name: 'Fronteiras' },
  { ibge: '220435205', name: 'Geminiano' },
  { ibge: '220440205', name: 'Gilbués' },
  { ibge: '220450105', name: 'Guadalupe' },
  { ibge: '220455005', name: 'Guaribas' },
  { ibge: '220460005', name: 'Hugo Napoleão' },
  { ibge: '220465905', name: 'Ilha Grande' },
  { ibge: '220470905', name: 'Inhuma' },
  { ibge: '220480805', name: 'Ipiranga do Piauí' },
  { ibge: '220490705', name: 'Isaías Coelho' },
  { ibge: '220500305', name: 'Itainópolis' },
  { ibge: '220510205', name: 'Itaueira' },
  { ibge: '220515105', name: 'Jacobina do Piauí' },
  { ibge: '220520105', name: 'Jaicós' },
  { ibge: '220525005', name: 'Jardim do Mulato' },
  { ibge: '220527605', name: 'Jatobá do Piauí' },
  { ibge: '220530005', name: 'Jerumenha' },
  { ibge: '220535905', name: 'João Costa' },
  { ibge: '220540905', name: 'Joaquim Pires' },
  { ibge: '220545805', name: 'Joca Marques' },
  { ibge: '220550805', name: 'José de Freitas' },
  { ibge: '220551605', name: 'Juazeiro do Piauí' },
  { ibge: '220552405', name: 'Júlio Borges' },
  { ibge: '220553205', name: 'Jurema' },
  { ibge: '220554005', name: 'Lagoinha do Piauí' },
  { ibge: '220555705', name: 'Lagoa Alegre' },
  { ibge: '220556505', name: 'Lagoa do Barro do Piauí' },
  { ibge: '220557305', name: 'Lagoa de São Francisco' },
  { ibge: '220558105', name: 'Lagoa do Piauí' },
  { ibge: '220559905', name: 'Lagoa do Sítio' },
  { ibge: '220560705', name: 'Landri Sales' },
  { ibge: '220570605', name: 'Luís Correia' },
  { ibge: '220580505', name: 'Luzilândia' },
  { ibge: '220585405', name: 'Madeiro' },
  { ibge: '220590405', name: 'Manoel Emídio' },
  { ibge: '220595305', name: 'Marcolândia' },
  { ibge: '220600105', name: 'Marcos Parente' },
  { ibge: '220605005', name: 'Massapê do Piauí' },
  { ibge: '220610005', name: 'Matias Olímpio' },
  { ibge: '220620905', name: 'Miguel Alves' },
  { ibge: '220630805', name: 'Miguel Leão' },
  { ibge: '220635705', name: 'Milton Brandão' },
  { ibge: '220640705', name: 'Monsenhor Gil' },
  { ibge: '220650605', name: 'Monsenhor Hipólito' },
  { ibge: '220660505', name: 'Monte Alegre do Piauí' },
  { ibge: '220665405', name: 'Morro Cabeça no Tempo' },
  { ibge: '220667005', name: 'Morro do Chapéu do Piauí' },
  { ibge: '220669605', name: 'Murici dos Portelas' },
  { ibge: '220670405', name: 'Nazaré do Piauí' },
  { ibge: '220672005', name: 'Nazária' },
  { ibge: '220675305', name: 'Nossa Senhora de Nazaré' },
  { ibge: '220680305', name: 'Nossa Senhora dos Remédios' },
  { ibge: '220690205', name: 'Novo Oriente do Piauí' },
  { ibge: '220695105', name: 'Novo Santo Antônio' },
  { ibge: '220700905', name: 'Oeiras' },
  { ibge: '220710805', name: "Olho D'Água do Piauí" },
  { ibge: '220720705', name: 'Padre Marcos' },
  { ibge: '220730605', name: 'Paes Landim' },
  { ibge: '220735505', name: 'Pajeú do Piauí' },
  { ibge: '220740505', name: 'Palmeira do Piauí' },
  { ibge: '220750405', name: 'Palmeirais' },
  { ibge: '220755305', name: 'Paquetá' },
  { ibge: '220760305', name: 'Parnaguá' },
  { ibge: '220770205', name: 'Parnaíba' },
  { ibge: '220775105', name: 'Passagem Franca do Piauí' },
  { ibge: '220777705', name: 'Patos do Piauí' },
  { ibge: '220779305', name: "Pau D'Arco do Piauí" },
  { ibge: '220780105', name: 'Paulistana' },
  { ibge: '220785005', name: 'Pavussu' },
  { ibge: '220790005', name: 'Pedro II' },
  { ibge: '220793405', name: 'Pedro Laurentino' },
  { ibge: '220795905', name: 'Nova Santa Rita' },
  { ibge: '220800705', name: 'Picos' },
  { ibge: '220810605', name: 'Pimenteiras' },
  { ibge: '220820505', name: 'Pio IX' },
  { ibge: '220830405', name: 'Piracuruca' },
  { ibge: '220840305', name: 'Piripiri' },
  { ibge: '220850205', name: 'Porto' },
  { ibge: '220855105', name: 'Porto Alegre do Piauí' },
  { ibge: '220860105', name: 'Prata do Piauí' },
  { ibge: '220865005', name: 'Queimada Nova' },
  { ibge: '220870005', name: 'Redenção do Gurguéia' },
  { ibge: '220880905', name: 'Regeneração' },
  { ibge: '220885805', name: 'Riacho Frio' },
  { ibge: '220887405', name: 'Ribeira do Piauí' },
  { ibge: '220890805', name: 'Ribeiro Gonçalves' },
  { ibge: '220900505', name: 'Rio Grande do Piauí' },
  { ibge: '220910405', name: 'Santa Cruz do Piauí' },
  { ibge: '220915305', name: 'Santa Cruz dos Milagres' },
  { ibge: '220920305', name: 'Santa Filomena' },
  { ibge: '220930205', name: 'Santa Luz' },
  { ibge: '220935105', name: 'Santana do Piauí' },
  { ibge: '220937705', name: 'Santa Rosa do Piauí' },
  { ibge: '220940105', name: 'Santo Antônio de Lisboa' },
  { ibge: '220945005', name: 'Santo Antônio dos Milagres' },
  { ibge: '220950005', name: 'Santo Inácio do Piauí' },
  { ibge: '220955905', name: 'São Braz do Piauí' },
  { ibge: '220960905', name: 'São Félix do Piauí' },
  { ibge: '220965805', name: 'São Francisco de Assis do Piauí' },
  { ibge: '220970805', name: 'São Francisco do Piauí' },
  { ibge: '220975705', name: 'São Gonçalo do Gurguéia' },
  { ibge: '220980705', name: 'São Gonçalo do Piauí' },
  { ibge: '220985605', name: 'São João da Canabrava' },
  { ibge: '220987205', name: 'São João da Fronteira' },
  { ibge: '220990605', name: 'São João da Serra' },
  { ibge: '220995505', name: 'São João da Varjota' },
  { ibge: '220997105', name: 'São João do Arraial' },
  { ibge: '221000305', name: 'São João do Piauí' },
  { ibge: '221005205', name: 'São José do Divino' },
  { ibge: '221010205', name: 'São José do Peixe' },
  { ibge: '221020105', name: 'São José do Piauí' },
  { ibge: '221030005', name: 'São Julião' },
  { ibge: '221035905', name: 'São Lourenço do Piauí' },
  { ibge: '221037505', name: 'São Luis do Piauí' },
  { ibge: '221038305', name: 'São Miguel da Baixa Grande' },
  { ibge: '221039105', name: 'São Miguel do Fidalgo' },
  { ibge: '221040905', name: 'São Miguel do Tapuio' },
  { ibge: '221050805', name: 'São Pedro do Piauí' },
  { ibge: '221060705', name: 'São Raimundo Nonato' },
  { ibge: '221062305', name: 'Sebastião Barros' },
  { ibge: '221063105', name: 'Sebastião Leal' },
  { ibge: '221065605', name: 'Sigefredo Pacheco' },
  { ibge: '221070605', name: 'Simões' },
  { ibge: '221080505', name: 'Simplício Mendes' },
  { ibge: '221090405', name: 'Socorro do Piauí' },
  { ibge: '221093805', name: 'Sussuapara' },
  { ibge: '221095305', name: 'Tamboril do Piauí' },
  { ibge: '221097905', name: 'Tanque do Piauí' },
  { ibge: '221100105', name: 'Teresina' },
  { ibge: '221110005', name: 'União' },
  { ibge: '221120905', name: 'Uruçuí' },
  { ibge: '221130805', name: 'Valença do Piauí' },
  { ibge: '221135705', name: 'Várzea Branca' },
  { ibge: '221140705', name: 'Várzea Grande' },
  { ibge: '221150605', name: 'Vera Mendes' },
  { ibge: '221160505', name: 'Vila Nova do Piauí' },
  { ibge: '221170405', name: 'Wall Ferraz' },
  { ibge: '230010105', name: 'Abaiara' },
  { ibge: '230010120', name: 'São José' },
  { ibge: '230015005', name: 'Acarape' },
  { ibge: '230020005', name: 'Acaraú' },
  { ibge: '230020010', name: 'Aranaú' },
  { ibge: '230020030', name: 'Juritianha' },
  { ibge: '230020040', name: 'Lagoa do Carneiro' },
  { ibge: '230030905', name: 'Acopiara' },
  { ibge: '230030907', name: 'Barra do Ingá' },
  { ibge: '230030910', name: 'Ebron' },
  { ibge: '230030915', name: 'Isidoro' },
  { ibge: '230030920', name: 'Quincoê' },
  { ibge: '230030925', name: 'Santa Felícia' },
  { ibge: '230030930', name: 'Santo Antônio' },
  { ibge: '230030931', name: 'São Paulinho' },
  { ibge: '230030933', name: 'Solidão' },
  { ibge: '230030935', name: 'Trussu' },
  { ibge: '230040805', name: 'Aiuaba' },
  { ibge: '230040810', name: 'Barra' },
  { ibge: '230050705', name: 'Alcântaras' },
  { ibge: '230050710', name: 'Ventura' },
  { ibge: '230060605', name: 'Altaneira' },
  { ibge: '230060610', name: 'São Romão' },
  { ibge: '230070505', name: 'Alto Santo' },
  { ibge: '230070508', name: 'Baixio Grande' },
  { ibge: '230070510', name: 'Castanhão' },
  { ibge: '230070512', name: 'Batoque' },
  { ibge: '230070515', name: 'Boa Fé' },
  { ibge: '230070520', name: 'Bom Jesus' },
  { ibge: '230070525', name: 'Cabrito' },
  { ibge: '230075405', name: 'Amontada' },
  { ibge: '230075410', name: 'Aracatiara' },
  { ibge: '230075412', name: 'Garças' },
  { ibge: '230075415', name: 'Icaraí' },
  { ibge: '230075420', name: 'Lagoa Grande' },
  { ibge: '230075425', name: 'Moitas' },
  { ibge: '230075428', name: 'Mosquito' },
  { ibge: '230075430', name: 'Nascente' },
  { ibge: '230075435', name: 'Poço Comprido' },
  { ibge: '230075440', name: 'Sabiaguaba' },
  { ibge: '230080405', name: 'Antonina do Norte' },
  { ibge: '230080410', name: 'Taboleiro' },
  { ibge: '230090305', name: 'Apuiarés' },
  { ibge: '230090310', name: 'Canafístula' },
  { ibge: '230090315', name: 'Vila Soares' },
  { ibge: '230100005', name: 'Aquiraz' },
  { ibge: '230100007', name: 'Camará' },
  { ibge: '230100008', name: 'Caponga da Bernarda' },
  { ibge: '230100015', name: 'Jacaúna' },
  { ibge: '230100017', name: 'João de Castro' },
  { ibge: '230100020', name: 'Justiniano de Serpa' },
  { ibge: '230100025', name: 'Patacas' },
  { ibge: '230100030', name: 'Tapera' },
  { ibge: '230110905', name: 'Aracati' },
  { ibge: '230110907', name: 'Barreira dos Vianas' },
  { ibge: '230110910', name: 'Cabreiro' },
  { ibge: '230110916', name: 'Córrego dos Fernandes' },
  { ibge: '230110924', name: 'Jirau' },
  { ibge: '230110935', name: 'Mata Fresca' },
  { ibge: '230110940', name: 'Santa Tereza' },
  { ibge: '230120805', name: 'Aracoiaba' },
  { ibge: '230120815', name: 'Ideal' },
  { ibge: '230120816', name: 'Jaguarão' },
  { ibge: '230120817', name: 'Jenipapeiro' },
  { ibge: '230120819', name: 'Lagoa de São João' },
  { ibge: '230120821', name: 'Milton Belo' },
  { ibge: '230120823', name: 'Pedra Branca' },
  { ibge: '230120824', name: 'Plácido Martins' },
  { ibge: '230120825', name: 'Vazantes' },
  { ibge: '230125705', name: 'Ararendá' },
  { ibge: '230125715', name: 'Santo Antonio' },
  { ibge: '230130705', name: 'Araripe' },
  { ibge: '230130710', name: 'Alagoinha' },
  { ibge: '230130715', name: 'Brejinho' },
  { ibge: '230130720', name: 'Pajeú' },
  { ibge: '230130725', name: 'Riacho Grande' },
  { ibge: '230140605', name: 'Aratuba' },
  { ibge: '230140620', name: 'Pai João' },
  { ibge: '230150505', name: 'Arneiroz' },
  { ibge: '230150510', name: 'Cachoeira de Fora' },
  { ibge: '230150520', name: 'Planalto' },
  { ibge: '230160405', name: 'Assaré' },
  { ibge: '230160410', name: 'Amaro' },
  { ibge: '230160415', name: 'Aratama' },
  { ibge: '230170305', name: 'Aurora' },
  { ibge: '230170310', name: 'Ingazeiras' },
  { ibge: '230170312', name: 'Santa Vitória' },
  { ibge: '230170315', name: 'Tipi' },
  { ibge: '230180205', name: 'Baixio' },
  { ibge: '230185105', name: 'Banabuiú' },
  { ibge: '230185112', name: 'Laranjeiras' },
  { ibge: '230185114', name: 'Pedras Brancas' },
  { ibge: '230185115', name: 'Rinaré' },
  { ibge: '230185120', name: 'Sitiá' },
  { ibge: '230190105', name: 'Barbalha' },
  { ibge: '230190110', name: 'Arajara' },
  { ibge: '230190112', name: 'Caldas' },
  { ibge: '230190115', name: 'Estrela' },
  { ibge: '230195005', name: 'Barreira' },
  { ibge: '230195010', name: 'Córrego' },
  { ibge: '230195015', name: 'Lagoa do Barro' },
  { ibge: '230195025', name: 'Lagoa Grande' },
  { ibge: '230200805', name: 'Barro' },
  { ibge: '230200808', name: 'Brejinho' },
  { ibge: '230200810', name: 'Cuncas' },
  { ibge: '230200812', name: 'Engenho Velho' },
  { ibge: '230200815', name: 'Iara' },
  { ibge: '230200817', name: 'Monte Alegre' },
  { ibge: '230200820', name: 'Santo Antônio' },
  { ibge: '230200825', name: 'Serrota' },
  { ibge: '230205705', name: 'Barroquinha' },
  { ibge: '230205710', name: 'Araras' },
  { ibge: '230205715', name: 'Bitupitá' },
  { ibge: '230210705', name: 'Baturité' },
  { ibge: '230210710', name: 'Boa Vista' },
  { ibge: '230210740', name: 'São Sebastião' },
  { ibge: '230220605', name: 'Beberibe' },
  { ibge: '230220608', name: 'Forquilha' },
  { ibge: '230220610', name: 'Itapeim' },
  { ibge: '230220615', name: 'Parajuru' },
  { ibge: '230220620', name: 'Paripueira' },
  { ibge: '230220622', name: 'Serra do Félix' },
  { ibge: '230220625', name: 'Sucatinga' },
  { ibge: '230230505', name: 'Bela Cruz' },
  { ibge: '230230510', name: 'Prata' },
  { ibge: '230240405', name: 'Boa Viagem' },
  { ibge: '230240407', name: 'Águas Belas' },
  { ibge: '230240408', name: 'Boqueirão' },
  { ibge: '230240410', name: 'Domingos da Costa' },
  { ibge: '230240415', name: 'Guia' },
  { ibge: '230240420', name: 'Ibuaçu' },
  { ibge: '230240422', name: 'Ipiranga' },
  { ibge: '230240425', name: 'Jacampari' },
  { ibge: '230240430', name: 'Massapê dos Paes' },
  { ibge: '230240431', name: "Olho D'Água do Bezerril" },
  { ibge: '230240432', name: "Olho d'Água dos Facundos" },
  { ibge: '230240435', name: 'Poço da Pedra' },
  { ibge: '230240440', name: 'Várzea da Ipueira' },
  { ibge: '230250305', name: 'Brejo Santo' },
  { ibge: '230250310', name: 'Poço' },
  { ibge: '230250315', name: 'São Filipe' },
  { ibge: '230260205', name: 'Camocim' },
  { ibge: '230260210', name: 'Amarelas' },
  { ibge: '230260225', name: 'Guriú' },
  { ibge: '230270105', name: 'Campos Sales' },
  { ibge: '230270110', name: 'Barão de Aquiraz' },
  { ibge: '230270115', name: 'Carmelópolis' },
  { ibge: '230270120', name: 'Itaguá' },
  { ibge: '230270123', name: 'Monte Castelo' },
  { ibge: '230270125', name: 'Quixariú' },
  { ibge: '230280005', name: 'Canindé' },
  { ibge: '230280010', name: 'Bonito' },
  { ibge: '230280011', name: 'Campos' },
  { ibge: '230280012', name: 'Caiçara' },
  { ibge: '230280013', name: 'Capitão Pedro Sampaio' },
  { ibge: '230280015', name: 'Esperança' },
  { ibge: '230280017', name: 'Iguaçu' },
  { ibge: '230280020', name: 'Ipueiras dos Gomes' },
  { ibge: '230280025', name: 'Monte Alegre' },
  { ibge: '230280030', name: 'Targinos' },
  { ibge: '230280035', name: 'Salitre' },
  { ibge: '230290905', name: 'Capistrano' },
  { ibge: '230300605', name: 'Caridade' },
  { ibge: '230300610', name: 'Campos Belos' },
  { ibge: '230300615', name: 'São Domingos' },
  { ibge: '230310505', name: 'Cariré' },
  { ibge: '230310510', name: 'Arariús' },
  { ibge: '230310515', name: 'Cacimbas' },
  { ibge: '230310520', name: 'Jucá' },
  { ibge: '230310535', name: 'Tapuio' },
  { ibge: '230320405', name: 'Caririaçu' },
  { ibge: '230320410', name: 'Feitosa' },
  { ibge: '230320415', name: 'Miguel Xavier' },
  { ibge: '230320420', name: 'Miragem' },
  { ibge: '230330305', name: 'Cariús' },
  { ibge: '230330307', name: 'Bela Vista' },
  { ibge: '230330310', name: 'Caipu' },
  { ibge: '230330315', name: 'São Bartolomeu' },
  { ibge: '230330320', name: 'São Sebastião' },
  { ibge: '230340205', name: 'Carnaubal' },
  { ibge: '230350105', name: 'Cascavel' },
  { ibge: '230350110', name: 'Caponga' },
  { ibge: '230350112', name: 'Cristais' },
  { ibge: '230350115', name: 'Guanacés' },
  { ibge: '230350120', name: 'Jacarecoara' },
  { ibge: '230350130', name: 'Pitombeiras' },
  { ibge: '230360005', name: 'Catarina' },
  { ibge: '230365905', name: 'Catunda' },
  { ibge: '230365920', name: 'Paraíso' },
  { ibge: '230365925', name: 'Video' },
  { ibge: '230370905', name: 'Caucaia' },
  { ibge: '230370907', name: 'Bom Príncipio' },
  { ibge: '230370910', name: 'Catuana' },
  { ibge: '230370915', name: 'Guararu' },
  { ibge: '230370917', name: 'Jurema' },
  { ibge: '230370920', name: 'Mirambé' },
  { ibge: '230370925', name: 'Sítios Novos' },
  { ibge: '230370930', name: 'Tucunduba' },
  { ibge: '230380805', name: 'Cedro' },
  { ibge: '230380807', name: 'Assunção' },
  { ibge: '230380810', name: 'Várzea da Conceição' },
  { ibge: '230380820', name: 'Candeias' },
  { ibge: '230380830', name: 'Lagedo' },
  { ibge: '230380840', name: 'Santo Antônio' },
  { ibge: '230380850', name: 'São Miguel' },
  { ibge: '230390705', name: 'Chaval' },
  { ibge: '230390710', name: 'Passagem' },
  { ibge: '230393105', name: 'Choró' },
  { ibge: '230393108', name: 'Barbada' },
  { ibge: '230393110', name: 'Caiçarinha' },
  { ibge: '230393120', name: 'Maravilha' },
  { ibge: '230393125', name: 'Monte Castelo' },
  { ibge: '230393135', name: 'Santa Rita' },
  { ibge: '230395605', name: 'Chorozinho' },
  { ibge: '230395610', name: 'Campestre' },
  { ibge: '230395615', name: 'Cedro' },
  { ibge: '230395625', name: 'Patos dos Liberatos' },
  { ibge: '230395635', name: 'Timbaúba dos Marinheiros' },
  { ibge: '230395640', name: 'Triângulo' },
  { ibge: '230400405', name: 'Coreaú' },
  { ibge: '230400410', name: 'Araquém' },
  { ibge: '230400415', name: 'Aroeiras' },
  { ibge: '230400417', name: 'Canto' },
  { ibge: '230400420', name: 'Ubaúna' },
  { ibge: '230410305', name: 'Crateús' },
  { ibge: '230410307', name: 'Assis' },
  { ibge: '230410309', name: 'Curral Velho' },
  { ibge: '230410310', name: 'Ibiapaba' },
  { ibge: '230410315', name: 'Irapuá' },
  { ibge: '230410317', name: 'Lagoa das Pedras' },
  { ibge: '230410320', name: 'Montenebo' },
  { ibge: '230410325', name: 'Oiticica' },
  { ibge: '230410330', name: 'Poti' },
  { ibge: '230410333', name: 'Realejo' },
  { ibge: '230410335', name: 'Santo Antônio' },
  { ibge: '230410337', name: 'Santana' },
  { ibge: '230410340', name: 'Tucuns' },
  { ibge: '230420205', name: 'Crato' },
  { ibge: '230420206', name: 'Baixio das Palmeiras' },
  { ibge: '230420207', name: 'Belmonte' },
  { ibge: '230420209', name: 'Campo Alegre' },
  { ibge: '230420210', name: 'Dom Quintino' },
  { ibge: '230420218', name: 'Monte Alverne' },
  { ibge: '230420222', name: 'Bela Vista' },
  { ibge: '230420225', name: 'Ponta da Serra' },
  { ibge: '230420230', name: 'Santa Fé' },
  { ibge: '230420240', name: 'Santa Rosa' },
  { ibge: '230423605', name: 'Croatá' },
  { ibge: '230423610', name: 'Barra do Sotero' },
  { ibge: '230423620', name: 'Betânia' },
  { ibge: '230423625', name: 'Lagoa da Cruz' },
  { ibge: '230423635', name: 'Repartição' },
  { ibge: '230423640', name: 'Santa Tereza' },
  { ibge: '230423645', name: 'Vista Alegre' },
  { ibge: '230423650', name: 'São Roque' },
  { ibge: '230425105', name: 'Cruz' },
  { ibge: '230425110', name: 'Caiçara' },
  { ibge: '230426905', name: 'Deputado Irapuan Pinheiro' },
  { ibge: '230426906', name: 'Aurora' },
  { ibge: '230426907', name: 'Baixio' },
  { ibge: '230426910', name: 'Betânia' },
  { ibge: '230426920', name: 'Maratoã' },
  { ibge: '230426925', name: 'Velame' },
  { ibge: '230427705', name: 'Ererê' },
  { ibge: '230427720', name: 'São João' },
  { ibge: '230427725', name: 'Tomé Vieira' },
  { ibge: '230428505', name: 'Eusébio' },
  { ibge: '230430105', name: 'Farias Brito' },
  { ibge: '230430110', name: 'Cariutaba' },
  { ibge: '230430115', name: 'Nova Betânia' },
  { ibge: '230430120', name: 'Quincuncá' },
  { ibge: '230435005', name: 'Forquilha' },
  { ibge: '230435020', name: 'Salgado dos Mendes' },
  { ibge: '230435025', name: 'Trapiá' },
  { ibge: '230440005', name: 'Fortaleza' },
  { ibge: '230440060', name: 'Antônio Bezerra' },
  { ibge: '230440065', name: 'Messejana' },
  { ibge: '230440070', name: 'Mondubim' },
  { ibge: '230440075', name: 'Parangaba' },
  { ibge: '230445905', name: 'Fortim' },
  { ibge: '230445910', name: 'Barra' },
  { ibge: '230445915', name: 'Campestre' },
  { ibge: '230445920', name: 'Guajiru' },
  { ibge: '230445925', name: 'Maceió' },
  { ibge: '230445930', name: 'Viçosa' },
  { ibge: '230450905', name: 'Frecheirinha' },
  { ibge: '230460805', name: 'General Sampaio' },
  { ibge: '230465705', name: 'Graça' },
  { ibge: '230465720', name: 'Lapa' },
  { ibge: '230470705', name: 'Granja' },
  { ibge: '230470710', name: 'Adrianópolis' },
  { ibge: '230470715', name: 'Ibuguaçu' },
  { ibge: '230470720', name: 'Parazinho' },
  { ibge: '230470725', name: 'Pessoa Anta' },
  { ibge: '230470730', name: 'Sambaíba' },
  { ibge: '230470735', name: 'Timonha' },
  { ibge: '230480605', name: 'Granjeiro' },
  { ibge: '230490505', name: 'Groaíras' },
  { ibge: '230490515', name: 'Itamaracá' },
  { ibge: '230495405', name: 'Guaiúba' },
  { ibge: '230495410', name: 'Água Verde' },
  { ibge: '230495411', name: 'Baú' },
  { ibge: '230495413', name: 'Dourado' },
  { ibge: '230495415', name: 'Itacima' },
  { ibge: '230495425', name: 'Núcleo Colonial Pio XII (São Gerônimo)' },
  { ibge: '230500105', name: 'Guaraciaba do Norte' },
  { ibge: '230500120', name: 'Várzea dos Espinhos' },
  { ibge: '230500122', name: 'Martinslândia' },
  { ibge: '230500125', name: 'Morrinhos Novos' },
  { ibge: '230500127', name: 'Mucambo' },
  { ibge: '230500130', name: 'Sussuanha' },
  { ibge: '230510005', name: 'Guaramiranga' },
  { ibge: '230510010', name: 'Pernambuquinho' },
  { ibge: '230520905', name: 'Hidrolândia' },
  { ibge: '230520910', name: 'Betânia' },
  { ibge: '230520915', name: 'Conceição' },
  { ibge: '230520920', name: 'Irajá' },
  { ibge: '230523305', name: 'Horizonte' },
  { ibge: '230523307', name: 'Aningas' },
  { ibge: '230523312', name: 'Dourados' },
  { ibge: '230523330', name: 'Queimados' },
  { ibge: '230526605', name: 'Ibaretama' },
  { ibge: '230526620', name: 'Nova-Vida' },
  { ibge: '230526625', name: 'Oiticica' },
  { ibge: '230526627', name: 'Pedra e Cal' },
  { ibge: '230526630', name: 'Piranji' },
  { ibge: '230530805', name: 'Ibiapina' },
  { ibge: '230530807', name: 'Alto Lindo' },
  { ibge: '230530808', name: 'Betânia' },
  { ibge: '230530810', name: 'Santo Antônio da Pindoba' },
  { ibge: '230533205', name: 'Ibicuitinga' },
  { ibge: '230533210', name: 'Açude dos Pinheiros' },
  { ibge: '230533215', name: 'Canindezinho' },
  { ibge: '230533220', name: 'Chile' },
  { ibge: '230533230', name: 'Viçosa' },
  { ibge: '230535705', name: 'Icapuí' },
  { ibge: '230535710', name: 'Ibicuitaba' },
  { ibge: '230535715', name: 'Manibú' },
  { ibge: '230540705', name: 'Icó' },
  { ibge: '230540710', name: 'Cruzeirinho' },
  { ibge: '230540715', name: 'Icozinho' },
  { ibge: '230540720', name: 'Lima Campos' },
  { ibge: '230540725', name: 'Pedrinhas' },
  { ibge: '230540730', name: 'São Vicente' },
  { ibge: '230550605', name: 'Iguatu' },
  { ibge: '230550610', name: 'Barreiras' },
  { ibge: '230550613', name: 'Barro Alto' },
  { ibge: '230550615', name: 'Baú' },
  { ibge: '230550625', name: 'José de Alencar' },
  { ibge: '230550635', name: 'Gadelha' },
  { ibge: '230550636', name: 'Riacho Vermelho' },
  { ibge: '230550640', name: 'Suassurana' },
  { ibge: '230560505', name: 'Independência' },
  { ibge: '230560520', name: 'Ematuba' },
  { ibge: '230560525', name: 'Iapi' },
  { ibge: '230560530', name: 'Jandrangoeira' },
  { ibge: '230560534', name: 'Monte Sinai' },
  { ibge: '230560545', name: 'Tranqueiras' },
  { ibge: '230565405', name: 'Ipaporanga' },
  { ibge: '230565410', name: 'Sacramento' },
  { ibge: '230570405', name: 'Ipaumirim' },
  { ibge: '230570410', name: 'Felizardo' },
  { ibge: '230570420', name: 'Canaúna' },
  { ibge: '230580305', name: 'Ipu' },
  { ibge: '230580308', name: 'Abílio Martins' },
  { ibge: '230580315', name: 'Flores' },
  { ibge: '230580318', name: 'Ingazeiras' },
  { ibge: '230580323', name: 'Recanto' },
  { ibge: '230580325', name: 'Várzea do Giló' },
  { ibge: '230590205', name: 'Ipueiras' },
  { ibge: '230590207', name: 'Alazans' },
  { ibge: '230590210', name: 'América' },
  { ibge: '230590212', name: 'Balseiros' },
  { ibge: '230590215', name: 'Engenheiro João Tomé' },
  { ibge: '230590220', name: 'Gázea' },
  { ibge: '230590225', name: 'Livramento' },
  { ibge: '230590230', name: 'Matriz' },
  { ibge: '230590235', name: 'Nova Fátima' },
  { ibge: '230590237', name: 'São José' },
  { ibge: '230590240', name: 'São José das Lontras' },
  { ibge: '230600905', name: 'Iracema' },
  { ibge: '230600907', name: 'Bastiões' },
  { ibge: '230600915', name: 'Ema' },
  { ibge: '230600925', name: 'São José' },
  { ibge: '230610805', name: 'Irauçuba' },
  { ibge: '230610810', name: 'Boa Vista do Caxitoré' },
  { ibge: '230610815', name: 'Juá' },
  { ibge: '230610820', name: 'Missi' },
  { ibge: '230620705', name: 'Itaiçaba' },
  { ibge: '230625605', name: 'Itaitinga' },
  { ibge: '230630605', name: 'Itapagé' },
  { ibge: '230630607', name: 'Aguaí' },
  { ibge: '230630610', name: 'Baixa Grande' },
  { ibge: '230630620', name: 'Cruz' },
  { ibge: '230630625', name: 'Iratinga' },
  { ibge: '230630630', name: 'Pitombeira' },
  { ibge: '230630632', name: 'São Tomé' },
  { ibge: '230630634', name: 'Serrote do Meio' },
  { ibge: '230630635', name: 'Soledade' },
  { ibge: '230640505', name: 'Itapipoca' },
  { ibge: '230640520', name: 'Arapari' },
  { ibge: '230640522', name: 'Ipu Mazagão' },
  { ibge: '230640525', name: 'Assunção' },
  { ibge: '230640527', name: 'Baleia' },
  { ibge: '230640530', name: 'Barrento' },
  { ibge: '230640535', name: 'Bela Vista' },
  { ibge: '230640536', name: 'Calugi' },
  { ibge: '230640540', name: 'Cruxati' },
  { ibge: '230640547', name: 'Deserto' },
  { ibge: '230640549', name: 'Lagoa das Mercês' },
  { ibge: '230640550', name: 'Marinheiros' },
  { ibge: '230650405', name: 'Itapiúna' },
  { ibge: '230650410', name: 'Caio Prado' },
  { ibge: '230650415', name: 'Itans' },
  { ibge: '230650420', name: 'Palmatória' },
  { ibge: '230655305', name: 'Itarema' },
  { ibge: '230655310', name: 'Almofala' },
  { ibge: '230655315', name: 'Carvoeiro' },
  { ibge: '230660305', name: 'Itatira' },
  { ibge: '230660310', name: 'Bandeira' },
  { ibge: '230660312', name: 'Cachoeira' },
  { ibge: '230660315', name: 'Lagoa do Mato' },
  { ibge: '230660320', name: 'Morro Branco' },
  { ibge: '230670205', name: 'Jaguaretama' },
  { ibge: '230680105', name: 'Jaguaribara' },
  { ibge: '230690005', name: 'Jaguaribe' },
  { ibge: '230690010', name: 'Aquinópolis' },
  { ibge: '230690015', name: 'Feiticeiro' },
  { ibge: '230690020', name: 'Mapuá' },
  { ibge: '230690025', name: 'Nova Floresta' },
  { ibge: '230700705', name: 'Jaguaruana' },
  { ibge: '230700710', name: 'Borges' },
  { ibge: '230700715', name: 'Giqui' },
  { ibge: '230700718', name: 'Santa Luzia' },
  { ibge: '230700720', name: 'São José do Lagamar' },
  { ibge: '230700725', name: 'Saquinho' },
  { ibge: '230710605', name: 'Jardim' },
  { ibge: '230710607', name: 'Corrente' },
  { ibge: '230710610', name: 'Jardimirim' },
  { ibge: '230720505', name: 'Jati' },
  { ibge: '230720510', name: 'Balanças' },
  { ibge: '230720515', name: 'Carnaúba' },
  { ibge: '230725405', name: 'Jijoca de Jericoacoara' },
  { ibge: '230730405', name: 'Juazeiro do Norte' },
  { ibge: '230730410', name: 'Marrocos' },
  { ibge: '230730415', name: 'Padre Cícero' },
  { ibge: '230740305', name: 'Jucás' },
  { ibge: '230740310', name: 'Baixio da Donana' },
  { ibge: '230740315', name: 'Canafístula' },
  { ibge: '230740320', name: 'Mel' },
  { ibge: '230740325', name: 'Poço Grande' },
  { ibge: '230740330', name: 'São Pedro do Norte' },
  { ibge: '230750205', name: 'Lavras da Mangabeira' },
  { ibge: '230750210', name: 'Amaniutuba' },
  { ibge: '230750215', name: 'Arrojado' },
  { ibge: '230750220', name: 'Iborepi' },
  { ibge: '230750225', name: 'Mangabeira' },
  { ibge: '230750230', name: 'Quitaiús' },
  { ibge: '230760105', name: 'Limoeiro do Norte' },
  { ibge: '230760110', name: 'Bixopá' },
  { ibge: '230763505', name: 'Madalena' },
  { ibge: '230763510', name: 'Macaoca' },
  { ibge: '230763515', name: 'Cacimba Nova' },
  { ibge: '230763520', name: 'Cajazeiras' },
  { ibge: '230763530', name: 'Paus Branco' },
  { ibge: '230763545', name: 'União' },
  { ibge: '230765005', name: 'Maracanaú' },
  { ibge: '230765030', name: 'Pajuçara' },
  { ibge: '230770005', name: 'Maranguape' },
  { ibge: '230770010', name: 'Amanari' },
  { ibge: '230770015', name: 'Antônio Marques' },
  { ibge: '230770017', name: 'Cachoeira' },
  { ibge: '230770020', name: 'Itapebussu' },
  { ibge: '230770025', name: 'Jubaia' },
  { ibge: '230770026', name: 'Ladeira Grande' },
  { ibge: '230770027', name: 'Lages' },
  { ibge: '230770029', name: 'Lagoa do Juvenal' },
  { ibge: '230770031', name: 'Manoel Guedes' },
  { ibge: '230770032', name: 'Papara' },
  { ibge: '230770033', name: 'Penedo' },
  { ibge: '230770035', name: 'Sapupara' },
  { ibge: '230770037', name: 'São João do Amanari' },
  { ibge: '230770040', name: 'Tanques' },
  { ibge: '230770042', name: 'Umarizeiras' },
  { ibge: '230770045', name: 'Vertentes do Lagedo' },
  { ibge: '230780905', name: 'Marco' },
  { ibge: '230780910', name: 'Panacuí' },
  { ibge: '230780915', name: 'Mocambo' },
  { ibge: '230790805', name: 'Martinópole' },
  { ibge: '230800505', name: 'Massapê' },
  { ibge: '230800510', name: 'Aiuá' },
  { ibge: '230800513', name: 'Ipaguaçu' },
  { ibge: '230800515', name: 'Mumbaba' },
  { ibge: '230800520', name: 'Padre Linhares' },
  { ibge: '230800522', name: 'Tangente' },
  { ibge: '230800525', name: 'Tuína' },
  { ibge: '230810405', name: 'Mauriti' },
  { ibge: '230810410', name: 'Anauá' },
  { ibge: '230810412', name: 'Buritizinho' },
  { ibge: '230810415', name: 'Coité' },
  { ibge: '230810420', name: 'Nova Santa Cruz' },
  { ibge: '230810425', name: 'São Félix' },
  { ibge: '230810427', name: 'Palestina do Cariri' },
  { ibge: '230810429', name: 'São Miguel' },
  { ibge: '230810430', name: 'Umburanas' },
  { ibge: '230820305', name: 'Meruoca' },
  { ibge: '230820307', name: 'Anil' },
  { ibge: '230820310', name: 'Camilos' },
  { ibge: '230820315', name: 'Palestina do Norte' },
  { ibge: '230820320', name: 'Santo Antônio dos Fernandes' },
  { ibge: '230820325', name: 'São Francisco' },
  { ibge: '230830205', name: 'Milagres' },
  { ibge: '230830210', name: 'Rosário' },
  { ibge: '230835105', name: 'Milhã' },
  { ibge: '230835106', name: 'Baixa Verde' },
  { ibge: '230835108', name: 'Barra' },
  { ibge: '230835110', name: 'Carnaubinha' },
  { ibge: '230835115', name: 'Ipueira' },
  { ibge: '230835120', name: 'Monte grave' },
  { ibge: '230837705', name: 'Miraíma' },
  { ibge: '230837710', name: 'Brotas' },
  { ibge: '230837720', name: 'Poço da Onça' },
  { ibge: '230837725', name: 'Riachão' },
  { ibge: '230840105', name: 'Missão Velha' },
  { ibge: '230840115', name: 'Jamacaru' },
  { ibge: '230840120', name: 'Missão Nova' },
  { ibge: '230840125', name: 'Quimami' },
  { ibge: '230850005', name: 'Mombaça' },
  { ibge: '230850007', name: 'Açudinho dos Costas' },
  { ibge: '230850010', name: 'Boa Vista' },
  { ibge: '230850015', name: 'Cangatí' },
  { ibge: '230850020', name: 'Carnaúbas' },
  { ibge: '230850025', name: 'Catolé' },
  { ibge: '230850026', name: 'Cipó' },
  { ibge: '230850027', name: 'Manoel Correia' },
  { ibge: '230850030', name: 'São Gonçalo do Umari' },
  { ibge: '230850035', name: 'São Vicente' },
  { ibge: '230860905', name: 'Monsenhor Tabosa' },
  { ibge: '230860910', name: 'Barreiros' },
  { ibge: '230860915', name: 'Nossa Senhora do Livramento' },
  { ibge: '230870805', name: 'Morada Nova' },
  { ibge: '230870810', name: 'Aruaru' },
  { ibge: '230870815', name: 'Boa Água' },
  { ibge: '230870825', name: 'Juazeiro de Baixo' },
  { ibge: '230870827', name: 'Lagoa Grande' },
  { ibge: '230870830', name: 'Pedras' },
  { ibge: '230870835', name: 'Roldão' },
  { ibge: '230870840', name: 'Uiraponga' },
  { ibge: '230880705', name: 'Moraújo' },
  { ibge: '230880707', name: 'Boa Esperança' },
  { ibge: '230880710', name: 'Várzea da Volta' },
  { ibge: '230890605', name: 'Morrinhos' },
  { ibge: '230890640', name: 'Sítio Alegre' },
  { ibge: '230900305', name: 'Mucambo' },
  { ibge: '230900310', name: 'Carqueijo' },
  { ibge: '230900315', name: 'Poço Verde' },
  { ibge: '230910205', name: 'Mulungu' },
  { ibge: '230920105', name: 'Nova Olinda' },
  { ibge: '230920130', name: 'Triunfo' },
  { ibge: '230930005', name: 'Nova Russas' },
  { ibge: '230930015', name: 'Canindezinho' },
  { ibge: '230930019', name: 'Espacinha' },
  { ibge: '230930025', name: 'Major Simplício' },
  { ibge: '230930030', name: 'Nova Betânia' },
  { ibge: '230930045', name: 'São Pedro' },
  { ibge: '230940905', name: 'Novo Oriente' },
  { ibge: '230940915', name: 'Emaús' },
  { ibge: '230940920', name: 'Palestina' },
  { ibge: '230940924', name: 'Santa Maria' },
  { ibge: '230940925', name: 'São Raimundo' },
  { ibge: '230940930', name: 'Três Irmãos' },
  { ibge: '230945805', name: 'Ocara' },
  { ibge: '230945807', name: 'Arisco dos Marianos' },
  { ibge: '230945810', name: 'Curupira' },
  { ibge: '230945820', name: 'Novo Horizonte' },
  { ibge: '230945830', name: 'Sereno de Cima' },
  { ibge: '230945840', name: 'Serragem' },
  { ibge: '230950805', name: 'Orós' },
  { ibge: '230950810', name: 'Guassussê' },
  { ibge: '230950815', name: 'Igarói' },
  { ibge: '230950820', name: 'Palestina' },
  { ibge: '230950830', name: 'Santarém' },
  { ibge: '230960705', name: 'Pacajus' },
  { ibge: '230960720', name: 'Itaipaba' },
  { ibge: '230960730', name: 'Pascoal' },
  { ibge: '230970605', name: 'Pacatuba' },
  { ibge: '230970633', name: 'Monguba' },
  { ibge: '230970635', name: 'Pavuna' },
  { ibge: '230970650', name: 'Senador Carlos Jereissati' },
  { ibge: '230980505', name: 'Pacoti' },
  { ibge: '230980510', name: 'Colina' },
  { ibge: '230980515', name: 'Fátima' },
  { ibge: '230980520', name: 'Santa Ana' },
  { ibge: '230990405', name: 'Pacujá' },
  { ibge: '231000105', name: 'Palhano' },
  { ibge: '231000110', name: 'São José' },
  { ibge: '231010005', name: 'Palmácia' },
  { ibge: '231010010', name: 'Gado' },
  { ibge: '231010015', name: 'Gados dos Rodrigues' },
  { ibge: '231020905', name: 'Paracuru' },
  { ibge: '231020910', name: 'Jardim' },
  { ibge: '231020920', name: 'Poço Doce' },
  { ibge: '231025805', name: 'Paraipaba' },
  { ibge: '231025810', name: 'Boa Vista' },
  { ibge: '231025815', name: 'Camboas' },
  { ibge: '231025820', name: 'Lagoinha' },
  { ibge: '231030805', name: 'Parambu' },
  { ibge: '231030810', name: 'Cococi' },
  { ibge: '231030812', name: 'Gavião' },
  { ibge: '231030814', name: 'Miranda' },
  { ibge: '231030815', name: 'Monte Sion' },
  { ibge: '231030820', name: 'Novo Assis' },
  { ibge: '231030830', name: 'Oiticica' },
  { ibge: '231040705', name: 'Paramoti' },
  { ibge: '231050605', name: 'Pedra Branca' },
  { ibge: '231050607', name: 'Capitão Mor' },
  { ibge: '231050610', name: 'Mineirolândia' },
  { ibge: '231050615', name: 'Santa Cruz do Banabuiú' },
  { ibge: '231050620', name: 'Tróia' },
  { ibge: '231060505', name: 'Penaforte' },
  { ibge: '231060515', name: 'Juá' },
  { ibge: '231060525', name: 'Santo André' },
  { ibge: '231070405', name: 'Pentecoste' },
  { ibge: '231070410', name: 'Matias' },
  { ibge: '231070415', name: 'Porfírio Sampaio' },
  { ibge: '231070420', name: 'Sebastião de Abreu' },
  { ibge: '231080305', name: 'Pereiro' },
  { ibge: '231080310', name: 'Crioulos' },
  { ibge: '231085205', name: 'Pindoretama' },
  { ibge: '231085210', name: 'Capim de Roça' },
  { ibge: '231085215', name: 'Caponguinha' },
  { ibge: '231085220', name: 'Ema' },
  { ibge: '231085225', name: 'Pratiús' },
  { ibge: '231090205', name: 'Piquet Carneiro' },
  { ibge: '231090207', name: 'Catolé da Pista' },
  { ibge: '231090210', name: 'Ibicuã' },
  { ibge: '231090215', name: 'Mulungu' },
  { ibge: '231095105', name: 'Pires Ferreira' },
  { ibge: '231095110', name: 'Santo Izidro' },
  { ibge: '231095115', name: 'Donato' },
  { ibge: '231095120', name: 'Otavilândia' },
  { ibge: '231100905', name: 'Poranga' },
  { ibge: '231100907', name: 'Buritizal' },
  { ibge: '231100908', name: 'Cachoeira Grande' },
  { ibge: '231100910', name: 'Macambira' },
  { ibge: '231110805', name: 'Porteiras' },
  { ibge: '231110830', name: 'Simão' },
  { ibge: '231120705', name: 'Potengi' },
  { ibge: '231120710', name: 'Barreiros' },
  { ibge: '231123105', name: 'Potiretama' },
  { ibge: '231123110', name: 'Canindezinho' },
  { ibge: '231126405', name: 'Quiterianópolis' },
  { ibge: '231126410', name: 'Algodões' },
  { ibge: '231126420', name: 'São Francisco' },
  { ibge: '231130605', name: 'Quixadá' },
  { ibge: '231130618', name: 'Califórnia' },
  { ibge: '231130625', name: 'Cipó dos Anjos' },
  { ibge: '231130630', name: 'Custódio' },
  { ibge: '231130635', name: 'Daniel de Queiróz' },
  { ibge: '231130640', name: 'Dom Maurício' },
  { ibge: '231130643', name: 'Juá' },
  { ibge: '231130650', name: 'Juatama' },
  { ibge: '231130652', name: 'Riacho Verde' },
  { ibge: '231130657', name: 'São Bernardo' },
  { ibge: '231130659', name: 'São João dos Queirozes' },
  { ibge: '231130665', name: 'Tapuiará' },
  { ibge: '231130670', name: 'Várzea da Onça' },
  { ibge: '231135505', name: 'Quixelô' },
  { ibge: '231135507', name: 'Antonico' },
  { ibge: '231140505', name: 'Quixeramobim' },
  { ibge: '231140507', name: 'Belém' },
  { ibge: '231140510', name: 'Encantado' },
  { ibge: '231140515', name: 'Lacerda' },
  { ibge: '231140530', name: 'Manituba' },
  { ibge: '231140532', name: 'Nenelândia' },
  { ibge: '231140535', name: 'Passagem' },
  { ibge: '231140540', name: 'Damião Carneiro' },
  { ibge: '231140545', name: 'São Miguel' },
  { ibge: '231140550', name: 'Uruquê' },
  { ibge: '231150405', name: 'Quixeré' },
  { ibge: '231150407', name: 'Agua Fria' },
  { ibge: '231150410', name: 'Lagoinha' },
  { ibge: '231150420', name: 'Tomé' },
  { ibge: '231160305', name: 'Redenção' },
  { ibge: '231160315', name: 'Antônio Diogo' },
  { ibge: '231160321', name: 'Faísca' },
  { ibge: '231160325', name: 'Guassi' },
  { ibge: '231160330', name: 'Barra Nova' },
  { ibge: '231170205', name: 'Reriutaba' },
  { ibge: '231170210', name: 'Amanaiara' },
  { ibge: '231170220', name: 'Campo Lindo' },
  { ibge: '231180105', name: 'Russas' },
  { ibge: '231180110', name: 'Bonhu' },
  { ibge: '231180115', name: 'Flores' },
  { ibge: '231180117', name: 'Lagoa Grande' },
  { ibge: '231180119', name: 'Peixe' },
  { ibge: '231180120', name: 'São João de Deus' },
  { ibge: '231190005', name: 'Saboeiro' },
  { ibge: '231190008', name: 'Barrinha' },
  { ibge: '231190009', name: 'Felipe' },
  { ibge: '231190010', name: 'Flamengo' },
  { ibge: '231190030', name: 'Malhada' },
  { ibge: '231190050', name: 'São José' },
  { ibge: '231195905', name: 'Salitre' },
  { ibge: '231195910', name: 'Caldeirão' },
  { ibge: '231195920', name: 'Lagoa dos Crioulos' },
  { ibge: '231200705', name: 'Santana do Acaraú' },
  { ibge: '231200707', name: 'Bahia' },
  { ibge: '231200708', name: 'Baixa Fria' },
  { ibge: '231200709', name: 'Barro Preto' },
  { ibge: '231200710', name: 'João Cordeiro' },
  { ibge: '231200715', name: 'Mutambeiras' },
  { ibge: '231200720', name: 'Parapuí' },
  { ibge: '231200725', name: 'Sapó' },
  { ibge: '231210605', name: 'Santana do Cariri' },
  { ibge: '231210610', name: 'Anjinhos' },
  { ibge: '231210615', name: 'Araporanga' },
  { ibge: '231210620', name: 'Brejo Grande' },
  { ibge: '231210625', name: 'Dom Leme' },
  { ibge: '231210630', name: 'Inhumas' },
  { ibge: '231210640', name: 'Pontal da Santa Cruz' },
  { ibge: '231220505', name: 'Santa Quitéria' },
  { ibge: '231220510', name: 'Lisieux' },
  { ibge: '231220520', name: 'Logradouro' },
  { ibge: '231220525', name: 'Macaraú' },
  { ibge: '231220530', name: 'Malhada Grande' },
  { ibge: '231220535', name: 'Muribeca' },
  { ibge: '231220540', name: 'Raimundo Martins' },
  { ibge: '231220545', name: 'Trapiá' },
  { ibge: '231230405', name: 'São Benedito' },
  { ibge: '231230409', name: 'Barreiros' },
  { ibge: '231230415', name: 'Inhuçu' },
  { ibge: '231240305', name: 'São Gonçalo do Amarante' },
  { ibge: '231240308', name: 'Cágado' },
  { ibge: '231240310', name: 'Croatá' },
  { ibge: '231240315', name: 'Pecém' },
  { ibge: '231240320', name: 'Serrote' },
  { ibge: '231240325', name: 'Siupé' },
  { ibge: '231240327', name: 'Taiba' },
  { ibge: '231240330', name: 'Umarituba' },
  { ibge: '231250205', name: 'São João do Jaguaribe' },
  { ibge: '231260105', name: 'São Luís do Curu' },
  { ibge: '231270005', name: 'Senador Pompeu' },
  { ibge: '231270006', name: 'Bonfim' },
  { ibge: '231270008', name: 'Codia' },
  { ibge: '231270010', name: 'Engenheiro José Lopes' },
  { ibge: '231270015', name: 'São Joaquim do Salgado' },
  { ibge: '231280905', name: 'Senador Sá' },
  { ibge: '231280910', name: 'Salão' },
  { ibge: '231280915', name: 'Serrota' },
  { ibge: '231290805', name: 'Sobral' },
  { ibge: '231290807', name: 'Aprazível' },
  { ibge: '231290810', name: 'Aracatiaçu' },
  { ibge: '231290815', name: 'Bonfim' },
  { ibge: '231290820', name: 'Caioca' },
  { ibge: '231290825', name: 'Caracará' },
  { ibge: '231290835', name: 'Jaibaras' },
  { ibge: '231290840', name: 'Jordão' },
  { ibge: '231290845', name: 'Rafael Arruda' },
  { ibge: '231290847', name: 'Patos' },
  { ibge: '231290850', name: 'Patriarca' },
  { ibge: '231290853', name: 'São José do Torto' },
  { ibge: '231290855', name: 'Taperuaba' },
  { ibge: '231300505', name: 'Solonópole' },
  { ibge: '231300510', name: 'Assunção' },
  { ibge: '231300515', name: 'Cangati' },
  { ibge: '231300530', name: 'Pasta' },
  { ibge: '231300532', name: 'Prefeita Suely Pinheiro' },
  { ibge: '231300535', name: 'São José de Solonópole' },
  { ibge: '231310405', name: 'Tabuleiro do Norte' },
  { ibge: '231310410', name: "Olho-d'Água da Bica" },
  { ibge: '231310415', name: 'Peixe Gordo' },
  { ibge: '231320305', name: 'Tamboril' },
  { ibge: '231320307', name: 'Açudinho' },
  { ibge: '231320310', name: 'Boa Esperança' },
  { ibge: '231320315', name: 'Carvalho' },
  { ibge: '231320320', name: 'Curatis' },
  { ibge: '231320325', name: 'Holanda' },
  { ibge: '231320330', name: 'Oliveiras' },
  { ibge: '231320335', name: 'Sucesso' },
  { ibge: '231325205', name: 'Tarrafas' },
  { ibge: '231330205', name: 'Tauá' },
  { ibge: '231330210', name: 'Barra Nova' },
  { ibge: '231330215', name: 'Carrapateiras' },
  { ibge: '231330220', name: 'Inhamuns' },
  { ibge: '231330225', name: 'Marrecas' },
  { ibge: '231330230', name: 'Marruás' },
  { ibge: '231330233', name: 'Santa Tereza' },
  { ibge: '231330235', name: 'Trici' },
  { ibge: '231335105', name: 'Tejuçuoca' },
  { ibge: '231335110', name: 'Caxitoré' },
  { ibge: '231340105', name: 'Tianguá' },
  { ibge: '231340110', name: 'Arapá' },
  { ibge: '231340115', name: 'Caruataí' },
  { ibge: '231340120', name: 'Pindoguaba' },
  { ibge: '231340125', name: 'Tabainha' },
  { ibge: '231350005', name: 'Trairi' },
  { ibge: '231350006', name: 'Córrego Fundo' },
  { ibge: '231350007', name: 'Canaan' },
  { ibge: '231350008', name: 'Fleicheiras' },
  { ibge: '231350009', name: 'Gualdrapas' },
  { ibge: '231350010', name: 'Mundaú' },
  { ibge: '231355905', name: 'Tururu' },
  { ibge: '231355910', name: 'Cemoaba' },
  { ibge: '231355915', name: 'Conceição' },
  { ibge: '231355925', name: 'São Pedro do Gavião' },
  { ibge: '231360905', name: 'Ubajara' },
  { ibge: '231360910', name: 'Araticum' },
  { ibge: '231360915', name: 'Jaburuna' },
  { ibge: '231360925', name: 'Nova Veneza' },
  { ibge: '231370805', name: 'Umari' },
  { ibge: '231370815', name: 'Pio X' },
  { ibge: '231375705', name: 'Umirim' },
  { ibge: '231375720', name: 'Caxitoré' },
  { ibge: '231375730', name: 'São Joaquim' },
  { ibge: '231380705', name: 'Uruburetama' },
  { ibge: '231380709', name: 'Itacolomy' },
  { ibge: '231380711', name: 'Mundaú' },
  { ibge: '231380713', name: 'Retiro' },
  { ibge: '231380715', name: 'Santa Luzia' },
  { ibge: '231390605', name: 'Uruoca' },
  { ibge: '231390610', name: 'Campánario' },
  { ibge: '231390615', name: 'Paracuá' },
  { ibge: '231395505', name: 'Varjota' },
  { ibge: '231395515', name: 'Croata' },
  { ibge: '231400305', name: 'Várzea Alegre' },
  { ibge: '231400310', name: 'Calabaça' },
  { ibge: '231400315', name: 'Canindezinho' },
  { ibge: '231400320', name: 'Ibicatu' },
  { ibge: '231400325', name: 'Naraniú' },
  { ibge: '231400330', name: 'Riacho Verde' },
  { ibge: '231410205', name: 'Viçosa do Ceará' },
  { ibge: '231410210', name: 'General Tibúrcio' },
  { ibge: '231410212', name: 'Juá dos Vieiras' },
  { ibge: '231410215', name: 'Lambedouro' },
  { ibge: '231410217', name: 'Manhoso' },
  { ibge: '231410220', name: 'Padre Vieira' },
  { ibge: '231410223', name: 'Passagem da Onça' },
  { ibge: '231410225', name: 'Quatiguaba' },
  { ibge: '240010905', name: 'Acari' },
  { ibge: '240020805', name: 'Açu' },
  { ibge: '240030705', name: 'Afonso Bezerra' },
  { ibge: '240040605', name: 'Água Nova' },
  { ibge: '240050505', name: 'Alexandria' },
  { ibge: '240060405', name: 'Almino Afonso' },
  { ibge: '240070305', name: 'Alto do Rodrigues' },
  { ibge: '240080205', name: 'Angicos' },
  { ibge: '240090105', name: 'Antônio Martins' },
  { ibge: '240100805', name: 'Apodi' },
  { ibge: '240110705', name: 'Areia Branca' },
  { ibge: '240120605', name: 'Arês' },
  { ibge: '240130505', name: 'Augusto Severo' },
  { ibge: '240140405', name: 'Baía Formosa' },
  { ibge: '240145305', name: 'Baraúna' },
  { ibge: '240150305', name: 'Barcelona' },
  { ibge: '240160205', name: 'Bento Fernandes' },
  { ibge: '240160210', name: 'Belo Horizonte' },
  { ibge: '240165105', name: 'Bodó' },
  { ibge: '240170105', name: 'Bom Jesus' },
  { ibge: '240180005', name: 'Brejinho' },
  { ibge: '240185905', name: 'Caiçara do Norte' },
  { ibge: '240190905', name: 'Caiçara do Rio do Vento' },
  { ibge: '240200605', name: 'Caicó' },
  { ibge: '240210505', name: 'Campo Redondo' },
  { ibge: '240220405', name: 'Canguaretama' },
  { ibge: '240230305', name: 'Caraúbas' },
  { ibge: '240230310', name: 'São Geraldo' },
  { ibge: '240240205', name: 'Carnaúba dos Dantas' },
  { ibge: '240250105', name: 'Carnaubais' },
  { ibge: '240260005', name: 'Ceará-Mirim' },
  { ibge: '240270905', name: 'Cerro Corá' },
  { ibge: '240280805', name: 'Coronel Ezequiel' },
  { ibge: '240290705', name: 'Coronel João Pessoa' },
  { ibge: '240300405', name: 'Cruzeta' },
  { ibge: '240310305', name: 'Currais Novos' },
  { ibge: '240320205', name: 'Doutor Severiano' },
  { ibge: '240325105', name: 'Parnamirim' },
  { ibge: '240330105', name: 'Encanto' },
  { ibge: '240340005', name: 'Equador' },
  { ibge: '240350905', name: 'Espírito Santo' },
  { ibge: '240360805', name: 'Extremoz' },
  { ibge: '240370705', name: 'Felipe Guerra' },
  { ibge: '240375605', name: 'Fernando Pedroza' },
  { ibge: '240380605', name: 'Florânia' },
  { ibge: '240390505', name: 'Francisco Dantas' },
  { ibge: '240400205', name: 'Frutuoso Gomes' },
  { ibge: '240410105', name: 'Galinhos' },
  { ibge: '240420005', name: 'Goianinha' },
  { ibge: '240430905', name: 'Governador Dix-Sept Rosado' },
  { ibge: '240440805', name: 'Grossos' },
  { ibge: '240450705', name: 'Guamaré' },
  { ibge: '240460605', name: 'Ielmo Marinho' },
  { ibge: '240470505', name: 'Ipanguaçu' },
  { ibge: '240480405', name: 'Ipueira' },
  { ibge: '240485305', name: 'Itajá' },
  { ibge: '240490305', name: 'Itaú' },
  { ibge: '240500905', name: 'Jaçanã' },
  { ibge: '240510805', name: 'Jandaíra' },
  { ibge: '240520705', name: 'Janduís' },
  { ibge: '240530605', name: 'Januário Cicco' },
  { ibge: '240530610', name: 'Córrego de São Mateus' },
  { ibge: '240540505', name: 'Japi' },
  { ibge: '240550405', name: 'Jardim de Angicos' },
  { ibge: '240560305', name: 'Jardim de Piranhas' },
  { ibge: '240570205', name: 'Jardim do Seridó' },
  { ibge: '240580105', name: 'João Câmara' },
  { ibge: '240590005', name: 'João Dias' },
  { ibge: '240590010', name: 'Rosário' },
  { ibge: '240600705', name: 'José da Penha' },
  { ibge: '240600710', name: 'Major Felipe' },
  { ibge: '240610605', name: 'Jucurutu' },
  { ibge: '240615505', name: 'Jundiá' },
  { ibge: '240620505', name: "Lagoa d'Anta" },
  { ibge: '240630405', name: 'Lagoa de Pedras' },
  { ibge: '240640305', name: 'Lagoa de Velhos' },
  { ibge: '240650205', name: 'Lagoa Nova' },
  { ibge: '240660105', name: 'Lagoa Salgada' },
  { ibge: '240670005', name: 'Lajes' },
  { ibge: '240670010', name: 'Firmamento' },
  { ibge: '240680905', name: 'Lajes Pintadas' },
  { ibge: '240690805', name: 'Lucrécia' },
  { ibge: '240700505', name: 'Luís Gomes' },
  { ibge: '240700515', name: 'São Bernardo' },
  { ibge: '240710405', name: 'Macaíba' },
  { ibge: '240720305', name: 'Macau' },
  { ibge: '240725205', name: 'Major Sales' },
  { ibge: '240730205', name: 'Marcelino Vieira' },
  { ibge: '240740105', name: 'Martins' },
  { ibge: '240740110', name: 'Salva Vida' },
  { ibge: '240750005', name: 'Maxaranguape' },
  { ibge: '240760905', name: 'Messias Targino' },
  { ibge: '240770805', name: 'Montanhas' },
  { ibge: '240780705', name: 'Monte Alegre' },
  { ibge: '240790605', name: 'Monte das Gameleiras' },
  { ibge: '240800305', name: 'Mossoró' },
  { ibge: '240810205', name: 'Natal' },
  { ibge: '240820105', name: 'Nísia Floresta' },
  { ibge: '240830005', name: 'Nova Cruz' },
  { ibge: '240840905', name: "Olho-d'Água do Borges" },
  { ibge: '240850805', name: 'Ouro Branco' },
  { ibge: '240860705', name: 'Paraná' },
  { ibge: '240870605', name: 'Paraú' },
  { ibge: '240880505', name: 'Parazinho' },
  { ibge: '240890405', name: 'Parelhas' },
  { ibge: '240895305', name: 'Rio do Fogo' },
  { ibge: '240910005', name: 'Passa e Fica' },
  { ibge: '240920905', name: 'Passagem' },
  { ibge: '240930805', name: 'Patu' },
  { ibge: '240933205', name: 'Santa Maria' },
  { ibge: '240940705', name: 'Pau dos Ferros' },
  { ibge: '240950605', name: 'Pedra Grande' },
  { ibge: '240960505', name: 'Pedra Preta' },
  { ibge: '240970405', name: 'Pedro Avelino' },
  { ibge: '240980305', name: 'Pedro Velho' },
  { ibge: '240990205', name: 'Pendências' },
  { ibge: '241000905', name: 'Pilões' },
  { ibge: '241010805', name: 'Poço Branco' },
  { ibge: '241020705', name: 'Portalegre' },
  { ibge: '241025605', name: 'Porto do Mangue' },
  { ibge: '241030605', name: 'Presidente Juscelino' },
  { ibge: '241040505', name: 'Pureza' },
  { ibge: '241050405', name: 'Rafael Fernandes' },
  { ibge: '241060305', name: 'Rafael Godeiro' },
  { ibge: '241070205', name: 'Riacho da Cruz' },
  { ibge: '241080105', name: 'Riacho de Santana' },
  { ibge: '241090005', name: 'Riachuelo' },
  { ibge: '241100705', name: 'Rodolfo Fernandes' },
  { ibge: '241105605', name: 'Tibau' },
  { ibge: '241110605', name: 'Ruy Barbosa' },
  { ibge: '241120505', name: 'Santa Cruz' },
  { ibge: '241140305', name: 'Santana do Matos' },
  { ibge: '241140310', name: 'Barão de Serra Branca' },
  { ibge: '241140320', name: 'Santa Teresa' },
  { ibge: '241140325', name: 'São José da Passagem' },
  { ibge: '241142905', name: 'Santana do Seridó' },
  { ibge: '241150205', name: 'Santo Antônio' },
  { ibge: '241160105', name: 'São Bento do Norte' },
  { ibge: '241170005', name: 'São Bento do Trairí' },
  { ibge: '241180905', name: 'São Fernando' },
  { ibge: '241190805', name: 'São Francisco do Oeste' },
  { ibge: '241200505', name: 'São Gonçalo do Amarante' },
  { ibge: '241210405', name: 'São João do Sabugi' },
  { ibge: '241220305', name: 'São José de Mipibu' },
  { ibge: '241230205', name: 'São José do Campestre' },
  { ibge: '241230210', name: 'Ipiranga' },
  { ibge: '241240105', name: 'São José do Seridó' },
  { ibge: '241250005', name: 'São Miguel' },
  { ibge: '241255905', name: 'São Miguel do Gostoso' },
  { ibge: '241260905', name: 'São Paulo do Potengi' },
  { ibge: '241270805', name: 'São Pedro' },
  { ibge: '241280705', name: 'São Rafael' },
  { ibge: '241290605', name: 'São Tomé' },
  { ibge: '241300305', name: 'São Vicente' },
  { ibge: '241310205', name: 'Senador Elói de Souza' },
  { ibge: '241320105', name: 'Senador Georgino Avelino' },
  { ibge: '241330005', name: 'Serra de São Bento' },
  { ibge: '241335905', name: 'Serra do Mel' },
  { ibge: '241340905', name: 'Serra Negra do Norte' },
  { ibge: '241350805', name: 'Serrinha' },
  { ibge: '241355705', name: 'Serrinha dos Pintos' },
  { ibge: '241360705', name: 'Severiano Melo' },
  { ibge: '241370605', name: 'Sítio Novo' },
  { ibge: '241370610', name: 'Serra da Tapuia' },
  { ibge: '241380505', name: 'Taboleiro Grande' },
  { ibge: '241390405', name: 'Taipu' },
  { ibge: '241390410', name: 'Gameleira' },
  { ibge: '241400105', name: 'Tangará' },
  { ibge: '241400110', name: 'Trairi' },
  { ibge: '241410005', name: 'Tenente Ananias' },
  { ibge: '241410010', name: 'Mata de São Braz' },
  { ibge: '241415905', name: 'Tenente Laurentino Cruz' },
  { ibge: '241420905', name: 'Tibau do Sul' },
  { ibge: '241430805', name: 'Timbaúba dos Batistas' },
  { ibge: '241440705', name: 'Touros' },
  { ibge: '241445605', name: 'Triunfo Potiguar' },
  { ibge: '241450605', name: 'Umarizal' },
  { ibge: '241460505', name: 'Upanema' },
  { ibge: '241470405', name: 'Várzea' },
  { ibge: '241475305', name: 'Venha-Ver' },
  { ibge: '241480305', name: 'Vera Cruz' },
  { ibge: '241490205', name: 'Viçosa' },
  { ibge: '241500805', name: 'Vila Flor' },
  { ibge: '250010605', name: 'Água Branca' },
  { ibge: '250020505', name: 'Aguiar' },
  { ibge: '250030405', name: 'Alagoa Grande' },
  { ibge: '250040305', name: 'Alagoa Nova' },
  { ibge: '250050205', name: 'Alagoinha' },
  { ibge: '250053605', name: 'Alcantil' },
  { ibge: '250057705', name: 'Algodão de Jandaíra' },
  { ibge: '250060105', name: 'Alhandra' },
  { ibge: '250070005', name: 'São João do Rio do Peixe' },
  { ibge: '250070015', name: 'Umari' },
  { ibge: '250073405', name: 'Amparo' },
  { ibge: '250077505', name: 'Aparecida' },
  { ibge: '250080905', name: 'Araçagi' },
  { ibge: '250090805', name: 'Arara' },
  { ibge: '250100505', name: 'Araruna' },
  { ibge: '250110405', name: 'Areia' },
  { ibge: '250110410', name: 'Cepilho' },
  { ibge: '250110415', name: 'Mata Limpa' },
  { ibge: '250110420', name: 'Muquém' },
  { ibge: '250115305', name: 'Areia de Baraúnas' },
  { ibge: '250120305', name: 'Areial' },
  { ibge: '250130205', name: 'Aroeiras' },
  { ibge: '250130220', name: 'Pedro Velho' },
  { ibge: '250135105', name: 'Assunção' },
  { ibge: '250140105', name: 'Baía da Traição' },
  { ibge: '250150005', name: 'Bananeiras' },
  { ibge: '250150010', name: 'Maia' },
  { ibge: '250150020', name: 'Taboleiro' },
  { ibge: '250153405', name: 'Baraúna' },
  { ibge: '250157505', name: 'Barra de Santana' },
  { ibge: '250160905', name: 'Barra de Santa Rosa' },
  { ibge: '250170805', name: 'Barra de São Miguel' },
  { ibge: '250180705', name: 'Bayeux' },
  { ibge: '250190605', name: 'Belém' },
  { ibge: '250190610', name: 'Rua Nova' },
  { ibge: '250200305', name: 'Belém do Brejo do Cruz' },
  { ibge: '250205205', name: 'Bernardino Batista' },
  { ibge: '250210205', name: 'Boa Ventura' },
  { ibge: '250215105', name: 'Boa Vista' },
  { ibge: '250220105', name: 'Bom Jesus' },
  { ibge: '250230005', name: 'Bom Sucesso' },
  { ibge: '250240905', name: 'Bonito de Santa Fé' },
  { ibge: '250240930', name: 'Viana' },
  { ibge: '250250805', name: 'Boqueirão' },
  { ibge: '250250830', name: 'Marinho' },
  { ibge: '250260705', name: 'Igaracy' },
  { ibge: '250270605', name: 'Borborema' },
  { ibge: '250280505', name: 'Brejo do Cruz' },
  { ibge: '250290405', name: 'Brejo dos Santos' },
  { ibge: '250300105', name: 'Caaporã' },
  { ibge: '250300115', name: 'Cupissura' },
  { ibge: '250310005', name: 'Cabaceiras' },
  { ibge: '250320905', name: 'Cabedelo' },
  { ibge: '250320925', name: 'Renascer' },
  { ibge: '250330805', name: 'Cachoeira dos Índios' },
  { ibge: '250330810', name: 'Balanços' },
  { ibge: '250330815', name: 'Fátima' },
  { ibge: '250330820', name: 'São José de Marimbas' },
  { ibge: '250340705', name: 'Cacimba de Areia' },
  { ibge: '250350605', name: 'Cacimba de Dentro' },
  { ibge: '250355505', name: 'Cacimbas' },
  { ibge: '250360505', name: 'Caiçara' },
  { ibge: '250370405', name: 'Cajazeiras' },
  { ibge: '250370410', name: 'Engenheiro Ávidos' },
  { ibge: '250375305', name: 'Cajazeirinhas' },
  { ibge: '250380305', name: 'Caldas Brandão' },
  { ibge: '250390205', name: 'Camalaú' },
  { ibge: '250390210', name: 'Pindurão' },
  { ibge: '250400905', name: 'Campina Grande' },
  { ibge: '250400915', name: 'Catolé' },
  { ibge: '250400920', name: 'Galante' },
  { ibge: '250400925', name: 'São José da Mata' },
  { ibge: '250403305', name: 'Capim' },
  { ibge: '250403315', name: "Olho D'Água do Serrão" },
  { ibge: '250407405', name: 'Caraúbas' },
  { ibge: '250410805', name: 'Carrapateira' },
  { ibge: '250415705', name: 'Casserengue' },
  { ibge: '250420705', name: 'Catingueira' },
  { ibge: '250420710', name: 'Itajubatiba' },
  { ibge: '250430605', name: 'Catolé do Rocha' },
  { ibge: '250430610', name: 'Coronel Maia' },
  { ibge: '250430625', name: 'Picos' },
  { ibge: '250435505', name: 'Caturité' },
  { ibge: '250440505', name: 'Conceição' },
  { ibge: '250440510', name: 'Cardoso' },
  { ibge: '250440515', name: 'Montevidéo' },
  { ibge: '250450405', name: 'Condado' },
  { ibge: '250460305', name: 'Conde' },
  { ibge: '250460316', name: 'Jacumã' },
  { ibge: '250470205', name: 'Congo' },
  { ibge: '250480105', name: 'Coremas' },
  { ibge: '250485005', name: 'Coxixola' },
  { ibge: '250490005', name: 'Cruz do Espírito Santo' },
  { ibge: '250500605', name: 'Cubati' },
  { ibge: '250510505', name: 'Cuité' },
  { ibge: '250510510', name: 'Melo' },
  { ibge: '250520405', name: 'Cuitegi' },
  { ibge: '250523805', name: 'Cuité de Mamanguape' },
  { ibge: '250527905', name: 'Curral de Cima' },
  { ibge: '250530305', name: 'Curral Velho' },
  { ibge: '250535205', name: 'Damião' },
  { ibge: '250540205', name: 'Desterro' },
  { ibge: '250550105', name: 'Vista Serrana' },
  { ibge: '250560005', name: 'Diamante' },
  { ibge: '250560010', name: 'Vazante' },
  { ibge: '250570905', name: 'Dona Inês' },
  { ibge: '250580805', name: 'Duas Estradas' },
  { ibge: '250590705', name: 'Emas' },
  { ibge: '250600405', name: 'Esperança' },
  { ibge: '250610305', name: 'Fagundes' },
  { ibge: '250620205', name: 'Frei Martinho' },
  { ibge: '250625105', name: 'Gado Bravo' },
  { ibge: '250630105', name: 'Guarabira' },
  { ibge: '250630110', name: 'Cachoeira' },
  { ibge: '250640005', name: 'Gurinhém' },
  { ibge: '250650905', name: 'Gurjão' },
  { ibge: '250660805', name: 'Ibiara' },
  { ibge: '250660810', name: 'Cachoeirinha' },
  { ibge: '250670705', name: 'Imaculada' },
  { ibge: '250670715', name: 'Palmeira' },
  { ibge: '250680605', name: 'Ingá' },
  { ibge: '250680607', name: 'Pontina' },
  { ibge: '250690505', name: 'Itabaiana' },
  { ibge: '250690510', name: 'Campo Grande' },
  { ibge: '250690515', name: 'Guarita' },
  { ibge: '250700205', name: 'Itaporanga' },
  { ibge: '250710105', name: 'Itapororoca' },
  { ibge: '250720005', name: 'Itatuba' },
  { ibge: '250730905', name: 'Jacaraú' },
  { ibge: '250730910', name: 'Timbó' },
  { ibge: '250740805', name: 'Jericó' },
  { ibge: '250750705', name: 'João Pessoa' },
  { ibge: '250760605', name: 'Juarez Távora' },
  { ibge: '250770505', name: 'Juazeirinho' },
  { ibge: '250780405', name: 'Junco do Seridó' },
  { ibge: '250790305', name: 'Juripiranga' },
  { ibge: '250800005', name: 'Juru' },
  { ibge: '250810905', name: 'Lagoa' },
  { ibge: '250820805', name: 'Lagoa de Dentro' },
  { ibge: '250830705', name: 'Lagoa Seca' },
  { ibge: '250840605', name: 'Lastro' },
  { ibge: '250850505', name: 'Livramento' },
  { ibge: '250855405', name: 'Logradouro' },
  { ibge: '250860405', name: 'Lucena' },
  { ibge: '250860415', name: 'Praia de Fagundes' },
  { ibge: '250870305', name: "Mãe d'Água" },
  { ibge: '250880205', name: 'Malta' },
  { ibge: '250890105', name: 'Mamanguape' },
  { ibge: '250890125', name: 'Pitanga da Estrada' },
  { ibge: '250900805', name: 'Manaíra' },
  { ibge: '250900810', name: 'Pelo Sinal' },
  { ibge: '250905705', name: 'Marcação' },
  { ibge: '250905710', name: 'Camurupim' },
  { ibge: '250910705', name: 'Mari' },
  { ibge: '250915605', name: 'Marizópolis' },
  { ibge: '250920605', name: 'Massaranduba' },
  { ibge: '250930505', name: 'Mataraca' },
  { ibge: '250930510', name: 'Barra de Camaratuba' },
  { ibge: '250933905', name: 'Matinhas' },
  { ibge: '250937005', name: 'Mato Grosso' },
  { ibge: '250939605', name: 'Maturéia' },
  { ibge: '250940405', name: 'Mogeiro' },
  { ibge: '250940420', name: 'Gameleira' },
  { ibge: '250950305', name: 'Montadas' },
  { ibge: '250960205', name: 'Monte Horebe' },
  { ibge: '250970105', name: 'Monteiro' },
  { ibge: '250980005', name: 'Mulungu' },
  { ibge: '250990905', name: 'Natuba' },
  { ibge: '250990910', name: 'Pirauá' },
  { ibge: '251000605', name: 'Nazarezinho' },
  { ibge: '251010505', name: 'Nova Floresta' },
  { ibge: '251020405', name: 'Nova Olinda' },
  { ibge: '251030305', name: 'Nova Palmeira' },
  { ibge: '251040205', name: "Olho d'Água" },
  { ibge: '251040230', name: 'Socorro' },
  { ibge: '251050105', name: 'Olivedos' },
  { ibge: '251060005', name: 'Ouro Velho' },
  { ibge: '251065905', name: 'Parari' },
  { ibge: '251070905', name: 'Passagem' },
  { ibge: '251080805', name: 'Patos' },
  { ibge: '251080810', name: 'Santa Gertrudes' },
  { ibge: '251090705', name: 'Paulista' },
  { ibge: '251090707', name: 'Ipueiras' },
  { ibge: '251090710', name: 'Mimoso' },
  { ibge: '251100405', name: 'Pedra Branca' },
  { ibge: '251110305', name: 'Pedra Lavrada' },
  { ibge: '251120205', name: 'Pedras de Fogo' },
  { ibge: '251130105', name: 'Piancó' },
  { ibge: '251140005', name: 'Picuí' },
  { ibge: '251150905', name: 'Pilar' },
  { ibge: '251160805', name: 'Pilões' },
  { ibge: '251170705', name: 'Pilõezinhos' },
  { ibge: '251180605', name: 'Pirpirituba' },
  { ibge: '251190505', name: 'Pitimbu' },
  { ibge: '251200205', name: 'Pocinhos' },
  { ibge: '251200210', name: 'Nazaré' },
  { ibge: '251203605', name: 'Poço Dantas' },
  { ibge: '251207705', name: 'Poço de José de Moura' },
  { ibge: '251210105', name: 'Pombal' },
  { ibge: '251210115', name: 'Várzea Comprida' },
  { ibge: '251220005', name: 'Prata' },
  { ibge: '251230905', name: 'Princesa Isabel' },
  { ibge: '251240805', name: 'Puxinanã' },
  { ibge: '251250705', name: 'Queimadas' },
  { ibge: '251260605', name: 'Quixabá' },
  { ibge: '251270505', name: 'Remígio' },
  { ibge: '251272105', name: 'Pedro Régis' },
  { ibge: '251274705', name: 'Riachão' },
  { ibge: '251275405', name: 'Riachão do Bacamarte' },
  { ibge: '251276205', name: 'Riachão do Poço' },
  { ibge: '251278805', name: 'Riacho de Santo Antônio' },
  { ibge: '251280405', name: 'Riacho dos Cavalos' },
  { ibge: '251290305', name: 'Rio Tinto' },
  { ibge: '251290307', name: 'Barra do Mamanguape' },
  { ibge: '251290320', name: 'Salema' },
  { ibge: '251300005', name: 'Salgadinho' },
  { ibge: '251300030', name: 'São José da Batalha' },
  { ibge: '251310905', name: 'Salgado de São Félix' },
  { ibge: '251310907', name: 'Dois Riachos' },
  { ibge: '251310908', name: 'Feira Nova' },
  { ibge: '251315805', name: 'Santa Cecília' },
  { ibge: '251320805', name: 'Santa Cruz' },
  { ibge: '251330705', name: 'Santa Helena' },
  { ibge: '251335605', name: 'Santa Inês' },
  { ibge: '251340605', name: 'Santa Luzia' },
  { ibge: '251350505', name: 'Santana de Mangueira' },
  { ibge: '251360405', name: 'Santana dos Garrotes' },
  { ibge: '251360420', name: 'Pitombeira de Dentro' },
  { ibge: '251365305', name: 'Joca Claudino' },
  { ibge: '251370305', name: 'Santa Rita' },
  { ibge: '251370310', name: 'Nossa Senhora do Livramento' },
  { ibge: '251380205', name: 'Santa Teresinha' },
  { ibge: '251385105', name: 'Santo André' },
  { ibge: '251390105', name: 'São Bento' },
  { ibge: '251392705', name: 'São Bentinho' },
  { ibge: '251394305', name: 'São Domingos do Cariri' },
  { ibge: '251396805', name: 'São Domingos' },
  { ibge: '251398405', name: 'São Francisco' },
  { ibge: '251400805', name: 'São João do Cariri' },
  { ibge: '251410705', name: 'São João do Tigre' },
  { ibge: '251410710', name: 'Santa Maria' },
  { ibge: '251420605', name: 'São José da Lagoa Tapada' },
  { ibge: '251430505', name: 'São José de Caiana' },
  { ibge: '251440405', name: 'São José de Espinharas' },
  { ibge: '251445305', name: 'São José dos Ramos' },
  { ibge: '251450305', name: 'São José de Piranhas' },
  { ibge: '251450310', name: 'Bom Jesus' },
  { ibge: '251455205', name: 'São José de Princesa' },
  { ibge: '251460205', name: 'São José do Bonfim' },
  { ibge: '251465105', name: 'São José do Brejo do Cruz' },
  { ibge: '251470105', name: 'São José do Sabugi' },
  { ibge: '251480005', name: 'São José dos Cordeiros' },
  { ibge: '251490905', name: 'São Mamede' },
  { ibge: '251500505', name: 'São Miguel de Taipu' },
  { ibge: '251510405', name: 'São Sebastião de Lagoa de Roça' },
  { ibge: '251520305', name: 'São Sebastião do Umbuzeiro' },
  { ibge: '251530205', name: 'Sapé' },
  { ibge: '251530220', name: 'Renascença' },
  { ibge: '251540105', name: 'Seridó' },
  { ibge: '251540110', name: 'São Vicente do Seridó' },
  { ibge: '251550005', name: 'Serra Branca' },
  { ibge: '251550015', name: 'Santa Luzia do Cariri' },
  { ibge: '251550020', name: 'Sucuru' },
  { ibge: '251560905', name: 'Serra da Raiz' },
  { ibge: '251570805', name: 'Serra Grande' },
  { ibge: '251580705', name: 'Serra Redonda' },
  { ibge: '251590605', name: 'Serraria' },
  { ibge: '251593005', name: 'Sertãozinho' },
  { ibge: '251597105', name: 'Sobrado' },
  { ibge: '251600305', name: 'Solânea' },
  { ibge: '251610205', name: 'Soledade' },
  { ibge: '251610210', name: 'Bom Sucesso' },
  { ibge: '251615105', name: 'Sossêgo' },
  { ibge: '251620105', name: 'Sousa' },
  { ibge: '251630005', name: 'Sumé' },
  { ibge: '251630015', name: 'Pio X' },
  { ibge: '251640905', name: 'Tacima' },
  { ibge: '251650805', name: 'Taperoá' },
  { ibge: '251660705', name: 'Tavares' },
  { ibge: '251670605', name: 'Teixeira' },
  { ibge: '251675505', name: 'Tenório' },
  { ibge: '251680505', name: 'Triunfo' },
  { ibge: '251690405', name: 'Uiraúna' },
  { ibge: '251700105', name: 'Umbuzeiro' },
  { ibge: '251700110', name: 'Mata Virgem' },
  { ibge: '251710005', name: 'Várzea' },
  { ibge: '251720905', name: 'Vieirópolis' },
  { ibge: '251740705', name: 'Zabelê' },
  { ibge: '260005405', name: 'Abreu e Lima' },
  { ibge: '260005410', name: 'Distrito Industrial' },
  { ibge: '260010405', name: 'Afogados da Ingazeira' },
  { ibge: '260020305', name: 'Afrânio' },
  { ibge: '260020310', name: 'Arizona' },
  { ibge: '260020315', name: 'Cachoeira do Roberto' },
  { ibge: '260020320', name: 'Poção de Afrânio' },
  { ibge: '260030205', name: 'Agrestina' },
  { ibge: '260030210', name: 'Barra do Chata' },
  { ibge: '260030215', name: 'Barra do Jardim' },
  { ibge: '260040105', name: 'Água Preta' },
  { ibge: '260040110', name: 'Santa Terezinha' },
  { ibge: '260050005', name: 'Águas Belas' },
  { ibge: '260050010', name: 'Curral Novo' },
  { ibge: '260060905', name: 'Alagoinha' },
  { ibge: '260060910', name: 'Perpétuo Socorro' },
  { ibge: '260070805', name: 'Aliança' },
  { ibge: '260070810', name: 'Macujê' },
  { ibge: '260070815', name: 'Tupaoca' },
  { ibge: '260070820', name: 'Upatininga' },
  { ibge: '260080705', name: 'Altinho' },
  { ibge: '260080710', name: 'Ituguaçu' },
  { ibge: '260090605', name: 'Amaraji' },
  { ibge: '260100305', name: 'Angelim' },
  { ibge: '260105205', name: 'Araçoiaba' },
  { ibge: '260110205', name: 'Araripina' },
  { ibge: '260110208', name: 'Gergelim' },
  { ibge: '260110210', name: 'Lagoa do Barro' },
  { ibge: '260110215', name: 'Morais' },
  { ibge: '260110220', name: 'Nascente' },
  { ibge: '260110225', name: 'Bom Jardim do Araripe' },
  { ibge: '260110230', name: 'Serrânia' },
  { ibge: '260120105', name: 'Arcoverde' },
  { ibge: '260130005', name: 'Barra de Guabiraba' },
  { ibge: '260140905', name: 'Barreiros' },
  { ibge: '260140910', name: 'Carimã' },
  { ibge: '260150805', name: 'Belém de Maria' },
  { ibge: '260150810', name: 'Batateira' },
  { ibge: '260160705', name: 'Belém do São Francisco' },
  { ibge: '260160710', name: 'Ibó' },
  { ibge: '260160715', name: 'Riacho Pequeno' },
  { ibge: '260170605', name: 'Belo Jardim' },
  { ibge: '260170610', name: 'Água Fria' },
  { ibge: '260170615', name: 'Serra do Vento' },
  { ibge: '260170620', name: 'Xucuru' },
  { ibge: '260180505', name: 'Betânia' },
  { ibge: '260180510', name: 'São Caetano do Navio' },
  { ibge: '260190405', name: 'Bezerros' },
  { ibge: '260190410', name: 'Boas Novas' },
  { ibge: '260190415', name: 'Sapucarana' },
  { ibge: '260200105', name: 'Bodocó' },
  { ibge: '260200110', name: 'Claranã' },
  { ibge: '260200115', name: 'Feitoria' },
  { ibge: '260210005', name: 'Bom Conselho' },
  { ibge: '260210010', name: 'Barra do Brejo' },
  { ibge: '260210015', name: 'Caldeirões' },
  { ibge: '260210020', name: 'Lagoa de São José' },
  { ibge: '260210025', name: 'Rainha Isabel' },
  { ibge: '260220905', name: 'Bom Jardim' },
  { ibge: '260220910', name: 'Bizarra' },
  { ibge: '260220913', name: 'Encruzilhada' },
  { ibge: '260220915', name: 'Tamboatá' },
  { ibge: '260220920', name: 'Umari' },
  { ibge: '260230805', name: 'Bonito' },
  { ibge: '260230810', name: 'Bentevi' },
  { ibge: '260230815', name: 'Alto Bonito' },
  { ibge: '260240705', name: 'Brejão' },
  { ibge: '260250605', name: 'Brejinho' },
  { ibge: '260260505', name: 'Brejo da Madre de Deus' },
  { ibge: '260260507', name: 'Barra do Farias' },
  { ibge: '260260510', name: 'Fazenda Nova' },
  { ibge: '260260520', name: 'Mandaçaia' },
  { ibge: '260260530', name: 'São Domingos' },
  { ibge: '260270405', name: 'Buenos Aires' },
  { ibge: '260280305', name: 'Buíque' },
  { ibge: '260280310', name: 'Carneiro' },
  { ibge: '260280315', name: 'Catimbau' },
  { ibge: '260280320', name: 'Guanumbi' },
  { ibge: '260290205', name: 'Cabo de Santo Agostinho' },
  { ibge: '260290210', name: 'Juçaral' },
  { ibge: '260290215', name: 'Ponte dos Carvalhos' },
  { ibge: '260290220', name: 'Santo Agostinho' },
  { ibge: '260300905', name: 'Cabrobó' },
  { ibge: '260310805', name: 'Cachoeirinha' },
  { ibge: '260310810', name: 'Cabanas' },
  { ibge: '260320705', name: 'Caetés' },
  { ibge: '260330605', name: 'Calçado' },
  { ibge: '260340505', name: 'Calumbi' },
  { ibge: '260345405', name: 'Camaragibe' },
  { ibge: '260350405', name: 'Camocim de São Félix' },
  { ibge: '260360305', name: 'Camutanga' },
  { ibge: '260370205', name: 'Canhotinho' },
  { ibge: '260370210', name: "Olho d'Água de Dentro" },
  { ibge: '260370215', name: 'Paquevira' },
  { ibge: '260380105', name: 'Capoeiras' },
  { ibge: '260390005', name: 'Carnaíba' },
  { ibge: '260390010', name: 'Ibitiranga' },
  { ibge: '260392605', name: 'Carnaubeira da Penha' },
  { ibge: '260400705', name: 'Carpina' },
  { ibge: '260410605', name: 'Caruaru' },
  { ibge: '260410610', name: 'Carapotós' },
  { ibge: '260410615', name: 'Gonçalves Ferreira' },
  { ibge: '260410620', name: 'Lajedo do Cedro' },
  { ibge: '260415505', name: 'Casinhas' },
  { ibge: '260420505', name: 'Catende' },
  { ibge: '260420510', name: 'Laje Grande' },
  { ibge: '260430405', name: 'Cedro' },
  { ibge: '260440305', name: 'Chã de Alegria' },
  { ibge: '260450205', name: 'Chã Grande' },
  { ibge: '260460105', name: 'Condado' },
  { ibge: '260470005', name: 'Correntes' },
  { ibge: '260470010', name: 'Poço Comprido' },
  { ibge: '260480905', name: 'Cortês' },
  { ibge: '260490805', name: 'Cumaru' },
  { ibge: '260490810', name: 'Ameixas' },
  { ibge: '260500405', name: 'Cupira' },
  { ibge: '260500410', name: 'Laje de São José' },
  { ibge: '260510305', name: 'Custódia' },
  { ibge: '260510310', name: 'Maravilha' },
  { ibge: '260510315', name: 'Quitimbu' },
  { ibge: '260515205', name: 'Dormentes' },
  { ibge: '260515210', name: 'Caatinga Grande' },
  { ibge: '260515215', name: 'Lagoa de Fora' },
  { ibge: '260515218', name: 'Lagoas' },
  { ibge: '260515220', name: 'Monte Orebe' },
  { ibge: '260520205', name: 'Escada' },
  { ibge: '260520210', name: 'Frexeiras' },
  { ibge: '260530105', name: 'Exu' },
  { ibge: '260530110', name: 'Tabocas' },
  { ibge: '260530115', name: 'Timorante' },
  { ibge: '260530120', name: 'Viração' },
  { ibge: '260530125', name: 'Zé Gomes' },
  { ibge: '260540005', name: 'Feira Nova' },
  { ibge: '260545905', name: 'Fernando de Noronha' },
  { ibge: '260550905', name: 'Ferreiros' },
  { ibge: '260560805', name: 'Flores' },
  { ibge: '260560808', name: 'Fátima' },
  { ibge: '260560810', name: 'Sítio dos Nunes' },
  { ibge: '260570705', name: 'Floresta' },
  { ibge: '260570710', name: 'Airi' },
  { ibge: '260570720', name: 'Nazaré do Pico' },
  { ibge: '260580605', name: 'Frei Miguelinho' },
  { ibge: '260580610', name: 'Lagoa de João Carlos' },
  { ibge: '260590505', name: 'Gameleira' },
  { ibge: '260590510', name: 'Cuiambuca' },
  { ibge: '260590515', name: 'José da Costa' },
  { ibge: '260600205', name: 'Garanhuns' },
  { ibge: '260600210', name: 'Iratama' },
  { ibge: '260600215', name: 'São Pedro' },
  { ibge: '260600220', name: 'Miracica' },
  { ibge: '260610105', name: 'Glória do Goitá' },
  { ibge: '260610110', name: 'Apoti' },
  { ibge: '260620005', name: 'Goiana' },
  { ibge: '260620010', name: 'Ponta de Pedras' },
  { ibge: '260620015', name: 'Tejucopapo' },
  { ibge: '260630905', name: 'Granito' },
  { ibge: '260640805', name: 'Gravatá' },
  { ibge: '260640810', name: 'Mandacaru' },
  { ibge: '260640815', name: 'Uruçu-Mirim' },
  { ibge: '260650705', name: 'Iati' },
  { ibge: '260660605', name: 'Ibimirim' },
  { ibge: '260660610', name: 'Moxotó' },
  { ibge: '260670505', name: 'Ibirajuba' },
  { ibge: '260680405', name: 'Igarassu' },
  { ibge: '260680420', name: 'Nova Cruz' },
  { ibge: '260680425', name: 'Três Ladeiras' },
  { ibge: '260690305', name: 'Iguaraci' },
  { ibge: '260690310', name: 'Irajaí' },
  { ibge: '260690315', name: 'Jabitacá' },
  { ibge: '260700005', name: 'Inajá' },
  { ibge: '260710905', name: 'Ingazeira' },
  { ibge: '260720805', name: 'Ipojuca' },
  { ibge: '260720810', name: 'Camela' },
  { ibge: '260720815', name: 'Nossa Senhora do Ó' },
  { ibge: '260730705', name: 'Ipubi' },
  { ibge: '260730710', name: 'Serra Branca' },
  { ibge: '260730715', name: 'Serrolândia' },
  { ibge: '260740605', name: 'Itacuruba' },
  { ibge: '260750505', name: 'Itaíba' },
  { ibge: '260750515', name: 'Negras' },
  { ibge: '260760405', name: 'Ilha de Itamaracá' },
  { ibge: '260765305', name: 'Itambé' },
  { ibge: '260765310', name: 'Caricé' },
  { ibge: '260765315', name: 'Ibiranga' },
  { ibge: '260770305', name: 'Itapetim' },
  { ibge: '260770310', name: 'São Vicente' },
  { ibge: '260775205', name: 'Itapissuma' },
  { ibge: '260780205', name: 'Itaquitinga' },
  { ibge: '260790105', name: 'Jaboatão dos Guararapes' },
  { ibge: '260790110', name: 'Cavaleiro' },
  { ibge: '260790115', name: 'Jaboatão' },
  { ibge: '260790120', name: 'Curado' },
  { ibge: '260790125', name: 'Jardim Jordão' },
  { ibge: '260795005', name: 'Jaqueira' },
  { ibge: '260800805', name: 'Jataúba' },
  { ibge: '260800807', name: 'Jacu' },
  { ibge: '260800808', name: 'Jundiá' },
  { ibge: '260800810', name: 'Passagem do Tó' },
  { ibge: '260800820', name: 'Riacho do Meio' },
  { ibge: '260805705', name: 'Jatobá' },
  { ibge: '260805720', name: 'Volta do Moxotó' },
  { ibge: '260810705', name: 'João Alfredo' },
  { ibge: '260820605', name: 'Joaquim Nabuco' },
  { ibge: '260825505', name: 'Jucati' },
  { ibge: '260830505', name: 'Jupi' },
  { ibge: '260840405', name: 'Jurema' },
  { ibge: '260840410', name: 'Santo Antônio das Queimadas' },
  { ibge: '260845305', name: 'Lagoa do Carro' },
  { ibge: '260850305', name: 'Lagoa de Itaenga' },
  { ibge: '260860205', name: 'Lagoa do Ouro' },
  { ibge: '260860210', name: 'Igapó' },
  { ibge: '260870105', name: 'Lagoa dos Gatos' },
  { ibge: '260870110', name: 'Entroncamento' },
  { ibge: '260870115', name: 'Igarapeassu' },
  { ibge: '260870120', name: 'Lagoa do Souza' },
  { ibge: '260875005', name: 'Lagoa Grande' },
  { ibge: '260875015', name: 'Jutaí' },
  { ibge: '260880005', name: 'Lajedo' },
  { ibge: '260890905', name: 'Limoeiro' },
  { ibge: '260890910', name: 'Urucuba' },
  { ibge: '260900605', name: 'Macaparana' },
  { ibge: '260910505', name: 'Machados' },
  { ibge: '260915405', name: 'Manari' },
  { ibge: '260920405', name: 'Maraial' },
  { ibge: '260920415', name: 'Sertãozinho de Baixo' },
  { ibge: '260930305', name: 'Mirandiba' },
  { ibge: '260930310', name: 'Tupanaci' },
  { ibge: '260940205', name: 'Moreno' },
  { ibge: '260940210', name: 'Bonança' },
  { ibge: '260950105', name: 'Nazaré da Mata' },
  { ibge: '260960005', name: 'Olinda' },
  { ibge: '260970905', name: 'Orobó' },
  { ibge: '260970910', name: 'Chã do Rocha' },
  { ibge: '260970915', name: 'Umburetama' },
  { ibge: '260980805', name: 'Orocó' },
  { ibge: '260990705', name: 'Ouricuri' },
  { ibge: '260990710', name: 'Barra de São Pedro' },
  { ibge: '261000405', name: 'Palmares' },
  { ibge: '261000410', name: 'Santo Antônio dos Palmares' },
  { ibge: '261010305', name: 'Palmeirina' },
  { ibge: '261020205', name: 'Panelas' },
  { ibge: '261020210', name: 'Cruzes' },
  { ibge: '261020215', name: 'São José' },
  { ibge: '261020220', name: 'São Lázaro' },
  { ibge: '261030105', name: 'Paranatama' },
  { ibge: '261040005', name: 'Parnamirim' },
  { ibge: '261040010', name: 'Icaiçara' },
  { ibge: '261040015', name: 'Veneza' },
  { ibge: '261050905', name: 'Passira' },
  { ibge: '261050910', name: 'Bengalas' },
  { ibge: '261060805', name: 'Paudalho' },
  { ibge: '261070705', name: 'Paulista' },
  { ibge: '261080605', name: 'Pedra' },
  { ibge: '261080607', name: 'Horizonte Alegre' },
  { ibge: '261080609', name: 'Poço do Boi' },
  { ibge: '261080610', name: 'São Pedro do Cordeiro' },
  { ibge: '261080615', name: 'Santo Antônio' },
  { ibge: '261090505', name: 'Pesqueira' },
  { ibge: '261090510', name: 'Cimbres' },
  { ibge: '261090515', name: 'Mimoso' },
  { ibge: '261090520', name: 'Mutuca' },
  { ibge: '261090525', name: 'Papagaio' },
  { ibge: '261090530', name: 'Salobro' },
  { ibge: '261100205', name: 'Petrolândia' },
  { ibge: '261110105', name: 'Petrolina' },
  { ibge: '261110110', name: 'Cristália' },
  { ibge: '261110115', name: 'Curral Queimado' },
  { ibge: '261110130', name: 'Rajada' },
  { ibge: '261120005', name: 'Poção' },
  { ibge: '261120010', name: 'Pão de Açúcar de Poção' },
  { ibge: '261130905', name: 'Pombos' },
  { ibge: '261130910', name: 'Dois Leões' },
  { ibge: '261130915', name: 'Nossa Senhora do Carmo' },
  { ibge: '261140805', name: 'Primavera' },
  { ibge: '261150705', name: 'Quipapá' },
  { ibge: '261150710', name: 'Pau Ferro' },
  { ibge: '261153305', name: 'Quixaba' },
  { ibge: '261160605', name: 'Recife' },
  { ibge: '261170505', name: 'Riacho das Almas' },
  { ibge: '261170510', name: "Couro d'Antas" },
  { ibge: '261170512', name: 'Pinhões' },
  { ibge: '261170515', name: 'Trapiá' },
  { ibge: '261170520', name: 'Vitorino' },
  { ibge: '261180405', name: 'Ribeirão' },
  { ibge: '261180410', name: 'Aripibu' },
  { ibge: '261180415', name: 'José Mariano' },
  { ibge: '261190305', name: 'Rio Formoso' },
  { ibge: '261190310', name: 'Cocaú' },
  { ibge: '261200005', name: 'Sairé' },
  { ibge: '261210905', name: 'Salgadinho' },
  { ibge: '261220805', name: 'Salgueiro' },
  { ibge: '261220810', name: 'Conceição das Crioulas' },
  { ibge: '261220815', name: 'Umãs' },
  { ibge: '261220820', name: 'Vasques' },
  { ibge: '261220825', name: 'Pau Ferro' },
  { ibge: '261230705', name: 'Saloá' },
  { ibge: '261230710', name: 'Iatecá' },
  { ibge: '261240605', name: 'Sanharó' },
  { ibge: '261240610', name: 'Jenipapo' },
  { ibge: '261240615', name: 'Mulungu' },
  { ibge: '261245505', name: 'Santa Cruz' },
  { ibge: '261247105', name: 'Santa Cruz da Baixa Verde' },
  { ibge: '261250505', name: 'Santa Cruz do Capibaribe' },
  { ibge: '261250510', name: 'Pará' },
  { ibge: '261250515', name: 'Poço Fundo' },
  { ibge: '261255405', name: 'Santa Filomena' },
  { ibge: '261260405', name: 'Santa Maria da Boa Vista' },
  { ibge: '261260408', name: 'Caraíbas' },
  { ibge: '261260425', name: 'Urimamã' },
  { ibge: '261270305', name: 'Santa Maria do Cambucá' },
  { ibge: '261280205', name: 'Santa Terezinha' },
  { ibge: '261290105', name: 'São Benedito do Sul' },
  { ibge: '261290110', name: 'Igarapeba' },
  { ibge: '261300805', name: 'São Bento do Una' },
  { ibge: '261300810', name: 'Espírito Santo' },
  { ibge: '261310705', name: 'São Caitano' },
  { ibge: '261310710', name: 'Maniçoba' },
  { ibge: '261310715', name: 'Tapiraim' },
  { ibge: '261320605', name: 'São João' },
  { ibge: '261330505', name: 'São Joaquim do Monte' },
  { ibge: '261330510', name: 'Barra do Riachão' },
  { ibge: '261330515', name: 'Santana de São Joaquim' },
  { ibge: '261340405', name: 'São José da Coroa Grande' },
  { ibge: '261350305', name: 'São José do Belmonte' },
  { ibge: '261350310', name: 'Bom Nome' },
  { ibge: '261360205', name: 'São José do Egito' },
  { ibge: '261360210', name: 'Bonfim' },
  { ibge: '261360215', name: 'Riacho do Meio' },
  { ibge: '261370105', name: 'São Lourenço da Mata' },
  { ibge: '261370115', name: 'Nossa Senhora da Luz' },
  { ibge: '261380005', name: 'São Vicente Ferrer' },
  { ibge: '261380010', name: 'Siriji' },
  { ibge: '261390905', name: 'Serra Talhada' },
  { ibge: '261390910', name: 'Bernardo Vieira' },
  { ibge: '261390915', name: 'Caiçarinha da Penha' },
  { ibge: '261390918', name: 'Logradouro' },
  { ibge: '261390920', name: 'Luanda' },
  { ibge: '261390925', name: 'Pajeú' },
  { ibge: '261390928', name: 'Santa Rita' },
  { ibge: '261390930', name: 'Tauapiranga' },
  { ibge: '261390935', name: 'Varzinha' },
  { ibge: '261400605', name: 'Serrita' },
  { ibge: '261400610', name: 'Ipuera' },
  { ibge: '261400615', name: 'Ori' },
  { ibge: '261410505', name: 'Sertânia' },
  { ibge: '261410510', name: 'Albuquerque Né' },
  { ibge: '261410515', name: 'Algodões' },
  { ibge: '261410520', name: 'Henrique Dias' },
  { ibge: '261410525', name: 'Rio da Barra' },
  { ibge: '261420405', name: 'Sirinhaém' },
  { ibge: '261420410', name: 'Barra do Sirinhaém' },
  { ibge: '261420415', name: 'Ibiratinga' },
  { ibge: '261430305', name: 'Moreilândia' },
  { ibge: '261430310', name: 'Caririmirim' },
  { ibge: '261440205', name: 'Solidão' },
  { ibge: '261450105', name: 'Surubim' },
  { ibge: '261450112', name: 'Chéus' },
  { ibge: '261460005', name: 'Tabira' },
  { ibge: '261470905', name: 'Tacaimbó' },
  { ibge: '261470910', name: 'Riacho Fechado' },
  { ibge: '261480805', name: 'Tacaratu' },
  { ibge: '261480810', name: 'Caraibeiras' },
  { ibge: '261485705', name: 'Tamandaré' },
  { ibge: '261485715', name: 'Sauê' },
  { ibge: '261500305', name: 'Taquaritinga do Norte' },
  { ibge: '261500310', name: 'Gravatá do Ibiapina' },
  { ibge: '261500315', name: 'Pão de Açucar' },
  { ibge: '261510205', name: 'Terezinha' },
  { ibge: '261520105', name: 'Terra Nova' },
  { ibge: '261530005', name: 'Timbaúba' },
  { ibge: '261530010', name: 'Cruanji' },
  { ibge: '261530015', name: 'Livramento do Tiúma' },
  { ibge: '261540905', name: 'Toritama' },
  { ibge: '261550805', name: 'Tracunhaém' },
  { ibge: '261560705', name: 'Trindade' },
  { ibge: '261570605', name: 'Triunfo' },
  { ibge: '261570610', name: 'Canaã' },
  { ibge: '261570615', name: 'Iraguaçu' },
  { ibge: '261580505', name: 'Tupanatinga' },
  { ibge: '261590405', name: 'Tuparetama' },
  { ibge: '261590410', name: 'Santa Rita' },
  { ibge: '261600105', name: 'Venturosa' },
  { ibge: '261600110', name: 'Grotão' },
  { ibge: '261610005', name: 'Verdejante' },
  { ibge: '261618305', name: 'Vertente do Lério' },
  { ibge: '261620905', name: 'Vertentes' },
  { ibge: '261630805', name: 'Vicência' },
  { ibge: '261630810', name: 'Murupé' },
  { ibge: '261640705', name: 'Vitória de Santo Antão' },
  { ibge: '261640710', name: 'Pirituba' },
  { ibge: '261650605', name: 'Xexéu' },
  { ibge: '270010205', name: 'Água Branca' },
  { ibge: '270020105', name: 'Anadia' },
  { ibge: '270030005', name: 'Arapiraca' },
  { ibge: '270040905', name: 'Atalaia' },
  { ibge: '270040910', name: 'Sapucaia' },
  { ibge: '270050805', name: 'Barra de Santo Antônio' },
  { ibge: '270060705', name: 'Barra de São Miguel' },
  { ibge: '270070605', name: 'Batalha' },
  { ibge: '270080505', name: 'Belém' },
  { ibge: '270090405', name: 'Belo Monte' },
  { ibge: '270100105', name: 'Boca da Mata' },
  { ibge: '270110005', name: 'Branquinha' },
  { ibge: '270120905', name: 'Cacimbinhas' },
  { ibge: '270130805', name: 'Cajueiro' },
  { ibge: '270135705', name: 'Campestre' },
  { ibge: '270140705', name: 'Campo Alegre' },
  { ibge: '270150605', name: 'Campo Grande' },
  { ibge: '270160505', name: 'Canapi' },
  { ibge: '270170405', name: 'Capela' },
  { ibge: '270170410', name: 'Santa Efigênia' },
  { ibge: '270180305', name: 'Carneiros' },
  { ibge: '270190205', name: 'Chã Preta' },
  { ibge: '270200905', name: 'Coité do Nóia' },
  { ibge: '270210805', name: 'Colônia Leopoldina' },
  { ibge: '270220705', name: 'Coqueiro Seco' },
  { ibge: '270230605', name: 'Coruripe' },
  { ibge: '270230610', name: 'Poxim' },
  { ibge: '270235505', name: 'Craíbas' },
  { ibge: '270240505', name: 'Delmiro Gouveia' },
  { ibge: '270250405', name: 'Dois Riachos' },
  { ibge: '270255305', name: 'Estrela de Alagoas' },
  { ibge: '270260305', name: 'Feira Grande' },
  { ibge: '270270205', name: 'Feliz Deserto' },
  { ibge: '270280105', name: 'Flexeiras' },
  { ibge: '270290005', name: 'Girau do Ponciano' },
  { ibge: '270300705', name: 'Ibateguara' },
  { ibge: '270310605', name: 'Igaci' },
  { ibge: '270320505', name: 'Igreja Nova' },
  { ibge: '270330405', name: 'Inhapi' },
  { ibge: '270340305', name: 'Jacaré dos Homens' },
  { ibge: '270350205', name: 'Jacuípe' },
  { ibge: '270360105', name: 'Japaratinga' },
  { ibge: '270370005', name: 'Jaramataia' },
  { ibge: '270375905', name: 'Jequiá da Praia' },
  { ibge: '270380905', name: 'Joaquim Gomes' },
  { ibge: '270390805', name: 'Jundiá' },
  { ibge: '270400505', name: 'Junqueiro' },
  { ibge: '270410405', name: 'Lagoa da Canoa' },
  { ibge: '270420305', name: 'Limoeiro de Anadia' },
  { ibge: '270430205', name: 'Maceió' },
  { ibge: '270440105', name: 'Major Isidoro' },
  { ibge: '270440110', name: 'Riacho do Sertão' },
  { ibge: '270450005', name: 'Maragogi' },
  { ibge: '270450010', name: 'Barra Grande' },
  { ibge: '270460905', name: 'Maravilha' },
  { ibge: '270470805', name: 'Marechal Deodoro' },
  { ibge: '270480705', name: 'Maribondo' },
  { ibge: '270490605', name: 'Mar Vermelho' },
  { ibge: '270500205', name: 'Mata Grande' },
  { ibge: '270510105', name: 'Matriz de Camaragibe' },
  { ibge: '270520005', name: 'Messias' },
  { ibge: '270530905', name: 'Minador do Negrão' },
  { ibge: '270540805', name: 'Monteirópolis' },
  { ibge: '270550705', name: 'Murici' },
  { ibge: '270560605', name: 'Novo Lino' },
  { ibge: '270570505', name: "Olho d'Água das Flores" },
  { ibge: '270580405', name: "Olho d'Água do Casado" },
  { ibge: '270590305', name: "Olho d'Água Grande" },
  { ibge: '270600005', name: 'Olivença' },
  { ibge: '270610905', name: 'Ouro Branco' },
  { ibge: '270620805', name: 'Palestina' },
  { ibge: '270630705', name: 'Palmeira dos Índios' },
  { ibge: '270630710', name: 'Caldeirões de Cima' },
  { ibge: '270630715', name: 'Canafístula' },
  { ibge: '270640605', name: 'Pão de Açúcar' },
  { ibge: '270640610', name: 'Limoeiro' },
  { ibge: '270642205', name: 'Pariconha' },
  { ibge: '270644805', name: 'Paripueira' },
  { ibge: '270650505', name: 'Passo de Camaragibe' },
  { ibge: '270660405', name: 'Paulo Jacinto' },
  { ibge: '270670305', name: 'Penedo' },
  { ibge: '270680205', name: 'Piaçabuçu' },
  { ibge: '270690105', name: 'Pilar' },
  { ibge: '270700805', name: 'Pindoba' },
  { ibge: '270710705', name: 'Piranhas' },
  { ibge: '270710710', name: 'Entremontes' },
  { ibge: '270720605', name: 'Poço das Trincheiras' },
  { ibge: '270730505', name: 'Porto Calvo' },
  { ibge: '270740405', name: 'Porto de Pedras' },
  { ibge: '270740410', name: 'Tatuamunha' },
  { ibge: '270750305', name: 'Porto Real do Colégio' },
  { ibge: '270760205', name: 'Quebrangulo' },
  { ibge: '270770105', name: 'Rio Largo' },
  { ibge: '270780005', name: 'Roteiro' },
  { ibge: '270790905', name: 'Santa Luzia do Norte' },
  { ibge: '270800605', name: 'Santana do Ipanema' },
  { ibge: '270810505', name: 'Santana do Mundaú' },
  { ibge: '270810510', name: 'Munguba' },
  { ibge: '270820405', name: 'São Brás' },
  { ibge: '270830305', name: 'São José da Laje' },
  { ibge: '270840205', name: 'São José da Tapera' },
  { ibge: '270850105', name: 'São Luís do Quitunde' },
  { ibge: '270860005', name: 'São Miguel dos Campos' },
  { ibge: '270870905', name: 'São Miguel dos Milagres' },
  { ibge: '270880805', name: 'São Sebastião' },
  { ibge: '270890705', name: 'Satuba' },
  { ibge: '270895605', name: 'Senador Rui Palmeira' },
  { ibge: '270900405', name: "Tanque d'Arca" },
  { ibge: '270910305', name: 'Taquarana' },
  { ibge: '270915205', name: 'Teotônio Vilela' },
  { ibge: '270920205', name: 'Traipu' },
  { ibge: '270930105', name: 'União dos Palmares' },
  { ibge: '270930110', name: 'Rocha Cavalcante' },
  { ibge: '270940005', name: 'Viçosa' },
  { ibge: '270940010', name: 'Anel' },
  { ibge: '280010005', name: 'Amparo de São Francisco' },
  { ibge: '280020905', name: 'Aquidabã' },
  { ibge: '280030805', name: 'Aracaju' },
  { ibge: '280040705', name: 'Arauá' },
  { ibge: '280050605', name: 'Areia Branca' },
  { ibge: '280060505', name: 'Barra dos Coqueiros' },
  { ibge: '280067005', name: 'Boquim' },
  { ibge: '280070405', name: 'Brejo Grande' },
  { ibge: '280100905', name: 'Campo do Brito' },
  { ibge: '280110805', name: 'Canhoba' },
  { ibge: '280120705', name: 'Canindé de São Francisco' },
  { ibge: '280130605', name: 'Capela' },
  { ibge: '280130610', name: 'Barracas' },
  { ibge: '280130615', name: 'Miranda' },
  { ibge: '280130620', name: 'Pedras' },
  { ibge: '280140505', name: 'Carira' },
  { ibge: '280140510', name: 'Altos Verdes' },
  { ibge: '280150405', name: 'Carmópolis' },
  { ibge: '280160305', name: 'Cedro de São João' },
  { ibge: '280170205', name: 'Cristinápolis' },
  { ibge: '280190005', name: 'Cumbe' },
  { ibge: '280200705', name: 'Divina Pastora' },
  { ibge: '280210605', name: 'Estância' },
  { ibge: '280220505', name: 'Feira Nova' },
  { ibge: '280230405', name: 'Frei Paulo' },
  { ibge: '280240305', name: 'Gararu' },
  { ibge: '280240310', name: 'Lagoa Funda' },
  { ibge: '280240315', name: 'São Mateus da Palestina' },
  { ibge: '280250205', name: 'General Maynard' },
  { ibge: '280260105', name: 'Gracho Cardoso' },
  { ibge: '280270005', name: 'Ilha das Flores' },
  { ibge: '280280905', name: 'Indiaroba' },
  { ibge: '280290805', name: 'Itabaiana' },
  { ibge: '280300505', name: 'Itabaianinha' },
  { ibge: '280310405', name: 'Itabi' },
  { ibge: '280320305', name: "Itaporanga d'Ajuda" },
  { ibge: '280330205', name: 'Japaratuba' },
  { ibge: '280340105', name: 'Japoatã' },
  { ibge: '280350005', name: 'Lagarto' },
  { ibge: '280360905', name: 'Laranjeiras' },
  { ibge: '280370805', name: 'Macambira' },
  { ibge: '280380705', name: 'Malhada dos Bois' },
  { ibge: '280390605', name: 'Malhador' },
  { ibge: '280400305', name: 'Maruim' },
  { ibge: '280410205', name: 'Moita Bonita' },
  { ibge: '280420105', name: 'Monte Alegre de Sergipe' },
  { ibge: '280430005', name: 'Muribeca' },
  { ibge: '280440905', name: 'Neópolis' },
  { ibge: '280445805', name: 'Nossa Senhora Aparecida' },
  { ibge: '280450805', name: 'Nossa Senhora da Glória' },
  { ibge: '280460705', name: 'Nossa Senhora das Dores' },
  { ibge: '280470605', name: 'Nossa Senhora de Lourdes' },
  { ibge: '280480505', name: 'Nossa Senhora do Socorro' },
  { ibge: '280490405', name: 'Pacatuba' },
  { ibge: '280500005', name: 'Pedra Mole' },
  { ibge: '280510905', name: 'Pedrinhas' },
  { ibge: '280520805', name: 'Pinhão' },
  { ibge: '280530705', name: 'Pirambu' },
  { ibge: '280540605', name: 'Poço Redondo' },
  { ibge: '280550505', name: 'Poço Verde' },
  { ibge: '280560405', name: 'Porto da Folha' },
  { ibge: '280570305', name: 'Propriá' },
  { ibge: '280580205', name: 'Riachão do Dantas' },
  { ibge: '280580210', name: 'Palmares' },
  { ibge: '280590105', name: 'Riachuelo' },
  { ibge: '280600805', name: 'Ribeirópolis' },
  { ibge: '280610705', name: 'Rosário do Catete' },
  { ibge: '280620605', name: 'Salgado' },
  { ibge: '280630505', name: 'Santa Luzia do Itanhy' },
  { ibge: '280640405', name: 'Santana do São Francisco' },
  { ibge: '280650305', name: 'Santa Rosa de Lima' },
  { ibge: '280660205', name: 'Santo Amaro das Brotas' },
  { ibge: '280670105', name: 'São Cristóvão' },
  { ibge: '280680005', name: 'São Domingos' },
  { ibge: '280690905', name: 'São Francisco' },
  { ibge: '280700605', name: 'São Miguel do Aleixo' },
  { ibge: '280710505', name: 'Simão Dias' },
  { ibge: '280720405', name: 'Siriri' },
  { ibge: '280730305', name: 'Telha' },
  { ibge: '280740205', name: 'Tobias Barreto' },
  { ibge: '280740210', name: 'Samambaia' },
  { ibge: '280750105', name: 'Tomar do Geru' },
  { ibge: '280760005', name: 'Umbaúba' },
  { ibge: '290010805', name: 'Abaíra' },
  { ibge: '290010810', name: 'Catolés' },
  { ibge: '290020705', name: 'Abaré' },
  { ibge: '290020710', name: 'Ibó' },
  { ibge: '290030605', name: 'Acajutiba' },
  { ibge: '290035505', name: 'Adustina' },
  { ibge: '290040505', name: 'Água Fria' },
  { ibge: '290040510', name: 'Pataíba' },
  { ibge: '290050405', name: 'Érico Cardoso' },
  { ibge: '290060305', name: 'Aiquara' },
  { ibge: '290070205', name: 'Alagoinhas' },
  { ibge: '290070215', name: 'Boa União' },
  { ibge: '290070220', name: 'Riacho da Guia' },
  { ibge: '290080105', name: 'Alcobaça' },
  { ibge: '290080125', name: 'São José' },
  { ibge: '290090005', name: 'Almadina' },
  { ibge: '290100705', name: 'Amargosa' },
  { ibge: '290100710', name: 'Corta Mão' },
  { ibge: '290100715', name: 'Diógenes Sampaio' },
  { ibge: '290100720', name: 'Itachama' },
  { ibge: '290110605', name: 'Amélia Rodrigues' },
  { ibge: '290110610', name: 'Inhatá' },
  { ibge: '290110615', name: 'Mata da Aliança' },
  { ibge: '290115505', name: 'América Dourada' },
  { ibge: '290115510', name: 'Belo Campo' },
  { ibge: '290115515', name: 'Prevenido' },
  { ibge: '290115520', name: 'Soares' },
  { ibge: '290120505', name: 'Anagé' },
  { ibge: '290120510', name: 'Coquinhos' },
  { ibge: '290130405', name: 'Andaraí' },
  { ibge: '290130410', name: 'Igatu' },
  { ibge: '290130420', name: 'Ubiraitá' },
  { ibge: '290135305', name: 'Andorinha' },
  { ibge: '290135310', name: 'Sítio da Baraúna' },
  { ibge: '290135315', name: 'Tanquinho do Poço' },
  { ibge: '290140305', name: 'Angical' },
  { ibge: '290140315', name: 'Missão de Aricobé' },
  { ibge: '290150205', name: 'Anguera' },
  { ibge: '290160105', name: 'Antas' },
  { ibge: '290170005', name: 'Antônio Cardoso' },
  { ibge: '290170012', name: 'Oleiro' },
  { ibge: '290170015', name: 'Poço' },
  { ibge: '290170020', name: 'Santo Estevão Velho' },
  { ibge: '290180905', name: 'Antônio Gonçalves' },
  { ibge: '290190805', name: 'Aporá' },
  { ibge: '290190810', name: 'Itamira' },
  { ibge: '290195705', name: 'Apuarema' },
  { ibge: '290200505', name: 'Aracatu' },
  { ibge: '290205405', name: 'Araças' },
  { ibge: '290210405', name: 'Araci' },
  { ibge: '290220305', name: 'Aramari' },
  { ibge: '290225205', name: 'Arataca' },
  { ibge: '290225210', name: 'Itatingui' },
  { ibge: '290230205', name: 'Aratuípe' },
  { ibge: '290230210', name: 'Maragogipinho' },
  { ibge: '290240105', name: 'Aurelino Leal' },
  { ibge: '290240110', name: 'Laje do Banco' },
  { ibge: '290240115', name: 'Poço Central' },
  { ibge: '290250005', name: 'Baianópolis' },
  { ibge: '290250010', name: 'Várzeas' },
  { ibge: '290260905', name: 'Baixa Grande' },
  { ibge: '290265805', name: 'Banzaê' },
  { ibge: '290270805', name: 'Barra' },
  { ibge: '290270815', name: 'Ibiraba' },
  { ibge: '290270820', name: 'Igarité' },
  { ibge: '290280705', name: 'Barra da Estiva' },
  { ibge: '290280710', name: 'Triunfo do Sincora' },
  { ibge: '290290605', name: 'Barra do Choça' },
  { ibge: '290290610', name: 'Barra Nova' },
  { ibge: '290300305', name: 'Barra do Mendes' },
  { ibge: '290300307', name: 'Antari' },
  { ibge: '290300310', name: 'Minas do Espírito Santo' },
  { ibge: '290310205', name: 'Barra do Rocha' },
  { ibge: '290320105', name: 'Barreiras' },
  { ibge: '290323505', name: 'Barro Alto' },
  { ibge: '290323510', name: 'Lagoa do Boi' },
  { ibge: '290323515', name: 'Lagoa Funda' },
  { ibge: '290327605', name: 'Barrocas' },
  { ibge: '290330005', name: 'Barro Preto' },
  { ibge: '290340905', name: 'Belmonte' },
  { ibge: '290340910', name: 'Boca do Córrego' },
  { ibge: '290340915', name: 'Mogiquiçaba' },
  { ibge: '290350805', name: 'Belo Campo' },
  { ibge: '290360705', name: 'Biritinga' },
  { ibge: '290370605', name: 'Boa Nova' },
  { ibge: '290380505', name: 'Boa Vista do Tupim' },
  { ibge: '290380510', name: 'Brejo Novo' },
  { ibge: '290390405', name: 'Bom Jesus da Lapa' },
  { ibge: '290390407', name: 'Favelândia' },
  { ibge: '290390410', name: 'Formoso' },
  { ibge: '290395305', name: 'Bom Jesus da Serra' },
  { ibge: '290395310', name: 'Água Bela' },
  { ibge: '290400105', name: 'Boninal' },
  { ibge: '290400110', name: 'Bastião' },
  { ibge: '290405005', name: 'Bonito' },
  { ibge: '290410005', name: 'Boquira' },
  { ibge: '290410010', name: 'Bucuituba' },
  { ibge: '290420905', name: 'Botuporã' },
  { ibge: '290430805', name: 'Brejões' },
  { ibge: '290440705', name: 'Brejolândia' },
  { ibge: '290450605', name: 'Brotas de Macaúbas' },
  { ibge: '290450610', name: 'Ouricuri do Ouro' },
  { ibge: '290450615', name: 'Saudável' },
  { ibge: '290460505', name: 'Brumado' },
  { ibge: '290460510', name: 'Cristalândia' },
  { ibge: '290460515', name: 'Itaquaraí' },
  { ibge: '290460520', name: 'Ubiraçaba' },
  { ibge: '290470405', name: 'Buerarema' },
  { ibge: '290475305', name: 'Buritirama' },
  { ibge: '290480305', name: 'Caatiba' },
  { ibge: '290485205', name: 'Cabaceiras do Paraguaçu' },
  { ibge: '290485210', name: 'Geolândia' },
  { ibge: '290490205', name: 'Cachoeira' },
  { ibge: '290490210', name: 'Belém da Cachoeira' },
  { ibge: '290490215', name: 'Santiago do Iguape' },
  { ibge: '290500805', name: 'Caculé' },
  { ibge: '290510705', name: 'Caém' },
  { ibge: '290515605', name: 'Caetanos' },
  { ibge: '290515610', name: 'Alegre' },
  { ibge: '290515615', name: 'Caldeirão' },
  { ibge: '290520605', name: 'Caetité' },
  { ibge: '290520610', name: 'Brejinho das Ametistas' },
  { ibge: '290520615', name: 'Caldeiras' },
  { ibge: '290520625', name: 'Maniaçu' },
  { ibge: '290520630', name: 'Pajeú do Vento' },
  { ibge: '290530505', name: 'Cafarnaum' },
  { ibge: '290530510', name: 'Canal' },
  { ibge: '290540405', name: 'Cairu' },
  { ibge: '290540410', name: 'Galeão' },
  { ibge: '290540415', name: 'Gamboa' },
  { ibge: '290540420', name: 'Velha Boipeba' },
  { ibge: '290550305', name: 'Caldeirão Grande' },
  { ibge: '290560205', name: 'Camacan' },
  { ibge: '290560220', name: 'São João do Panelinha' },
  { ibge: '290570105', name: 'Camaçari' },
  { ibge: '290570110', name: 'Abrantes' },
  { ibge: '290570120', name: 'Monte Gordo' },
  { ibge: '290580005', name: 'Camamu' },
  { ibge: '290580010', name: 'Barcelos do Sul' },
  { ibge: '290580020', name: 'Tapuia' },
  { ibge: '290590905', name: 'Campo Alegre de Lourdes' },
  { ibge: '290590910', name: 'Peixe' },
  { ibge: '290600605', name: 'Campo Formoso' },
  { ibge: '290600615', name: 'Laje dos Negros' },
  { ibge: '290610505', name: 'Canápolis' },
  { ibge: '290620405', name: 'Canarana' },
  { ibge: '290620420', name: 'Paz de Salobro' },
  { ibge: '290630305', name: 'Canavieiras' },
  { ibge: '290630315', name: 'Ouricana' },
  { ibge: '290630320', name: 'Poxim do Sul' },
  { ibge: '290640205', name: 'Candeal' },
  { ibge: '290650105', name: 'Candeias' },
  { ibge: '290650110', name: 'Caboto' },
  { ibge: '290650115', name: 'Caroba' },
  { ibge: '290650120', name: 'Madeira' },
  { ibge: '290650125', name: 'Menino Jesus' },
  { ibge: '290650130', name: 'Passagem dos Teixeiras' },
  { ibge: '290650135', name: 'Passé' },
  { ibge: '290660005', name: 'Candiba' },
  { ibge: '290660015', name: 'Pilões' },
  { ibge: '290670905', name: 'Cândido Sales' },
  { ibge: '290670915', name: 'Lagoa Grande' },
  { ibge: '290670920', name: 'Quaraçu' },
  { ibge: '290680805', name: 'Cansanção' },
  { ibge: '290682405', name: 'Canudos' },
  { ibge: '290682410', name: 'Bedengó' },
  { ibge: '290685705', name: 'Capela do Alto Alegre' },
  { ibge: '290687305', name: 'Capim Grosso' },
  { ibge: '290689905', name: 'Caraíbas' },
  { ibge: '290689940', name: 'Vila Mariana' },
  { ibge: '290690705', name: 'Caravelas' },
  { ibge: '290690710', name: 'Juerana' },
  { ibge: '290690715', name: 'Ponta da Areia' },
  { ibge: '290690720', name: 'Santo Antônio de Barcelona' },
  { ibge: '290700405', name: 'Cardeal da Silva' },
  { ibge: '290710305', name: 'Carinhanha' },
  { ibge: '290710310', name: 'Barra da Parateca' },
  { ibge: '290720205', name: 'Casa Nova' },
  { ibge: '290720210', name: 'Bem-Bom' },
  { ibge: '290720215', name: 'Luís Viana' },
  { ibge: '290720220', name: 'Pau a Pique' },
  { ibge: '290720225', name: 'Sobrado' },
  { ibge: '290730105', name: 'Castro Alves' },
  { ibge: '290730115', name: 'Crussai' },
  { ibge: '290730125', name: 'Petim' },
  { ibge: '290730130', name: 'Sítio do Meio' },
  { ibge: '290740005', name: 'Catolândia' },
  { ibge: '290750905', name: 'Catu' },
  { ibge: '290750910', name: 'Bela Flor' },
  { ibge: '290750915', name: 'Sítio Novo' },
  { ibge: '290755805', name: 'Caturama' },
  { ibge: '290760805', name: 'Central' },
  { ibge: '290770705', name: 'Chorrochó' },
  { ibge: '290780605', name: 'Cícero Dantas' },
  { ibge: '290780615', name: 'São João da Fortaleza' },
  { ibge: '290790505', name: 'Cipó' },
  { ibge: '290800205', name: 'Coaraci' },
  { ibge: '290800210', name: 'Itamotinga' },
  { ibge: '290800220', name: 'São Roque' },
  { ibge: '290810105', name: 'Cocos' },
  { ibge: '290820005', name: 'Conceição da Feira' },
  { ibge: '290830905', name: 'Conceição do Almeida' },
  { ibge: '290830910', name: 'Comércio' },
  { ibge: '290830915', name: 'Rio da Dona' },
  { ibge: '290840805', name: 'Conceição do Coité' },
  { ibge: '290840806', name: 'Bandiaçu' },
  { ibge: '290840808', name: 'Joazeiro' },
  { ibge: '290840809', name: 'São João' },
  { ibge: '290840810', name: 'Salgadália' },
  { ibge: '290840815', name: 'Aroeira' },
  { ibge: '290850705', name: 'Conceição do Jacuípe' },
  { ibge: '290860605', name: 'Conde' },
  { ibge: '290870505', name: 'Condeúba' },
  { ibge: '290870508', name: 'Alegre' },
  { ibge: '290880405', name: 'Contendas do Sincorá' },
  { ibge: '290880410', name: 'Caraibuna' },
  { ibge: '290890305', name: 'Coração de Maria' },
  { ibge: '290890310', name: 'Itacava' },
  { ibge: '290890330', name: 'Retiro' },
  { ibge: '290900005', name: 'Cordeiros' },
  { ibge: '290910905', name: 'Coribe' },
  { ibge: '290910910', name: 'Descoberto' },
  { ibge: '290920805', name: 'Coronel João Sá' },
  { ibge: '290930705', name: 'Correntina' },
  { ibge: '290930710', name: 'São Manoel do Norte' },
  { ibge: '290940605', name: 'Cotegipe' },
  { ibge: '290940610', name: 'Jupaguá' },
  { ibge: '290940615', name: 'Taguá' },
  { ibge: '290950505', name: 'Cravolândia' },
  { ibge: '290960405', name: 'Crisópolis' },
  { ibge: '290960410', name: 'Buril' },
  { ibge: '290970305', name: 'Cristópolis' },
  { ibge: '290980205', name: 'Cruz das Almas' },
  { ibge: '290990105', name: 'Curaçá' },
  { ibge: '290990110', name: 'Barro Vermelho' },
  { ibge: '290990115', name: 'Patamuté' },
  { ibge: '290990120', name: 'Poço de Fora' },
  { ibge: '290990125', name: 'Riacho Seco' },
  { ibge: '291000805', name: 'Dário Meira' },
  { ibge: '291005705', name: "Dias d'Ávila" },
  { ibge: '291010705', name: 'Dom Basílio' },
  { ibge: '291020605', name: 'Dom Macedo Costa' },
  { ibge: '291030505', name: 'Elísio Medrado' },
  { ibge: '291030510', name: 'Monte Cruzeiro' },
  { ibge: '291040405', name: 'Encruzilhada' },
  { ibge: '291050305', name: 'Entre Rios' },
  { ibge: '291050310', name: 'Ibatuí' },
  { ibge: '291050315', name: 'Subaúma' },
  { ibge: '291060205', name: 'Esplanada' },
  { ibge: '291060210', name: 'Palame' },
  { ibge: '291060220', name: 'São José do Mucambo' },
  { ibge: '291070105', name: 'Euclides da Cunha' },
  { ibge: '291070107', name: 'Aribice' },
  { ibge: '291070109', name: 'Caimbe' },
  { ibge: '291070115', name: 'Massacará' },
  { ibge: '291072705', name: 'Eunápolis' },
  { ibge: '291075005', name: 'Fátima' },
  { ibge: '291077605', name: 'Feira da Mata' },
  { ibge: '291077620', name: 'Ramalho' },
  { ibge: '291080005', name: 'Feira de Santana' },
  { ibge: '291080010', name: 'Bonfim da Feira' },
  { ibge: '291080015', name: 'Humildes' },
  { ibge: '291080020', name: 'Governador Dr. João Durval Carneiro' },
  { ibge: '291080025', name: 'Jaguara' },
  { ibge: '291080030', name: 'Jaíba' },
  { ibge: '291080035', name: 'Maria Quitéria' },
  { ibge: '291080038', name: 'Matinha' },
  { ibge: '291080040', name: 'Tiquaruçu' },
  { ibge: '291085905', name: 'Filadélfia' },
  { ibge: '291090905', name: 'Firmino Alves' },
  { ibge: '291090910', name: 'Itaiá' },
  { ibge: '291100605', name: 'Floresta Azul' },
  { ibge: '291110505', name: 'Formosa do Rio Preto' },
  { ibge: '291120405', name: 'Gandu' },
  { ibge: '291125305', name: 'Gavião' },
  { ibge: '291130305', name: 'Gentio do Ouro' },
  { ibge: '291130310', name: 'Gameleira do Assuruá' },
  { ibge: '291130315', name: 'Ibitunane' },
  { ibge: '291130320', name: 'Itajubaquara' },
  { ibge: '291130323', name: 'Pituba' },
  { ibge: '291130325', name: 'Santo Inácio' },
  { ibge: '291140205', name: 'Glória' },
  { ibge: '291150105', name: 'Gongogi' },
  { ibge: '291150110', name: 'Tapirama' },
  { ibge: '291160005', name: 'Governador Mangabeira' },
  { ibge: '291165905', name: 'Guajeru' },
  { ibge: '291170905', name: 'Guanambi' },
  { ibge: '291170910', name: 'Ceraíma' },
  { ibge: '291170912', name: 'Morrinhos' },
  { ibge: '291170915', name: 'Mutãs' },
  { ibge: '291180805', name: 'Guaratinga' },
  { ibge: '291180810', name: 'Buranhém' },
  { ibge: '291185705', name: 'Heliópolis' },
  { ibge: '291190705', name: 'Iaçu' },
  { ibge: '291190710', name: 'João Amaro' },
  { ibge: '291190715', name: 'Lajedo Alto' },
  { ibge: '291200405', name: 'Ibiassucê' },
  { ibge: '291210305', name: 'Ibicaraí' },
  { ibge: '291220205', name: 'Ibicoara' },
  { ibge: '291220215', name: 'Cascavel' },
  { ibge: '291230105', name: 'Ibicuí' },
  { ibge: '291230110', name: 'Água Doce' },
  { ibge: '291230115', name: 'Ibitupã' },
  { ibge: '291240005', name: 'Ibipeba' },
  { ibge: '291240010', name: 'Iguitu' },
  { ibge: '291240015', name: 'Lagoa Grande' },
  { ibge: '291240020', name: 'Mirorós' },
  { ibge: '291250905', name: 'Ibipitanga' },
  { ibge: '291260805', name: 'Ibiquera' },
  { ibge: '291270705', name: 'Ibirapitanga' },
  { ibge: '291280605', name: 'Ibirapuã' },
  { ibge: '291290505', name: 'Ibirataia' },
  { ibge: '291290510', name: 'Algodão' },
  { ibge: '291300205', name: 'Ibitiara' },
  { ibge: '291300208', name: 'Mocambo' },
  { ibge: '291300210', name: "Olhos d'Água do Seco" },
  { ibge: '291310105', name: 'Ibititá' },
  { ibge: '291310120', name: 'Canoão' },
  { ibge: '291310130', name: 'Feira Nova' },
  { ibge: '291320005', name: 'Ibotirama' },
  { ibge: '291320010', name: 'Boa Vista do Lagamar' },
  { ibge: '291330905', name: 'Ichu' },
  { ibge: '291340805', name: 'Igaporã' },
  { ibge: '291345705', name: 'Igrapiúna' },
  { ibge: '291350705', name: 'Iguaí' },
  { ibge: '291350710', name: 'Iguaibi' },
  { ibge: '291360605', name: 'Ilhéus' },
  { ibge: '291360610', name: 'Aritaguá' },
  { ibge: '291360615', name: 'Banco Central' },
  { ibge: '291360625', name: 'Castelo Novo' },
  { ibge: '291360630', name: 'Coutos' },
  { ibge: '291360635', name: 'Inema' },
  { ibge: '291360640', name: 'Japu' },
  { ibge: '291360645', name: 'Olivença' },
  { ibge: '291360650', name: 'Pimenteira' },
  { ibge: '291360655', name: 'Rio do Braço' },
  { ibge: '291370505', name: 'Inhambupe' },
  { ibge: '291380405', name: 'Ipecaetá' },
  { ibge: '291380410', name: 'Cavunge' },
  { ibge: '291390305', name: 'Ipiaú' },
  { ibge: '291400005', name: 'Ipirá' },
  { ibge: '291400015', name: 'Bonfim de Ipirá' },
  { ibge: '291400025', name: 'Malhador' },
  { ibge: '291410905', name: 'Ipupiara' },
  { ibge: '291410910', name: 'Ibipetum' },
  { ibge: '291420805', name: 'Irajuba' },
  { ibge: '291430705', name: 'Iramaia' },
  { ibge: '291430710', name: 'Novo acre' },
  { ibge: '291440605', name: 'Iraquara' },
  { ibge: '291440610', name: 'Iraporanga' },
  { ibge: '291450505', name: 'Irará' },
  { ibge: '291450510', name: 'Bento Simões' },
  { ibge: '291460405', name: 'Irecê' },
  { ibge: '291460425', name: 'Angical' },
  { ibge: '291460430', name: 'Conquista' },
  { ibge: '291460435', name: 'Itapicuru' },
  { ibge: '291465305', name: 'Itabela' },
  { ibge: '291470305', name: 'Itaberaba' },
  { ibge: '291480205', name: 'Itabuna' },
  { ibge: '291490105', name: 'Itacaré' },
  { ibge: '291490110', name: 'Taboquinhas' },
  { ibge: '291500705', name: 'Itaeté' },
  { ibge: '291510605', name: 'Itagi' },
  { ibge: '291520505', name: 'Itagibá' },
  { ibge: '291520510', name: 'Japomirim' },
  { ibge: '291530405', name: 'Itagimirim' },
  { ibge: '291535305', name: 'Itaguaçu da Bahia' },
  { ibge: '291540305', name: 'Itaju do Colônia' },
  { ibge: '291540320', name: 'Palmira' },
  { ibge: '291550205', name: 'Itajuípe' },
  { ibge: '291550210', name: 'Bandeira do Almada' },
  { ibge: '291560105', name: 'Itamaraju' },
  { ibge: '291570005', name: 'Itamari' },
  { ibge: '291580905', name: 'Itambé' },
  { ibge: '291580910', name: 'Catolezinho' },
  { ibge: '291580915', name: 'São José do Colônia' },
  { ibge: '291590805', name: 'Itanagra' },
  { ibge: '291600505', name: 'Itanhém' },
  { ibge: '291600510', name: 'Batinga' },
  { ibge: '291600515', name: 'Ibirajá' },
  { ibge: '291610405', name: 'Itaparica' },
  { ibge: '291620305', name: 'Itapé' },
  { ibge: '291630205', name: 'Itapebi' },
  { ibge: '291630210', name: 'Caiubi' },
  { ibge: '291640105', name: 'Itapetinga' },
  { ibge: '291640110', name: 'Bandeira do Colônia' },
  { ibge: '291650005', name: 'Itapicuru' },
  { ibge: '291650010', name: 'Sambaíba' },
  { ibge: '291660905', name: 'Itapitanga' },
  { ibge: '291670805', name: 'Itaquara' },
  { ibge: '291680705', name: 'Itarantim' },
  { ibge: '291680710', name: 'Ribeirão do Salto' },
  { ibge: '291685605', name: 'Itatim' },
  { ibge: '291690605', name: 'Itiruçu' },
  { ibge: '291700305', name: 'Itiúba' },
  { ibge: '291710205', name: 'Itororó' },
  { ibge: '291710210', name: 'Itati' },
  { ibge: '291710215', name: 'Rio do Meio' },
  { ibge: '291720105', name: 'Ituaçu' },
  { ibge: '291720130', name: 'Tranqueiras' },
  { ibge: '291730005', name: 'Ituberá' },
  { ibge: '291733405', name: 'Iuiú' },
  { ibge: '291735905', name: 'Jaborandi' },
  { ibge: '291740905', name: 'Jacaraci' },
  { ibge: '291740910', name: 'Irundiara' },
  { ibge: '291740915', name: 'Paiol' },
  { ibge: '291750805', name: 'Jacobina' },
  { ibge: '291750810', name: 'Catinga do Moura' },
  { ibge: '291750815', name: 'Itaitu' },
  { ibge: '291750820', name: 'Itapeipu' },
  { ibge: '291750826', name: 'Junco' },
  { ibge: '291760705', name: 'Jaguaquara' },
  { ibge: '291760715', name: 'Ipiúna' },
  { ibge: '291770605', name: 'Jaguarari' },
  { ibge: '291770608', name: 'Pilar' },
  { ibge: '291770610', name: 'Juacema' },
  { ibge: '291780505', name: 'Jaguaripe' },
  { ibge: '291780510', name: 'Camassandi' },
  { ibge: '291780515', name: 'Cunhangi' },
  { ibge: '291780520', name: 'Jacuruna' },
  { ibge: '291780526', name: 'Pirajuia' },
  { ibge: '291790405', name: 'Jandaíra' },
  { ibge: '291790410', name: 'Abadia' },
  { ibge: '291790415', name: 'Itanhi' },
  { ibge: '291790420', name: 'Mangue Seco' },
  { ibge: '291800105', name: 'Jequié' },
  { ibge: '291800110', name: 'Baixão' },
  { ibge: '291800115', name: 'Boaçu' },
  { ibge: '291800118', name: 'Florestal' },
  { ibge: '291800120', name: 'Itaibó' },
  { ibge: '291800125', name: 'Itajuru' },
  { ibge: '291800128', name: 'Monte Branco' },
  { ibge: '291800130', name: 'Oriente Novo' },
  { ibge: '291810005', name: 'Jeremoabo' },
  { ibge: '291810010', name: 'Canché' },
  { ibge: '291820905', name: 'Jiquiriçá' },
  { ibge: '291830805', name: 'Jitaúna' },
  { ibge: '291835705', name: 'João Dourado' },
  { ibge: '291840705', name: 'Juazeiro' },
  { ibge: '291840707', name: 'Abóbora' },
  { ibge: '291840710', name: 'Carnaíba do Sertão' },
  { ibge: '291840715', name: 'Itamotinga' },
  { ibge: '291840720', name: 'Junco' },
  { ibge: '291840725', name: 'Juremal' },
  { ibge: '291840730', name: 'Massaroca' },
  { ibge: '291840735', name: 'Pinhões' },
  { ibge: '291845605', name: 'Jucuruçu' },
  { ibge: '291850605', name: 'Jussara' },
  { ibge: '291850607', name: 'Queimada Nova' },
  { ibge: '291850610', name: 'Recife' },
  { ibge: '291855505', name: 'Jussari' },
  { ibge: '291860505', name: 'Jussiape' },
  { ibge: '291860510', name: 'Caraguataí' },
  { ibge: '291870405', name: 'Lafaiete Coutinho' },
  { ibge: '291875305', name: 'Lagoa Real' },
  { ibge: '291880305', name: 'Laje' },
  { ibge: '291880310', name: 'Capão' },
  { ibge: '291880315', name: 'Engenheiro Pontes' },
  { ibge: '291890205', name: 'Lajedão' },
  { ibge: '291900905', name: 'Lajedinho' },
  { ibge: '291905805', name: 'Lajedo do Tabocal' },
  { ibge: '291910805', name: 'Lamarão' },
  { ibge: '291915705', name: 'Lapão' },
  { ibge: '291920705', name: 'Lauro de Freitas' },
  { ibge: '291930605', name: 'Lençóis' },
  { ibge: '291930610', name: 'Afrânio Peixoto' },
  { ibge: '291930615', name: 'Coronel Octaviano Alves' },
  { ibge: '291940505', name: 'Licínio de Almeida' },
  { ibge: '291940510', name: 'Tauape' },
  { ibge: '291950405', name: 'Livramento de Nossa Senhora' },
  { ibge: '291950410', name: 'Iguatemi' },
  { ibge: '291950415', name: 'Itanagé' },
  { ibge: '291950420', name: 'São Timóteo' },
  { ibge: '291955305', name: 'Luís Eduardo Magalhães' },
  { ibge: '291960305', name: 'Macajuba' },
  { ibge: '291960321', name: 'Nova Cruz' },
  { ibge: '291960326', name: 'Santa Luzia' },
  { ibge: '291970205', name: 'Macarani' },
  { ibge: '291980105', name: 'Macaúbas' },
  { ibge: '291980110', name: 'Canatiba' },
  { ibge: '291980115', name: 'Lagoa Clara' },
  { ibge: '291990005', name: 'Macururé' },
  { ibge: '291992605', name: 'Madre de Deus' },
  { ibge: '291995905', name: 'Maetinga' },
  { ibge: '292000705', name: 'Maiquinique' },
  { ibge: '292010605', name: 'Mairi' },
  { ibge: '292010610', name: 'Angico' },
  { ibge: '292020505', name: 'Malhada' },
  { ibge: '292020510', name: 'Canabrava' },
  { ibge: '292020515', name: 'Parateca' },
  { ibge: '292030405', name: 'Malhada de Pedras' },
  { ibge: '292040305', name: 'Manoel Vitorino' },
  { ibge: '292040310', name: 'Catingal' },
  { ibge: '292045205', name: 'Mansidão' },
  { ibge: '292045210', name: 'Aroeiras' },
  { ibge: '292050205', name: 'Maracás' },
  { ibge: '292060105', name: 'Maragogipe' },
  { ibge: '292060110', name: 'Coqueiros' },
  { ibge: '292060115', name: 'Guaí' },
  { ibge: '292060120', name: 'Guapira' },
  { ibge: '292060125', name: 'Nagé' },
  { ibge: '292060130', name: 'São Roque do Paraguaçu' },
  { ibge: '292070005', name: 'Maraú' },
  { ibge: '292070010', name: 'Ibiaçu' },
  { ibge: '292070015', name: 'Piabanha' },
  { ibge: '292080905', name: 'Marcionílio Souza' },
  { ibge: '292080910', name: 'Juraci' },
  { ibge: '292090805', name: 'Mascote' },
  { ibge: '292090811', name: 'Pimenta' },
  { ibge: '292090816', name: 'São João do Paraíso' },
  { ibge: '292090825', name: 'Teixeira do Progresso' },
  { ibge: '292100505', name: 'Mata de São João' },
  { ibge: '292100510', name: 'Açu da Torre' },
  { ibge: '292100515', name: 'Amado Bahia' },
  { ibge: '292105405', name: 'Matina' },
  { ibge: '292110405', name: 'Medeiros Neto' },
  { ibge: '292110410', name: 'Itupeva' },
  { ibge: '292110415', name: 'Nova Lídice' },
  { ibge: '292120305', name: 'Miguel Calmon' },
  { ibge: '292120310', name: 'Itapura' },
  { ibge: '292120315', name: 'Tapiranga' },
  { ibge: '292130205', name: 'Milagres' },
  { ibge: '292130210', name: 'Tartaruga' },
  { ibge: '292140105', name: 'Mirangaba' },
  { ibge: '292140110', name: 'Nuguaçu' },
  { ibge: '292140113', name: 'Canabrava' },
  { ibge: '292140115', name: 'Taquarandi' },
  { ibge: '292145005', name: 'Mirante' },
  { ibge: '292150005', name: 'Monte Santo' },
  { ibge: '292160905', name: 'Morpará' },
  { ibge: '292160910', name: 'Quixaba' },
  { ibge: '292170805', name: 'Morro do Chapéu' },
  { ibge: '292170810', name: 'Camirim' },
  { ibge: '292170815', name: 'Dias Coelho' },
  { ibge: '292170820', name: 'Duas Barras do Morro' },
  { ibge: '292170822', name: 'Icó' },
  { ibge: '292170824', name: 'Tamboril' },
  { ibge: '292170830', name: 'Ventura' },
  { ibge: '292180705', name: 'Mortugaba' },
  { ibge: '292190605', name: 'Mucugê' },
  { ibge: '292190610', name: 'Guiné' },
  { ibge: '292190615', name: 'João Correia' },
  { ibge: '292200305', name: 'Mucuri' },
  { ibge: '292200310', name: 'Ibiranhém' },
  { ibge: '292200312', name: 'Itabatã' },
  { ibge: '292200315', name: 'Taquarinha' },
  { ibge: '292205205', name: 'Mulungu do Morro' },
  { ibge: '292205210', name: 'Canudos' },
  { ibge: '292205215', name: 'Várzea do Cerco' },
  { ibge: '292210205', name: 'Mundo Novo' },
  { ibge: '292210210', name: 'Alto Bonito' },
  { ibge: '292210215', name: 'Ibiaporã' },
  { ibge: '292210220', name: 'Indaí' },
  { ibge: '292220105', name: 'Muniz Ferreira' },
  { ibge: '292220110', name: 'Onha' },
  { ibge: '292225005', name: 'Muquém de São Francisco' },
  { ibge: '292230005', name: 'Muritiba' },
  { ibge: '292230020', name: 'Itaporã' },
  { ibge: '292240905', name: 'Mutuípe' },
  { ibge: '292250805', name: 'Nazaré' },
  { ibge: '292260705', name: 'Nilo Peçanha' },
  { ibge: '292265605', name: 'Nordestina' },
  { ibge: '292270605', name: 'Nova Canaã' },
  { ibge: '292270610', name: 'Itajaí' },
  { ibge: '292273005', name: 'Nova Fátima' },
  { ibge: '292275505', name: 'Nova Ibiá' },
  { ibge: '292280505', name: 'Nova Itarana' },
  { ibge: '292285405', name: 'Nova Redenção' },
  { ibge: '292290405', name: 'Nova Soure' },
  { ibge: '292300105', name: 'Nova Viçosa' },
  { ibge: '292300110', name: 'Argolo' },
  { ibge: '292300115', name: 'Helvécia' },
  { ibge: '292300125', name: 'Posto da Mata' },
  { ibge: '292303505', name: 'Novo Horizonte' },
  { ibge: '292303510', name: 'Brejo Luiza de Brito' },
  { ibge: '292303515', name: 'Remédios' },
  { ibge: '292305005', name: 'Novo Triunfo' },
  { ibge: '292310005', name: 'Olindina' },
  { ibge: '292320905', name: 'Oliveira dos Brejinhos' },
  { ibge: '292320910', name: 'Bom Sossego' },
  { ibge: '292320915', name: 'Ipuçaba' },
  { ibge: '292330805', name: 'Ouriçangas' },
  { ibge: '292335705', name: 'Ourolândia' },
  { ibge: '292340705', name: 'Palmas de Monte Alto' },
  { ibge: '292340715', name: 'Espraiado' },
  { ibge: '292340720', name: 'Pinga Fogo' },
  { ibge: '292340725', name: 'Rancho das Mães' },
  { ibge: '292350605', name: 'Palmeiras' },
  { ibge: '292350610', name: 'Caeté-Açu' },
  { ibge: '292360505', name: 'Paramirim' },
  { ibge: '292360510', name: 'Canabravinha' },
  { ibge: '292370405', name: 'Paratinga' },
  { ibge: '292370410', name: 'Águas do Paulista' },
  { ibge: '292380305', name: 'Paripiranga' },
  { ibge: '292380310', name: 'Conceição de Campinas' },
  { ibge: '292390205', name: 'Pau Brasil' },
  { ibge: '292400905', name: 'Paulo Afonso' },
  { ibge: '292405805', name: 'Pé de Serra' },
  { ibge: '292410805', name: 'Pedrão' },
  { ibge: '292420705', name: 'Pedro Alexandre' },
  { ibge: '292430605', name: 'Piatã' },
  { ibge: '292430610', name: 'Cabrália' },
  { ibge: '292430615', name: 'Inúbia' },
  { ibge: '292440505', name: 'Pilão Arcado' },
  { ibge: '292440510', name: 'Baluarte' },
  { ibge: '292440515', name: 'Brejo da Serra' },
  { ibge: '292440520', name: 'Saldanha' },
  { ibge: '292450405', name: 'Pindaí' },
  { ibge: '292450410', name: 'Guirapá' },
  { ibge: '292460305', name: 'Pindobaçu' },
  { ibge: '292460310', name: 'Bananeiras' },
  { ibge: '292460315', name: 'Carnaíba de Baixo' },
  { ibge: '292460325', name: 'Várzea Grande' },
  { ibge: '292465205', name: 'Pintadas' },
  { ibge: '292467805', name: 'Piraí do Norte' },
  { ibge: '292470205', name: 'Piripá' },
  { ibge: '292480105', name: 'Piritiba' },
  { ibge: '292480110', name: 'França' },
  { ibge: '292480115', name: 'Largo' },
  { ibge: '292480125', name: 'Porto Feliz' },
  { ibge: '292490005', name: 'Planaltino' },
  { ibge: '292490010', name: 'Ibitiguira' },
  { ibge: '292490015', name: 'Nova Itaípe' },
  { ibge: '292500605', name: 'Planalto' },
  { ibge: '292500610', name: 'Lucaia' },
  { ibge: '292510505', name: 'Poções' },
  { ibge: '292520405', name: 'Pojuca' },
  { ibge: '292520410', name: 'Miranga' },
  { ibge: '292525305', name: 'Ponto Novo' },
  { ibge: '292530305', name: 'Porto Seguro' },
  { ibge: '292530307', name: "Arraial D'Ajuda" },
  { ibge: '292530310', name: 'Caraiva' },
  { ibge: '292530315', name: 'Vale Verde' },
  { ibge: '292530320', name: 'Trancoso' },
  { ibge: '292540205', name: 'Potiraguá' },
  { ibge: '292540210', name: 'Gurupá Mirim' },
  { ibge: '292550105', name: 'Prado' },
  { ibge: '292550110', name: 'Cumuruxatiba' },
  { ibge: '292560005', name: 'Presidente Dutra' },
  { ibge: '292560010', name: 'Campo Formoso' },
  { ibge: '292570905', name: 'Presidente Jânio Quadros' },
  { ibge: '292575805', name: 'Presidente Tancredo Neves' },
  { ibge: '292580805', name: 'Queimadas' },
  { ibge: '292580808', name: 'Coronel João Borges' },
  { ibge: '292590705', name: 'Quijingue' },
  { ibge: '292590710', name: 'Algodões' },
  { ibge: '292593105', name: 'Quixabeira' },
  { ibge: '292595605', name: 'Rafael Jambeiro' },
  { ibge: '292595610', name: 'Argoim' },
  { ibge: '292595615', name: 'Cajueiro' },
  { ibge: '292595625', name: 'Paraguassú' },
  { ibge: '292600405', name: 'Remanso' },
  { ibge: '292600410', name: 'Poços' },
  { ibge: '292610305', name: 'Retirolândia' },
  { ibge: '292620205', name: 'Riachão das Neves' },
  { ibge: '292620210', name: 'Cariparé' },
  { ibge: '292620215', name: 'São José do Rio Grande' },
  { ibge: '292630105', name: 'Riachão do Jacuípe' },
  { ibge: '292630107', name: 'Barreiros' },
  { ibge: '292640005', name: 'Riacho de Santana' },
  { ibge: '292640010', name: 'Botuquara' },
  { ibge: '292650905', name: 'Ribeira do Amparo' },
  { ibge: '292660805', name: 'Ribeira do Pombal' },
  { ibge: '292665705', name: 'Ribeirão do Largo' },
  { ibge: '292665710', name: 'Nova Brasilia' },
  { ibge: '292670705', name: 'Rio de Contas' },
  { ibge: '292670710', name: 'Arapiranga' },
  { ibge: '292670715', name: 'Marcolino Moura' },
  { ibge: '292680605', name: 'Rio do Antônio' },
  { ibge: '292680610', name: 'Ibitira' },
  { ibge: '292690505', name: 'Rio do Pires' },
  { ibge: '292690510', name: 'Ibiajara' },
  { ibge: '292700205', name: 'Rio Real' },
  { ibge: '292710105', name: 'Rodelas' },
  { ibge: '292720005', name: 'Ruy Barbosa' },
  { ibge: '292720010', name: 'Morro das Flores' },
  { ibge: '292720015', name: 'Tapiraípe' },
  { ibge: '292730905', name: 'Salinas da Margarida' },
  { ibge: '292740805', name: 'Salvador' },
  { ibge: '292750705', name: 'Santa Bárbara' },
  { ibge: '292760605', name: 'Santa Brígida' },
  { ibge: '292770505', name: 'Santa Cruz Cabrália' },
  { ibge: '292780405', name: 'Santa Cruz da Vitória' },
  { ibge: '292790305', name: 'Santa Inês' },
  { ibge: '292800005', name: 'Santaluz' },
  { ibge: '292800020', name: 'Pereira' },
  { ibge: '292805905', name: 'Santa Luzia' },
  { ibge: '292810905', name: 'Santa Maria da Vitória' },
  { ibge: '292810910', name: 'Açudina' },
  { ibge: '292810915', name: 'Inhaúmas' },
  { ibge: '292820805', name: 'Santana' },
  { ibge: '292820810', name: 'Porto Novo' },
  { ibge: '292830705', name: 'Santanópolis' },
  { ibge: '292830710', name: 'Boa Espera' },
  { ibge: '292840605', name: 'Santa Rita de Cássia' },
  { ibge: '292840615', name: 'Malhada Grande' },
  { ibge: '292850505', name: 'Santa Teresinha' },
  { ibge: '292860405', name: 'Santo Amaro' },
  { ibge: '292860410', name: 'Acupe' },
  { ibge: '292860415', name: 'Campinhos' },
  { ibge: '292870305', name: 'Santo Antônio de Jesus' },
  { ibge: '292880205', name: 'Santo Estêvão' },
  { ibge: '292890105', name: 'São Desidério' },
  { ibge: '292890110', name: 'Sítio Grande' },
  { ibge: '292895005', name: 'São Domingos' },
  { ibge: '292895025', name: 'Santo Antônio' },
  { ibge: '292900805', name: 'São Félix' },
  { ibge: '292900810', name: 'Outeiro Redondo' },
  { ibge: '292905705', name: 'São Félix do Coribe' },
  { ibge: '292910705', name: 'São Felipe' },
  { ibge: '292910710', name: 'Caraípe' },
  { ibge: '292920605', name: 'São Francisco do Conde' },
  { ibge: '292920610', name: 'Mataripe' },
  { ibge: '292920615', name: 'Monte Recôncavo' },
  { ibge: '292925505', name: 'São Gabriel' },
  { ibge: '292925515', name: 'Gameleira do Jacaré' },
  { ibge: '292930505', name: 'São Gonçalo dos Campos' },
  { ibge: '292930510', name: 'Afligidos' },
  { ibge: '292930515', name: 'Sergi' },
  { ibge: '292935405', name: 'São José da Vitória' },
  { ibge: '292937005', name: 'São José do Jacuípe' },
  { ibge: '292937015', name: 'Itatiaia do Alto Bonito' },
  { ibge: '292940405', name: 'São Miguel das Matas' },
  { ibge: '292950305', name: 'São Sebastião do Passé' },
  { ibge: '292950310', name: 'Maracangalha' },
  { ibge: '292950315', name: 'Jacuípe' },
  { ibge: '292950320', name: 'Lamarão do Passé' },
  { ibge: '292960205', name: 'Sapeaçu' },
  { ibge: '292960210', name: 'Baixa do Palmeira' },
  { ibge: '292970105', name: 'Sátiro Dias' },
  { ibge: '292975005', name: 'Saubara' },
  { ibge: '292975010', name: 'Bom Jesus dos Pobres' },
  { ibge: '292975015', name: 'Cabuçu' },
  { ibge: '292980005', name: 'Saúde' },
  { ibge: '292990905', name: 'Seabra' },
  { ibge: '292990910', name: 'Baraúnas' },
  { ibge: '292990915', name: 'Várzea do Caldas' },
  { ibge: '293000605', name: 'Sebastião Laranjeiras' },
  { ibge: '293000610', name: 'Mandiroba' },
  { ibge: '293010505', name: 'Senhor do Bonfim' },
  { ibge: '293010515', name: 'Carrapichel' },
  { ibge: '293010520', name: 'Igara' },
  { ibge: '293010525', name: 'Tijuaçu' },
  { ibge: '293015405', name: 'Serra do Ramalho' },
  { ibge: '293020405', name: 'Sento Sé' },
  { ibge: '293020410', name: 'Amaniú' },
  { ibge: '293020415', name: 'Américo Alves' },
  { ibge: '293020420', name: 'Piçarrão' },
  { ibge: '293020425', name: 'Cajuí' },
  { ibge: '293020430', name: 'Minas do Mimoso' },
  { ibge: '293020435', name: 'Piri' },
  { ibge: '293030305', name: 'Serra Dourada' },
  { ibge: '293040205', name: 'Serra Preta' },
  { ibge: '293050105', name: 'Serrinha' },
  { ibge: '293060005', name: 'Serrolândia' },
  { ibge: '293070905', name: 'Simões Filho' },
  { ibge: '293075805', name: 'Sítio do Mato' },
  { ibge: '293075810', name: 'Gameleira da Lapa' },
  { ibge: '293076605', name: 'Sítio do Quinto' },
  { ibge: '293077405', name: 'Sobradinho' },
  { ibge: '293080805', name: 'Souto Soares' },
  { ibge: '293080810', name: 'Cisterna' },
  { ibge: '293080825', name: 'Segrêdo' },
  { ibge: '293090705', name: 'Tabocas do Brejo Velho' },
  { ibge: '293090710', name: 'Mariquita' },
  { ibge: '293100405', name: 'Tanhaçu' },
  { ibge: '293100410', name: 'Sussuarana' },
  { ibge: '293105305', name: 'Tanque Novo' },
  { ibge: '293110305', name: 'Tanquinho' },
  { ibge: '293120205', name: 'Taperoá' },
  { ibge: '293120210', name: 'Camurugi' },
  { ibge: '293130105', name: 'Tapiramutá' },
  { ibge: '293130110', name: 'Volta Grande' },
  { ibge: '293135005', name: 'Teixeira de Freitas' },
  { ibge: '293135010', name: 'Cachoeira do Mato' },
  { ibge: '293140005', name: 'Teodoro Sampaio' },
  { ibge: '293140010', name: 'Buracica' },
  { ibge: '293140015', name: 'Lustosa' },
  { ibge: '293150905', name: 'Teofilândia' },
  { ibge: '293160805', name: 'Teolândia' },
  { ibge: '293170705', name: 'Terra Nova' },
  { ibge: '293170710', name: 'Jacu' },
  { ibge: '293170715', name: 'Rio Fundo' },
  { ibge: '293180605', name: 'Tremedal' },
  { ibge: '293180615', name: 'Lagoa Preta' },
  { ibge: '293190505', name: 'Tucano' },
  { ibge: '293200205', name: 'Uauá' },
  { ibge: '293200210', name: 'Caldeirão' },
  { ibge: '293200215', name: 'Serra da Canabrava' },
  { ibge: '293210105', name: 'Ubaíra' },
  { ibge: '293210110', name: 'Baixinha' },
  { ibge: '293210115', name: 'Engenheiro Franca' },
  { ibge: '293220005', name: 'Ubaitaba' },
  { ibge: '293220010', name: 'Faisqueira' },
  { ibge: '293220025', name: 'Piraúna' },
  { ibge: '293230905', name: 'Ubatã' },
  { ibge: '293240805', name: 'Uibaí' },
  { ibge: '293240810', name: 'Hidrolândia' },
  { ibge: '293245705', name: 'Umburanas' },
  { ibge: '293245710', name: 'Delfino' },
  { ibge: '293250705', name: 'Una' },
  { ibge: '293250720', name: 'Colônia' },
  { ibge: '293250725', name: 'Comandatuba' },
  { ibge: '293250735', name: 'Pedras de Una' },
  { ibge: '293250740', name: 'Vila Brasil' },
  { ibge: '293260605', name: 'Urandi' },
  { ibge: '293270505', name: 'Uruçuca' },
  { ibge: '293270525', name: 'Serra Grande' },
  { ibge: '293280405', name: 'Utinga' },
  { ibge: '293280410', name: 'Riachão do Utinga' },
  { ibge: '293290305', name: 'Valença' },
  { ibge: '293290308', name: 'Guaibim' },
  { ibge: '293290310', name: 'Guerém' },
  { ibge: '293290315', name: 'Maricoabo' },
  { ibge: '293290320', name: 'Serra Grande' },
  { ibge: '293300005', name: 'Valente' },
  { ibge: '293305905', name: 'Várzea da Roça' },
  { ibge: '293310905', name: 'Várzea do Poço' },
  { ibge: '293315805', name: 'Várzea Nova' },
  { ibge: '293317405', name: 'Varzedo' },
  { ibge: '293317425', name: 'Taboleiro do Castro' },
  { ibge: '293320805', name: 'Vera Cruz' },
  { ibge: '293320810', name: 'Cacha Pregos' },
  { ibge: '293320815', name: 'Jiribatuba' },
  { ibge: '293320820', name: 'Mar Grande' },
  { ibge: '293325705', name: 'Vereda' },
  { ibge: '293330705', name: 'Vitória da Conquista' },
  { ibge: '293330706', name: 'Bate-Pé' },
  { ibge: '293330707', name: 'Cabeceira da Jiboia' },
  { ibge: '293330708', name: 'Cercadinho' },
  { ibge: '293330709', name: 'Dantilândia' },
  { ibge: '293330710', name: 'Iguá' },
  { ibge: '293330715', name: 'Inhobim' },
  { ibge: '293330720', name: 'José Gonçalves' },
  { ibge: '293330730', name: 'Pradoso' },
  { ibge: '293330733', name: 'São João da Vitória' },
  { ibge: '293330735', name: 'São Sebastião' },
  { ibge: '293330740', name: 'Veredinha' },
  { ibge: '293340605', name: 'Wagner' },
  { ibge: '293345505', name: 'Wanderley' },
  { ibge: '293350505', name: 'Wenceslau Guimarães' },
  { ibge: '293360405', name: 'Xique-Xique' },
  { ibge: '293360410', name: 'Copixaba' },
  { ibge: '293360415', name: 'Iguira' },
  { ibge: '310010405', name: 'Abadia dos Dourados' },
  { ibge: '310020305', name: 'Abaeté' },
  { ibge: '310030205', name: 'Abre Campo' },
  { ibge: '310030210', name: 'Granada' },
  { ibge: '310040105', name: 'Acaiaca' },
  { ibge: '310050005', name: 'Açucena' },
  { ibge: '310050010', name: 'Aramirim' },
  { ibge: '310050015', name: 'Felicina' },
  { ibge: '310050020', name: 'Gama' },
  { ibge: '310050028', name: 'Naque-Nanuque' },
  { ibge: '310060905', name: 'Água Boa' },
  { ibge: '310060915', name: 'Palmeira de Resplendor' },
  { ibge: '310070805', name: 'Água Comprida' },
  { ibge: '310080705', name: 'Aguanil' },
  { ibge: '310090605', name: 'Águas Formosas' },
  { ibge: '310090607', name: 'Água Quente' },
  { ibge: '310100305', name: 'Águas Vermelhas' },
  { ibge: '310100320', name: 'Itamarati' },
  { ibge: '310100330', name: 'Machado Mineiro' },
  { ibge: '310110205', name: 'Aimorés' },
  { ibge: '310110210', name: 'Alto do Capim' },
  { ibge: '310110215', name: 'Conceição do Capim' },
  { ibge: '310110220', name: 'Expedicionário Alício' },
  { ibge: '310110225', name: 'Mundo Novo de Minas' },
  { ibge: '310110230', name: 'Penha do Capim' },
  { ibge: '310110233', name: 'Santo Antônio do Rio Doce' },
  { ibge: '310110235', name: 'São Sebastião da Vala' },
  { ibge: '310110240', name: 'Tabaúna' },
  { ibge: '310120105', name: 'Aiuruoca' },
  { ibge: '310130005', name: 'Alagoa' },
  { ibge: '310140905', name: 'Albertina' },
  { ibge: '310150805', name: 'Além Paraíba' },
  { ibge: '310150810', name: 'Angustura' },
  { ibge: '310160705', name: 'Alfenas' },
  { ibge: '310160710', name: 'Barranco Alto' },
  { ibge: '310163105', name: 'Alfredo Vasconcelos' },
  { ibge: '310163125', name: 'São José de Pouso Alegre' },
  { ibge: '310170605', name: 'Almenara' },
  { ibge: '310170620', name: 'Pedra Grande' },
  { ibge: '310180505', name: 'Alpercata' },
  { ibge: '310190405', name: 'Alpinópolis' },
  { ibge: '310200105', name: 'Alterosa' },
  { ibge: '310200110', name: 'Divino Espírito Santo' },
  { ibge: '310205005', name: 'Alto Caparaó' },
  { ibge: '310210005', name: 'Alto Rio Doce' },
  { ibge: '310210010', name: 'Abreus' },
  { ibge: '310210015', name: 'Missionário' },
  { ibge: '310210020', name: 'Vitorinos' },
  { ibge: '310220905', name: 'Alvarenga' },
  { ibge: '310230805', name: 'Alvinópolis' },
  { ibge: '310230807', name: 'Barretos de Alvinópolis' },
  { ibge: '310230810', name: 'Fonseca' },
  { ibge: '310230815', name: 'Major Ezequiel' },
  { ibge: '310240705', name: 'Alvorada de Minas' },
  { ibge: '310240710', name: 'Itapanhoacanga' },
  { ibge: '310250605', name: 'Amparo do Serra' },
  { ibge: '310250610', name: 'Padre Felisberto' },
  { ibge: '310260505', name: 'Andradas' },
  { ibge: '310260510', name: 'Gramínea' },
  { ibge: '310260515', name: 'Campestrinho' },
  { ibge: '310270405', name: 'Cachoeira de Pajeú' },
  { ibge: '310270410', name: 'Águas Altas' },
  { ibge: '310280305', name: 'Andrelândia' },
  { ibge: '310285205', name: 'Angelândia' },
  { ibge: '310290205', name: 'Antônio Carlos' },
  { ibge: '310290206', name: 'Curral Novo de Minas' },
  { ibge: '310290207', name: 'Dr. Sá Fortes' },
  { ibge: '310290215', name: 'São Sebastião de Campolide' },
  { ibge: '310300905', name: 'Antônio Dias' },
  { ibge: '310300910', name: 'Hematita' },
  { ibge: '310310805', name: 'Antônio Prado de Minas' },
  { ibge: '310320705', name: 'Araçaí' },
  { ibge: '310330605', name: 'Aracitaba' },
  { ibge: '310340505', name: 'Araçuaí' },
  { ibge: '310340510', name: 'Engenheiro Schnoor' },
  { ibge: '310340515', name: 'Itira' },
  { ibge: '310350405', name: 'Araguari' },
  { ibge: '310350410', name: 'Amanhece' },
  { ibge: '310350415', name: 'Florestina' },
  { ibge: '310350420', name: 'Piracaíba' },
  { ibge: '310360305', name: 'Arantina' },
  { ibge: '310370205', name: 'Araponga' },
  { ibge: '310370210', name: 'Estêvão de Araújo' },
  { ibge: '310375105', name: 'Araporã' },
  { ibge: '310380105', name: 'Arapuá' },
  { ibge: '310390005', name: 'Araújos' },
  { ibge: '310400705', name: 'Araxá' },
  { ibge: '310400715', name: 'Itaipu de Araxá' },
  { ibge: '310410605', name: 'Arceburgo' },
  { ibge: '310420505', name: 'Arcos' },
  { ibge: '310430405', name: 'Areado' },
  { ibge: '310440305', name: 'Argirita' },
  { ibge: '310445205', name: 'Aricanduva' },
  { ibge: '310450205', name: 'Arinos' },
  { ibge: '310450215', name: 'Pic Sagarana' },
  { ibge: '310450220', name: 'Vila Bom Jesus' },
  { ibge: '310460105', name: 'Astolfo Dutra' },
  { ibge: '310460110', name: 'Santana do Campestre' },
  { ibge: '310460115', name: 'Sobral Pinto' },
  { ibge: '310470005', name: 'Ataléia' },
  { ibge: '310470010', name: 'Fidelândia' },
  { ibge: '310470015', name: 'Novo Horizonte' },
  { ibge: '310480905', name: 'Augusto de Lima' },
  { ibge: '310490805', name: 'Baependi' },
  { ibge: '310500405', name: 'Baldim' },
  { ibge: '310500410', name: 'Amanda' },
  { ibge: '310500415', name: 'São Vicente' },
  { ibge: '310510305', name: 'Bambuí' },
  { ibge: '310520205', name: 'Bandeira' },
  { ibge: '310530105', name: 'Bandeira do Sul' },
  { ibge: '310540005', name: 'Barão de Cocais' },
  { ibge: '310540010', name: 'Cocais' },
  { ibge: '310550905', name: 'Barão de Monte Alto' },
  { ibge: '310550910', name: 'Cachoeira Alegre' },
  { ibge: '310550915', name: 'Silveira Carvalho' },
  { ibge: '310560805', name: 'Barbacena' },
  { ibge: '310560807', name: 'Colônia Rodrigo Silva' },
  { ibge: '310560810', name: 'Correia de Almeida' },
  { ibge: '310560812', name: 'Costas da Mantiqueira' },
  { ibge: '310560814', name: 'Faria' },
  { ibge: '310560815', name: 'Padre Brito' },
  { ibge: '310560816', name: 'Galego' },
  { ibge: '310560819', name: 'Mantiqueira do Palmital' },
  { ibge: '310560820', name: 'Senhora das Dores' },
  { ibge: '310560823', name: 'Pinheiro Grosso' },
  { ibge: '310560825', name: 'Ponte do Cosme' },
  { ibge: '310560827', name: 'Ponto Chique do Martelo' },
  { ibge: '310560830', name: 'São Sebastião dos Torres' },
  { ibge: '310570705', name: 'Barra Longa' },
  { ibge: '310570715', name: 'Bonfim da Barra' },
  { ibge: '310590505', name: 'Barroso' },
  { ibge: '310600205', name: 'Bela Vista de Minas' },
  { ibge: '310610105', name: 'Belmiro Braga' },
  { ibge: '310610110', name: 'Porto das Flores' },
  { ibge: '310610113', name: 'Sobragi' },
  { ibge: '310610115', name: 'Três Ilhas' },
  { ibge: '310620005', name: 'Belo Horizonte' },
  { ibge: '310620025', name: 'Barreiro' },
  { ibge: '310620060', name: 'Venda Nova' },
  { ibge: '310630905', name: 'Belo Oriente' },
  { ibge: '310630906', name: 'Bom Jesus do Bagre' },
  { ibge: '310630910', name: 'Perpétuo Socorro' },
  { ibge: '310630920', name: 'São Sebastião de Braúnas' },
  { ibge: '310640805', name: 'Belo Vale' },
  { ibge: '310640810', name: 'Santana do Paraopeba' },
  { ibge: '310650705', name: 'Berilo' },
  { ibge: '310650720', name: 'Lelivéldia' },
  { ibge: '310660605', name: 'Bertópolis' },
  { ibge: '310660620', name: 'Umburaninha' },
  { ibge: '310665505', name: 'Berizal' },
  { ibge: '310670505', name: 'Betim' },
  { ibge: '310680405', name: 'Bias Fortes' },
  { ibge: '310690305', name: 'Bicas' },
  { ibge: '310700005', name: 'Biquinhas' },
  { ibge: '310710905', name: 'Boa Esperança' },
  { ibge: '310720805', name: 'Bocaina de Minas' },
  { ibge: '310720810', name: 'Mirantão' },
  { ibge: '310730705', name: 'Bocaiúva' },
  { ibge: '310730720', name: 'Alto Belo' },
  { ibge: '310730725', name: 'Terra Branca' },
  { ibge: '310740605', name: 'Bom Despacho' },
  { ibge: '310740610', name: 'Engenho do Ribeiro' },
  { ibge: '310750505', name: 'Bom Jardim de Minas' },
  { ibge: '310750510', name: 'Tabuão' },
  { ibge: '310760405', name: 'Bom Jesus da Penha' },
  { ibge: '310770305', name: 'Bom Jesus do Amparo' },
  { ibge: '310780205', name: 'Bom Jesus do Galho' },
  { ibge: '310780210', name: 'Passa Dez' },
  { ibge: '310780215', name: 'Quartel do Sacramento' },
  { ibge: '310780220', name: 'Revés do Belém' },
  { ibge: '310790105', name: 'Bom Repouso' },
  { ibge: '310800805', name: 'Bom Sucesso' },
  { ibge: '310800810', name: 'Macaia' },
  { ibge: '310810705', name: 'Bonfim' },
  { ibge: '310810710', name: 'Santo Antônio da Vargem Alegre' },
  { ibge: '310820605', name: 'Bonfinópolis de Minas' },
  { ibge: '310825505', name: 'Bonito de Minas' },
  { ibge: '310830505', name: 'Borda da Mata' },
  { ibge: '310830510', name: 'Cervo' },
  { ibge: '310830515', name: 'Sertãozinho' },
  { ibge: '310840405', name: 'Botelhos' },
  { ibge: '310840410', name: 'Palmeiral' },
  { ibge: '310840415', name: 'São Gonçalo de Botelhos' },
  { ibge: '310850305', name: 'Botumirim' },
  { ibge: '310850310', name: 'Adão Colares' },
  { ibge: '310850315', name: 'Santa Cruz de Botumirim' },
  { ibge: '310855205', name: 'Brasilândia de Minas' },
  { ibge: '310860205', name: 'Brasília de Minas' },
  { ibge: '310860210', name: 'Angicos de Minas' },
  { ibge: '310860220', name: 'Fernão Dias' },
  { ibge: '310860235', name: 'Retiro de Santo Antônio' },
  { ibge: '310860240', name: 'Vargem Grande do Bom Jesus' },
  { ibge: '310860245', name: 'Vila de Fátima' },
  { ibge: '310870105', name: 'Brás Pires' },
  { ibge: '310880005', name: 'Braúnas' },
  { ibge: '310890905', name: 'Brazópolis' },
  { ibge: '310890910', name: 'Dias' },
  { ibge: '310890915', name: 'Luminosa' },
  { ibge: '310900605', name: 'Brumadinho' },
  { ibge: '310900610', name: 'Aranha' },
  { ibge: '310900615', name: 'Conceição de Itaguá' },
  { ibge: '310900620', name: 'Piedade do Paraopeba' },
  { ibge: '310900625', name: 'São José do Paraopeba' },
  { ibge: '310910505', name: 'Bueno Brandão' },
  { ibge: '310920405', name: 'Buenópolis' },
  { ibge: '310920410', name: 'Curimataí' },
  { ibge: '310925305', name: 'Bugre' },
  { ibge: '310930305', name: 'Buritis' },
  { ibge: '310930310', name: 'Serra Bonita' },
  { ibge: '310930320', name: 'São Pedro do Passa Três' },
  { ibge: '310940205', name: 'Buritizeiro' },
  { ibge: '310940210', name: 'Cachoeira do Manteiga' },
  { ibge: '310940215', name: 'Paredão de Minas' },
  { ibge: '310940220', name: 'Sambaíba de Minas' },
  { ibge: '310945105', name: 'Cabeceira Grande' },
  { ibge: '310945120', name: 'Palmital de Minas' },
  { ibge: '310950105', name: 'Cabo Verde' },
  { ibge: '310950110', name: 'Serra dos Lemes' },
  { ibge: '310950115', name: 'São Bartolomeu de Minas' },
  { ibge: '310960005', name: 'Cachoeira da Prata' },
  { ibge: '310970905', name: 'Cachoeira de Minas' },
  { ibge: '310970910', name: 'Itaim' },
  { ibge: '310980805', name: 'Cachoeira Dourada' },
  { ibge: '310990705', name: 'Caetanópolis' },
  { ibge: '311000405', name: 'Caeté' },
  { ibge: '311000410', name: 'Antônio dos Santos' },
  { ibge: '311000415', name: 'Morro Vermelho' },
  { ibge: '311000420', name: 'Penedia' },
  { ibge: '311000425', name: 'Roças Novas' },
  { ibge: '311010305', name: 'Caiana' },
  { ibge: '311020205', name: 'Cajuri' },
  { ibge: '311020210', name: 'Paraguai' },
  { ibge: '311030105', name: 'Caldas' },
  { ibge: '311030107', name: 'Laranjeiras de Caldas' },
  { ibge: '311030110', name: 'Santana de Caldas' },
  { ibge: '311030115', name: 'São Pedro de Caldas' },
  { ibge: '311040005', name: 'Camacho' },
  { ibge: '311050905', name: 'Camanducaia' },
  { ibge: '311050910', name: 'Monte Verde' },
  { ibge: '311050915', name: 'São Mateus de Minas' },
  { ibge: '311060805', name: 'Cambuí' },
  { ibge: '311070705', name: 'Cambuquira' },
  { ibge: '311080605', name: 'Campanário' },
  { ibge: '311090505', name: 'Campanha' },
  { ibge: '311100205', name: 'Campestre' },
  { ibge: '311110105', name: 'Campina Verde' },
  { ibge: '311110110', name: 'Honorópolis' },
  { ibge: '311115005', name: 'Campo Azul' },
  { ibge: '311120005', name: 'Campo Belo' },
  { ibge: '311120010', name: 'Porto dos Mendes' },
  { ibge: '311130905', name: 'Campo do Meio' },
  { ibge: '311140805', name: 'Campo Florido' },
  { ibge: '311150705', name: 'Campos Altos' },
  { ibge: '311150710', name: 'São Jerônimo dos Poções' },
  { ibge: '311160605', name: 'Campos Gerais' },
  { ibge: '311160610', name: 'Córrego do Ouro' },
  { ibge: '311170505', name: 'Canaã' },
  { ibge: '311180405', name: 'Canápolis' },
  { ibge: '311190305', name: 'Cana Verde' },
  { ibge: '311200005', name: 'Candeias' },
  { ibge: '311205905', name: 'Cantagalo' },
  { ibge: '311210905', name: 'Caparaó' },
  { ibge: '311220805', name: 'Capela Nova' },
  { ibge: '311230705', name: 'Capelinha' },
  { ibge: '311240605', name: 'Capetinga' },
  { ibge: '311240610', name: 'Goianases' },
  { ibge: '311250505', name: 'Capim Branco' },
  { ibge: '311260405', name: 'Capinópolis' },
  { ibge: '311265305', name: 'Capitão Andrade' },
  { ibge: '311270305', name: 'Capitão Enéas' },
  { ibge: '311270310', name: 'Caçarema' },
  { ibge: '311270320', name: 'Santana da Serra' },
  { ibge: '311280205', name: 'Capitólio' },
  { ibge: '311290105', name: 'Caputira' },
  { ibge: '311300805', name: 'Caraí' },
  { ibge: '311300810', name: 'Marambainha' },
  { ibge: '311300812', name: 'Maranhão' },
  { ibge: '311300815', name: 'Ponto do Marambaia' },
  { ibge: '311310705', name: 'Caranaíba' },
  { ibge: '311320605', name: 'Carandaí' },
  { ibge: '311320610', name: 'Hermilo Alves' },
  { ibge: '311320615', name: 'Pedra do Sino' },
  { ibge: '311330505', name: 'Carangola' },
  { ibge: '311330510', name: 'Alvorada' },
  { ibge: '311330520', name: 'Lacerdinha' },
  { ibge: '311330525', name: 'Ponte Alta de Minas' },
  { ibge: '311340405', name: 'Caratinga' },
  { ibge: '311340407', name: 'Cordeiro de Minas' },
  { ibge: '311340410', name: 'Dom Lara' },
  { ibge: '311340413', name: 'Dom Modesto' },
  { ibge: '311340424', name: 'Patrocínio de Caratinga' },
  { ibge: '311340426', name: 'Santa Efigênia de Caratinga' },
  { ibge: '311340427', name: 'Santa Luzia de Caratinga' },
  { ibge: '311340435', name: 'Santo Antônio do Manhuaçu' },
  { ibge: '311340440', name: 'São Cândido' },
  { ibge: '311340445', name: 'São João do Jacutinga' },
  { ibge: '311340450', name: 'Sapucaia' },
  { ibge: '311350305', name: 'Carbonita' },
  { ibge: '311360205', name: 'Careaçu' },
  { ibge: '311370105', name: 'Carlos Chagas' },
  { ibge: '311370110', name: 'Epaminondas Otoni' },
  { ibge: '311370115', name: 'Presidente Pena' },
  { ibge: '311380005', name: 'Carmésia' },
  { ibge: '311390905', name: 'Carmo da Cachoeira' },
  { ibge: '311390920', name: 'Palmital do Cervo' },
  { ibge: '311400605', name: 'Carmo da Mata' },
  { ibge: '311410505', name: 'Carmo de Minas' },
  { ibge: '311420405', name: 'Carmo do Cajuru' },
  { ibge: '311420408', name: 'Bom Jesus de Angicos' },
  { ibge: '311420409', name: 'Santo Antônio da Serra' },
  { ibge: '311420410', name: 'São José dos Salgados' },
  { ibge: '311430305', name: 'Carmo do Paranaíba' },
  { ibge: '311430310', name: 'Quintinos' },
  { ibge: '311440205', name: 'Carmo do Rio Claro' },
  { ibge: '311440210', name: 'Itaci' },
  { ibge: '311440220', name: 'Vilelândia' },
  { ibge: '311450105', name: 'Carmópolis de Minas' },
  { ibge: '311455005', name: 'Carneirinho' },
  { ibge: '311455010', name: 'Estrela da Barra' },
  { ibge: '311455017', name: 'Fátima do Pontal' },
  { ibge: '311455020', name: 'São Sebastião do Pontal' },
  { ibge: '311460005', name: 'Carrancas' },
  { ibge: '311470905', name: 'Carvalhópolis' },
  { ibge: '311480805', name: 'Carvalhos' },
  { ibge: '311480810', name: 'Franceses' },
  { ibge: '311490705', name: 'Casa Grande' },
  { ibge: '311500305', name: 'Cascalho Rico' },
  { ibge: '311510205', name: 'Cássia' },
  { ibge: '311520105', name: 'Conceição da Barra de Minas' },
  { ibge: '311530005', name: 'Cataguases' },
  { ibge: '311530007', name: 'Aracati de Minas' },
  { ibge: '311530010', name: 'Cataguarino' },
  { ibge: '311530012', name: 'Glória de Cataguases' },
  { ibge: '311530015', name: 'Sereno' },
  { ibge: '311530020', name: 'Vista Alegre' },
  { ibge: '311535905', name: 'Catas Altas' },
  { ibge: '311540905', name: 'Catas Altas da Noruega' },
  { ibge: '311545805', name: 'Catuji' },
  { ibge: '311547405', name: 'Catuti' },
  { ibge: '311547410', name: 'Barreiro Branco' },
  { ibge: '311550805', name: 'Caxambu' },
  { ibge: '311560705', name: 'Cedro do Abaeté' },
  { ibge: '311570605', name: 'Central de Minas' },
  { ibge: '311570610', name: 'Floresta' },
  { ibge: '311580505', name: 'Centralina' },
  { ibge: '311590405', name: 'Chácara' },
  { ibge: '311600105', name: 'Chalé' },
  { ibge: '311600110', name: 'Professor Sperber' },
  { ibge: '311610005', name: 'Chapada do Norte' },
  { ibge: '311610010', name: 'Cachoeira do Norte' },
  { ibge: '311610020', name: 'Granjas do Norte' },
  { ibge: '311610030', name: 'Santa Rita do Araçuaí' },
  { ibge: '311610040', name: 'São Sebastião da Boa Vista' },
  { ibge: '311615905', name: 'Chapada Gaúcha' },
  { ibge: '311615920', name: 'Serra das Araras' },
  { ibge: '311615930', name: 'Vila Bom Jesus de Minas' },
  { ibge: '311615935', name: 'Vila Retiro Velho' },
  { ibge: '311620905', name: 'Chiador' },
  { ibge: '311620910', name: 'Penha Longa' },
  { ibge: '311630805', name: 'Cipotânea' },
  { ibge: '311640705', name: 'Claraval' },
  { ibge: '311650605', name: 'Claro dos Poções' },
  { ibge: '311650610', name: 'Vista Alegre' },
  { ibge: '311660505', name: 'Cláudio' },
  { ibge: '311660510', name: 'Monsenhor João Alexandre' },
  { ibge: '311670405', name: 'Coimbra' },
  { ibge: '311680305', name: 'Coluna' },
  { ibge: '311690205', name: 'Comendador Gomes' },
  { ibge: '311700905', name: 'Comercinho' },
  { ibge: '311700910', name: 'Água Branca de Minas' },
  { ibge: '311710805', name: 'Conceição da Aparecida' },
  { ibge: '311720705', name: 'Conceição das Pedras' },
  { ibge: '311730605', name: 'Conceição das Alagoas' },
  { ibge: '311730610', name: 'Poncianos' },
  { ibge: '311740505', name: 'Conceição de Ipanema' },
  { ibge: '311750405', name: 'Conceição do Mato Dentro' },
  { ibge: '311750410', name: 'Brejaúba' },
  { ibge: '311750415', name: 'Córregos' },
  { ibge: '311750420', name: 'Costa Sena' },
  { ibge: '311750425', name: 'Itacolomi' },
  { ibge: '311750428', name: 'Ouro Fino do Mato Dentro' },
  { ibge: '311750430', name: 'Santo Antônio do Norte' },
  { ibge: '311750432', name: 'Santo Antônio do Cruzeiro' },
  { ibge: '311750435', name: 'São Sebastião do Bom Sucesso' },
  { ibge: '311750436', name: 'Senhora do Socorro' },
  { ibge: '311750440', name: 'Tabuleiro do Mato Dentro' },
  { ibge: '311760305', name: 'Conceição do Pará' },
  { ibge: '311770205', name: 'Conceição do Rio Verde' },
  { ibge: '311770210', name: 'Águas de Contendas' },
  { ibge: '311780105', name: 'Conceição dos Ouros' },
  { ibge: '311783605', name: 'Cônego Marinho' },
  { ibge: '311783615', name: 'Cruz dos Araújos' },
  { ibge: '311783625', name: "Olhos d'Água do Bom Jesus" },
  { ibge: '311787605', name: 'Confins' },
  { ibge: '311790005', name: 'Congonhal' },
  { ibge: '311800705', name: 'Congonhas' },
  { ibge: '311800710', name: 'Alto Maranhão' },
  { ibge: '311800715', name: 'Lobo Leite' },
  { ibge: '311810605', name: 'Congonhas do Norte' },
  { ibge: '311820505', name: 'Conquista' },
  { ibge: '311820510', name: 'Guaxima' },
  { ibge: '311820515', name: 'Jubaí' },
  { ibge: '311830405', name: 'Conselheiro Lafaiete' },
  { ibge: '311830410', name: 'Buarque de Macedo' },
  { ibge: '311840305', name: 'Conselheiro Pena' },
  { ibge: '311840315', name: 'Barra do Cuieté' },
  { ibge: '311840320', name: 'Bueno' },
  { ibge: '311840325', name: 'Cuieté Velho' },
  { ibge: '311840335', name: 'Ferruginha' },
  { ibge: '311840345', name: 'Penha do Norte' },
  { ibge: '311850205', name: 'Consolação' },
  { ibge: '311860105', name: 'Contagem' },
  { ibge: '311860110', name: 'Parque Industrial' },
  { ibge: '311870005', name: 'Coqueiral' },
  { ibge: '311870010', name: 'Frei Eustáquio' },
  { ibge: '311880905', name: 'Coração de Jesus' },
  { ibge: '311880910', name: 'Alvação' },
  { ibge: '311880913', name: 'Aristides Batista' },
  { ibge: '311880915', name: 'Luiz Pires de Minas' },
  { ibge: '311880920', name: 'Ponte dos Ciganos' },
  { ibge: '311880925', name: 'São Geraldo' },
  { ibge: '311880940', name: 'São Joaquim' },
  { ibge: '311890805', name: 'Cordisburgo' },
  { ibge: '311890810', name: 'Lagoa Bonita' },
  { ibge: '311900505', name: 'Cordislândia' },
  { ibge: '311910405', name: 'Corinto' },
  { ibge: '311910408', name: 'Beltrão' },
  { ibge: '311910410', name: 'Contria' },
  { ibge: '311920305', name: 'Coroaci' },
  { ibge: '311920310', name: 'Conceição de Tronqueiras' },
  { ibge: '311920315', name: 'São Sebastião do Bugre' },
  { ibge: '311930205', name: 'Coromandel' },
  { ibge: '311930210', name: 'Alegre' },
  { ibge: '311930212', name: 'Lagamar dos Coqueiros' },
  { ibge: '311930213', name: 'Pântano de Santa Cruz' },
  { ibge: '311930214', name: 'Mateiro' },
  { ibge: '311930215', name: 'Santa Rosa dos Dourados' },
  { ibge: '311940105', name: 'Coronel Fabriciano' },
  { ibge: '311940110', name: 'Senador Melo Viana' },
  { ibge: '311950005', name: 'Coronel Murta' },
  { ibge: '311950007', name: 'Barra do Salinas' },
  { ibge: '311950010', name: 'Freire Cardoso' },
  { ibge: '311960905', name: 'Coronel Pacheco' },
  { ibge: '311970805', name: 'Coronel Xavier Chaves' },
  { ibge: '311980705', name: 'Córrego Danta' },
  { ibge: '311980710', name: 'Cachoeirinha' },
  { ibge: '311990605', name: 'Córrego do Bom Jesus' },
  { ibge: '311995505', name: 'Córrego Fundo' },
  { ibge: '312000305', name: 'Córrego Novo' },
  { ibge: '312010205', name: 'Couto de Magalhães de Minas' },
  { ibge: '312015105', name: 'Crisólita' },
  { ibge: '312015120', name: 'Nova Santa Luzia' },
  { ibge: '312020105', name: 'Cristais' },
  { ibge: '312030005', name: 'Cristália' },
  { ibge: '312040905', name: 'Cristiano Otoni' },
  { ibge: '312040920', name: 'São Caetano' },
  { ibge: '312050805', name: 'Cristina' },
  { ibge: '312060705', name: 'Crucilândia' },
  { ibge: '312070605', name: 'Cruzeiro da Fortaleza' },
  { ibge: '312070610', name: 'Brejo Bonito' },
  { ibge: '312080505', name: 'Cruzília' },
  { ibge: '312083905', name: 'Cuparaque' },
  { ibge: '312083910', name: 'Aldeia' },
  { ibge: '312087005', name: 'Curral de Dentro' },
  { ibge: '312087020', name: 'Maristela de Minas' },
  { ibge: '312090405', name: 'Curvelo' },
  { ibge: '312090410', name: 'Angueretá' },
  { ibge: '312090412', name: 'J.K.' },
  { ibge: '312090415', name: 'Santa Rita do Cedro' },
  { ibge: '312090420', name: 'Tomás Gonzaga' },
  { ibge: '312100105', name: 'Datas' },
  { ibge: '312110005', name: 'Delfim Moreira' },
  { ibge: '312120905', name: 'Delfinópolis' },
  { ibge: '312120910', name: 'Babilônia' },
  { ibge: '312120920', name: "Olhos D'Água da Canastra" },
  { ibge: '312125805', name: 'Delta' },
  { ibge: '312130805', name: 'Descoberto' },
  { ibge: '312140705', name: 'Desterro de Entre Rios' },
  { ibge: '312140707', name: 'Pereirinhas' },
  { ibge: '312140710', name: 'São Sebastião do Gil' },
  { ibge: '312150605', name: 'Desterro do Melo' },
  { ibge: '312160505', name: 'Diamantina' },
  { ibge: '312160510', name: 'Conselheiro Mata' },
  { ibge: '312160515', name: 'Desembargador Otoni' },
  { ibge: '312160520', name: 'Extração' },
  { ibge: '312160525', name: 'Guinda' },
  { ibge: '312160530', name: 'Inhaí' },
  { ibge: '312160535', name: 'Mendanha' },
  { ibge: '312160540', name: 'Planalto de Minas' },
  { ibge: '312160545', name: 'São João da Chapada' },
  { ibge: '312160550', name: 'Senador Mourão' },
  { ibge: '312160555', name: 'Sopa' },
  { ibge: '312170405', name: 'Diogo de Vasconcelos' },
  { ibge: '312180305', name: 'Dionísio' },
  { ibge: '312180308', name: 'Baixa Verde' },
  { ibge: '312180310', name: 'Conceição de Minas' },
  { ibge: '312190205', name: 'Divinésia' },
  { ibge: '312200905', name: 'Divino' },
  { ibge: '312200910', name: 'Bom Jesus do Divino' },
  { ibge: '312200925', name: 'Viletes' },
  { ibge: '312210805', name: 'Divino das Laranjeiras' },
  { ibge: '312210810', name: 'Central de Santa Helena' },
  { ibge: '312220705', name: 'Divinolândia de Minas' },
  { ibge: '312230605', name: 'Divinópolis' },
  { ibge: '312230610', name: 'Santo Antônio dos Campos' },
  { ibge: '312235505', name: 'Divisa Alegre' },
  { ibge: '312240505', name: 'Divisa Nova' },
  { ibge: '312245405', name: 'Divisópolis' },
  { ibge: '312247005', name: 'Dom Bosco' },
  { ibge: '312250405', name: 'Dom Cavati' },
  { ibge: '312260305', name: 'Dom Joaquim' },
  { ibge: '312260310', name: 'Gororós' },
  { ibge: '312270205', name: 'Dom Silvério' },
  { ibge: '312280105', name: 'Dom Viçoso' },
  { ibge: '312290005', name: 'Dona Eusébia' },
  { ibge: '312290030', name: 'São Manoel do Guaiaçu' },
  { ibge: '312300705', name: 'Dores de Campos' },
  { ibge: '312310605', name: 'Dores de Guanhães' },
  { ibge: '312320505', name: 'Dores do Indaiá' },
  { ibge: '312330405', name: 'Dores do Turvo' },
  { ibge: '312340305', name: 'Doresópolis' },
  { ibge: '312350205', name: 'Douradoquara' },
  { ibge: '312352805', name: 'Durandé' },
  { ibge: '312352815', name: 'São João da Figueira' },
  { ibge: '312352820', name: 'São José da Figueira' },
  { ibge: '312360105', name: 'Elói Mendes' },
  { ibge: '312370005', name: 'Engenheiro Caldas' },
  { ibge: '312370006', name: 'Divino do Traíra' },
  { ibge: '312370010', name: 'São José do Acácio' },
  { ibge: '312380905', name: 'Engenheiro Navarro' },
  { ibge: '312385805', name: 'Entre Folhas' },
  { ibge: '312390805', name: 'Entre Rios de Minas' },
  { ibge: '312390810', name: 'Serra do Camapuã' },
  { ibge: '312400505', name: 'Ervália' },
  { ibge: '312410405', name: 'Esmeraldas' },
  { ibge: '312410410', name: 'Andiroba' },
  { ibge: '312410415', name: 'Melo Viana' },
  { ibge: '312420305', name: 'Espera Feliz' },
  { ibge: '312420310', name: 'São José da Pedra Menina' },
  { ibge: '312430205', name: 'Espinosa' },
  { ibge: '312430210', name: 'Itamirim' },
  { ibge: '312440105', name: 'Espírito Santo do Dourado' },
  { ibge: '312450005', name: 'Estiva' },
  { ibge: '312450010', name: 'Pântano' },
  { ibge: '312460905', name: 'Estrela Dalva' },
  { ibge: '312460910', name: 'Água Viva' },
  { ibge: '312470805', name: 'Estrela do Indaiá' },
  { ibge: '312470810', name: 'Baú' },
  { ibge: '312480705', name: 'Estrela do Sul' },
  { ibge: '312480710', name: 'Chapada de Minas' },
  { ibge: '312480712', name: 'Dolearina' },
  { ibge: '312480715', name: 'Santa Rita da Estrela' },
  { ibge: '312480720', name: 'São Félix de Estrela' },
  { ibge: '312490605', name: 'Eugenópolis' },
  { ibge: '312490610', name: 'Gavião' },
  { ibge: '312490615', name: 'Pinhotiba' },
  { ibge: '312490620', name: 'Queirozes' },
  { ibge: '312500205', name: 'Ewbank da Câmara' },
  { ibge: '312510105', name: 'Extrema' },
  { ibge: '312520005', name: 'Fama' },
  { ibge: '312530905', name: 'Faria Lemos' },
  { ibge: '312540805', name: 'Felício dos Santos' },
  { ibge: '312550705', name: 'São Gonçalo do Rio Preto' },
  { ibge: '312560605', name: 'Felisburgo' },
  { ibge: '312570505', name: 'Felixlândia' },
  { ibge: '312570508', name: 'São Geraldo do Salto' },
  { ibge: '312570510', name: 'São José do Buriti' },
  { ibge: '312580405', name: 'Fernandes Tourinho' },
  { ibge: '312580410', name: 'Senhora da Penha' },
  { ibge: '312590305', name: 'Ferros' },
  { ibge: '312590310', name: 'Borba Gato' },
  { ibge: '312590315', name: 'Cubas' },
  { ibge: '312590320', name: 'Esmeraldas de Ferros' },
  { ibge: '312590325', name: 'Santa Rita do Rio do Peixe' },
  { ibge: '312590330', name: 'Santo Antônio da Fortaleza' },
  { ibge: '312590335', name: 'Sete Cachoeiras' },
  { ibge: '312595205', name: 'Fervedouro' },
  { ibge: '312595210', name: 'Bom Jesus do Madeira' },
  { ibge: '312595220', name: 'São Pedro do Glória' },
  { ibge: '312600005', name: 'Florestal' },
  { ibge: '312610905', name: 'Formiga' },
  { ibge: '312610910', name: 'Albertos' },
  { ibge: '312610915', name: 'Baiões' },
  { ibge: '312610925', name: 'Pontevila' },
  { ibge: '312620805', name: 'Formoso' },
  { ibge: '312620815', name: 'Goiasminas' },
  { ibge: '312630705', name: 'Fortaleza de Minas' },
  { ibge: '312640605', name: 'Fortuna de Minas' },
  { ibge: '312650505', name: 'Francisco Badaró' },
  { ibge: '312650530', name: 'Tocoiós de Minas' },
  { ibge: '312660405', name: 'Francisco Dumont' },
  { ibge: '312670305', name: 'Francisco Sá' },
  { ibge: '312670310', name: 'Canabrava' },
  { ibge: '312670315', name: 'Catuni' },
  { ibge: '312675205', name: 'Franciscópolis' },
  { ibge: '312675210', name: 'Antônio Ferreira' },
  { ibge: '312680205', name: 'Frei Gaspar' },
  { ibge: '312690105', name: 'Frei Inocêncio' },
  { ibge: '312695005', name: 'Frei Lagonegro' },
  { ibge: '312700805', name: 'Fronteira' },
  { ibge: '312700820', name: 'Santo Antônio do Rio Grande' },
  { ibge: '312705705', name: 'Fronteira dos Vales' },
  { ibge: '312707305', name: 'Fruta de Leite' },
  { ibge: '312710705', name: 'Frutal' },
  { ibge: '312710710', name: 'Aparecida de Minas' },
  { ibge: '312720605', name: 'Funilândia' },
  { ibge: '312730505', name: 'Galiléia' },
  { ibge: '312730508', name: 'Santa Cruz de Galiléia' },
  { ibge: '312730515', name: 'Sapucaia do Norte' },
  { ibge: '312733905', name: 'Gameleiras' },
  { ibge: '312733915', name: 'Jacu das Piranhas' },
  { ibge: '312735405', name: 'Glaucilândia' },
  { ibge: '312737005', name: 'Goiabeira' },
  { ibge: '312738805', name: 'Goianá' },
  { ibge: '312740405', name: 'Gonçalves' },
  { ibge: '312750305', name: 'Gonzaga' },
  { ibge: '312750310', name: 'Conceição da Brejaúba' },
  { ibge: '312760205', name: 'Gouveia' },
  { ibge: '312770105', name: 'Governador Valadares' },
  { ibge: '312770110', name: 'Alto de Santa Helena' },
  { ibge: '312770115', name: 'Baguari' },
  { ibge: '312770120', name: 'Brejaubinha' },
  { ibge: '312770125', name: 'Chonim' },
  { ibge: '312770128', name: 'Chonin de Baixo' },
  { ibge: '312770130', name: 'Derribadinha' },
  { ibge: '312770135', name: 'Penha do Cassiano' },
  { ibge: '312770137', name: 'Santo Antônio do Pontal' },
  { ibge: '312770140', name: 'Goiabal' },
  { ibge: '312770142', name: 'São José do Itapinoã' },
  { ibge: '312770145', name: 'São Vítor' },
  { ibge: '312770155', name: 'Vila Nova Floresta' },
  { ibge: '312780005', name: 'Grão Mogol' },
  { ibge: '312780010', name: 'Barrocão' },
  { ibge: '312780030', name: 'Vale das Cancelas' },
  { ibge: '312780035', name: 'Vila Sítio' },
  { ibge: '312790905', name: 'Grupiara' },
  { ibge: '312800605', name: 'Guanhães' },
  { ibge: '312800610', name: 'Correntinho' },
  { ibge: '312800615', name: 'Farias' },
  { ibge: '312800620', name: 'Sapucaia de Guanhães' },
  { ibge: '312800625', name: 'Taquaral de Guanhães' },
  { ibge: '312810505', name: 'Guapé' },
  { ibge: '312810510', name: 'Araúna' },
  { ibge: '312820405', name: 'Guaraciaba' },
  { ibge: '312825305', name: 'Guaraciama' },
  { ibge: '312830305', name: 'Guaranésia' },
  { ibge: '312830310', name: 'Santa Cruz do Prata' },
  { ibge: '312840205', name: 'Guarani' },
  { ibge: '312850105', name: 'Guarará' },
  { ibge: '312860005', name: 'Guarda-Mor' },
  { ibge: '312870905', name: 'Guaxupé' },
  { ibge: '312880805', name: 'Guidoval' },
  { ibge: '312890705', name: 'Guimarânia' },
  { ibge: '312900405', name: 'Guiricema' },
  { ibge: '312900410', name: 'Tuiutinga' },
  { ibge: '312900415', name: 'Vilas Boas' },
  { ibge: '312910305', name: 'Gurinhatã' },
  { ibge: '312910310', name: 'Flor de Minas' },
  { ibge: '312920205', name: 'Heliodora' },
  { ibge: '312930105', name: 'Iapu' },
  { ibge: '312930115', name: 'São Sebastião da Barra' },
  { ibge: '312940005', name: 'Ibertioga' },
  { ibge: '312950905', name: 'Ibiá' },
  { ibge: '312950910', name: 'Argenita' },
  { ibge: '312950915', name: 'Tobati' },
  { ibge: '312960805', name: 'Ibiaí' },
  { ibge: '312960815', name: 'Bom Jesus da Vereda' },
  { ibge: '312965705', name: 'Ibiracatu' },
  { ibge: '312965710', name: 'Bonança' },
  { ibge: '312965715', name: 'Campo Alegre de Minas' },
  { ibge: '312970705', name: 'Ibiraci' },
  { ibge: '312980605', name: 'Ibirité' },
  { ibge: '312980610', name: 'Parque Durval de Barros' },
  { ibge: '312990505', name: 'Ibitiúra de Minas' },
  { ibge: '313000205', name: 'Ibituruna' },
  { ibge: '313005105', name: 'Icaraí de Minas' },
  { ibge: '313010105', name: 'Igarapé' },
  { ibge: '313020005', name: 'Igaratinga' },
  { ibge: '313020010', name: 'Antunes' },
  { ibge: '313030905', name: 'Iguatama' },
  { ibge: '313030915', name: 'Corguinhos' },
  { ibge: '313040805', name: 'Ijaci' },
  { ibge: '313050705', name: 'Ilicínea' },
  { ibge: '313055605', name: 'Imbé de Minas' },
  { ibge: '313060605', name: 'Inconfidentes' },
  { ibge: '313065505', name: 'Indaiabira' },
  { ibge: '313065510', name: 'Barra de Alegria' },
  { ibge: '313070505', name: 'Indianópolis' },
  { ibge: '313080405', name: 'Ingaí' },
  { ibge: '313090305', name: 'Inhapim' },
  { ibge: '313090307', name: 'Bom Jesus do Rio Preto' },
  { ibge: '313090310', name: 'Itajutiba' },
  { ibge: '313090313', name: 'Jerusalém' },
  { ibge: '313090316', name: 'Macadame' },
  { ibge: '313090319', name: 'Novo Horizonte de Inhapim' },
  { ibge: '313090321', name: 'Santo Antônio do Alegre' },
  { ibge: '313090323', name: 'São Tomé de Minas' },
  { ibge: '313090325', name: 'Tabajara' },
  { ibge: '313100005', name: 'Inhaúma' },
  { ibge: '313110905', name: 'Inimutaba' },
  { ibge: '313115805', name: 'Ipaba' },
  { ibge: '313115815', name: 'Vale Verde de Minas' },
  { ibge: '313120805', name: 'Ipanema' },
  { ibge: '313130705', name: 'Ipatinga' },
  { ibge: '313130710', name: 'Barra Alegre' },
  { ibge: '313140605', name: 'Ipiaçu' },
  { ibge: '313150505', name: 'Ipuiúna' },
  { ibge: '313160405', name: 'Iraí de Minas' },
  { ibge: '313170305', name: 'Itabira' },
  { ibge: '313170310', name: 'Ipoema' },
  { ibge: '313170315', name: 'Senhora do Carmo' },
  { ibge: '313180205', name: 'Itabirinha' },
  { ibge: '313180210', name: 'Boa União de Itabirinha' },
  { ibge: '313190105', name: 'Itabirito' },
  { ibge: '313190110', name: 'Acuruí' },
  { ibge: '313190115', name: 'Bação' },
  { ibge: '313190120', name: 'São Gonçalo do Monte' },
  { ibge: '313200805', name: 'Itacambira' },
  { ibge: '313210705', name: 'Itacarambi' },
  { ibge: '313220605', name: 'Itaguara' },
  { ibge: '313230505', name: 'Itaipé' },
  { ibge: '313240405', name: 'Itajubá' },
  { ibge: '313240460', name: 'Lourenço Velho' },
  { ibge: '313250305', name: 'Itamarandiba' },
  { ibge: '313250313', name: 'Contrato' },
  { ibge: '313250315', name: 'Padre João Afonso' },
  { ibge: '313250320', name: 'Penha de França' },
  { ibge: '313260205', name: 'Itamarati de Minas' },
  { ibge: '313270105', name: 'Itambacuri' },
  { ibge: '313270110', name: 'Frei Serafim' },
  { ibge: '313270115', name: 'Guarataia' },
  { ibge: '313280005', name: 'Itambé do Mato Dentro' },
  { ibge: '313290905', name: 'Itamogi' },
  { ibge: '313300605', name: 'Itamonte' },
  { ibge: '313310505', name: 'Itanhandu' },
  { ibge: '313320405', name: 'Itanhomi' },
  { ibge: '313320415', name: 'Edgard Melo' },
  { ibge: '313320425', name: 'São Francisco do Jataí' },
  { ibge: '313330305', name: 'Itaobim' },
  { ibge: '313340205', name: 'Itapagipe' },
  { ibge: '313350105', name: 'Itapecerica' },
  { ibge: '313350110', name: 'Lamounier' },
  { ibge: '313350115', name: 'Marilândia' },
  { ibge: '313350120', name: 'Neolândia' },
  { ibge: '313360005', name: 'Itapeva' },
  { ibge: '313360010', name: 'Areias de Itapeva' },
  { ibge: '313360035', name: 'Tropical Flores' },
  { ibge: '313370905', name: 'Itatiaiuçu' },
  { ibge: '313370910', name: 'Santa Terezinha de Minas' },
  { ibge: '313375805', name: 'Itaú de Minas' },
  { ibge: '313380805', name: 'Itaúna' },
  { ibge: '313390705', name: 'Itaverava' },
  { ibge: '313390710', name: 'Monsenhor Isidro' },
  { ibge: '313400405', name: 'Itinga' },
  { ibge: '313400410', name: 'Jacaré' },
  { ibge: '313410305', name: 'Itueta' },
  { ibge: '313410310', name: 'Quatituba' },
  { ibge: '313420205', name: 'Ituiutaba' },
  { ibge: '313430105', name: 'Itumirim' },
  { ibge: '313430110', name: 'Macuco de Minas' },
  { ibge: '313430120', name: 'Rosário do Rio Grande' },
  { ibge: '313440005', name: 'Iturama' },
  { ibge: '313440010', name: 'Alexandrita' },
  { ibge: '313450905', name: 'Itutinga' },
  { ibge: '313460805', name: 'Jaboticatubas' },
  { ibge: '313460810', name: 'Almeida' },
  { ibge: '313470705', name: 'Jacinto' },
  { ibge: '313470707', name: 'Avaí do Jacinto' },
  { ibge: '313470710', name: 'Jaguarão' },
  { ibge: '313480605', name: 'Jacuí' },
  { ibge: '313490505', name: 'Jacutinga' },
  { ibge: '313490510', name: 'São Sebastião dos Robertos' },
  { ibge: '313490515', name: 'Sapucaí' },
  { ibge: '313500105', name: 'Jaguaraçu' },
  { ibge: '313500116', name: 'Lagoa de Pau' },
  { ibge: '313505005', name: 'Jaíba' },
  { ibge: '313507605', name: 'Jampruca' },
  { ibge: '313507615', name: 'São Sebastião do Barroso' },
  { ibge: '313510005', name: 'Janaúba' },
  { ibge: '313510010', name: 'Barreiro da Raiz' },
  { ibge: '313510015', name: 'Quem-Quem' },
  { ibge: '313510020', name: 'Vila Nova dos Poções' },
  { ibge: '313520905', name: 'Januária' },
  { ibge: '313520915', name: 'Brejo do Amparo' },
  { ibge: '313520925', name: 'Levinópolis' },
  { ibge: '313520933', name: 'Pandeiros' },
  { ibge: '313520935', name: 'Riacho da Cruz' },
  { ibge: '313520940', name: 'São Joaquim' },
  { ibge: '313520945', name: 'Tejuco' },
  { ibge: '313520950', name: 'Várzea Bonita' },
  { ibge: '313530805', name: 'Japaraíba' },
  { ibge: '313535705', name: 'Japonvar' },
  { ibge: '313535720', name: 'Nova Minda' },
  { ibge: '313540705', name: 'Jeceaba' },
  { ibge: '313540710', name: 'Bituri' },
  { ibge: '313540715', name: 'Caetano Lopes' },
  { ibge: '313545605', name: 'Jenipapo de Minas' },
  { ibge: '313550605', name: 'Jequeri' },
  { ibge: '313550610', name: 'Grota' },
  { ibge: '313550615', name: 'Piscamba' },
  { ibge: '313550617', name: 'Pouso Alegre da Mata' },
  { ibge: '313550620', name: 'São Vicente do Grama' },
  { ibge: '313560505', name: 'Jequitaí' },
  { ibge: '313570405', name: 'Jequitibá' },
  { ibge: '313570410', name: 'Doutor Campolina' },
  { ibge: '313580305', name: 'Jequitinhonha' },
  { ibge: '313580310', name: 'Guaranilândia' },
  { ibge: '313580315', name: 'São Pedro do Jequitinhonha' },
  { ibge: '313590205', name: 'Jesuânia' },
  { ibge: '313600905', name: 'Joaíma' },
  { ibge: '313600907', name: 'Giru' },
  { ibge: '313610805', name: 'Joanésia' },
  { ibge: '313620705', name: 'João Monlevade' },
  { ibge: '313630605', name: 'João Pinheiro' },
  { ibge: '313630610', name: 'Caatinga' },
  { ibge: '313630615', name: 'Canabrava' },
  { ibge: '313630617', name: 'Luizlândia do Oeste' },
  { ibge: '313630620', name: "Olhos d'Água do Oeste" },
  { ibge: '313630623', name: 'Santa Luzia da Serra' },
  { ibge: '313630625', name: 'Veredas' },
  { ibge: '313640505', name: 'Joaquim Felício' },
  { ibge: '313650405', name: 'Jordânia' },
  { ibge: '313650410', name: 'Estrela de Jordânia' },
  { ibge: '313652005', name: 'José Gonçalves de Minas' },
  { ibge: '313655305', name: 'José Raydan' },
  { ibge: '313657905', name: 'Josenópolis' },
  { ibge: '313660305', name: 'Nova União' },
  { ibge: '313665205', name: 'Juatuba' },
  { ibge: '313665210', name: 'Boa Vista da Serra' },
  { ibge: '313670205', name: 'Juiz de Fora' },
  { ibge: '313670260', name: 'Rosário de Minas' },
  { ibge: '313670270', name: 'Sarandira' },
  { ibge: '313670280', name: 'Torreões' },
  { ibge: '313680105', name: 'Juramento' },
  { ibge: '313690005', name: 'Juruaia' },
  { ibge: '313695905', name: 'Juvenília' },
  { ibge: '313695915', name: 'Monte Rei' },
  { ibge: '313695920', name: 'Porto Agrário' },
  { ibge: '313700705', name: 'Ladainha' },
  { ibge: '313700710', name: 'Concórdia do Mucuri' },
  { ibge: '313710605', name: 'Lagamar' },
  { ibge: '313710620', name: 'São Brás de Minas' },
  { ibge: '313720505', name: 'Lagoa da Prata' },
  { ibge: '313720515', name: 'Martins Guimarães' },
  { ibge: '313730405', name: 'Lagoa dos Patos' },
  { ibge: '313740305', name: 'Lagoa Dourada' },
  { ibge: '313750205', name: 'Lagoa Formosa' },
  { ibge: '313750207', name: 'Limeira de Minas' },
  { ibge: '313750210', name: 'Monjolinho de Minas' },
  { ibge: '313753605', name: 'Lagoa Grande' },
  { ibge: '313760105', name: 'Lagoa Santa' },
  { ibge: '313760114', name: 'Lagoinha de Fora' },
  { ibge: '313760115', name: 'Lapinha' },
  { ibge: '313770005', name: 'Lajinha' },
  { ibge: '313770020', name: 'Prata de Lajinha' },
  { ibge: '313780905', name: 'Lambari' },
  { ibge: '313790805', name: 'Lamim' },
  { ibge: '313800505', name: 'Laranjal' },
  { ibge: '313800510', name: 'São João da Sapucaia' },
  { ibge: '313810405', name: 'Lassance' },
  { ibge: '313820305', name: 'Lavras' },
  { ibge: '313830205', name: 'Leandro Ferreira' },
  { ibge: '313835105', name: 'Leme do Prado' },
  { ibge: '313835120', name: 'Posses' },
  { ibge: '313840105', name: 'Leopoldina' },
  { ibge: '313840110', name: 'Abaíba' },
  { ibge: '313840115', name: 'Piacatuba' },
  { ibge: '313840120', name: 'Providência' },
  { ibge: '313840125', name: 'Ribeiro Junqueira' },
  { ibge: '313840130', name: 'Tebas' },
  { ibge: '313850005', name: 'Liberdade' },
  { ibge: '313860905', name: 'Lima Duarte' },
  { ibge: '313860910', name: 'Conceição da Ibitipoca' },
  { ibge: '313860915', name: 'São Domingos da Bocaina' },
  { ibge: '313860920', name: 'São José dos Lopes' },
  { ibge: '313862505', name: 'Limeira do Oeste' },
  { ibge: '313865805', name: 'Lontra' },
  { ibge: '313865815', name: 'Umbuzeiro' },
  { ibge: '313867405', name: 'Luisburgo' },
  { ibge: '313868205', name: 'Luislândia' },
  { ibge: '313868225', name: 'São Judas Tadeu de Minas' },
  { ibge: '313870805', name: 'Luminárias' },
  { ibge: '313880705', name: 'Luz' },
  { ibge: '313880710', name: 'Esteios' },
  { ibge: '313890605', name: 'Machacalis' },
  { ibge: '313900305', name: 'Machado' },
  { ibge: '313900310', name: 'Douradinho' },
  { ibge: '313910205', name: 'Madre de Deus de Minas' },
  { ibge: '313920105', name: 'Malacacheta' },
  { ibge: '313920120', name: 'Jaguaritira' },
  { ibge: '313920125', name: 'Junco de Minas' },
  { ibge: '313920130', name: 'Santo Antônio do Mucuri' },
  { ibge: '313925005', name: 'Mamonas' },
  { ibge: '313925010', name: 'Barrinha' },
  { ibge: '313930005', name: 'Manga' },
  { ibge: '313930020', name: 'Nhandutiba' },
  { ibge: '313940905', name: 'Manhuaçu' },
  { ibge: '313940907', name: 'Dom Corrêa' },
  { ibge: '313940911', name: 'Ponte do Silva' },
  { ibge: '313940913', name: 'Palmeiras do Manhuaçu' },
  { ibge: '313940914', name: 'Realeza' },
  { ibge: '313940925', name: 'São Pedro do Avaí' },
  { ibge: '313940930', name: 'São Sebastião do Sacramento' },
  { ibge: '313940940', name: 'Vilanova' },
  { ibge: '313950805', name: 'Manhumirim' },
  { ibge: '313960705', name: 'Mantena' },
  { ibge: '313960710', name: 'Barra do Ariranha' },
  { ibge: '313960715', name: 'Limeira de Mantena' },
  { ibge: '313970605', name: 'Maravilhas' },
  { ibge: '313980505', name: 'Mar de Espanha' },
  { ibge: '313980510', name: 'Engenho Novo' },
  { ibge: '313980515', name: 'Saudade' },
  { ibge: '313990405', name: 'Maria da Fé' },
  { ibge: '313990410', name: 'Pintos Negreiros' },
  { ibge: '314000105', name: 'Mariana' },
  { ibge: '314000110', name: 'Bandeirantes' },
  { ibge: '314000115', name: 'Cachoeira do Brumado' },
  { ibge: '314000120', name: 'Camargos' },
  { ibge: '314000125', name: 'Cláudio Manuel' },
  { ibge: '314000130', name: 'Furquim' },
  { ibge: '314000135', name: 'Monsenhor Horta' },
  { ibge: '314000140', name: 'Padre Viegas' },
  { ibge: '314000145', name: 'Passagem de Mariana' },
  { ibge: '314000150', name: 'Santa Rita Durão' },
  { ibge: '314010005', name: 'Marilac' },
  { ibge: '314015905', name: 'Mário Campos' },
  { ibge: '314020905', name: 'Maripá de Minas' },
  { ibge: '314030805', name: 'Marliéria' },
  { ibge: '314030815', name: 'Cava Grande' },
  { ibge: '314040705', name: 'Marmelópolis' },
  { ibge: '314050605', name: 'Martinho Campos' },
  { ibge: '314050610', name: 'Alberto Isaacson' },
  { ibge: '314050615', name: 'Ibitira' },
  { ibge: '314053005', name: 'Martins Soares' },
  { ibge: '314053020', name: 'Pinheiro de Minas' },
  { ibge: '314055505', name: 'Mata Verde' },
  { ibge: '314060505', name: 'Materlândia' },
  { ibge: '314070405', name: 'Mateus Leme' },
  { ibge: '314070410', name: 'Azurita' },
  { ibge: '314070420', name: 'Serra Azul' },
  { ibge: '314080305', name: 'Matias Barbosa' },
  { ibge: '314085205', name: 'Matias Cardoso' },
  { ibge: '314085235', name: 'Rio Verde de Minas' },
  { ibge: '314090205', name: 'Matipó' },
  { ibge: '314090210', name: 'Padre Fialho' },
  { ibge: '314100905', name: 'Mato Verde' },
  { ibge: '314100910', name: 'São João do Bonito' },
  { ibge: '314110805', name: 'Matozinhos' },
  { ibge: '314110810', name: 'Mocambeiro' },
  { ibge: '314120705', name: 'Matutina' },
  { ibge: '314130605', name: 'Medeiros' },
  { ibge: '314140505', name: 'Medina' },
  { ibge: '314140510', name: 'Tuparecê' },
  { ibge: '314150405', name: 'Mendes Pimentel' },
  { ibge: '314160305', name: 'Mercês' },
  { ibge: '314170205', name: 'Mesquita' },
  { ibge: '314170211', name: 'Barra Grande de Mesquita' },
  { ibge: '314180105', name: 'Minas Novas' },
  { ibge: '314180106', name: 'Baixa Quente' },
  { ibge: '314180115', name: 'Cruzinha' },
  { ibge: '314180120', name: 'Lagoa Grande de Minas Novas' },
  { ibge: '314180125', name: 'Ribeirão da Folha' },
  { ibge: '314190005', name: 'Minduri' },
  { ibge: '314200705', name: 'Mirabela' },
  { ibge: '314200710', name: 'Muquém' },
  { ibge: '314210605', name: 'Miradouro' },
  { ibge: '314220505', name: 'Miraí' },
  { ibge: '314220510', name: 'Dores da Vitória' },
  { ibge: '314225405', name: 'Miravânia' },
  { ibge: '314230405', name: 'Moeda' },
  { ibge: '314230410', name: 'Coco' },
  { ibge: '314240305', name: 'Moema' },
  { ibge: '314250205', name: 'Monjolos' },
  { ibge: '314250210', name: 'Rodeador' },
  { ibge: '314260105', name: 'Monsenhor Paulo' },
  { ibge: '314270005', name: 'Montalvânia' },
  { ibge: '314270020', name: 'Pitarana' },
  { ibge: '314270025', name: 'São Sebastião dos Poções' },
  { ibge: '314270030', name: 'Capitania' },
  { ibge: '314280905', name: 'Monte Alegre de Minas' },
  { ibge: '314290805', name: 'Monte Azul' },
  { ibge: '314300505', name: 'Monte Belo' },
  { ibge: '314300510', name: 'Juréia' },
  { ibge: '314300515', name: 'Santa Cruz da Aparecida' },
  { ibge: '314310405', name: 'Monte Carmelo' },
  { ibge: '314315305', name: 'Monte Formoso' },
  { ibge: '314320305', name: 'Monte Santo de Minas' },
  { ibge: '314320310', name: 'Milagre' },
  { ibge: '314330205', name: 'Montes Claros' },
  { ibge: '314330208', name: 'Aparecida do Mundo Novo' },
  { ibge: '314330210', name: 'Ermidinha' },
  { ibge: '314330215', name: 'Miralta' },
  { ibge: '314330220', name: 'Nova Esperança' },
  { ibge: '314330222', name: 'Panorâmica' },
  { ibge: '314330225', name: 'Santa Rosa de Lima' },
  { ibge: '314330230', name: 'São João da Vereda' },
  { ibge: '314330235', name: 'São Pedro da Garça' },
  { ibge: '314330240', name: 'Vila Nova de Minas' },
  { ibge: '314340105', name: 'Monte Sião' },
  { ibge: '314345005', name: 'Montezuma' },
  { ibge: '314350005', name: 'Morada Nova de Minas' },
  { ibge: '314350010', name: 'Frei Orlando' },
  { ibge: '314360905', name: 'Morro da Garça' },
  { ibge: '314370805', name: 'Morro do Pilar' },
  { ibge: '314380705', name: 'Munhoz' },
  { ibge: '314390605', name: 'Muriaé' },
  { ibge: '314390610', name: 'Belisário' },
  { ibge: '314390615', name: 'Boa Família' },
  { ibge: '314390620', name: 'Bom Jesus da Cachoeira' },
  { ibge: '314390625', name: 'Itamuri' },
  { ibge: '314390630', name: 'Pirapanema' },
  { ibge: '314390640', name: 'Vermelho' },
  { ibge: '314400305', name: 'Mutum' },
  { ibge: '314400310', name: 'Centenário' },
  { ibge: '314400312', name: 'Imbiruçu' },
  { ibge: '314400315', name: 'Ocidente' },
  { ibge: '314400320', name: 'Roseiral' },
  { ibge: '314400325', name: 'São Francisco do Humaitá' },
  { ibge: '314410205', name: 'Muzambinho' },
  { ibge: '314420105', name: 'Nacip Raydan' },
  { ibge: '314430005', name: 'Nanuque' },
  { ibge: '314430010', name: 'Vila Pereira' },
  { ibge: '314435905', name: 'Naque' },
  { ibge: '314437505', name: 'Natalândia' },
  { ibge: '314440905', name: 'Natércia' },
  { ibge: '314450805', name: 'Nazareno' },
  { ibge: '314460705', name: 'Nepomuceno' },
  { ibge: '314460720', name: 'Nazaré de Minas' },
  { ibge: '314460725', name: 'Santo Antônio do Cruzeiro' },
  { ibge: '314465605', name: 'Ninheira' },
  { ibge: '314467205', name: 'Nova Belém' },
  { ibge: '314467220', name: 'Santo Antônio de Nova Belém' },
  { ibge: '314470605', name: 'Nova Era' },
  { ibge: '314480505', name: 'Nova Lima' },
  { ibge: '314490405', name: 'Nova Módica' },
  { ibge: '314500005', name: 'Nova Ponte' },
  { ibge: '314505905', name: 'Nova Porteirinha' },
  { ibge: '314510905', name: 'Nova Resende' },
  { ibge: '314510910', name: 'Petúnia' },
  { ibge: '314520805', name: 'Nova Serrana' },
  { ibge: '314520810', name: 'Boa Vista de Minas' },
  { ibge: '314530705', name: 'Novo Cruzeiro' },
  { ibge: '314530710', name: 'Lufa' },
  { ibge: '314530715', name: 'Novilhona' },
  { ibge: '314530720', name: 'Queixada' },
  { ibge: '314535605', name: 'Novo Oriente de Minas' },
  { ibge: '314537205', name: 'Novorizonte' },
  { ibge: '314540605', name: 'Olaria' },
  { ibge: '314545505', name: "Olhos-d'Água" },
  { ibge: '314550505', name: 'Olímpio Noronha' },
  { ibge: '314560405', name: 'Oliveira' },
  { ibge: '314560410', name: 'Morro do Ferro' },
  { ibge: '314570305', name: 'Oliveira Fortes' },
  { ibge: '314580205', name: 'Onça de Pitangui' },
  { ibge: '314585105', name: 'Oratórios' },
  { ibge: '314587705', name: 'Orizânia' },
  { ibge: '314590105', name: 'Ouro Branco' },
  { ibge: '314600805', name: 'Ouro Fino' },
  { ibge: '314600810', name: 'Crisólia' },
  { ibge: '314600815', name: 'São José do Mato Dentro' },
  { ibge: '314610705', name: 'Ouro Preto' },
  { ibge: '314610710', name: 'Amarantina' },
  { ibge: '314610715', name: 'Antônio Pereira' },
  { ibge: '314610720', name: 'Cachoeira do Campo' },
  { ibge: '314610725', name: 'Engenheiro Correia' },
  { ibge: '314610730', name: 'Glaura' },
  { ibge: '314610734', name: 'Lavras Novas' },
  { ibge: '314610735', name: 'Miguel Burnier' },
  { ibge: '314610740', name: 'Rodrigo Silva' },
  { ibge: '314610745', name: 'Santa Rita de Ouro Preto' },
  { ibge: '314610750', name: 'Santo Antônio do Leite' },
  { ibge: '314610752', name: 'Santo Antônio do Salto' },
  { ibge: '314610755', name: 'São Bartolomeu' },
  { ibge: '314620605', name: 'Ouro Verde de Minas' },
  { ibge: '314625505', name: 'Padre Carvalho' },
  { ibge: '314630505', name: 'Padre Paraíso' },
  { ibge: '314640405', name: 'Paineiras' },
  { ibge: '314640410', name: 'Poções de Paineiras' },
  { ibge: '314650305', name: 'Pains' },
  { ibge: '314650310', name: 'Vila Costina' },
  { ibge: '314655205', name: 'Pai Pedro' },
  { ibge: '314660205', name: 'Paiva' },
  { ibge: '314670105', name: 'Palma' },
  { ibge: '314670110', name: 'Cisneiros' },
  { ibge: '314670115', name: 'Itapiruçu' },
  { ibge: '314675005', name: 'Palmópolis' },
  { ibge: '314675010', name: 'Dois de Abril' },
  { ibge: '314690905', name: 'Papagaios' },
  { ibge: '314700605', name: 'Paracatu' },
  { ibge: '314710505', name: 'Pará de Minas' },
  { ibge: '314710510', name: 'Ascensão' },
  { ibge: '314710515', name: 'Carioca' },
  { ibge: '314710520', name: 'Córrego do Barro' },
  { ibge: '314710522', name: 'Tavares de Minas' },
  { ibge: '314710525', name: 'Torneiros' },
  { ibge: '314720405', name: 'Paraguaçu' },
  { ibge: '314720410', name: 'Guaipava' },
  { ibge: '314730305', name: 'Paraisópolis' },
  { ibge: '314730310', name: 'Costas' },
  { ibge: '314740205', name: 'Paraopeba' },
  { ibge: '314750105', name: 'Passabém' },
  { ibge: '314760005', name: 'Passa Quatro' },
  { ibge: '314760010', name: 'Pé do Morro' },
  { ibge: '314760015', name: 'Pinheirinhos' },
  { ibge: '314770905', name: 'Passa Tempo' },
  { ibge: '314780805', name: 'Passa-Vinte' },
  { ibge: '314790705', name: 'Passos' },
  { ibge: '314795605', name: 'Patis' },
  { ibge: '314800405', name: 'Patos de Minas' },
  { ibge: '314800410', name: 'Bom Sucesso de Patos' },
  { ibge: '314800415', name: 'Chumbo' },
  { ibge: '314800420', name: 'Major Porto' },
  { ibge: '314800425', name: 'Pindaíbas' },
  { ibge: '314800427', name: 'Pilar' },
  { ibge: '314800430', name: 'Santana de Patos' },
  { ibge: '314810305', name: 'Patrocínio' },
  { ibge: '314810310', name: 'Salitre de Minas' },
  { ibge: '314810313', name: 'Santa Luzia dos Barros' },
  { ibge: '314810315', name: 'São João da Serra Negra' },
  { ibge: '314810320', name: 'Silvano' },
  { ibge: '314820205', name: 'Patrocínio do Muriaé' },
  { ibge: '314830105', name: 'Paula Cândido' },
  { ibge: '314830110', name: 'Airões' },
  { ibge: '314840005', name: 'Paulistas' },
  { ibge: '314850905', name: 'Pavão' },
  { ibge: '314860805', name: 'Peçanha' },
  { ibge: '314860815', name: 'Santa Teresa do Bonito' },
  { ibge: '314870705', name: 'Pedra Azul' },
  { ibge: '314870710', name: 'Araçaji de Minas' },
  { ibge: '314875605', name: 'Pedra Bonita' },
  { ibge: '314880605', name: 'Pedra do Anta' },
  { ibge: '314890505', name: 'Pedra do Indaiá' },
  { ibge: '314900205', name: 'Pedra Dourada' },
  { ibge: '314910105', name: 'Pedralva' },
  { ibge: '314915005', name: 'Pedras de Maria da Cruz' },
  { ibge: '314915010', name: 'São Pedro das Tabocas' },
  { ibge: '314920005', name: 'Pedrinópolis' },
  { ibge: '314930905', name: 'Pedro Leopoldo' },
  { ibge: '314930910', name: 'Doutor Lund' },
  { ibge: '314930915', name: 'Fidalgo' },
  { ibge: '314930917', name: 'Lagoa de Santo Antônio' },
  { ibge: '314930920', name: 'Vera Cruz de Minas' },
  { ibge: '314940805', name: 'Pedro Teixeira' },
  { ibge: '314950705', name: 'Pequeri' },
  { ibge: '314960605', name: 'Pequi' },
  { ibge: '314970505', name: 'Perdigão' },
  { ibge: '314980405', name: 'Perdizes' },
  { ibge: '314990305', name: 'Perdões' },
  { ibge: '314995205', name: 'Periquito' },
  { ibge: '314995215', name: 'Pedra Corrida' },
  { ibge: '314995220', name: 'São Sebastião do Baixio' },
  { ibge: '315000005', name: 'Pescador' },
  { ibge: '315010905', name: 'Piau' },
  { ibge: '315015805', name: 'Piedade de Caratinga' },
  { ibge: '315020805', name: 'Piedade de Ponte Nova' },
  { ibge: '315030705', name: 'Piedade do Rio Grande' },
  { ibge: '315030720', name: 'Paraíso da Piedade' },
  { ibge: '315030725', name: 'Santo Antônio do Porto' },
  { ibge: '315040605', name: 'Piedade dos Gerais' },
  { ibge: '315050505', name: 'Pimenta' },
  { ibge: '315050510', name: 'Santo Hilário' },
  { ibge: '315053905', name: "Pingo-d'Água" },
  { ibge: '315057005', name: 'Pintópolis' },
  { ibge: '315057035', name: 'Vila Acari' },
  { ibge: '315060405', name: 'Piracema' },
  { ibge: '315070305', name: 'Pirajuba' },
  { ibge: '315080205', name: 'Piranga' },
  { ibge: '315080210', name: 'Pinheiros Altos' },
  { ibge: '315080215', name: 'Santo Antônio do Pirapetinga' },
  { ibge: '315090105', name: 'Piranguçu' },
  { ibge: '315100805', name: 'Piranguinho' },
  { ibge: '315100810', name: 'Olegário Maciel' },
  { ibge: '315100820', name: 'Santa Bárbara do Sapucaí' },
  { ibge: '315110705', name: 'Pirapetinga' },
  { ibge: '315110710', name: 'Caiapó' },
  { ibge: '315110720', name: 'Valão Quente' },
  { ibge: '315120605', name: 'Pirapora' },
  { ibge: '315130505', name: 'Piraúba' },
  { ibge: '315140405', name: 'Pitangui' },
  { ibge: '315150305', name: 'Piumhi' },
  { ibge: '315160205', name: 'Planura' },
  { ibge: '315170105', name: 'Poço Fundo' },
  { ibge: '315170110', name: 'Paiolinho' },
  { ibge: '315180005', name: 'Poços de Caldas' },
  { ibge: '315190905', name: 'Pocrane' },
  { ibge: '315190910', name: 'Assaraí' },
  { ibge: '315190915', name: 'Barra da Figueira' },
  { ibge: '315200605', name: 'Pompéu' },
  { ibge: '315200610', name: 'Silva Campos' },
  { ibge: '315210505', name: 'Ponte Nova' },
  { ibge: '315210513', name: 'Rosário do Pontal' },
  { ibge: '315210515', name: 'Vau-Açu' },
  { ibge: '315213105', name: 'Ponto Chique' },
  { ibge: '315217005', name: 'Ponto dos Volantes' },
  { ibge: '315217020', name: 'Santana do Araçuaí' },
  { ibge: '315220405', name: 'Porteirinha' },
  { ibge: '315220410', name: 'Gorutuba' },
  { ibge: '315220412', name: 'Mocambinho' },
  { ibge: '315220414', name: 'Paciência' },
  { ibge: '315220416', name: 'Mulungu de Minas' },
  { ibge: '315220422', name: 'Serra Branca de Minas' },
  { ibge: '315220423', name: 'Tanque de Porteirinha' },
  { ibge: '315220425', name: 'Tocandira' },
  { ibge: '315230305', name: 'Porto Firme' },
  { ibge: '315240205', name: 'Poté' },
  { ibge: '315240207', name: 'Sucanga' },
  { ibge: '315240210', name: 'Valão' },
  { ibge: '315250105', name: 'Pouso Alegre' },
  { ibge: '315250110', name: 'Sao José do Pântano' },
  { ibge: '315260005', name: 'Pouso Alto' },
  { ibge: '315260010', name: 'Santana do Capivari' },
  { ibge: '315270905', name: 'Prados' },
  { ibge: '315280805', name: 'Prata' },
  { ibge: '315280810', name: 'Jardinésia' },
  { ibge: '315280815', name: 'Patrimônio' },
  { ibge: '315290705', name: 'Pratápolis' },
  { ibge: '315300405', name: 'Pratinha' },
  { ibge: '315310305', name: 'Presidente Bernardes' },
  { ibge: '315320205', name: 'Presidente Juscelino' },
  { ibge: '315330105', name: 'Presidente Kubitschek' },
  { ibge: '315340005', name: 'Presidente Olegário' },
  { ibge: '315340010', name: 'Galena' },
  { ibge: '315340020', name: 'Ponte Firme' },
  { ibge: '315340030', name: 'Santiago de Minas' },
  { ibge: '315350905', name: 'Alto Jequitibá' },
  { ibge: '315350925', name: 'Padre Júlio Maria' },
  { ibge: '315360805', name: 'Prudente de Morais' },
  { ibge: '315370705', name: 'Quartel Geral' },
  { ibge: '315370710', name: 'Quartel de São João' },
  { ibge: '315380605', name: 'Queluzito' },
  { ibge: '315390505', name: 'Raposos' },
  { ibge: '315400205', name: 'Raul Soares' },
  { ibge: '315400210', name: 'Bicuíba' },
  { ibge: '315400215', name: 'Santana do Tabuleiro' },
  { ibge: '315400220', name: 'São Sebastião do Óculo' },
  { ibge: '315400225', name: 'São Vicente da Estrela' },
  { ibge: '315400235', name: 'Vermelho Velho' },
  { ibge: '315410105', name: 'Recreio' },
  { ibge: '315410110', name: 'Angaturama' },
  { ibge: '315410115', name: 'Conceição da Boa Vista' },
  { ibge: '315415005', name: 'Reduto' },
  { ibge: '315420005', name: 'Resende Costa' },
  { ibge: '315420010', name: 'Jacarandira' },
  { ibge: '315430905', name: 'Resplendor' },
  { ibge: '315430910', name: 'Bom Pastor' },
  { ibge: '315430915', name: 'Calixto' },
  { ibge: '315430920', name: 'Campo Alegre de Minas' },
  { ibge: '315430925', name: 'Independência' },
  { ibge: '315430930', name: 'Nicolândia' },
  { ibge: '315440805', name: 'Ressaquinha' },
  { ibge: '315440807', name: 'Canjamba' },
  { ibge: '315440830', name: 'Simão Tamm' },
  { ibge: '315440835', name: 'Vargem do Amargoso' },
  { ibge: '315445705', name: 'Riachinho' },
  { ibge: '315450705', name: 'Riacho dos Machados' },
  { ibge: '315450710', name: 'Bem-Querer' },
  { ibge: '315460605', name: 'Ribeirão das Neves' },
  { ibge: '315460610', name: 'Justinópolis' },
  { ibge: '315470505', name: 'Ribeirão Vermelho' },
  { ibge: '315480405', name: 'Rio Acima' },
  { ibge: '315490305', name: 'Rio Casca' },
  { ibge: '315490310', name: 'Jurumirim' },
  { ibge: '315500905', name: 'Rio Doce' },
  { ibge: '315510805', name: 'Rio do Prado' },
  { ibge: '315520705', name: 'Rio Espera' },
  { ibge: '315520710', name: 'Piranguita' },
  { ibge: '315520715', name: 'Rio Melo' },
  { ibge: '315530605', name: 'Rio Manso' },
  { ibge: '315530610', name: 'Souza' },
  { ibge: '315540505', name: 'Rio Novo' },
  { ibge: '315550405', name: 'Rio Paranaíba' },
  { ibge: '315550410', name: 'Abaeté dos Mendes' },
  { ibge: '315560305', name: 'Rio Pardo de Minas' },
  { ibge: '315560325', name: 'Serra Nova' },
  { ibge: '315570205', name: 'Rio Piracicaba' },
  { ibge: '315570210', name: 'Conceição de Piracicaba' },
  { ibge: '315570215', name: 'Padre Pinto' },
  { ibge: '315580105', name: 'Rio Pomba' },
  { ibge: '315590005', name: 'Rio Preto' },
  { ibge: '315600705', name: 'Rio Vermelho' },
  { ibge: '315600710', name: 'Pedra Menina' },
  { ibge: '315610605', name: 'Ritápolis' },
  { ibge: '315620505', name: 'Rochedo de Minas' },
  { ibge: '315630405', name: 'Rodeiro' },
  { ibge: '315640305', name: 'Romaria' },
  { ibge: '315645205', name: 'Rosário da Limeira' },
  { ibge: '315650205', name: 'Rubelita' },
  { ibge: '315660105', name: 'Rubim' },
  { ibge: '315660110', name: 'Itapiru' },
  { ibge: '315670005', name: 'Sabará' },
  { ibge: '315670010', name: 'Carvalho de Brito' },
  { ibge: '315670015', name: 'Mestre Caetano' },
  { ibge: '315670020', name: 'Ravena' },
  { ibge: '315680905', name: 'Sabinópolis' },
  { ibge: '315680910', name: 'Euxenita' },
  { ibge: '315680915', name: 'Quilombo' },
  { ibge: '315690805', name: 'Sacramento' },
  { ibge: '315690810', name: 'Desemboque' },
  { ibge: '315700505', name: 'Salinas' },
  { ibge: '315700510', name: 'Ferreirópolis' },
  { ibge: '315700525', name: 'Nova Matrona' },
  { ibge: '315710405', name: 'Salto da Divisa' },
  { ibge: '315720305', name: 'Santa Bárbara' },
  { ibge: '315720310', name: 'Barra Feliz' },
  { ibge: '315720315', name: 'Brumal' },
  { ibge: '315720325', name: 'Conceição do Rio Acima' },
  { ibge: '315720330', name: 'Florália' },
  { ibge: '315725205', name: 'Santa Bárbara do Leste' },
  { ibge: '315727805', name: 'Santa Bárbara do Monte Verde' },
  { ibge: '315727820', name: 'São Sebastião do Barreado' },
  { ibge: '315730205', name: 'Santa Bárbara do Tugúrio' },
  { ibge: '315730210', name: 'Bom Retiro' },
  { ibge: '315733605', name: 'Santa Cruz de Minas' },
  { ibge: '315737705', name: 'Santa Cruz de Salinas' },
  { ibge: '315740105', name: 'Santa Cruz do Escalvado' },
  { ibge: '315740110', name: 'São Sebastião do Soberbo' },
  { ibge: '315740115', name: 'Zito Soares' },
  { ibge: '315750005', name: 'Santa Efigênia de Minas' },
  { ibge: '315760905', name: 'Santa Fé de Minas' },
  { ibge: '315765805', name: 'Santa Helena de Minas' },
  { ibge: '315765810', name: 'Bom Jesus da Vitória' },
  { ibge: '315770805', name: 'Santa Juliana' },
  { ibge: '315770810', name: 'Zelândia' },
  { ibge: '315780705', name: 'Santa Luzia' },
  { ibge: '315780710', name: 'São Benedito' },
  { ibge: '315790605', name: 'Santa Margarida' },
  { ibge: '315790610', name: 'Ribeirão de São Domingos' },
  { ibge: '315800305', name: 'Santa Maria de Itabira' },
  { ibge: '315800310', name: 'Itauninha' },
  { ibge: '315810205', name: 'Santa Maria do Salto' },
  { ibge: '315820105', name: 'Santa Maria do Suaçuí' },
  { ibge: '315820107', name: 'Brejo de Minas' },
  { ibge: '315820110', name: 'Glucínio' },
  { ibge: '315820120', name: 'Poaia' },
  { ibge: '315830005', name: 'Santana da Vargem' },
  { ibge: '315840905', name: 'Santana de Cataguases' },
  { ibge: '315850805', name: 'Santana de Pirapama' },
  { ibge: '315850810', name: 'Fechados' },
  { ibge: '315860705', name: 'Santana do Deserto' },
  { ibge: '315870605', name: 'Santana do Garambéu' },
  { ibge: '315880505', name: 'Santana do Jacaré' },
  { ibge: '315890405', name: 'Santana do Manhuaçu' },
  { ibge: '315890410', name: 'Santa Filomena' },
  { ibge: '315895305', name: 'Santana do Paraíso' },
  { ibge: '315900105', name: 'Santana do Riacho' },
  { ibge: '315900110', name: 'Serra do Cipó' },
  { ibge: '315910005', name: 'Santana dos Montes' },
  { ibge: '315910010', name: 'Joselândia' },
  { ibge: '315920905', name: 'Santa Rita de Caldas' },
  { ibge: '315920907', name: 'Pião' },
  { ibge: '315920910', name: 'São Bento de Caldas' },
  { ibge: '315930805', name: 'Santa Rita de Jacutinga' },
  { ibge: '315930810', name: 'Itaboca' },
  { ibge: '315935705', name: 'Santa Rita de Minas' },
  { ibge: '315940705', name: 'Santa Rita de Ibitipoca' },
  { ibge: '315940710', name: 'Bom Jesus do Vermelho' },
  { ibge: '315940715', name: 'Paraíso Garcia' },
  { ibge: '315950605', name: 'Santa Rita do Itueto' },
  { ibge: '315950607', name: 'Alto Pião' },
  { ibge: '315950610', name: 'São José do Itueto' },
  { ibge: '315960505', name: 'Santa Rita do Sapucaí' },
  { ibge: '315970405', name: 'Santa Rosa da Serra' },
  { ibge: '315980305', name: 'Santa Vitória' },
  { ibge: '315980310', name: 'Chaveslândia' },
  { ibge: '315980315', name: 'Perdilândia' },
  { ibge: '315990205', name: 'Santo Antônio do Amparo' },
  { ibge: '315990230', name: 'São Sebastião da Estrela' },
  { ibge: '316000905', name: 'Santo Antônio do Aventureiro' },
  { ibge: '316000910', name: 'São Domingos' },
  { ibge: '316010805', name: 'Santo Antônio do Grama' },
  { ibge: '316020705', name: 'Santo Antônio do Itambé' },
  { ibge: '316030605', name: 'Santo Antônio do Jacinto' },
  { ibge: '316030610', name: 'Catajás' },
  { ibge: '316040505', name: 'Santo Antônio do Monte' },
  { ibge: '316045405', name: 'Santo Antônio do Retiro' },
  { ibge: '316050405', name: 'Santo Antônio do Rio Abaixo' },
  { ibge: '316060305', name: 'Santo Hipólito' },
  { ibge: '316060310', name: 'Senhora da Glória' },
  { ibge: '316060320', name: 'Valo Fundo' },
  { ibge: '316070205', name: 'Santos Dumont' },
  { ibge: '316070210', name: 'Conceição do Formoso' },
  { ibge: '316070215', name: 'Dores do Paraibuna' },
  { ibge: '316070217', name: 'Mantiqueira' },
  { ibge: '316070220', name: 'São João da Serra' },
  { ibge: '316080105', name: 'São Bento Abade' },
  { ibge: '316090005', name: 'São Brás do Suaçuí' },
  { ibge: '316095905', name: 'São Domingos das Dores' },
  { ibge: '316100705', name: 'São Domingos do Prata' },
  { ibge: '316100710', name: 'Cônego João Pio' },
  { ibge: '316100715', name: 'Ilhéus do Prata' },
  { ibge: '316100720', name: 'Juiraçu' },
  { ibge: '316100725', name: 'Santana do Alfié' },
  { ibge: '316100730', name: 'Vargem Linda' },
  { ibge: '316105605', name: 'São Félix de Minas' },
  { ibge: '316110605', name: 'São Francisco' },
  { ibge: '316110612', name: 'Lapa do Espírito Santo' },
  { ibge: '316110615', name: 'Morro' },
  { ibge: '316110618', name: 'Retiro' },
  { ibge: '316110630', name: 'Santa Isabel de Minas' },
  { ibge: '316110635', name: 'Santana de São Francisco' },
  { ibge: '316110640', name: 'Travessão de Minas' },
  { ibge: '316120505', name: 'São Francisco de Paula' },
  { ibge: '316130405', name: 'São Francisco de Sales' },
  { ibge: '316140305', name: 'São Francisco do Glória' },
  { ibge: '316150205', name: 'São Geraldo' },
  { ibge: '316150210', name: 'Monte Celeste' },
  { ibge: '316160105', name: 'São Geraldo da Piedade' },
  { ibge: '316165005', name: 'São Geraldo do Baixio' },
  { ibge: '316165015', name: 'Conceição das Laranjeiras' },
  { ibge: '316170005', name: 'São Gonçalo do Abaeté' },
  { ibge: '316170010', name: 'Canoeiros' },
  { ibge: '316180905', name: 'São Gonçalo do Pará' },
  { ibge: '316190805', name: 'São Gonçalo do Rio Abaixo' },
  { ibge: '316200505', name: 'São Gonçalo do Sapucaí' },
  { ibge: '316200510', name: 'Ferreiras' },
  { ibge: '316200515', name: 'Ribeiros' },
  { ibge: '316210405', name: 'São Gotardo' },
  { ibge: '316210407', name: 'Abaeté dos Venâncios' },
  { ibge: '316210410', name: 'Funchal' },
  { ibge: '316210415', name: 'Guarda dos Ferreiros' },
  { ibge: '316210420', name: 'São José da Bela Vista' },
  { ibge: '316220305', name: 'São João Batista do Glória' },
  { ibge: '316225205', name: 'São João da Lagoa' },
  { ibge: '316225220', name: 'São Roberto de Minas' },
  { ibge: '316230205', name: 'São João da Mata' },
  { ibge: '316240105', name: 'São João da Ponte' },
  { ibge: '316240110', name: 'Condado do Norte' },
  { ibge: '316240120', name: 'Olímpio Campos' },
  { ibge: '316240125', name: 'Santo Antônio da Boa Vista' },
  { ibge: '316240130', name: 'Simão Campos' },
  { ibge: '316245005', name: 'São João das Missões' },
  { ibge: '316250005', name: 'São João del Rei' },
  { ibge: '316250010', name: 'Arcângelo' },
  { ibge: '316250015', name: 'São Gonçalo do Amarante' },
  { ibge: '316250020', name: 'Emboabas' },
  { ibge: '316250025', name: 'Rio das Mortes' },
  { ibge: '316250030', name: 'São Sebastião da Vitória' },
  { ibge: '316255905', name: 'São João do Manhuaçu' },
  { ibge: '316257505', name: 'São João do Manteninha' },
  { ibge: '316260905', name: 'São João do Oriente' },
  { ibge: '316260930', name: 'Santa Maria do Baixio' },
  { ibge: '316265805', name: 'São João do Pacuí' },
  { ibge: '316265820', name: 'Santa da Pedra' },
  { ibge: '316270805', name: 'São João do Paraíso' },
  { ibge: '316280705', name: 'São João Evangelista' },
  { ibge: '316280710', name: 'Nelson de Sena' },
  { ibge: '316280715', name: 'São Geraldo do Baguari' },
  { ibge: '316290605', name: 'São João Nepomuceno' },
  { ibge: '316290610', name: 'Carlos Alves' },
  { ibge: '316290615', name: 'Ituí' },
  { ibge: '316290620', name: 'Roça Grande' },
  { ibge: '316290625', name: 'Taruaçu' },
  { ibge: '316292205', name: 'São Joaquim de Bicas' },
  { ibge: '316294805', name: 'São José da Barra' },
  { ibge: '316295505', name: 'São José da Lapa' },
  { ibge: '316300305', name: 'São José da Safira' },
  { ibge: '316310205', name: 'São José da Varginha' },
  { ibge: '316320105', name: 'São José do Alegre' },
  { ibge: '316330005', name: 'São José do Divino' },
  { ibge: '316340905', name: 'São José do Goiabal' },
  { ibge: '316350805', name: 'São José do Jacuri' },
  { ibge: '316360705', name: 'São José do Mantimento' },
  { ibge: '316370605', name: 'São Lourenço' },
  { ibge: '316380505', name: 'São Miguel do Anta' },
  { ibge: '316390405', name: 'São Pedro da União' },
  { ibge: '316400105', name: 'São Pedro dos Ferros' },
  { ibge: '316400110', name: 'Águas Férreas' },
  { ibge: '316410005', name: 'São Pedro do Suaçuí' },
  { ibge: '316410010', name: 'Córrego Dantas do Suaçuí' },
  { ibge: '316420905', name: 'São Romão' },
  { ibge: '316420915', name: 'Ribanceira' },
  { ibge: '316430805', name: 'São Roque de Minas' },
  { ibge: '316430810', name: 'São José do Barreiro' },
  { ibge: '316430815', name: 'Serra da Canastra' },
  { ibge: '316440705', name: 'São Sebastião da Bela Vista' },
  { ibge: '316443105', name: 'São Sebastião da Vargem Alegre' },
  { ibge: '316447205', name: 'São Sebastião do Anta' },
  { ibge: '316450605', name: 'São Sebastião do Maranhão' },
  { ibge: '316450610', name: 'Mãe dos Homens' },
  { ibge: '316450615', name: 'Santo Antônio dos Araújos' },
  { ibge: '316460505', name: 'São Sebastião do Oeste' },
  { ibge: '316470405', name: 'São Sebastião do Paraíso' },
  { ibge: '316470410', name: 'Guardinha' },
  { ibge: '316480305', name: 'São Sebastião do Rio Preto' },
  { ibge: '316490205', name: 'São Sebastião do Rio Verde' },
  { ibge: '316500805', name: 'São Tiago' },
  { ibge: '316500810', name: 'Mercês de Água Limpa' },
  { ibge: '316510705', name: 'São Tomás de Aquino' },
  { ibge: '316520605', name: 'São Thomé das Letras' },
  { ibge: '316530505', name: 'São Vicente de Minas' },
  { ibge: '316540405', name: 'Sapucaí-Mirim' },
  { ibge: '316550305', name: 'Sardoá' },
  { ibge: '316553705', name: 'Sarzedo' },
  { ibge: '316555205', name: 'Setubinha' },
  { ibge: '316555220', name: 'Palmeiras do Vale' },
  { ibge: '316556005', name: 'Sem-Peixe' },
  { ibge: '316556030', name: 'São Bartolomeu de Sem Peixe' },
  { ibge: '316557805', name: 'Senador Amaral' },
  { ibge: '316557815', name: 'Ponte Segura' },
  { ibge: '316560205', name: 'Senador Cortes' },
  { ibge: '316560220', name: 'Pregos' },
  { ibge: '316570105', name: 'Senador Firmino' },
  { ibge: '316580005', name: 'Senador José Bento' },
  { ibge: '316590905', name: 'Senador Modestino Gonçalves' },
  { ibge: '316600605', name: 'Senhora de Oliveira' },
  { ibge: '316610505', name: 'Senhora do Porto' },
  { ibge: '316610530', name: 'São José do Jacaré' },
  { ibge: '316620405', name: 'Senhora dos Remédios' },
  { ibge: '316620410', name: 'Palmital dos Carvalhos' },
  { ibge: '316630305', name: 'Sericita' },
  { ibge: '316640205', name: 'Seritinga' },
  { ibge: '316650105', name: 'Serra Azul de Minas' },
  { ibge: '316660005', name: 'Serra da Saudade' },
  { ibge: '316670905', name: 'Serra dos Aimorés' },
  { ibge: '316680805', name: 'Serra do Salitre' },
  { ibge: '316680810', name: 'Catiara' },
  { ibge: '316690705', name: 'Serrania' },
  { ibge: '316695605', name: 'Serranópolis de Minas' },
  { ibge: '316700405', name: 'Serranos' },
  { ibge: '316710305', name: 'Serro' },
  { ibge: '316710310', name: 'Deputado Augusto Clementino' },
  { ibge: '316710315', name: 'Milho Verde' },
  { ibge: '316710320', name: 'Pedro Lessa' },
  { ibge: '316710325', name: 'São Gonçalo do Rio das Pedras' },
  { ibge: '316710330', name: 'Três Barras da Estrada Real' },
  { ibge: '316720205', name: 'Sete Lagoas' },
  { ibge: '316720210', name: 'Silva Xavier' },
  { ibge: '316730105', name: 'Silveirânia' },
  { ibge: '316740005', name: 'Silvianópolis' },
  { ibge: '316750905', name: 'Simão Pereira' },
  { ibge: '316760805', name: 'Simonésia' },
  { ibge: '316760810', name: 'Alegria' },
  { ibge: '316760820', name: 'São Simão do Rio Preto' },
  { ibge: '316770705', name: 'Sobrália' },
  { ibge: '316770710', name: 'Plautino Soares' },
  { ibge: '316780605', name: 'Soledade de Minas' },
  { ibge: '316790505', name: 'Tabuleiro' },
  { ibge: '316800205', name: 'Taiobeiras' },
  { ibge: '316805105', name: 'Taparuba' },
  { ibge: '316810105', name: 'Tapira' },
  { ibge: '316820005', name: 'Tapiraí' },
  { ibge: '316820010', name: 'Altolândia' },
  { ibge: '316830905', name: 'Taquaraçu de Minas' },
  { ibge: '316840805', name: 'Tarumirim' },
  { ibge: '316840807', name: 'Café-Mirim' },
  { ibge: '316840810', name: 'São Vicente do Rio Doce' },
  { ibge: '316840812', name: 'Taruaçu de Minas' },
  { ibge: '316840815', name: 'Vai-Volta' },
  { ibge: '316850705', name: 'Teixeiras' },
  { ibge: '316860605', name: 'Teófilo Otoni' },
  { ibge: '316860610', name: 'Crispim Jacques' },
  { ibge: '316860620', name: 'Pedro Versiani' },
  { ibge: '316860625', name: 'Topázio' },
  { ibge: '316860630', name: 'Mucuri' },
  { ibge: '316860635', name: 'Rio Pretinho' },
  { ibge: '316870505', name: 'Timóteo' },
  { ibge: '316870510', name: 'Cachoeira do Vale' },
  { ibge: '316880405', name: 'Tiradentes' },
  { ibge: '316880412', name: "Caixa D'Água da Esperança" },
  { ibge: '316880415', name: 'Elvas' },
  { ibge: '316890305', name: 'Tiros' },
  { ibge: '316890310', name: 'Canastrão' },
  { ibge: '316900005', name: 'Tocantins' },
  { ibge: '316905905', name: 'Tocos do Moji' },
  { ibge: '316910905', name: 'Toledo' },
  { ibge: '316920805', name: 'Tombos' },
  { ibge: '316920807', name: 'Água Santa de Minas' },
  { ibge: '316920810', name: 'Catuné' },
  { ibge: '316930705', name: 'Três Corações' },
  { ibge: '316935605', name: 'Três Marias' },
  { ibge: '316935610', name: 'Andrequicé' },
  { ibge: '316940605', name: 'Três Pontas' },
  { ibge: '316940610', name: 'Pontalete' },
  { ibge: '316950505', name: 'Tumiritinga' },
  { ibge: '316950510', name: 'São Geraldo de Tumiritinga' },
  { ibge: '316960405', name: 'Tupaciguara' },
  { ibge: '316970305', name: 'Turmalina' },
  { ibge: '316970310', name: 'Caçaratiba' },
  { ibge: '316980205', name: 'Turvolândia' },
  { ibge: '316990105', name: 'Ubá' },
  { ibge: '316990110', name: 'Diamante de Ubá' },
  { ibge: '316990112', name: 'Miragaia' },
  { ibge: '316990115', name: 'Ubari' },
  { ibge: '317000805', name: 'Ubaí' },
  { ibge: '317000810', name: 'Bentópolis de Minas' },
  { ibge: '317005705', name: 'Ubaporanga' },
  { ibge: '317005735', name: 'São José do Batatal' },
  { ibge: '317005740', name: 'São Sebastião do Batatal' },
  { ibge: '317010705', name: 'Uberaba' },
  { ibge: '317020605', name: 'Uberlândia' },
  { ibge: '317020610', name: 'Cruzeiro dos Peixotos' },
  { ibge: '317020615', name: 'Martinésia' },
  { ibge: '317020620', name: 'Miraporanga' },
  { ibge: '317020625', name: 'Tapuirama' },
  { ibge: '317030505', name: 'Umburatiba' },
  { ibge: '317040405', name: 'Unaí' },
  { ibge: '317040415', name: 'Garapuava' },
  { ibge: '317040417', name: 'Pedras de Marilândia' },
  { ibge: '317040418', name: 'Palmeirinha' },
  { ibge: '317040419', name: 'Ruralminas' },
  { ibge: '317040420', name: 'Santo Antônio do Boqueirão' },
  { ibge: '317043805', name: 'União de Minas' },
  { ibge: '317047905', name: 'Uruana de Minas' },
  { ibge: '317050305', name: 'Urucânia' },
  { ibge: '317050310', name: 'Bom Jesus de Cardosos' },
  { ibge: '317052905', name: 'Urucuia' },
  { ibge: '317057805', name: 'Vargem Alegre' },
  { ibge: '317060205', name: 'Vargem Bonita' },
  { ibge: '317065105', name: 'Vargem Grande do Rio Pardo' },
  { ibge: '317070105', name: 'Varginha' },
  { ibge: '317075005', name: 'Varjão de Minas' },
  { ibge: '317080005', name: 'Várzea da Palma' },
  { ibge: '317080010', name: 'Guaicuí' },
  { ibge: '317090905', name: 'Varzelândia' },
  { ibge: '317090912', name: 'Brejo do Mutambal' },
  { ibge: '317090915', name: 'Campo Redondo' },
  { ibge: '317100605', name: 'Vazante' },
  { ibge: '317100610', name: 'Claro de Minas' },
  { ibge: '317103005', name: 'Verdelândia' },
  { ibge: '317107105', name: 'Veredinha' },
  { ibge: '317107115', name: 'Mendonça' },
  { ibge: '317110505', name: 'Veríssimo' },
  { ibge: '317115405', name: 'Vermelho Novo' },
  { ibge: '317120405', name: 'Vespasiano' },
  { ibge: '317130305', name: 'Viçosa' },
  { ibge: '317130310', name: 'Silvestre' },
  { ibge: '317130315', name: 'Cachoeira de Santa Cruz' },
  { ibge: '317130325', name: 'São José do Triunfo' },
  { ibge: '317140205', name: 'Vieiras' },
  { ibge: '317140210', name: 'Santo Antônio do Glória' },
  { ibge: '317150105', name: 'Mathias Lobato' },
  { ibge: '317160005', name: 'Virgem da Lapa' },
  { ibge: '317160020', name: 'São João do Vacaria' },
  { ibge: '317170905', name: 'Virgínia' },
  { ibge: '317180805', name: 'Virginópolis' },
  { ibge: '317190705', name: 'Virgolândia' },
  { ibge: '317190710', name: 'Divino de Virgolândia' },
  { ibge: '317200405', name: 'Visconde do Rio Branco' },
  { ibge: '317210305', name: 'Volta Grande' },
  { ibge: '317210310', name: 'Trimonte' },
  { ibge: '317220205', name: 'Wenceslau Braz' },
  { ibge: '317220210', name: 'Itererê' },
  { ibge: '320010205', name: 'Afonso Cláudio' },
  { ibge: '320010213', name: 'Fazenda Guandu' },
  { ibge: '320010215', name: 'Ibicaba' },
  { ibge: '320010228', name: 'Mata Fria' },
  { ibge: '320010230', name: 'Piracema' },
  { ibge: '320010235', name: 'Pontões' },
  { ibge: '320010237', name: 'São Francisco Xavier do Guandu' },
  { ibge: '320010239', name: 'São Luís de Boa Sorte' },
  { ibge: '320010240', name: 'Serra Pelada' },
  { ibge: '320013605', name: 'Águia Branca' },
  { ibge: '320013610', name: 'Águas Claras' },
  { ibge: '320016905', name: 'Água Doce do Norte' },
  { ibge: '320016910', name: 'Governador Lacerda de Aguiar' },
  { ibge: '320016915', name: 'Santa Luzia do Azul' },
  { ibge: '320016920', name: 'Santo Agostinho' },
  { ibge: '320016930', name: 'Vila Nelita' },
  { ibge: '320020105', name: 'Alegre' },
  { ibge: '320020110', name: 'Anutiba' },
  { ibge: '320020115', name: 'Araraí' },
  { ibge: '320020120', name: 'Café' },
  { ibge: '320020125', name: 'Celina' },
  { ibge: '320020135', name: 'Rive' },
  { ibge: '320020140', name: 'Santa Angélica' },
  { ibge: '320020145', name: 'São João do Norte' },
  { ibge: '320030005', name: 'Alfredo Chaves' },
  { ibge: '320030010', name: 'Crubixá' },
  { ibge: '320030015', name: 'Ibitiruí' },
  { ibge: '320030020', name: 'Matilde' },
  { ibge: '320030025', name: 'Ribeirão do Cristo' },
  { ibge: '320030030', name: 'Sagrada Família' },
  { ibge: '320030035', name: 'Urânia' },
  { ibge: '320035905', name: 'Alto Rio Novo' },
  { ibge: '320035915', name: 'Monte Carmelo do Rio Novo' },
  { ibge: '320035920', name: 'Palmerino' },
  { ibge: '320040905', name: 'Anchieta' },
  { ibge: '320040910', name: 'Alto Pongal' },
  { ibge: '320040915', name: 'Jabaquara' },
  { ibge: '320050805', name: 'Apiacá' },
  { ibge: '320050807', name: 'Bonsucesso' },
  { ibge: '320050810', name: 'José Carlos' },
  { ibge: '320060705', name: 'Aracruz' },
  { ibge: '320060710', name: 'Guaraná' },
  { ibge: '320060712', name: 'Jacupemba' },
  { ibge: '320060715', name: 'Riacho' },
  { ibge: '320060720', name: 'Santa Cruz' },
  { ibge: '320070605', name: 'Atilio Vivacqua' },
  { ibge: '320080505', name: 'Baixo Guandu' },
  { ibge: '320080510', name: 'Alto Mutum Preto' },
  { ibge: '320080515', name: 'Ibituba' },
  { ibge: '320080520', name: 'Quilômetro 14 do Mutum' },
  { ibge: '320080525', name: 'Vila Nova de Bananal' },
  { ibge: '320090405', name: 'Barra de São Francisco' },
  { ibge: '320090415', name: 'Cachoeirinha de Itaúna' },
  { ibge: '320090425', name: 'Itaperuna' },
  { ibge: '320090427', name: 'Monte Senir' },
  { ibge: '320090430', name: 'Monte Sinai' },
  { ibge: '320090435', name: 'Paulista' },
  { ibge: '320090440', name: 'Poranga' },
  { ibge: '320090450', name: 'Santo Antônio' },
  { ibge: '320090456', name: 'Vargem Alegre' },
  { ibge: '320100105', name: 'Boa Esperança' },
  { ibge: '320100140', name: 'Santo Antônio do Pousalegre' },
  { ibge: '320100145', name: 'São José do Sobradinho' },
  { ibge: '320110005', name: 'Bom Jesus do Norte' },
  { ibge: '320115905', name: 'Brejetuba' },
  { ibge: '320115918', name: 'Santa Rita de Brejetuba' },
  { ibge: '320115920', name: 'São Jorge de Oliveira' },
  { ibge: '320120905', name: 'Cachoeiro de Itapemirim' },
  { ibge: '320120910', name: 'Burarama' },
  { ibge: '320120915', name: 'Conduru' },
  { ibge: '320120916', name: 'Córrego dos Monos' },
  { ibge: '320120917', name: 'Coutinho' },
  { ibge: '320120918', name: 'Gironda' },
  { ibge: '320120919', name: 'Gruta' },
  { ibge: '320120920', name: 'Itaoca' },
  { ibge: '320120930', name: 'Pacotuba' },
  { ibge: '320120936', name: 'São Vicente' },
  { ibge: '320120940', name: 'Vargem Grande do Soturno' },
  { ibge: '320130805', name: 'Cariacica' },
  { ibge: '320130810', name: 'Itaquari' },
  { ibge: '320140705', name: 'Castelo' },
  { ibge: '320140715', name: 'Estrela do Norte' },
  { ibge: '320140723', name: 'Limoeiro' },
  { ibge: '320140725', name: 'Monte Pio' },
  { ibge: '320140730', name: 'Patrimônio do Ouro' },
  { ibge: '320150605', name: 'Colatina' },
  { ibge: '320150610', name: 'Ângelo Frechiani' },
  { ibge: '320150615', name: 'Baunilha' },
  { ibge: '320150620', name: 'Boapaba' },
  { ibge: '320150630', name: 'Graça Aranha' },
  { ibge: '320150635', name: 'Itapina' },
  { ibge: '320160505', name: 'Conceição da Barra' },
  { ibge: '320160507', name: 'Braço do Rio' },
  { ibge: '320160510', name: 'Itaúnas' },
  { ibge: '320170405', name: 'Conceição do Castelo' },
  { ibge: '320180305', name: 'Divino de São Lourenço' },
  { ibge: '320190205', name: 'Domingos Martins' },
  { ibge: '320190210', name: 'Aracê' },
  { ibge: '320190213', name: 'Biriricas' },
  { ibge: '320190220', name: 'Isabel' },
  { ibge: '320190230', name: 'Melgaço' },
  { ibge: '320190235', name: 'Paraju' },
  { ibge: '320200905', name: 'Dores do Rio Preto' },
  { ibge: '320200910', name: 'Mundo Novo' },
  { ibge: '320200915', name: 'São Raimundo da Pedra Menina' },
  { ibge: '320210805', name: 'Ecoporanga' },
  { ibge: '320210810', name: 'Cotaxé' },
  { ibge: '320210815', name: 'Imburana' },
  { ibge: '320210820', name: 'Joaçuba' },
  { ibge: '320210822', name: 'Muritiba' },
  { ibge: '320210823', name: 'Prata dos Baianos' },
  { ibge: '320210830', name: 'Santa Luzia do Norte' },
  { ibge: '320210840', name: 'Santa Terezinha' },
  { ibge: '320220705', name: 'Fundão' },
  { ibge: '320220710', name: 'Irundi' },
  { ibge: '320220713', name: 'Praia Grande' },
  { ibge: '320220715', name: 'Timbuí' },
  { ibge: '320225605', name: 'Governador Lindenberg' },
  { ibge: '320225610', name: 'Moacir Ávidos' },
  { ibge: '320225613', name: 'Morello' },
  { ibge: '320225615', name: 'Novo Brasil' },
  { ibge: '320230605', name: 'Guaçuí' },
  { ibge: '320230608', name: 'São Miguel do Caparaó' },
  { ibge: '320230610', name: 'São Pedro de Rates' },
  { ibge: '320230615', name: 'São Tiago' },
  { ibge: '320240505', name: 'Guarapari' },
  { ibge: '320240510', name: 'Rio Calçado' },
  { ibge: '320240515', name: 'Todos os Santos' },
  { ibge: '320245405', name: 'Ibatiba' },
  { ibge: '320250405', name: 'Ibiraçu' },
  { ibge: '320250420', name: 'Pendanga' },
  { ibge: '320255305', name: 'Ibitirama' },
  { ibge: '320255310', name: 'Santa Marta' },
  { ibge: '320260305', name: 'Iconha' },
  { ibge: '320260310', name: 'Duas Barras' },
  { ibge: '320265205', name: 'Irupi' },
  { ibge: '320265220', name: 'Santa Cruz de Irupi' },
  { ibge: '320270205', name: 'Itaguaçu' },
  { ibge: '320270210', name: 'Itaçu' },
  { ibge: '320270215', name: 'Itaimbé' },
  { ibge: '320270225', name: 'Palmeira' },
  { ibge: '320280105', name: 'Itapemirim' },
  { ibge: '320280109', name: 'Itaipava' },
  { ibge: '320280110', name: 'Itapecoá' },
  { ibge: '320280114', name: 'Piabanha do Norte' },
  { ibge: '320280115', name: 'Rio Muqui' },
  { ibge: '320290005', name: 'Itarana' },
  { ibge: '320300705', name: 'Iúna' },
  { ibge: '320300720', name: 'Pequiá' },
  { ibge: '320300723', name: 'Nossa Senhora das Graças' },
  { ibge: '320300725', name: 'Santíssima Trindade' },
  { ibge: '320300730', name: 'São João do Príncipe' },
  { ibge: '320305605', name: 'Jaguaré' },
  { ibge: '320305610', name: 'Barra Seca' },
  { ibge: '320305620', name: 'Nossa Senhora de Fátima' },
  { ibge: '320310605', name: 'Jerônimo Monteiro' },
  { ibge: '320313005', name: 'João Neiva' },
  { ibge: '320313010', name: 'Acioli' },
  { ibge: '320316305', name: 'Laranja da Terra' },
  { ibge: '320316315', name: 'Joatuba' },
  { ibge: '320316320', name: 'São Luiz de Miranda' },
  { ibge: '320316325', name: 'Sobreiro' },
  { ibge: '320316330', name: 'Vila de Laranja da Terra' },
  { ibge: '320320505', name: 'Linhares' },
  { ibge: '320320507', name: 'Bebedouro' },
  { ibge: '320320510', name: 'Desengano' },
  { ibge: '320320512', name: 'Farias' },
  { ibge: '320320513', name: 'Pontal do Ipiranga' },
  { ibge: '320320514', name: 'Povoação' },
  { ibge: '320320515', name: 'Regência' },
  { ibge: '320320523', name: 'Rio Quartel' },
  { ibge: '320320525', name: 'São Rafael' },
  { ibge: '320330405', name: 'Mantenópolis' },
  { ibge: '320330410', name: 'Santa Luzia de Mantenópolis' },
  { ibge: '320330415', name: 'São Geraldo' },
  { ibge: '320330425', name: 'São José de Mantenópolis' },
  { ibge: '320332005', name: 'Marataízes' },
  { ibge: '320332010', name: 'Barra do Itapemirim' },
  { ibge: '320334605', name: 'Marechal Floriano' },
  { ibge: '320334610', name: 'Araguaia' },
  { ibge: '320334625', name: 'Santa Maria de Marechal' },
  { ibge: '320335305', name: 'Marilândia' },
  { ibge: '320335310', name: 'Sapucaia' },
  { ibge: '320340305', name: 'Mimoso do Sul' },
  { ibge: '320340310', name: 'Conceição do Muqui' },
  { ibge: '320340315', name: 'Dona América' },
  { ibge: '320340320', name: 'Ponte de Itabapoana' },
  { ibge: '320340325', name: 'Santo Antônio do Muqui' },
  { ibge: '320340330', name: 'São José das Torres' },
  { ibge: '320340335', name: 'São Pedro de Itabapoana' },
  { ibge: '320350205', name: 'Montanha' },
  { ibge: '320350210', name: 'Vinhático' },
  { ibge: '320360105', name: 'Mucurici' },
  { ibge: '320360110', name: 'Itabaiana' },
  { ibge: '320370005', name: 'Muniz Freire' },
  { ibge: '320370008', name: 'Alto Norte' },
  { ibge: '320370010', name: 'Itaici' },
  { ibge: '320370015', name: 'Menino Jesus' },
  { ibge: '320370020', name: 'Piaçu' },
  { ibge: '320370023', name: 'São Pedro' },
  { ibge: '320370025', name: 'Vieira Machado' },
  { ibge: '320380905', name: 'Muqui' },
  { ibge: '320380910', name: 'Camará' },
  { ibge: '320390805', name: 'Nova Venécia' },
  { ibge: '320390815', name: 'Guararema' },
  { ibge: '320390830', name: 'Santo Antônio do Quinze' },
  { ibge: '320400505', name: 'Pancas' },
  { ibge: '320400515', name: 'Lajinha' },
  { ibge: '320400525', name: 'Vila Verde' },
  { ibge: '320405405', name: 'Pedro Canário' },
  { ibge: '320405415', name: 'Cristal do Norte' },
  { ibge: '320410405', name: 'Pinheiros' },
  { ibge: '320410410', name: 'São João do Sobrado' },
  { ibge: '320420305', name: 'Piúma' },
  { ibge: '320420310', name: 'Aghá' },
  { ibge: '320425205', name: 'Ponto Belo' },
  { ibge: '320425215', name: 'Itamira' },
  { ibge: '320430205', name: 'Presidente Kennedy' },
  { ibge: '320435105', name: 'Rio Bananal' },
  { ibge: '320435145', name: 'São Jorge de Tiradentes' },
  { ibge: '320440105', name: 'Rio Novo do Sul' },
  { ibge: '320440110', name: 'Princesa' },
  { ibge: '320450005', name: 'Santa Leopoldina' },
  { ibge: '320450010', name: 'Djalma Coutinho' },
  { ibge: '320450025', name: 'Mangaraí' },
  { ibge: '320455905', name: 'Santa Maria de Jetibá' },
  { ibge: '320455915', name: 'Garrafão' },
  { ibge: '320460905', name: 'Santa Teresa' },
  { ibge: '320460907', name: 'Alto Caldeirão' },
  { ibge: '320460910', name: 'Alto Santa Maria' },
  { ibge: '320460917', name: 'Santo Antônio do Canaã' },
  { ibge: '320460920', name: 'São João de Petrópolis' },
  { ibge: '320460925', name: 'Vinte e Cinco de Julho' },
  { ibge: '320465805', name: 'São Domingos do Norte' },
  { ibge: '320470805', name: 'São Gabriel da Palha' },
  { ibge: '320470815', name: 'Fartura' },
  { ibge: '320480705', name: 'São José do Calçado' },
  { ibge: '320480710', name: 'Airituba' },
  { ibge: '320480715', name: 'Alto Calçado' },
  { ibge: '320480720', name: 'Divino Espírito Santo' },
  { ibge: '320490605', name: 'São Mateus' },
  { ibge: '320490610', name: 'Barra Nova' },
  { ibge: '320490620', name: 'Itauninhas' },
  { ibge: '320490630', name: 'Nestor Gomes' },
  { ibge: '320490635', name: 'Nova Verona' },
  { ibge: '320495505', name: 'São Roque do Canaã' },
  { ibge: '320495520', name: 'Santa Júlia' },
  { ibge: '320495525', name: 'São Jacinto' },
  { ibge: '320500205', name: 'Serra' },
  { ibge: '320500210', name: 'Calogi' },
  { ibge: '320500215', name: 'Carapina' },
  { ibge: '320500220', name: 'Nova Almeida' },
  { ibge: '320500225', name: 'Queimado' },
  { ibge: '320501005', name: 'Sooretama' },
  { ibge: '320503605', name: 'Vargem Alta' },
  { ibge: '320503610', name: 'Alto Castelinho' },
  { ibge: '320503615', name: 'Jaciguá' },
  { ibge: '320503620', name: 'Prosperidade' },
  { ibge: '320503625', name: 'São José de Fruteiras' },
  { ibge: '320506905', name: 'Venda Nova do Imigrante' },
  { ibge: '320506910', name: 'Alto Caxixe' },
  { ibge: '320506920', name: 'São João de Viçosa' },
  { ibge: '320510105', name: 'Viana' },
  { ibge: '320510110', name: 'Araçatiba' },
  { ibge: '320515005', name: 'Vila Pavão' },
  { ibge: '320517605', name: 'Vila Valério' },
  { ibge: '320517615', name: 'Jurama' },
  { ibge: '320517630', name: 'São Jorge da Barra Seca' },
  { ibge: '320520005', name: 'Vila Velha' },
  { ibge: '320520010', name: 'Argolas' },
  { ibge: '320520015', name: 'Ibes' },
  { ibge: '320520020', name: 'Jucu' },
  { ibge: '320520025', name: 'São Torquato' },
  { ibge: '320530905', name: 'Vitória' },
  { ibge: '320530910', name: 'Goiabeiras' },
  { ibge: '330010005', name: 'Angra dos Reis' },
  { ibge: '330010010', name: 'Abraão' },
  { ibge: '330010015', name: 'Cunhambebe' },
  { ibge: '330010025', name: 'Mambucaba' },
  { ibge: '330015905', name: 'Aperibé' },
  { ibge: '330020905', name: 'Araruama' },
  { ibge: '330020908', name: 'Iguabinha' },
  { ibge: '330020910', name: 'Morro Grande' },
  { ibge: '330020912', name: 'Praia Seca' },
  { ibge: '330020915', name: 'São Vicente de Paula' },
  { ibge: '330022505', name: 'Areal' },
  { ibge: '330023305', name: 'Armação dos Búzios' },
  { ibge: '330025805', name: 'Arraial do Cabo' },
  { ibge: '330030805', name: 'Barra do Piraí' },
  { ibge: '330030807', name: 'Califórnia da Barra' },
  { ibge: '330030810', name: 'Dorândia' },
  { ibge: '330030815', name: 'Ipiabas' },
  { ibge: '330030820', name: 'São José do Turvo' },
  { ibge: '330030825', name: 'Vargem Alegre' },
  { ibge: '330040705', name: 'Barra Mansa' },
  { ibge: '330040709', name: 'Antônio Rocha' },
  { ibge: '330040715', name: 'Floriano' },
  { ibge: '330040720', name: 'Nossa Senhora do Amparo' },
  { ibge: '330040730', name: 'Rialto' },
  { ibge: '330040740', name: 'Santa Rita de Cássia' },
  { ibge: '330045605', name: 'Belford Roxo' },
  { ibge: '330050605', name: 'Bom Jardim' },
  { ibge: '330050610', name: 'Banquete' },
  { ibge: '330050615', name: 'Barra Alegre' },
  { ibge: '330050620', name: 'São José do Ribeirão' },
  { ibge: '330060505', name: 'Bom Jesus do Itabapoana' },
  { ibge: '330060510', name: 'Calheiros' },
  { ibge: '330060515', name: 'Carabuçu' },
  { ibge: '330060520', name: 'Pirapetinga de Bom Jesus' },
  { ibge: '330060525', name: 'Rosal' },
  { ibge: '330060530', name: 'Serrinha' },
  { ibge: '330070405', name: 'Cabo Frio' },
  { ibge: '330070420', name: 'Tamoios' },
  { ibge: '330080305', name: 'Cachoeiras de Macacu' },
  { ibge: '330080310', name: 'Japuíba' },
  { ibge: '330080315', name: 'Subaio' },
  { ibge: '330090205', name: 'Cambuci' },
  { ibge: '330090207', name: 'Cruzeiro' },
  { ibge: '330090210', name: 'Funil' },
  { ibge: '330090215', name: 'Monte Verde' },
  { ibge: '330090220', name: 'São João do Paraíso' },
  { ibge: '330090230', name: 'Três Irmãos' },
  { ibge: '330093605', name: 'Carapebus' },
  { ibge: '330095105', name: 'Comendador Levy Gasparian' },
  { ibge: '330095110', name: 'Afonso Arinos' },
  { ibge: '330100905', name: 'Campos dos Goytacazes' },
  { ibge: '330100915', name: 'Dores de Macabu' },
  { ibge: '330100920', name: 'Ibitioca' },
  { ibge: '330100930', name: 'Morangaba' },
  { ibge: '330100935', name: 'Morro do Coco' },
  { ibge: '330100940', name: 'Mussurepe' },
  { ibge: '330100945', name: 'Santa Maria' },
  { ibge: '330100950', name: 'Santo Amaro de Campos' },
  { ibge: '330100955', name: 'Santo Eduardo' },
  { ibge: '330100965', name: 'São Sebastião de Campos' },
  { ibge: '330100970', name: 'Serrinha' },
  { ibge: '330100975', name: 'Tocos' },
  { ibge: '330100980', name: 'Travessão' },
  { ibge: '330100985', name: 'Vila Nova de Campos' },
  { ibge: '330110805', name: 'Cantagalo' },
  { ibge: '330110810', name: 'Boa Sorte' },
  { ibge: '330110815', name: 'Euclidelândia' },
  { ibge: '330110820', name: 'Santa Rita da Floresta' },
  { ibge: '330110825', name: 'São Sebastião do Paraíba' },
  { ibge: '330115705', name: 'Cardoso Moreira' },
  { ibge: '330115710', name: 'São Joaquim' },
  { ibge: '330120705', name: 'Carmo' },
  { ibge: '330120710', name: 'Córrego da Prata' },
  { ibge: '330120715', name: 'Porto Velho do Cunha' },
  { ibge: '330130605', name: 'Casimiro de Abreu' },
  { ibge: '330130610', name: 'Barra de São João' },
  { ibge: '330130618', name: 'Professor Souza' },
  { ibge: '330130620', name: 'Rio Dourado' },
  { ibge: '330140505', name: 'Conceição de Macabu' },
  { ibge: '330140510', name: 'Macabuzinho' },
  { ibge: '330150405', name: 'Cordeiro' },
  { ibge: '330160305', name: 'Duas Barras' },
  { ibge: '330160310', name: 'Monnerat' },
  { ibge: '330160315', name: 'Fazenda do Campo' },
  { ibge: '330160335', name: 'Vargem Grande' },
  { ibge: '330170205', name: 'Duque de Caxias' },
  { ibge: '330170210', name: 'Campos Elyseos' },
  { ibge: '330170215', name: 'Imbariê' },
  { ibge: '330170220', name: 'Xerém' },
  { ibge: '330180105', name: 'Engenheiro Paulo de Frontin' },
  { ibge: '330180110', name: 'Sacra Família do Tinguá' },
  { ibge: '330185005', name: 'Guapimirim' },
  { ibge: '330187605', name: 'Iguaba Grande' },
  { ibge: '330190005', name: 'Itaboraí' },
  { ibge: '330190010', name: 'Cabuçu' },
  { ibge: '330190015', name: 'Itambi' },
  { ibge: '330190018', name: 'Manilha' },
  { ibge: '330190019', name: 'Pachecos' },
  { ibge: '330190020', name: 'Porto das Caixas' },
  { ibge: '330190025', name: 'Sambaetiba' },
  { ibge: '330190040', name: 'Visconde de Itaboraí' },
  { ibge: '330200705', name: 'Itaguaí' },
  { ibge: '330200715', name: 'Ibituporanga' },
  { ibge: '330205605', name: 'Italva' },
  { ibge: '330210605', name: 'Itaocara' },
  { ibge: '330210607', name: 'Batatal' },
  { ibge: '330210610', name: 'Estrada Nova' },
  { ibge: '330210615', name: 'Jaguarembé' },
  { ibge: '330210620', name: 'Laranjais' },
  { ibge: '330210625', name: 'Portela' },
  { ibge: '330220505', name: 'Itaperuna' },
  { ibge: '330220510', name: 'Boaventura' },
  { ibge: '330220515', name: 'Comendador Venâncio' },
  { ibge: '330220520', name: 'Itajara' },
  { ibge: '330220525', name: 'Nossa Senhora da Penha' },
  { ibge: '330220530', name: 'Retiro do Muriaé' },
  { ibge: '330220535', name: 'Raposo' },
  { ibge: '330225405', name: 'Itatiaia' },
  { ibge: '330227005', name: 'Japeri' },
  { ibge: '330230405', name: 'Laje do Muriaé' },
  { ibge: '330240305', name: 'Macaé' },
  { ibge: '330240315', name: 'Cachoeiros de Macaé' },
  { ibge: '330240325', name: 'Córrego do Ouro' },
  { ibge: '330240327', name: 'Frade' },
  { ibge: '330240330', name: 'Glicério' },
  { ibge: '330240340', name: 'Sana' },
  { ibge: '330245205', name: 'Macuco' },
  { ibge: '330250205', name: 'Magé' },
  { ibge: '330250215', name: 'Guia de Pacobaíba' },
  { ibge: '330250220', name: 'Inhomirim' },
  { ibge: '330250225', name: 'Santo Aleixo' },
  { ibge: '330250230', name: 'Suruí' },
  { ibge: '330260105', name: 'Mangaratiba' },
  { ibge: '330260110', name: 'Conceição de Jacareí' },
  { ibge: '330260115', name: 'Itacurussá' },
  { ibge: '330260120', name: 'Vila Muriqui' },
  { ibge: '330270005', name: 'Maricá' },
  { ibge: '330270010', name: 'Inoã' },
  { ibge: '330270015', name: 'Manoel Ribeiro' },
  { ibge: '330280905', name: 'Mendes' },
  { ibge: '330285805', name: 'Mesquita' },
  { ibge: '330290805', name: 'Miguel Pereira' },
  { ibge: '330290807', name: 'Conrado' },
  { ibge: '330290810', name: 'Governador Portela' },
  { ibge: '330300505', name: 'Miracema' },
  { ibge: '330300510', name: 'Paraíso do Tobias' },
  { ibge: '330300515', name: 'Venda das Flores' },
  { ibge: '330310405', name: 'Natividade' },
  { ibge: '330310407', name: 'Bom Jesus do Querendo' },
  { ibge: '330310410', name: 'Ourânia' },
  { ibge: '330320305', name: 'Nilópolis' },
  { ibge: '330320310', name: 'Olinda' },
  { ibge: '330330205', name: 'Niterói' },
  { ibge: '330340105', name: 'Nova Friburgo' },
  { ibge: '330340110', name: 'Amparo' },
  { ibge: '330340115', name: 'Campo do Coelho' },
  { ibge: '330340120', name: 'Conselheiro Paulino' },
  { ibge: '330340125', name: 'Lumiar' },
  { ibge: '330340127', name: 'Muri' },
  { ibge: '330340130', name: 'Riograndina' },
  { ibge: '330340135', name: 'São Pedro da Serra' },
  { ibge: '330350005', name: 'Nova Iguaçu' },
  { ibge: '330360905', name: 'Paracambi' },
  { ibge: '330370805', name: 'Paraíba do Sul' },
  { ibge: '330370810', name: 'Inconfidência' },
  { ibge: '330370815', name: 'Salutaris' },
  { ibge: '330370820', name: 'Werneck' },
  { ibge: '330380705', name: 'Paraty' },
  { ibge: '330380710', name: 'Parati Mirim' },
  { ibge: '330380715', name: 'Tarituba' },
  { ibge: '330385605', name: 'Paty do Alferes' },
  { ibge: '330385610', name: 'Avelar' },
  { ibge: '330390605', name: 'Petrópolis' },
  { ibge: '330390610', name: 'Cascatinha' },
  { ibge: '330390615', name: 'Itaipava' },
  { ibge: '330390620', name: 'Pedro do Rio' },
  { ibge: '330390623', name: 'Posse' },
  { ibge: '330395505', name: 'Pinheiral' },
  { ibge: '330400305', name: 'Piraí' },
  { ibge: '330400310', name: 'Arrozal' },
  { ibge: '330400315', name: 'Monumento' },
  { ibge: '330400325', name: 'Santanésia' },
  { ibge: '330410205', name: 'Porciúncula' },
  { ibge: '330410210', name: 'Purilândia' },
  { ibge: '330410215', name: 'Santa Clara' },
  { ibge: '330411005', name: 'Porto Real' },
  { ibge: '330412805', name: 'Quatis' },
  { ibge: '330412810', name: 'Falcão' },
  { ibge: '330412820', name: 'Ribeirão de São Joaquim' },
  { ibge: '330414405', name: 'Queimados' },
  { ibge: '330415105', name: 'Quissamã' },
  { ibge: '330420105', name: 'Resende' },
  { ibge: '330420110', name: 'Agulhas Negras' },
  { ibge: '330420115', name: 'Engenheiro Passos' },
  { ibge: '330420120', name: 'Fumaça' },
  { ibge: '330420130', name: 'Pedra Selada' },
  { ibge: '330430005', name: 'Rio Bonito' },
  { ibge: '330430007', name: 'Bazílio' },
  { ibge: '330430010', name: 'Boa Esperança' },
  { ibge: '330440905', name: 'Rio Claro' },
  { ibge: '330440910', name: 'Getulândia' },
  { ibge: '330440915', name: 'Lídice' },
  { ibge: '330440920', name: 'Passa Três' },
  { ibge: '330440925', name: 'São João Marcos' },
  { ibge: '330450805', name: 'Rio das Flores' },
  { ibge: '330450810', name: 'Abarracamento' },
  { ibge: '330450815', name: 'Manuel Duarte' },
  { ibge: '330450820', name: 'Taboas' },
  { ibge: '330452405', name: 'Rio das Ostras' },
  { ibge: '330455705', name: 'Rio de Janeiro' },
  { ibge: '330460705', name: 'Santa Maria Madalena' },
  { ibge: '330460710', name: 'Doutor Loréti' },
  { ibge: '330460715', name: 'Renascença' },
  { ibge: '330460720', name: 'Santo Antônio do Imbé' },
  { ibge: '330460725', name: 'Sossego' },
  { ibge: '330460730', name: 'Triunfo' },
  { ibge: '330470605', name: 'Santo Antônio de Pádua' },
  { ibge: '330470615', name: 'Baltazar' },
  { ibge: '330470617', name: 'Campelo' },
  { ibge: '330470620', name: 'Ibitiguaçu' },
  { ibge: '330470625', name: 'Marangatu' },
  { ibge: '330470630', name: 'Monte Alegre' },
  { ibge: '330470635', name: 'Paraoquena' },
  { ibge: '330470640', name: 'Santa Cruz' },
  { ibge: '330470650', name: 'São Pedro de Alcântara' },
  { ibge: '330475505', name: 'São Francisco de Itabapoana' },
  { ibge: '330475510', name: 'Barra Seca' },
  { ibge: '330475515', name: 'Maniva' },
  { ibge: '330480505', name: 'São Fidélis' },
  { ibge: '330480510', name: 'Cambiasca' },
  { ibge: '330480515', name: 'Colônia' },
  { ibge: '330480520', name: 'Ipuca' },
  { ibge: '330480525', name: 'Pureza' },
  { ibge: '330490405', name: 'São Gonçalo' },
  { ibge: '330490410', name: 'Ipiiba' },
  { ibge: '330490415', name: 'Monjolo' },
  { ibge: '330490420', name: 'Neves' },
  { ibge: '330490425', name: 'Sete Pontes' },
  { ibge: '330500005', name: 'São João da Barra' },
  { ibge: '330500007', name: 'Atafona' },
  { ibge: '330500010', name: 'Barcelos' },
  { ibge: '330500016', name: 'Cajueiro' },
  { ibge: '330500018', name: 'Grussaí' },
  { ibge: '330500030', name: 'Pipeiras' },
  { ibge: '330510905', name: 'São João de Meriti' },
  { ibge: '330510910', name: 'Coelho da Rocha' },
  { ibge: '330510915', name: 'São Mateus' },
  { ibge: '330513305', name: 'São José de Ubá' },
  { ibge: '330515805', name: 'São José do Vale do Rio Preto' },
  { ibge: '330520805', name: 'São Pedro da Aldeia' },
  { ibge: '330530705', name: 'São Sebastião do Alto' },
  { ibge: '330530707', name: 'Ipituna' },
  { ibge: '330530710', name: 'Valão do Barro' },
  { ibge: '330540605', name: 'Sapucaia' },
  { ibge: '330540610', name: 'Anta' },
  { ibge: '330540615', name: 'Jamapará' },
  { ibge: '330540620', name: 'Nossa Senhora da Aparecida' },
  { ibge: '330540625', name: 'Pião' },
  { ibge: '330550505', name: 'Saquarema' },
  { ibge: '330550510', name: 'Bacaxá' },
  { ibge: '330550515', name: 'Sampaio Correia' },
  { ibge: '330555405', name: 'Seropédica' },
  { ibge: '330560405', name: 'Silva Jardim' },
  { ibge: '330560410', name: 'Correntezas' },
  { ibge: '330560415', name: 'Gaviões' },
  { ibge: '330560420', name: 'Aldeia Velha' },
  { ibge: '330570305', name: 'Sumidouro' },
  { ibge: '330570310', name: 'Campinas' },
  { ibge: '330570315', name: 'Dona Mariana' },
  { ibge: '330570325', name: 'Soledade' },
  { ibge: '330575205', name: 'Tanguá' },
  { ibge: '330580205', name: 'Teresópolis' },
  { ibge: '330580210', name: 'Vale de Bonsucesso' },
  { ibge: '330580215', name: 'Vale do Paquequer' },
  { ibge: '330590105', name: 'Trajano de Moraes' },
  { ibge: '330590110', name: 'Doutor Elias' },
  { ibge: '330590115', name: 'Sodrelândia' },
  { ibge: '330590120', name: 'Vila da Grama' },
  { ibge: '330590125', name: 'Visconde de Imbé' },
  { ibge: '330600805', name: 'Três Rios' },
  { ibge: '330600820', name: 'Bemposta' },
  { ibge: '330610705', name: 'Valença' },
  { ibge: '330610710', name: 'Barão de Juparanã' },
  { ibge: '330610715', name: 'Conservatória' },
  { ibge: '330610720', name: 'Parapeúna' },
  { ibge: '330610725', name: 'Pentagna' },
  { ibge: '330610730', name: 'Santa Isabel do Rio Preto' },
  { ibge: '330615605', name: 'Varre-Sai' },
  { ibge: '330620605', name: 'Vassouras' },
  { ibge: '330620610', name: 'Andrade Pinto' },
  { ibge: '330620630', name: 'São Sebastião dos Ferreiros' },
  { ibge: '330620635', name: 'Sebastião de Lacerda' },
  { ibge: '330630505', name: 'Volta Redonda' },
  { ibge: '350010505', name: 'Adamantina' },
  { ibge: '350020405', name: 'Adolfo' },
  { ibge: '350030305', name: 'Aguaí' },
  { ibge: '350040205', name: 'Águas da Prata' },
  { ibge: '350040210', name: 'São Roque da Fartura' },
  { ibge: '350050105', name: 'Águas de Lindóia' },
  { ibge: '350055005', name: 'Águas de Santa Bárbara' },
  { ibge: '350060005', name: 'Águas de São Pedro' },
  { ibge: '350070905', name: 'Agudos' },
  { ibge: '350070910', name: 'Domélia' },
  { ibge: '350075805', name: 'Alambari' },
  { ibge: '350080805', name: 'Alfredo Marcondes' },
  { ibge: '350090705', name: 'Altair' },
  { ibge: '350090715', name: 'Suinana' },
  { ibge: '350100405', name: 'Altinópolis' },
  { ibge: '350110305', name: 'Alto Alegre' },
  { ibge: '350110310', name: 'Jatobá' },
  { ibge: '350110315', name: "São Martinho d'Oeste" },
  { ibge: '350115205', name: 'Alumínio' },
  { ibge: '350120205', name: 'Álvares Florence' },
  { ibge: '350120210', name: 'Boa Vista dos Andradas' },
  { ibge: '350130105', name: 'Álvares Machado' },
  { ibge: '350130110', name: 'Coronel Goulart' },
  { ibge: '350140005', name: 'Álvaro de Carvalho' },
  { ibge: '350150905', name: 'Alvinlândia' },
  { ibge: '350160805', name: 'Americana' },
  { ibge: '350170705', name: 'Américo Brasiliense' },
  { ibge: '350180605', name: 'Américo de Campos' },
  { ibge: '350190505', name: 'Amparo' },
  { ibge: '350190510', name: 'Arcadas' },
  { ibge: '350190515', name: 'Três Pontes' },
  { ibge: '350200205', name: 'Analândia' },
  { ibge: '350210105', name: 'Andradina' },
  { ibge: '350220005', name: 'Angatuba' },
  { ibge: '350220008', name: 'Bom Retiro da Esperança' },
  { ibge: '350230905', name: 'Anhembi' },
  { ibge: '350230910', name: 'Pirambóia' },
  { ibge: '350240805', name: 'Anhumas' },
  { ibge: '350250705', name: 'Aparecida' },
  { ibge: '350260605', name: "Aparecida d'Oeste" },
  { ibge: '350270505', name: 'Apiaí' },
  { ibge: '350270510', name: 'Araçaíba' },
  { ibge: '350270525', name: 'Lageado de Araçaíba' },
  { ibge: '350270530', name: 'Palmitalzinho' },
  { ibge: '350275405', name: 'Araçariguama' },
  { ibge: '350280405', name: 'Araçatuba' },
  { ibge: '350290305', name: 'Araçoiaba da Serra' },
  { ibge: '350300005', name: 'Aramina' },
  { ibge: '350310905', name: 'Arandu' },
  { ibge: '350315805', name: 'Arapeí' },
  { ibge: '350320805', name: 'Araraquara' },
  { ibge: '350320810', name: 'Bueno de Andrada' },
  { ibge: '350320825', name: 'Vila Xavier' },
  { ibge: '350330705', name: 'Araras' },
  { ibge: '350335605', name: 'Arco-Íris' },
  { ibge: '350340605', name: 'Arealva' },
  { ibge: '350340610', name: 'Jacuba' },
  { ibge: '350350505', name: 'Areias' },
  { ibge: '350360405', name: 'Areiópolis' },
  { ibge: '350370305', name: 'Ariranha' },
  { ibge: '350380205', name: 'Artur Nogueira' },
  { ibge: '350390105', name: 'Arujá' },
  { ibge: '350395005', name: 'Aspásia' },
  { ibge: '350400805', name: 'Assis' },
  { ibge: '350410705', name: 'Atibaia' },
  { ibge: '350420605', name: 'Auriflama' },
  { ibge: '350430505', name: 'Avaí' },
  { ibge: '350430510', name: 'Nogueira' },
  { ibge: '350440405', name: 'Avanhandava' },
  { ibge: '350450305', name: 'Avaré' },
  { ibge: '350460205', name: 'Bady Bassitt' },
  { ibge: '350470105', name: 'Balbinos' },
  { ibge: '350480005', name: 'Bálsamo' },
  { ibge: '350490905', name: 'Bananal' },
  { ibge: '350500505', name: 'Barão de Antonina' },
  { ibge: '350510405', name: 'Barbosa' },
  { ibge: '350520305', name: 'Bariri' },
  { ibge: '350530205', name: 'Barra Bonita' },
  { ibge: '350535105', name: 'Barra do Chapéu' },
  { ibge: '350540105', name: 'Barra do Turvo' },
  { ibge: '350550005', name: 'Barretos' },
  { ibge: '350550010', name: 'Alberto Moreira' },
  { ibge: '350550015', name: 'Ibitu' },
  { ibge: '350560905', name: 'Barrinha' },
  { ibge: '350570805', name: 'Barueri' },
  { ibge: '350570810', name: 'Aldeia' },
  { ibge: '350570815', name: 'Jardim Belval' },
  { ibge: '350570820', name: 'Jardim Silveira' },
  { ibge: '350580705', name: 'Bastos' },
  { ibge: '350590605', name: 'Batatais' },
  { ibge: '350600305', name: 'Bauru' },
  { ibge: '350600310', name: 'Tibiriçá' },
  { ibge: '350610205', name: 'Bebedouro' },
  { ibge: '350610210', name: 'Botafogo' },
  { ibge: '350610215', name: 'Turvínia' },
  { ibge: '350620105', name: 'Bento de Abreu' },
  { ibge: '350630005', name: 'Bernardino de Campos' },
  { ibge: '350635905', name: 'Bertioga' },
  { ibge: '350640905', name: 'Bilac' },
  { ibge: '350650805', name: 'Birigui' },
  { ibge: '350660705', name: 'Biritiba-Mirim' },
  { ibge: '350670605', name: 'Boa Esperança do Sul' },
  { ibge: '350680505', name: 'Bocaina' },
  { ibge: '350690405', name: 'Bofete' },
  { ibge: '350690410', name: 'São Roque Novo' },
  { ibge: '350700105', name: 'Boituva' },
  { ibge: '350710005', name: 'Bom Jesus dos Perdões' },
  { ibge: '350715905', name: 'Bom Sucesso de Itararé' },
  { ibge: '350720905', name: 'Borá' },
  { ibge: '350730805', name: 'Boracéia' },
  { ibge: '350740705', name: 'Borborema' },
  { ibge: '350745605', name: 'Borebi' },
  { ibge: '350750605', name: 'Botucatu' },
  { ibge: '350750610', name: 'Rubião Júnior' },
  { ibge: '350750615', name: 'Vitoriana' },
  { ibge: '350760505', name: 'Bragança Paulista' },
  { ibge: '350770405', name: 'Braúna' },
  { ibge: '350775305', name: 'Brejo Alegre' },
  { ibge: '350780305', name: 'Brodowski' },
  { ibge: '350790205', name: 'Brotas' },
  { ibge: '350790210', name: 'São Sebastião da Serra' },
  { ibge: '350800905', name: 'Buri' },
  { ibge: '350800910', name: 'Aracaçu' },
  { ibge: '350810805', name: 'Buritama' },
  { ibge: '350820705', name: 'Buritizal' },
  { ibge: '350830605', name: 'Cabrália Paulista' },
  { ibge: '350840505', name: 'Cabreúva' },
  { ibge: '350840510', name: 'Bom Fim do Bom Jesus' },
  { ibge: '350840520', name: 'Jacaré' },
  { ibge: '350850405', name: 'Caçapava' },
  { ibge: '350860305', name: 'Cachoeira Paulista' },
  { ibge: '350870205', name: 'Caconde' },
  { ibge: '350870210', name: 'Barrânia' },
  { ibge: '350880105', name: 'Cafelândia' },
  { ibge: '350880110', name: 'Bacuriti' },
  { ibge: '350880115', name: 'Cafesópolis' },
  { ibge: '350880120', name: 'Simões' },
  { ibge: '350890005', name: 'Caiabu' },
  { ibge: '350890010', name: "Boa Esperança d'Oeste" },
  { ibge: '350890015', name: 'Iubatinga' },
  { ibge: '350900705', name: 'Caieiras' },
  { ibge: '350910605', name: 'Caiuá' },
  { ibge: '350920505', name: 'Cajamar' },
  { ibge: '350920510', name: 'Jordanésia' },
  { ibge: '350920515', name: 'Polvilho' },
  { ibge: '350925405', name: 'Cajati' },
  { ibge: '350930405', name: 'Cajobi' },
  { ibge: '350930415', name: 'Monte Verde Paulista' },
  { ibge: '350940305', name: 'Cajuru' },
  { ibge: '350945205', name: 'Campina do Monte Alegre' },
  { ibge: '350945215', name: 'Salto' },
  { ibge: '350950205', name: 'Campinas' },
  { ibge: '350950210', name: 'Barão de Geraldo' },
  { ibge: '350950215', name: 'Joaquim Egídio' },
  { ibge: '350950220', name: 'Nova Aparecida' },
  { ibge: '350950225', name: 'Souzas' },
  { ibge: '350960105', name: 'Campo Limpo Paulista' },
  { ibge: '350960108', name: 'Botujuru' },
  { ibge: '350970005', name: 'Campos do Jordão' },
  { ibge: '350980905', name: 'Campos Novos Paulista' },
  { ibge: '350990805', name: 'Cananéia' },
  { ibge: '350990810', name: 'Ariri' },
  { ibge: '350995705', name: 'Canas' },
  { ibge: '351000505', name: 'Cândido Mota' },
  { ibge: '351000510', name: 'Frutal do Campo' },
  { ibge: '351000515', name: 'Nova Alexandria' },
  { ibge: '351000520', name: 'Santo Antônio do Paranapanema' },
  { ibge: '351010405', name: 'Cândido Rodrigues' },
  { ibge: '351015305', name: 'Canitar' },
  { ibge: '351020305', name: 'Capão Bonito' },
  { ibge: '351020308', name: 'Apiaí-Mirim' },
  { ibge: '351030205', name: 'Capela do Alto' },
  { ibge: '351030220', name: 'Porto' },
  { ibge: '351040105', name: 'Capivari' },
  { ibge: '351050005', name: 'Caraguatatuba' },
  { ibge: '351050010', name: 'Porto Novo' },
  { ibge: '351060905', name: 'Carapicuíba' },
  { ibge: '351060910', name: 'Aldeia de Carapicuíba' },
  { ibge: '351060930', name: 'Vila Dirce' },
  { ibge: '351070805', name: 'Cardoso' },
  { ibge: '351070810', name: 'São João do Marinheiro' },
  { ibge: '351080705', name: 'Casa Branca' },
  { ibge: '351080710', name: 'Lagoa Branca' },
  { ibge: '351080715', name: 'Venda Branca' },
  { ibge: '351090605', name: 'Cássia dos Coqueiros' },
  { ibge: '351100305', name: 'Castilho' },
  { ibge: '351110205', name: 'Catanduva' },
  { ibge: '351120105', name: 'Catiguá' },
  { ibge: '351130005', name: 'Cedral' },
  { ibge: '351140905', name: 'Cerqueira César' },
  { ibge: '351150805', name: 'Cerquilho' },
  { ibge: '351160705', name: 'Cesário Lange' },
  { ibge: '351160720', name: 'Fazenda Velha' },
  { ibge: '351170605', name: 'Charqueada' },
  { ibge: '351170610', name: 'Paraisolândia' },
  { ibge: '351190405', name: 'Clementina' },
  { ibge: '351190410', name: 'Lauro Penteado' },
  { ibge: '351200105', name: 'Colina' },
  { ibge: '351210005', name: 'Colômbia' },
  { ibge: '351220905', name: 'Conchal' },
  { ibge: '351220910', name: 'Tujuguaba' },
  { ibge: '351230805', name: 'Conchas' },
  { ibge: '351230810', name: 'Juquiratiba' },
  { ibge: '351240705', name: 'Cordeirópolis' },
  { ibge: '351250605', name: 'Coroados' },
  { ibge: '351260505', name: 'Coronel Macedo' },
  { ibge: '351270405', name: 'Corumbataí' },
  { ibge: '351280305', name: 'Cosmópolis' },
  { ibge: '351290205', name: 'Cosmorama' },
  { ibge: '351300905', name: 'Cotia' },
  { ibge: '351300910', name: 'Caucaia do Alto' },
  { ibge: '351310805', name: 'Cravinhos' },
  { ibge: '351320705', name: 'Cristais Paulista' },
  { ibge: '351330605', name: 'Cruzália' },
  { ibge: '351340505', name: 'Cruzeiro' },
  { ibge: '351350405', name: 'Cubatão' },
  { ibge: '351360305', name: 'Cunha' },
  { ibge: '351360310', name: 'Campos de Cunha' },
  { ibge: '351370205', name: 'Descalvado' },
  { ibge: '351380105', name: 'Diadema' },
  { ibge: '351385005', name: 'Dirce Reis' },
  { ibge: '351390005', name: 'Divinolândia' },
  { ibge: '351390010', name: 'Campestrinho' },
  { ibge: '351400705', name: 'Dobrada' },
  { ibge: '351410605', name: 'Dois Córregos' },
  { ibge: '351410610', name: 'Guarapuã' },
  { ibge: '351420505', name: 'Dolcinópolis' },
  { ibge: '351430405', name: 'Dourado' },
  { ibge: '351440305', name: 'Dracena' },
  { ibge: '351440310', name: 'Jaciporã' },
  { ibge: '351440315', name: 'Jamaica' },
  { ibge: '351450205', name: 'Duartina' },
  { ibge: '351460105', name: 'Dumont' },
  { ibge: '351470005', name: 'Echaporã' },
  { ibge: '351480905', name: 'Eldorado' },
  { ibge: '351480910', name: 'Braço' },
  { ibge: '351480915', name: 'Itapeúna' },
  { ibge: '351490805', name: 'Elias Fausto' },
  { ibge: '351490810', name: 'Cardeal' },
  { ibge: '351492405', name: 'Elisiário' },
  { ibge: '351495705', name: 'Embaúba' },
  { ibge: '351500405', name: 'Embu das Artes' },
  { ibge: '351510305', name: 'Embu-Guaçu' },
  { ibge: '351510310', name: 'Cipó-Guaçu' },
  { ibge: '351512905', name: 'Emilianópolis' },
  { ibge: '351515205', name: 'Engenheiro Coelho' },
  { ibge: '351518605', name: 'Espírito Santo do Pinhal' },
  { ibge: '351519405', name: 'Espírito Santo do Turvo' },
  { ibge: '351520205', name: "Estrela d'Oeste" },
  { ibge: '351530105', name: 'Estrela do Norte' },
  { ibge: '351535005', name: 'Euclides da Cunha Paulista' },
  { ibge: '351540005', name: 'Fartura' },
  { ibge: '351550905', name: 'Fernandópolis' },
  { ibge: '351550910', name: 'Brasitânia' },
  { ibge: '351560805', name: 'Fernando Prestes' },
  { ibge: '351560810', name: 'Agulha' },
  { ibge: '351565705', name: 'Fernão' },
  { ibge: '351570705', name: 'Ferraz de Vasconcelos' },
  { ibge: '351570710', name: 'Santa Margarida Paulista' },
  { ibge: '351570715', name: 'Santo Antônio Paulista' },
  { ibge: '351580605', name: 'Flora Rica' },
  { ibge: '351590505', name: 'Floreal' },
  { ibge: '351600205', name: 'Flórida Paulista' },
  { ibge: '351600210', name: 'Atlântida' },
  { ibge: '351600215', name: 'Indaiá do Aguapeí' },
  { ibge: '351610105', name: 'Florínia' },
  { ibge: '351620005', name: 'Franca' },
  { ibge: '351630905', name: 'Francisco Morato' },
  { ibge: '351640805', name: 'Franco da Rocha' },
  { ibge: '351650705', name: 'Gabriel Monteiro' },
  { ibge: '351660605', name: 'Gália' },
  { ibge: '351670505', name: 'Garça' },
  { ibge: '351670510', name: 'Jafa' },
  { ibge: '351680405', name: 'Gastão Vidigal' },
  { ibge: '351685305', name: 'Gavião Peixoto' },
  { ibge: '351690305', name: 'General Salgado' },
  { ibge: '351690313', name: 'Prudêncio e Moraes' },
  { ibge: '351700005', name: 'Getulina' },
  { ibge: '351700010', name: 'Macucos' },
  { ibge: '351700015', name: 'Santa América' },
  { ibge: '351710905', name: 'Glicério' },
  { ibge: '351710910', name: 'Juritis' },
  { ibge: '351720805', name: 'Guaiçara' },
  { ibge: '351730705', name: 'Guaimbê' },
  { ibge: '351730710', name: 'Fátima' },
  { ibge: '351740605', name: 'Guaíra' },
  { ibge: '351750505', name: 'Guapiaçu' },
  { ibge: '351760405', name: 'Guapiara' },
  { ibge: '351770305', name: 'Guará' },
  { ibge: '351770310', name: 'Pioneiros' },
  { ibge: '351780205', name: 'Guaraçaí' },
  { ibge: '351790105', name: 'Guaraci' },
  { ibge: '351800805', name: "Guarani d'Oeste" },
  { ibge: '351810705', name: 'Guarantã' },
  { ibge: '351820605', name: 'Guararapes' },
  { ibge: '351820610', name: 'Ribeiro do Vale' },
  { ibge: '351830505', name: 'Guararema' },
  { ibge: '351840405', name: 'Guaratinguetá' },
  { ibge: '351850305', name: 'Guareí' },
  { ibge: '351860205', name: 'Guariba' },
  { ibge: '351870105', name: 'Guarujá' },
  { ibge: '351870110', name: 'Vicente de Carvalho' },
  { ibge: '351880005', name: 'Guarulhos' },
  { ibge: '351880010', name: 'Jardim Presidente Dutra' },
  { ibge: '351885905', name: 'Guatapará' },
  { ibge: '351890905', name: 'Guzolândia' },
  { ibge: '351900605', name: 'Herculândia' },
  { ibge: '351900610', name: 'Juliânia' },
  { ibge: '351905505', name: 'Holambra' },
  { ibge: '351907105', name: 'Hortolândia' },
  { ibge: '351910505', name: 'Iacanga' },
  { ibge: '351920405', name: 'Iacri' },
  { ibge: '351920410', name: 'Anápolis' },
  { ibge: '351925305', name: 'Iaras' },
  { ibge: '351930305', name: 'Ibaté' },
  { ibge: '351940205', name: 'Ibirá' },
  { ibge: '351940225', name: 'Termas de Ibirá' },
  { ibge: '351950105', name: 'Ibirarema' },
  { ibge: '351960005', name: 'Ibitinga' },
  { ibge: '351960010', name: 'Cambaratiba' },
  { ibge: '351970905', name: 'Ibiúna' },
  { ibge: '351970910', name: 'Paruru' },
  { ibge: '351980805', name: 'Icém' },
  { ibge: '351990705', name: 'Iepê' },
  { ibge: '352000405', name: 'Igaraçu do Tietê' },
  { ibge: '352010305', name: 'Igarapava' },
  { ibge: '352020205', name: 'Igaratá' },
  { ibge: '352030105', name: 'Iguape' },
  { ibge: '352040005', name: 'Ilhabela' },
  { ibge: '352040010', name: 'Cambaquara' },
  { ibge: '352040015', name: 'Paranabi' },
  { ibge: '352042605', name: 'Ilha Comprida' },
  { ibge: '352044205', name: 'Ilha Solteira' },
  { ibge: '352050905', name: 'Indaiatuba' },
  { ibge: '352060805', name: 'Indiana' },
  { ibge: '352070705', name: 'Indiaporã' },
  { ibge: '352080605', name: 'Inúbia Paulista' },
  { ibge: '352090505', name: 'Ipaussu' },
  { ibge: '352100205', name: 'Iperó' },
  { ibge: '352100210', name: 'Bacaetava' },
  { ibge: '352110105', name: 'Ipeúna' },
  { ibge: '352115005', name: 'Ipiguá' },
  { ibge: '352120005', name: 'Iporanga' },
  { ibge: '352130905', name: 'Ipuã' },
  { ibge: '352140805', name: 'Iracemápolis' },
  { ibge: '352150705', name: 'Irapuã' },
  { ibge: '352160605', name: 'Irapuru' },
  { ibge: '352170505', name: 'Itaberá' },
  { ibge: '352170508', name: 'Tomé' },
  { ibge: '352170510', name: 'Turiba do Sul' },
  { ibge: '352170515', name: 'Engenheiro Maia' },
  { ibge: '352180405', name: 'Itaí' },
  { ibge: '352190305', name: 'Itajobi' },
  { ibge: '352190307', name: 'Nova Cardoso' },
  { ibge: '352200005', name: 'Itaju' },
  { ibge: '352210905', name: 'Itanhaém' },
  { ibge: '352215805', name: 'Itaóca' },
  { ibge: '352220805', name: 'Itapecerica da Serra' },
  { ibge: '352230705', name: 'Itapetininga' },
  { ibge: '352230708', name: 'Conceição' },
  { ibge: '352230715', name: 'Gramadinho' },
  { ibge: '352230720', name: 'Morro do Alto' },
  { ibge: '352230725', name: 'Rechan' },
  { ibge: '352230730', name: 'Tupy' },
  { ibge: '352230735', name: 'Varginha' },
  { ibge: '352240605', name: 'Itapeva' },
  { ibge: '352240611', name: 'Alto da Brancal' },
  { ibge: '352240613', name: 'Areia Branca' },
  { ibge: '352240615', name: 'Guarizinho' },
  { ibge: '352250505', name: 'Itapevi' },
  { ibge: '352260405', name: 'Itapira' },
  { ibge: '352260410', name: 'Barão Ataliba Nogueira' },
  { ibge: '352260415', name: 'Eleutério' },
  { ibge: '352265305', name: 'Itapirapuã Paulista' },
  { ibge: '352270305', name: 'Itápolis' },
  { ibge: '352270310', name: 'Nova América' },
  { ibge: '352270315', name: 'Tapinas' },
  { ibge: '352280205', name: 'Itaporanga' },
  { ibge: '352290105', name: 'Itapuí' },
  { ibge: '352300805', name: 'Itapura' },
  { ibge: '352310705', name: 'Itaquaquecetuba' },
  { ibge: '352320605', name: 'Itararé' },
  { ibge: '352320625', name: 'Pedra Branca de Itararé' },
  { ibge: '352320630', name: 'Santa Cruz dos Lopes' },
  { ibge: '352330505', name: 'Itariri' },
  { ibge: '352330510', name: 'Ana Dias' },
  { ibge: '352340405', name: 'Itatiba' },
  { ibge: '352350305', name: 'Itatinga' },
  { ibge: '352350310', name: 'Lobo' },
  { ibge: '352360205', name: 'Itirapina' },
  { ibge: '352360210', name: 'Itaqueri da Serra' },
  { ibge: '352370105', name: 'Itirapuã' },
  { ibge: '352380005', name: 'Itobi' },
  { ibge: '352390905', name: 'Itu' },
  { ibge: '352400605', name: 'Itupeva' },
  { ibge: '352410505', name: 'Ituverava' },
  { ibge: '352410510', name: 'Capivari da Mata' },
  { ibge: '352410515', name: 'São Benedito da Cachoeirinha' },
  { ibge: '352420405', name: 'Jaborandi' },
  { ibge: '352430305', name: 'Jaboticabal' },
  { ibge: '352430310', name: 'Córrego Rico' },
  { ibge: '352430315', name: 'Lusitânia' },
  { ibge: '352440205', name: 'Jacareí' },
  { ibge: '352440207', name: 'Parque Meia Lua' },
  { ibge: '352440210', name: 'São Silvestre de Jacareí' },
  { ibge: '352450105', name: 'Jaci' },
  { ibge: '352460005', name: 'Jacupiranga' },
  { ibge: '352470905', name: 'Jaguariúna' },
  { ibge: '352480805', name: 'Jales' },
  { ibge: '352490705', name: 'Jambeiro' },
  { ibge: '352500305', name: 'Jandira' },
  { ibge: '352510205', name: 'Jardinópolis' },
  { ibge: '352510210', name: 'Jurucê' },
  { ibge: '352520105', name: 'Jarinu' },
  { ibge: '352530005', name: 'Jaú' },
  { ibge: '352530010', name: 'Potunduva' },
  { ibge: '352540905', name: 'Jeriquara' },
  { ibge: '352550805', name: 'Joanópolis' },
  { ibge: '352560705', name: 'João Ramalho' },
  { ibge: '352570605', name: 'José Bonifácio' },
  { ibge: '352580505', name: 'Júlio Mesquita' },
  { ibge: '352585405', name: 'Jumirim' },
  { ibge: '352590405', name: 'Jundiaí' },
  { ibge: '352600105', name: 'Junqueirópolis' },
  { ibge: '352610005', name: 'Juquiá' },
  { ibge: '352620905', name: 'Juquitiba' },
  { ibge: '352620910', name: 'Barnabés' },
  { ibge: '352630805', name: 'Lagoinha' },
  { ibge: '352640705', name: 'Laranjal Paulista' },
  { ibge: '352640710', name: 'Laras' },
  { ibge: '352640715', name: 'Maristela' },
  { ibge: '352650605', name: 'Lavínia' },
  { ibge: '352650610', name: 'Tabajara' },
  { ibge: '352660505', name: 'Lavrinhas' },
  { ibge: '352660510', name: 'Pinheiros' },
  { ibge: '352670405', name: 'Leme' },
  { ibge: '352680305', name: 'Lençóis Paulista' },
  { ibge: '352680310', name: 'Alfredo Guedes' },
  { ibge: '352690205', name: 'Limeira' },
  { ibge: '352700905', name: 'Lindóia' },
  { ibge: '352710805', name: 'Lins' },
  { ibge: '352710810', name: 'Guapiranga' },
  { ibge: '352720705', name: 'Lorena' },
  { ibge: '352725605', name: 'Lourdes' },
  { ibge: '352730605', name: 'Louveira' },
  { ibge: '352740505', name: 'Lucélia' },
  { ibge: '352750405', name: 'Lucianópolis' },
  { ibge: '352760305', name: 'Luís Antônio' },
  { ibge: '352770205', name: 'Luiziânia' },
  { ibge: '352780105', name: 'Lupércio' },
  { ibge: '352780125', name: 'Santa Terezinha' },
  { ibge: '352790005', name: 'Lutécia' },
  { ibge: '352800705', name: 'Macatuba' },
  { ibge: '352810605', name: 'Macaubal' },
  { ibge: '352820505', name: 'Macedônia' },
  { ibge: '352830405', name: 'Magda' },
  { ibge: '352840305', name: 'Mairinque' },
  { ibge: '352850205', name: 'Mairiporã' },
  { ibge: '352850215', name: 'Terra Preta' },
  { ibge: '352860105', name: 'Manduri' },
  { ibge: '352860110', name: 'São Berto' },
  { ibge: '352870005', name: 'Marabá Paulista' },
  { ibge: '352880905', name: 'Maracaí' },
  { ibge: '352880910', name: 'São José das Laranjeiras' },
  { ibge: '352885805', name: 'Marapoama' },
  { ibge: '352890805', name: 'Mariápolis' },
  { ibge: '352890810', name: 'Mourão' },
  { ibge: '352900505', name: 'Marília' },
  { ibge: '352900510', name: 'Amadeu Amaral' },
  { ibge: '352900515', name: 'Avencas' },
  { ibge: '352900520', name: 'Dirceu' },
  { ibge: '352900525', name: 'Lácio' },
  { ibge: '352900530', name: 'Padre Nóbrega' },
  { ibge: '352900535', name: 'Rosália' },
  { ibge: '352910405', name: 'Marinópolis' },
  { ibge: '352920305', name: 'Martinópolis' },
  { ibge: '352920310', name: 'Guachos' },
  { ibge: '352920315', name: 'Teçaindá' },
  { ibge: '352930205', name: 'Matão' },
  { ibge: '352930210', name: 'São Lourenço do Turvo' },
  { ibge: '352940105', name: 'Mauá' },
  { ibge: '352950005', name: 'Mendonça' },
  { ibge: '352960905', name: 'Meridiano' },
  { ibge: '352965805', name: 'Mesópolis' },
  { ibge: '352970805', name: 'Miguelópolis' },
  { ibge: '352980705', name: 'Mineiros do Tietê' },
  { ibge: '352990605', name: 'Miracatu' },
  { ibge: '352990608', name: 'Oliveira Barros' },
  { ibge: '352990610', name: 'Pedro Barros' },
  { ibge: '352990615', name: 'Santa Rita do Ribeira' },
  { ibge: '353000305', name: 'Mira Estrela' },
  { ibge: '353010205', name: 'Mirandópolis' },
  { ibge: '353010210', name: 'Amandaba' },
  { ibge: '353010215', name: 'Três Alianças' },
  { ibge: '353020105', name: 'Mirante do Paranapanema' },
  { ibge: '353020110', name: 'Costa Machado' },
  { ibge: '353020115', name: 'Cuiabá Paulista' },
  { ibge: '353030005', name: 'Mirassol' },
  { ibge: '353030010', name: 'Ruilândia' },
  { ibge: '353040905', name: 'Mirassolândia' },
  { ibge: '353050805', name: 'Mococa' },
  { ibge: '353050810', name: 'Igaraí' },
  { ibge: '353050815', name: 'São Benedito das Areias' },
  { ibge: '353060705', name: 'Mogi das Cruzes' },
  { ibge: '353060707', name: 'Biritiba-Ussu' },
  { ibge: '353060710', name: 'Brás Cubas' },
  { ibge: '353060713', name: 'Cezar de Souza' },
  { ibge: '353060715', name: 'Jundiapeba' },
  { ibge: '353060717', name: 'Quatinga' },
  { ibge: '353060720', name: 'Sabaúna' },
  { ibge: '353060725', name: 'Taiaçupeba' },
  { ibge: '353070605', name: 'Mogi Guaçu' },
  { ibge: '353070615', name: 'Martinho Prado Júnior' },
  { ibge: '353080505', name: 'Mogi Mirim' },
  { ibge: '353080510', name: 'Martim Francisco' },
  { ibge: '353090405', name: 'Mombuca' },
  { ibge: '353100105', name: 'Monções' },
  { ibge: '353110005', name: 'Mongaguá' },
  { ibge: '353120905', name: 'Monte Alegre do Sul' },
  { ibge: '353120910', name: 'Mostardas' },
  { ibge: '353130805', name: 'Monte Alto' },
  { ibge: '353130808', name: 'Aparecida do Monte Alto' },
  { ibge: '353140705', name: 'Monte Aprazível' },
  { ibge: '353140710', name: 'Engenheiro Balduíno' },
  { ibge: '353140715', name: 'Itaiúba' },
  { ibge: '353140720', name: 'Junqueira' },
  { ibge: '353150605', name: 'Monte Azul Paulista' },
  { ibge: '353150610', name: 'Marcondésia' },
  { ibge: '353160505', name: 'Monte Castelo' },
  { ibge: '353170405', name: 'Monteiro Lobato' },
  { ibge: '353180305', name: 'Monte Mor' },
  { ibge: '353190205', name: 'Morro Agudo' },
  { ibge: '353200905', name: 'Morungaba' },
  { ibge: '353205805', name: 'Motuca' },
  { ibge: '353210805', name: 'Murutinga do Sul' },
  { ibge: '353215705', name: 'Nantes' },
  { ibge: '353220705', name: 'Narandiba' },
  { ibge: '353230605', name: 'Natividade da Serra' },
  { ibge: '353230610', name: 'Bairro Alto' },
  { ibge: '353240505', name: 'Nazaré Paulista' },
  { ibge: '353250405', name: 'Neves Paulista' },
  { ibge: '353250410', name: 'Barra Dourada' },
  { ibge: '353250415', name: 'Miraluz' },
  { ibge: '353260305', name: 'Nhandeara' },
  { ibge: '353260310', name: 'Ida Iolanda' },
  { ibge: '353270205', name: 'Nipoã' },
  { ibge: '353280105', name: 'Nova Aliança' },
  { ibge: '353280110', name: 'Nova Itapirema' },
  { ibge: '353282705', name: 'Nova Campina' },
  { ibge: '353282715', name: 'Itaoca de Cima' },
  { ibge: '353284305', name: 'Nova Canaã Paulista' },
  { ibge: '353286805', name: 'Nova Castilho' },
  { ibge: '353290005', name: 'Nova Europa' },
  { ibge: '353300705', name: 'Nova Granada' },
  { ibge: '353300710', name: 'Ingás' },
  { ibge: '353300715', name: 'Mangaratu' },
  { ibge: '353300720', name: 'Onda Branca' },
  { ibge: '353310605', name: 'Nova Guataporanga' },
  { ibge: '353320505', name: 'Nova Independência' },
  { ibge: '353325405', name: 'Novais' },
  { ibge: '353330405', name: 'Nova Luzitânia' },
  { ibge: '353340305', name: 'Nova Odessa' },
  { ibge: '353350205', name: 'Novo Horizonte' },
  { ibge: '353350210', name: 'Vale Formoso' },
  { ibge: '353360105', name: 'Nuporanga' },
  { ibge: '353370005', name: 'Ocauçu' },
  { ibge: '353380905', name: 'Óleo' },
  { ibge: '353380910', name: 'Batista Botelho' },
  { ibge: '353390805', name: 'Olímpia' },
  { ibge: '353390810', name: 'Baguaçu' },
  { ibge: '353390815', name: 'Ribeiro dos Santos' },
  { ibge: '353400505', name: 'Onda Verde' },
  { ibge: '353410405', name: 'Oriente' },
  { ibge: '353420305', name: 'Orindiúva' },
  { ibge: '353430205', name: 'Orlândia' },
  { ibge: '353440105', name: 'Osasco' },
  { ibge: '353450005', name: 'Oscar Bressane' },
  { ibge: '353460905', name: 'Osvaldo Cruz' },
  { ibge: '353460910', name: 'Lagoa Azul' },
  { ibge: '353470805', name: 'Ourinhos' },
  { ibge: '353475705', name: 'Ouroeste' },
  { ibge: '353480705', name: 'Ouro Verde' },
  { ibge: '353490605', name: 'Pacaembu' },
  { ibge: '353500205', name: 'Palestina' },
  { ibge: '353500210', name: 'Duplo Céu' },
  { ibge: '353500215', name: 'Jurupeba' },
  { ibge: '353510105', name: 'Palmares Paulista' },
  { ibge: '353520005', name: "Palmeira d'Oeste" },
  { ibge: '353520010', name: 'Dalas' },
  { ibge: '353530905', name: 'Palmital' },
  { ibge: '353530910', name: 'Sussuí' },
  { ibge: '353540805', name: 'Panorama' },
  { ibge: '353550705', name: 'Paraguaçu Paulista' },
  { ibge: '353550710', name: 'Conceição de Monte Alegre' },
  { ibge: '353550713', name: 'Roseta' },
  { ibge: '353550715', name: 'Sapezal' },
  { ibge: '353560605', name: 'Paraibuna' },
  { ibge: '353570505', name: 'Paraíso' },
  { ibge: '353580405', name: 'Paranapanema' },
  { ibge: '353590305', name: 'Paranapuã' },
  { ibge: '353600005', name: 'Parapuã' },
  { ibge: '353610905', name: 'Pardinho' },
  { ibge: '353620805', name: 'Pariquera-Açu' },
  { ibge: '353625705', name: 'Parisi' },
  { ibge: '353630705', name: 'Patrocínio Paulista' },
  { ibge: '353640605', name: 'Paulicéia' },
  { ibge: '353650505', name: 'Paulínia' },
  { ibge: '353657005', name: 'Paulistânia' },
  { ibge: '353660405', name: 'Paulo de Faria' },
  { ibge: '353670305', name: 'Pederneiras' },
  { ibge: '353670310', name: 'Guaianás' },
  { ibge: '353670315', name: 'Santelmo' },
  { ibge: '353670320', name: 'Vanglória' },
  { ibge: '353680205', name: 'Pedra Bela' },
  { ibge: '353690105', name: 'Pedranópolis' },
  { ibge: '353690110', name: 'Santa Isabel do Marinheiro' },
  { ibge: '353700805', name: 'Pedregulho' },
  { ibge: '353700810', name: 'Alto Porã' },
  { ibge: '353700815', name: 'Igaçaba' },
  { ibge: '353710705', name: 'Pedreira' },
  { ibge: '353715605', name: 'Pedrinhas Paulista' },
  { ibge: '353720605', name: 'Pedro de Toledo' },
  { ibge: '353730505', name: 'Penápolis' },
  { ibge: '353740405', name: 'Pereira Barreto' },
  { ibge: '353750305', name: 'Pereiras' },
  { ibge: '353760205', name: 'Peruíbe' },
  { ibge: '353770105', name: 'Piacatu' },
  { ibge: '353780005', name: 'Piedade' },
  { ibge: '353790905', name: 'Pilar do Sul' },
  { ibge: '353800605', name: 'Pindamonhangaba' },
  { ibge: '353800610', name: 'Moreira César' },
  { ibge: '353810505', name: 'Pindorama' },
  { ibge: '353810510', name: 'Roberto' },
  { ibge: '353820405', name: 'Pinhalzinho' },
  { ibge: '353830305', name: 'Piquerobi' },
  { ibge: '353850105', name: 'Piquete' },
  { ibge: '353860005', name: 'Piracaia' },
  { ibge: '353860010', name: 'Batatuba' },
  { ibge: '353870905', name: 'Piracicaba' },
  { ibge: '353870910', name: 'Artemis' },
  { ibge: '353870915', name: 'Ibitiruna' },
  { ibge: '353870925', name: 'Tupi' },
  { ibge: '353870930', name: 'Guamium' },
  { ibge: '353870935', name: 'Santa Teresinha de Piracicaba' },
  { ibge: '353880805', name: 'Piraju' },
  { ibge: '353880825', name: 'Tibiriçá do Paranapanema' },
  { ibge: '353890705', name: 'Pirajuí' },
  { ibge: '353890710', name: 'Corredeira' },
  { ibge: '353890715', name: 'Pradínia' },
  { ibge: '353890720', name: 'Santo Antônio da Estiva' },
  { ibge: '353900405', name: 'Pirangi' },
  { ibge: '353910305', name: 'Pirapora do Bom Jesus' },
  { ibge: '353920205', name: 'Pirapozinho' },
  { ibge: '353920210', name: 'Itororó do Paranapanema' },
  { ibge: '353930105', name: 'Pirassununga' },
  { ibge: '353930110', name: 'Cachoeira de Emas' },
  { ibge: '353940005', name: 'Piratininga' },
  { ibge: '353950905', name: 'Pitangueiras' },
  { ibge: '353950910', name: 'Ibitiúva' },
  { ibge: '353960805', name: 'Planalto' },
  { ibge: '353970705', name: 'Platina' },
  { ibge: '353980605', name: 'Poá' },
  { ibge: '353980610', name: 'Cidade Kemel' },
  { ibge: '353990505', name: 'Poloni' },
  { ibge: '354000205', name: 'Pompéia' },
  { ibge: '354000210', name: 'Novo Cravinhos' },
  { ibge: '354000215', name: 'Paulópolis' },
  { ibge: '354010105', name: 'Pongaí' },
  { ibge: '354020005', name: 'Pontal' },
  { ibge: '354020010', name: 'Cândia' },
  { ibge: '354025905', name: 'Pontalinda' },
  { ibge: '354030905', name: 'Pontes Gestal' },
  { ibge: '354040805', name: 'Populina' },
  { ibge: '354050705', name: 'Porangaba' },
  { ibge: '354060605', name: 'Porto Feliz' },
  { ibge: '354070505', name: 'Porto Ferreira' },
  { ibge: '354075405', name: 'Potim' },
  { ibge: '354080405', name: 'Potirendaba' },
  { ibge: '354085305', name: 'Pracinha' },
  { ibge: '354090305', name: 'Pradópolis' },
  { ibge: '354100005', name: 'Praia Grande' },
  { ibge: '354100010', name: 'Solemar' },
  { ibge: '354105905', name: 'Pratânia' },
  { ibge: '354110905', name: 'Presidente Alves' },
  { ibge: '354110910', name: 'Guaricanga' },
  { ibge: '354120805', name: 'Presidente Bernardes' },
  { ibge: '354120810', name: 'Araxãs' },
  { ibge: '354120820', name: 'Nova Pátria' },
  { ibge: '354130705', name: 'Presidente Epitácio' },
  { ibge: '354130715', name: 'Campinal' },
  { ibge: '354140605', name: 'Presidente Prudente' },
  { ibge: '354140610', name: 'Ameliópolis' },
  { ibge: '354140615', name: 'Eneida' },
  { ibge: '354140620', name: 'Floresta do Sul' },
  { ibge: '354140625', name: 'Montalvão' },
  { ibge: '354150505', name: 'Presidente Venceslau' },
  { ibge: '354160405', name: 'Promissão' },
  { ibge: '354160410', name: 'Santa Maria do Gurupá' },
  { ibge: '354165305', name: 'Quadra' },
  { ibge: '354170305', name: 'Quatá' },
  { ibge: '354180205', name: 'Queiroz' },
  { ibge: '354190105', name: 'Queluz' },
  { ibge: '354200805', name: 'Quintana' },
  { ibge: '354210705', name: 'Rafard' },
  { ibge: '354220605', name: 'Rancharia' },
  { ibge: '354220610', name: 'Agissê' },
  { ibge: '354220615', name: 'Gardênia' },
  { ibge: '354230505', name: 'Redenção da Serra' },
  { ibge: '354240405', name: 'Regente Feijó' },
  { ibge: '354240410', name: 'Espigão' },
  { ibge: '354250305', name: 'Reginópolis' },
  { ibge: '354260205', name: 'Registro' },
  { ibge: '354270105', name: 'Restinga' },
  { ibge: '354280005', name: 'Ribeira' },
  { ibge: '354290905', name: 'Ribeirão Bonito' },
  { ibge: '354290910', name: 'Guarapiranga' },
  { ibge: '354300605', name: 'Ribeirão Branco' },
  { ibge: '354300615', name: 'Campina de Fora' },
  { ibge: '354300625', name: 'Itabôa' },
  { ibge: '354310505', name: 'Ribeirão Corrente' },
  { ibge: '354320405', name: 'Ribeirão do Sul' },
  { ibge: '354323805', name: 'Ribeirão dos Índios' },
  { ibge: '354325305', name: 'Ribeirão Grande' },
  { ibge: '354330305', name: 'Ribeirão Pires' },
  { ibge: '354330308', name: 'Jardim Santa Luzia' },
  { ibge: '354330310', name: 'Ouro Fino Paulista' },
  { ibge: '354340205', name: 'Ribeirão Preto' },
  { ibge: '354340210', name: 'Bonfim Paulista' },
  { ibge: '354350105', name: 'Riversul' },
  { ibge: '354360005', name: 'Rifaina' },
  { ibge: '354370905', name: 'Rincão' },
  { ibge: '354380805', name: 'Rinópolis' },
  { ibge: '354390705', name: 'Rio Claro' },
  { ibge: '354390710', name: 'Ajapi' },
  { ibge: '354390715', name: 'Assistência' },
  { ibge: '354400405', name: 'Rio das Pedras' },
  { ibge: '354410305', name: 'Rio Grande da Serra' },
  { ibge: '354420205', name: 'Riolândia' },
  { ibge: '354425105', name: 'Rosana' },
  { ibge: '354430105', name: 'Roseira' },
  { ibge: '354440005', name: 'Rubiácea' },
  { ibge: '354450905', name: 'Rubinéia' },
  { ibge: '354450910', name: 'Esmeralda' },
  { ibge: '354460805', name: 'Sabino' },
  { ibge: '354470705', name: 'Sagres' },
  { ibge: '354480605', name: 'Sales' },
  { ibge: '354490505', name: 'Sales Oliveira' },
  { ibge: '354500105', name: 'Salesópolis' },
  { ibge: '354500110', name: 'Nossa Senhora do Remédio' },
  { ibge: '354510005', name: 'Salmourão' },
  { ibge: '354515905', name: 'Saltinho' },
  { ibge: '354520905', name: 'Salto' },
  { ibge: '354530805', name: 'Salto de Pirapora' },
  { ibge: '354540705', name: 'Salto Grande' },
  { ibge: '354550605', name: 'Sandovalina' },
  { ibge: '354560505', name: 'Santa Adélia' },
  { ibge: '354560510', name: 'Botelho' },
  { ibge: '354560515', name: 'Ururaí' },
  { ibge: '354560520', name: 'Santa Rosa' },
  { ibge: '354570405', name: 'Santa Albertina' },
  { ibge: '354580305', name: "Santa Bárbara d'Oeste" },
  { ibge: '354600905', name: 'Santa Branca' },
  { ibge: '354610805', name: "Santa Clara d'Oeste" },
  { ibge: '354620705', name: 'Santa Cruz da Conceição' },
  { ibge: '354625605', name: 'Santa Cruz da Esperança' },
  { ibge: '354630605', name: 'Santa Cruz das Palmeiras' },
  { ibge: '354640505', name: 'Santa Cruz do Rio Pardo' },
  { ibge: '354640510', name: 'Caporanga' },
  { ibge: '354640515', name: 'Clarínia' },
  { ibge: '354640525', name: 'Sodrélia' },
  { ibge: '354650405', name: 'Santa Ernestina' },
  { ibge: '354660305', name: 'Santa Fé do Sul' },
  { ibge: '354670205', name: 'Santa Gertrudes' },
  { ibge: '354680105', name: 'Santa Isabel' },
  { ibge: '354690005', name: 'Santa Lúcia' },
  { ibge: '354700705', name: 'Santa Maria da Serra' },
  { ibge: '354710605', name: 'Santa Mercedes' },
  { ibge: '354710610', name: "Terra Nova d'Oeste" },
  { ibge: '354720505', name: 'Santana da Ponte Pensa' },
  { ibge: '354730405', name: 'Santana de Parnaíba' },
  { ibge: '354740305', name: "Santa Rita d'Oeste" },
  { ibge: '354740310', name: 'Aparecida do Bonito' },
  { ibge: '354750205', name: 'Santa Rita do Passa Quatro' },
  { ibge: '354750210', name: 'Santa Cruz da Estrela' },
  { ibge: '354760105', name: 'Santa Rosa de Viterbo' },
  { ibge: '354765005', name: 'Santa Salete' },
  { ibge: '354770005', name: 'Santo Anastácio' },
  { ibge: '354780905', name: 'Santo André' },
  { ibge: '354780908', name: 'Capuava' },
  { ibge: '354780910', name: 'Paranapiacaba' },
  { ibge: '354790805', name: 'Santo Antônio da Alegria' },
  { ibge: '354800505', name: 'Santo Antônio de Posse' },
  { ibge: '354805405', name: 'Santo Antônio do Aracanguá' },
  { ibge: '354805410', name: 'Major Prado' },
  { ibge: '354805415', name: 'Vicentinópolis' },
  { ibge: '354810405', name: 'Santo Antônio do Jardim' },
  { ibge: '354820305', name: 'Santo Antônio do Pinhal' },
  { ibge: '354830205', name: 'Santo Expedito' },
  { ibge: '354840105', name: 'Santópolis do Aguapeí' },
  { ibge: '354850005', name: 'Santos' },
  { ibge: '354860905', name: 'São Bento do Sapucaí' },
  { ibge: '354870805', name: 'São Bernardo do Campo' },
  { ibge: '354870810', name: 'Riacho Grande' },
  { ibge: '354880705', name: 'São Caetano do Sul' },
  { ibge: '354890605', name: 'São Carlos' },
  { ibge: '354890610', name: 'Água Vermelha' },
  { ibge: '354890613', name: 'Bela Vista São-Carlense' },
  { ibge: '354890615', name: 'Santa Eudóxia' },
  { ibge: '354890620', name: 'Vila Nery' },
  { ibge: '354900305', name: 'São Francisco' },
  { ibge: '354910205', name: 'São João da Boa Vista' },
  { ibge: '354920105', name: 'São João das Duas Pontes' },
  { ibge: '354925005', name: 'São João de Iracema' },
  { ibge: '354930005', name: "São João do Pau d'Alho" },
  { ibge: '354940905', name: 'São Joaquim da Barra' },
  { ibge: '354950805', name: 'São José da Bela Vista' },
  { ibge: '354960705', name: 'São José do Barreiro' },
  { ibge: '354970605', name: 'São José do Rio Pardo' },
  { ibge: '354980505', name: 'São José do Rio Preto' },
  { ibge: '354980510', name: 'Engenheiro Schmidt' },
  { ibge: '354980520', name: 'Talhado' },
  { ibge: '354990405', name: 'São José dos Campos' },
  { ibge: '354990410', name: 'Eugênio de Melo' },
  { ibge: '354990415', name: 'São Francisco Xavier' },
  { ibge: '354995305', name: 'São Lourenço da Serra' },
  { ibge: '355000105', name: 'São Luís do Paraitinga' },
  { ibge: '355000110', name: 'Catuçaba' },
  { ibge: '355010005', name: 'São Manuel' },
  { ibge: '355010010', name: 'Aparecida de São Manuel' },
  { ibge: '355020905', name: 'São Miguel Arcanjo' },
  { ibge: '355020915', name: 'Gramadão' },
  { ibge: '355030801', name: 'Água Rasa' },
  { ibge: '355030802', name: 'Alto de Pinheiros' },
  { ibge: '355030803', name: 'Anhanguera' },
  { ibge: '355030804', name: 'Aricanduva' },
  { ibge: '355030805', name: 'Artur Alvim' },
  { ibge: '355030806', name: 'Barra Funda' },
  { ibge: '355030807', name: 'Bela Vista' },
  { ibge: '355030808', name: 'Belém' },
  { ibge: '355030809', name: 'Bom Retiro' },
  { ibge: '355030810', name: 'Brás' },
  { ibge: '355030811', name: 'Brasilândia' },
  { ibge: '355030812', name: 'Butantã' },
  { ibge: '355030813', name: 'Cachoeirinha' },
  { ibge: '355030814', name: 'Cambuci' },
  { ibge: '355030815', name: 'Campo Belo' },
  { ibge: '355030816', name: 'Campo Grande' },
  { ibge: '355030817', name: 'Campo Limpo' },
  { ibge: '355030818', name: 'Cangaiba' },
  { ibge: '355030819', name: 'Capão Redondo' },
  { ibge: '355030820', name: 'Carrão' },
  { ibge: '355030821', name: 'Casa Verde' },
  { ibge: '355030822', name: 'Cidade Ademar' },
  { ibge: '355030823', name: 'Cidade Dutra' },
  { ibge: '355030824', name: 'Cidade Lider' },
  { ibge: '355030825', name: 'Cidade Tiradentes' },
  { ibge: '355030826', name: 'Consolação' },
  { ibge: '355030827', name: 'Cursino' },
  { ibge: '355030828', name: 'Ermelino Matarazzo' },
  { ibge: '355030829', name: 'Freguesia do Ó' },
  { ibge: '355030830', name: 'Grajaú' },
  { ibge: '355030831', name: 'Guaianases' },
  { ibge: '355030832', name: 'Moema' },
  { ibge: '355030833', name: 'Iguatemi' },
  { ibge: '355030834', name: 'Ipiranga' },
  { ibge: '355030835', name: 'Itaim Bibi' },
  { ibge: '355030836', name: 'Itaim Paulista' },
  { ibge: '355030837', name: 'Itaquera' },
  { ibge: '355030838', name: 'Jabaquara' },
  { ibge: '355030839', name: 'Jaçanã' },
  { ibge: '355030840', name: 'Jaguara' },
  { ibge: '355030841', name: 'Jaguaré' },
  { ibge: '355030842', name: 'Jaraguá' },
  { ibge: '355030843', name: 'Jardim Ângela' },
  { ibge: '355030844', name: 'Jardim Helena' },
  { ibge: '355030845', name: 'Jardim Paulista' },
  { ibge: '355030846', name: 'Jardim São Luís' },
  { ibge: '355030847', name: 'José Bonifácio' },
  { ibge: '355030848', name: 'Lapa' },
  { ibge: '355030849', name: 'Liberdade' },
  { ibge: '355030850', name: 'Limão' },
  { ibge: '355030851', name: 'Mandaqui' },
  { ibge: '355030852', name: 'Marsilac' },
  { ibge: '355030853', name: 'Mooca' },
  { ibge: '355030854', name: 'Morumbi' },
  { ibge: '355030855', name: 'Parelheiros' },
  { ibge: '355030856', name: 'Pari' },
  { ibge: '355030857', name: 'Parque do Carmo' },
  { ibge: '355030858', name: 'Pedreira' },
  { ibge: '355030859', name: 'Penha' },
  { ibge: '355030860', name: 'Perdizes' },
  { ibge: '355030861', name: 'Perus' },
  { ibge: '355030862', name: 'Pinheiros' },
  { ibge: '355030863', name: 'Pirituba' },
  { ibge: '355030864', name: 'Ponte Rasa' },
  { ibge: '355030865', name: 'Raposo Tavares' },
  { ibge: '355030866', name: 'República' },
  { ibge: '355030867', name: 'Rio Pequeno' },
  { ibge: '355030868', name: 'Sacomã' },
  { ibge: '355030869', name: 'Santa Cecília' },
  { ibge: '355030870', name: 'Santana' },
  { ibge: '355030871', name: 'Santo Amaro' },
  { ibge: '355030872', name: 'São Lucas' },
  { ibge: '355030873', name: 'São Mateus' },
  { ibge: '355030874', name: 'São Miguel' },
  { ibge: '355030875', name: 'São Rafael' },
  { ibge: '355030876', name: 'Sapopemba' },
  { ibge: '355030877', name: 'Saúde' },
  { ibge: '355030878', name: 'Sé' },
  { ibge: '355030879', name: 'Socorro' },
  { ibge: '355030880', name: 'Tatuapé' },
  { ibge: '355030881', name: 'Tremembé' },
  { ibge: '355030882', name: 'Tucuruvi' },
  { ibge: '355030883', name: 'Vila Andrade' },
  { ibge: '355030884', name: 'Vila Curuçá' },
  { ibge: '355030885', name: 'Vila Formosa' },
  { ibge: '355030886', name: 'Vila Guilherme' },
  { ibge: '355030887', name: 'Vila Jacuí' },
  { ibge: '355030888', name: 'Vila Leopoldina' },
  { ibge: '355030889', name: 'Vila Maria' },
  { ibge: '355030890', name: 'Vila Mariana' },
  { ibge: '355030891', name: 'Vila Matilde' },
  { ibge: '355030892', name: 'Vila Medeiros' },
  { ibge: '355030893', name: 'Vila Prudente' },
  { ibge: '355030894', name: 'Vila Sônia' },
  { ibge: '355030895', name: 'São Domingos' },
  { ibge: '355030896', name: 'Lajeado' },
  { ibge: '355040705', name: 'São Pedro' },
  { ibge: '355050605', name: 'São Pedro do Turvo' },
  { ibge: '355060505', name: 'São Roque' },
  { ibge: '355060515', name: 'Canguera' },
  { ibge: '355060517', name: 'Mailasqui' },
  { ibge: '355060520', name: 'São João Novo' },
  { ibge: '355070405', name: 'São Sebastião' },
  { ibge: '355070410', name: 'Maresias' },
  { ibge: '355070415', name: 'São Francisco da Praia' },
  { ibge: '355080305', name: 'São Sebastião da Grama' },
  { ibge: '355090205', name: 'São Simão' },
  { ibge: '355100905', name: 'São Vicente' },
  { ibge: '355110805', name: 'Sarapuí' },
  { ibge: '355110815', name: 'Cocaes' },
  { ibge: '355120705', name: 'Sarutaiá' },
  { ibge: '355130605', name: 'Sebastianópolis do Sul' },
  { ibge: '355140505', name: 'Serra Azul' },
  { ibge: '355150405', name: 'Serrana' },
  { ibge: '355160305', name: 'Serra Negra' },
  { ibge: '355170205', name: 'Sertãozinho' },
  { ibge: '355170210', name: 'Cruz das Posses' },
  { ibge: '355180105', name: 'Sete Barras' },
  { ibge: '355190005', name: 'Severínia' },
  { ibge: '355200705', name: 'Silveiras' },
  { ibge: '355210605', name: 'Socorro' },
  { ibge: '355220505', name: 'Sorocaba' },
  { ibge: '355230405', name: 'Sud Mennucci' },
  { ibge: '355230410', name: "Bandeirantes D'Oeste" },
  { ibge: '355240305', name: 'Sumaré' },
  { ibge: '355240315', name: 'Nova Veneza' },
  { ibge: '355250205', name: 'Suzano' },
  { ibge: '355250210', name: 'Boa Vista Paulista' },
  { ibge: '355250215', name: 'Palmeiras de São Paulo' },
  { ibge: '355255105', name: 'Suzanápolis' },
  { ibge: '355260105', name: 'Tabapuã' },
  { ibge: '355270005', name: 'Tabatinga' },
  { ibge: '355270010', name: 'Curupá' },
  { ibge: '355280905', name: 'Taboão da Serra' },
  { ibge: '355290805', name: 'Taciba' },
  { ibge: '355300505', name: 'Taguaí' },
  { ibge: '355310405', name: 'Taiaçu' },
  { ibge: '355320305', name: 'Taiúva' },
  { ibge: '355330205', name: 'Tambaú' },
  { ibge: '355340105', name: 'Tanabi' },
  { ibge: '355340110', name: 'Ibiporanga' },
  { ibge: '355350005', name: 'Tapiraí' },
  { ibge: '355360905', name: 'Tapiratiba' },
  { ibge: '355365805', name: 'Taquaral' },
  { ibge: '355370805', name: 'Taquaritinga' },
  { ibge: '355370810', name: 'Guariroba' },
  { ibge: '355370815', name: 'Jurupema' },
  { ibge: '355380705', name: 'Taquarituba' },
  { ibge: '355385605', name: 'Taquarivaí' },
  { ibge: '355390605', name: 'Tarabai' },
  { ibge: '355395505', name: 'Tarumã' },
  { ibge: '355400305', name: 'Tatuí' },
  { ibge: '355410205', name: 'Taubaté' },
  { ibge: '355410210', name: 'Quiririm' },
  { ibge: '355420105', name: 'Tejupá' },
  { ibge: '355420110', name: 'Águas Virtuosas' },
  { ibge: '355420120', name: 'Ribeirão Bonito' },
  { ibge: '355430005', name: 'Teodoro Sampaio' },
  { ibge: '355430025', name: 'Planalto do Sul' },
  { ibge: '355440905', name: 'Terra Roxa' },
  { ibge: '355450805', name: 'Tietê' },
  { ibge: '355460705', name: 'Timburi' },
  { ibge: '355465605', name: 'Torre de Pedra' },
  { ibge: '355470605', name: 'Torrinha' },
  { ibge: '355475505', name: 'Trabiju' },
  { ibge: '355480505', name: 'Tremembé' },
  { ibge: '355490405', name: 'Três Fronteiras' },
  { ibge: '355495305', name: 'Tuiuti' },
  { ibge: '355500005', name: 'Tupã' },
  { ibge: '355500015', name: 'Parnaso' },
  { ibge: '355500020', name: 'Universo' },
  { ibge: '355500025', name: 'Varpa' },
  { ibge: '355510905', name: 'Tupi Paulista' },
  { ibge: '355510915', name: 'Oásis' },
  { ibge: '355520805', name: 'Turiúba' },
  { ibge: '355530705', name: 'Turmalina' },
  { ibge: '355530710', name: 'Fátima Paulista' },
  { ibge: '355535605', name: 'Ubarana' },
  { ibge: '355540605', name: 'Ubatuba' },
  { ibge: '355540610', name: 'Picinguaba' },
  { ibge: '355550505', name: 'Ubirajara' },
  { ibge: '355550510', name: 'Areia Branca' },
  { ibge: '355560405', name: 'Uchoa' },
  { ibge: '355570305', name: 'União Paulista' },
  { ibge: '355580205', name: 'Urânia' },
  { ibge: '355590105', name: 'Uru' },
  { ibge: '355600805', name: 'Urupês' },
  { ibge: '355600810', name: 'São João de Itaguaçu' },
  { ibge: '355610705', name: 'Valentim Gentil' },
  { ibge: '355620605', name: 'Valinhos' },
  { ibge: '355630505', name: 'Valparaíso' },
  { ibge: '355635405', name: 'Vargem' },
  { ibge: '355640405', name: 'Vargem Grande do Sul' },
  { ibge: '355645305', name: 'Vargem Grande Paulista' },
  { ibge: '355650305', name: 'Várzea Paulista' },
  { ibge: '355660205', name: 'Vera Cruz' },
  { ibge: '355670105', name: 'Vinhedo' },
  { ibge: '355680005', name: 'Viradouro' },
  { ibge: '355690905', name: 'Vista Alegre do Alto' },
  { ibge: '355695805', name: 'Vitória Brasil' },
  { ibge: '355700605', name: 'Votorantim' },
  { ibge: '355710505', name: 'Votuporanga' },
  { ibge: '355710515', name: 'Simonsen' },
  { ibge: '355715405', name: 'Zacarias' },
  { ibge: '355720405', name: 'Chavantes' },
  { ibge: '355720415', name: 'Irapé' },
  { ibge: '355730305', name: 'Estiva Gerbi' },
  { ibge: '410010305', name: 'Abatiá' },
  { ibge: '410020205', name: 'Adrianópolis' },
  { ibge: '410030105', name: 'Agudos do Sul' },
  { ibge: '410030110', name: 'Colônia Padre Paulo' },
  { ibge: '410040005', name: 'Almirante Tamandaré' },
  { ibge: '410045905', name: 'Altamira do Paraná' },
  { ibge: '410050905', name: 'Altônia' },
  { ibge: '410050910', name: 'Jardim Paredão' },
  { ibge: '410050915', name: 'São João' },
  { ibge: '410060805', name: 'Alto Paraná' },
  { ibge: '410060810', name: 'Maristela' },
  { ibge: '410060815', name: 'Santa Maria' },
  { ibge: '410070705', name: 'Alto Piquiri' },
  { ibge: '410070715', name: 'Mirante do Piquiri' },
  { ibge: '410070720', name: 'Paulistânia' },
  { ibge: '410070725', name: 'Saltinho do Oeste' },
  { ibge: '410080605', name: 'Alvorada do Sul' },
  { ibge: '410080610', name: 'Esperança do Norte' },
  { ibge: '410090505', name: 'Amaporã' },
  { ibge: '410090510', name: 'Nordestina' },
  { ibge: '410100205', name: 'Ampére' },
  { ibge: '410100210', name: 'São Pedro' },
  { ibge: '410100215', name: 'São Salvador' },
  { ibge: '410105105', name: 'Anahy' },
  { ibge: '410110105', name: 'Andirá' },
  { ibge: '410110110', name: 'Nossa Senhora Aparecida' },
  { ibge: '410115005', name: 'Ângulo' },
  { ibge: '410120005', name: 'Antonina' },
  { ibge: '410120010', name: 'Cacatu' },
  { ibge: '410120015', name: 'Cachoeira de Cima' },
  { ibge: '410130905', name: 'Antônio Olinto' },
  { ibge: '410140805', name: 'Apucarana' },
  { ibge: '410140810', name: 'Correia de Freitas' },
  { ibge: '410140815', name: 'Pirapó' },
  { ibge: '410140820', name: 'São Pedro' },
  { ibge: '410140825', name: 'Vila Reis' },
  { ibge: '410150705', name: 'Arapongas' },
  { ibge: '410160605', name: 'Arapoti' },
  { ibge: '410160610', name: 'Calógeras' },
  { ibge: '410160615', name: 'Caratuva' },
  { ibge: '410165505', name: 'Arapuã' },
  { ibge: '410170505', name: 'Araruna' },
  { ibge: '410170515', name: 'São Vicente' },
  { ibge: '410180405', name: 'Araucária' },
  { ibge: '410180410', name: 'Guajuvira' },
  { ibge: '410185305', name: 'Ariranha do Ivaí' },
  { ibge: '410190305', name: 'Assaí' },
  { ibge: '410190330', name: "Pau D'Alho do Sul" },
  { ibge: '410200005', name: 'Assis Chateaubriand' },
  { ibge: '410200010', name: 'Bragantina' },
  { ibge: '410200020', name: "Encantado d'Oeste" },
  { ibge: '410210905', name: 'Astorga' },
  { ibge: '410210910', name: 'Içara' },
  { ibge: '410210915', name: 'Santa Zélia' },
  { ibge: '410210920', name: 'Tupinambá' },
  { ibge: '410220805', name: 'Atalaia' },
  { ibge: '410230705', name: 'Balsa Nova' },
  { ibge: '410230710', name: 'Bugre' },
  { ibge: '410230715', name: 'São Luiz do Purunã' },
  { ibge: '410240605', name: 'Bandeirantes' },
  { ibge: '410240610', name: 'Nossa Senhora da Candelária' },
  { ibge: '410250505', name: 'Barbosa Ferraz' },
  { ibge: '410250507', name: 'Bourbonia' },
  { ibge: '410250515', name: 'Ourilândia' },
  { ibge: '410250520', name: 'Pocinho' },
  { ibge: '410250525', name: 'Tereza Breda' },
  { ibge: '410260405', name: 'Barracão' },
  { ibge: '410260415', name: 'Siqueira Belo' },
  { ibge: '410270305', name: 'Barra do Jacaré' },
  { ibge: '410275205', name: 'Bela Vista da Caroba' },
  { ibge: '410280205', name: 'Bela Vista do Paraíso' },
  { ibge: '410280210', name: 'Santa Margarida' },
  { ibge: '410290105', name: 'Bituruna' },
  { ibge: '410290110', name: 'Santo Antônio do Iratim' },
  { ibge: '410300805', name: 'Boa Esperança' },
  { ibge: '410302405', name: 'Boa Esperança do Iguaçu' },
  { ibge: '410304005', name: 'Boa Ventura de São Roque' },
  { ibge: '410305705', name: 'Boa Vista da Aparecida' },
  { ibge: '410310705', name: 'Bocaiúva do Sul' },
  { ibge: '410315605', name: 'Bom Jesus do Sul' },
  { ibge: '410320605', name: 'Bom Sucesso' },
  { ibge: '410322205', name: 'Bom Sucesso do Sul' },
  { ibge: '410330505', name: 'Borrazópolis' },
  { ibge: '410335405', name: 'Braganey' },
  { ibge: '410337005', name: 'Brasilândia do Sul' },
  { ibge: '410340405', name: 'Cafeara' },
  { ibge: '410345305', name: 'Cafelândia' },
  { ibge: '410347905', name: 'Cafezal do Sul' },
  { ibge: '410347910', name: 'Guaiporã' },
  { ibge: '410347915', name: 'Jangada' },
  { ibge: '410350305', name: 'Califórnia' },
  { ibge: '410360205', name: 'Cambará' },
  { ibge: '410370105', name: 'Cambé' },
  { ibge: '410370110', name: 'Prata' },
  { ibge: '410380005', name: 'Cambira' },
  { ibge: '410390905', name: 'Campina da Lagoa' },
  { ibge: '410390910', name: 'Bela Vista do Piquiri' },
  { ibge: '410390915', name: 'Herveira' },
  { ibge: '410390920', name: 'Salles de Oliveira' },
  { ibge: '410395805', name: 'Campina do Simão' },
  { ibge: '410400605', name: 'Campina Grande do Sul' },
  { ibge: '410405505', name: 'Campo Bonito' },
  { ibge: '410410505', name: 'Campo do Tenente' },
  { ibge: '410420405', name: 'Campo Largo' },
  { ibge: '410420410', name: 'Bateias' },
  { ibge: '410420415', name: 'Ferraria' },
  { ibge: '410420420', name: 'São Silvestre' },
  { ibge: '410420425', name: 'Três Córregos' },
  { ibge: '410425305', name: 'Campo Magro' },
  { ibge: '410430305', name: 'Campo Mourão' },
  { ibge: '410430320', name: 'Piquirivaí' },
  { ibge: '410440205', name: 'Cândido de Abreu' },
  { ibge: '410440210', name: 'Tereza Cristina' },
  { ibge: '410440215', name: 'Três Bicos' },
  { ibge: '410442805', name: 'Candói' },
  { ibge: '410445105', name: 'Cantagalo' },
  { ibge: '410450105', name: 'Capanema' },
  { ibge: '410450110', name: 'Cristo Rei' },
  { ibge: '410450115', name: 'Pinheiro' },
  { ibge: '410450120', name: 'São Luiz' },
  { ibge: '410460005', name: 'Capitão Leônidas Marques' },
  { ibge: '410460020', name: 'Alto Alegre do Iguaçu' },
  { ibge: '410465905', name: 'Carambeí' },
  { ibge: '410470905', name: 'Carlópolis' },
  { ibge: '410470910', name: 'Nova Brasília do Itararé' },
  { ibge: '410480805', name: 'Cascavel' },
  { ibge: '410480815', name: "São João d'Oeste" },
  { ibge: '410480825', name: 'Juvinópolis' },
  { ibge: '410480835', name: 'Rio do Salto' },
  { ibge: '410480840', name: 'Sede Alvorada' },
  { ibge: '410480845', name: 'Espigão Azul' },
  { ibge: '410480850', name: 'São Salvador' },
  { ibge: '410480855', name: 'Diamante' },
  { ibge: '410490705', name: 'Castro' },
  { ibge: '410490710', name: 'Abapã' },
  { ibge: '410490720', name: 'Socavão' },
  { ibge: '410500305', name: 'Catanduvas' },
  { ibge: '410510205', name: 'Centenário do Sul' },
  { ibge: '410520105', name: 'Cerro Azul' },
  { ibge: '410520110', name: 'São Sebastião' },
  { ibge: '410530005', name: 'Céu Azul' },
  { ibge: '410540905', name: 'Chopinzinho' },
  { ibge: '410540910', name: 'São Francisco' },
  { ibge: '410540915', name: 'São Luiz' },
  { ibge: '410550805', name: 'Cianorte' },
  { ibge: '410550810', name: 'São Lourenço' },
  { ibge: '410550815', name: 'Vidigal' },
  { ibge: '410560705', name: 'Cidade Gaúcha' },
  { ibge: '410570605', name: 'Clevelândia' },
  { ibge: '410570610', name: 'Coronel Firmino Martins' },
  { ibge: '410570615', name: 'São Francisco de Sales' },
  { ibge: '410580505', name: 'Colombo' },
  { ibge: '410590405', name: 'Colorado' },
  { ibge: '410590410', name: 'Alto Alegre' },
  { ibge: '410600105', name: 'Congonhinhas' },
  { ibge: '410600110', name: 'Santa Maria do Rio do Peixe' },
  { ibge: '410600115', name: 'São Francisco de Imbaú' },
  { ibge: '410610005', name: 'Conselheiro Mairinck' },
  { ibge: '410620905', name: 'Contenda' },
  { ibge: '410620910', name: 'Catanduvas do Sul' },
  { ibge: '410630805', name: 'Corbélia' },
  { ibge: '410630825', name: 'Ouro Verde do Piquiri' },
  { ibge: '410630830', name: 'Nossa Senhora da Penha' },
  { ibge: '410640705', name: 'Cornélio Procópio' },
  { ibge: '410640710', name: 'Congonhas' },
  { ibge: '410645605', name: 'Coronel Domingos Soares' },
  { ibge: '410650605', name: 'Coronel Vivida' },
  { ibge: '410650610', name: 'Vista Alegre' },
  { ibge: '410655505', name: 'Corumbataí do Sul' },
  { ibge: '410657105', name: 'Cruzeiro do Iguaçu' },
  { ibge: '410660505', name: 'Cruzeiro do Oeste' },
  { ibge: '410660510', name: 'Cafeeiros' },
  { ibge: '410660515', name: 'São Silvestre' },
  { ibge: '410670405', name: 'Cruzeiro do Sul' },
  { ibge: '410680305', name: 'Cruz Machado' },
  { ibge: '410680310', name: 'Pinaré' },
  { ibge: '410680315', name: 'Santana' },
  { ibge: '410685205', name: 'Cruzmaltina' },
  { ibge: '410690205', name: 'Curitiba' },
  { ibge: '410700905', name: 'Curiúva' },
  { ibge: '410700910', name: 'Alecrim' },
  { ibge: '410710805', name: 'Diamante do Norte' },
  { ibge: '410712405', name: 'Diamante do Sul' },
  { ibge: '410715705', name: "Diamante D'Oeste" },
  { ibge: '410720705', name: 'Dois Vizinhos' },
  { ibge: '410720730', name: 'Santa Lúcia' },
  { ibge: '410725605', name: 'Douradina' },
  { ibge: '410730605', name: 'Doutor Camargo' },
  { ibge: '410740505', name: 'Enéas Marques' },
  { ibge: '410740515', name: 'Pinhalzinho' },
  { ibge: '410740520', name: 'Vista Alegre' },
  { ibge: '410750405', name: 'Engenheiro Beltrão' },
  { ibge: '410750407', name: 'Figueira do Oeste' },
  { ibge: '410750409', name: 'Ivailândia' },
  { ibge: '410750410', name: 'Sertãozinho' },
  { ibge: '410750415', name: 'Sussuí' },
  { ibge: '410750420', name: 'Triângulo' },
  { ibge: '410752005', name: 'Esperança Nova' },
  { ibge: '410753805', name: 'Entre Rios do Oeste' },
  { ibge: '410754605', name: 'Espigão Alto do Iguaçu' },
  { ibge: '410755305', name: 'Farol' },
  { ibge: '410760305', name: 'Faxinal' },
  { ibge: '410760315', name: 'Nova Altamira' },
  { ibge: '410765205', name: 'Fazenda Rio Grande' },
  { ibge: '410770205', name: 'Fênix' },
  { ibge: '410770210', name: 'Bela Vista do Ivaí' },
  { ibge: '410770215', name: 'Porteira Preta' },
  { ibge: '410773605', name: 'Fernandes Pinheiro' },
  { ibge: '410775105', name: 'Figueira' },
  { ibge: '410780105', name: 'Floraí' },
  { ibge: '410780110', name: 'Nova Bilac' },
  { ibge: '410785005', name: 'Flor da Serra do Sul' },
  { ibge: '410790005', name: 'Floresta' },
  { ibge: '410800705', name: 'Florestópolis' },
  { ibge: '410810605', name: 'Flórida' },
  { ibge: '410820505', name: 'Formosa do Oeste' },
  { ibge: '410830405', name: 'Foz do Iguaçu' },
  { ibge: '410830410', name: 'Alvorada do Iguaçu' },
  { ibge: '410832005', name: 'Francisco Alves' },
  { ibge: '410832010', name: 'Rio Bonito' },
  { ibge: '410840305', name: 'Francisco Beltrão' },
  { ibge: '410840307', name: 'Jacutinga' },
  { ibge: '410840310', name: 'Nova Concórdia' },
  { ibge: '410840320', name: 'São Pio X' },
  { ibge: '410840325', name: 'Secção Jacaré' },
  { ibge: '410845205', name: 'Foz do Jordão' },
  { ibge: '410850205', name: 'General Carneiro' },
  { ibge: '410850215', name: 'Jangada do Sul' },
  { ibge: '410855105', name: 'Godoy Moreira' },
  { ibge: '410860105', name: 'Goioerê' },
  { ibge: '410865005', name: 'Goioxim' },
  { ibge: '410870005', name: 'Grandes Rios' },
  { ibge: '410870010', name: 'Ribeirão Bonito' },
  { ibge: '410880905', name: 'Guaíra' },
  { ibge: '410880910', name: 'Doutor Oliveira Castro' },
  { ibge: '410890805', name: 'Guairaçá' },
  { ibge: '410895705', name: 'Guamiranga' },
  { ibge: '410900505', name: 'Guapirama' },
  { ibge: '410910405', name: 'Guaporema' },
  { ibge: '410920305', name: 'Guaraci' },
  { ibge: '410920310', name: 'Bentópolis' },
  { ibge: '410930205', name: 'Guaraniaçu' },
  { ibge: '410930210', name: 'Bela Vista' },
  { ibge: '410930215', name: 'Borman' },
  { ibge: '410930230', name: 'Guaporé' },
  { ibge: '410940105', name: 'Guarapuava' },
  { ibge: '410940107', name: 'Atalaia' },
  { ibge: '410940135', name: 'Entre Rios' },
  { ibge: '410940145', name: 'Guairacá' },
  { ibge: '410940150', name: 'Guará' },
  { ibge: '410940175', name: 'Palmeirinha' },
  { ibge: '410950005', name: 'Guaraqueçaba' },
  { ibge: '410950010', name: 'Ararapira' },
  { ibge: '410950015', name: 'Serra Negra' },
  { ibge: '410960905', name: 'Guaratuba' },
  { ibge: '410960910', name: 'Pedra Branca do Araraquara' },
  { ibge: '410965805', name: 'Honório Serpa' },
  { ibge: '410965810', name: 'Pinho Fleck' },
  { ibge: '410970805', name: 'Ibaiti' },
  { ibge: '410970810', name: 'Amorinha' },
  { ibge: '410970812', name: 'Campinho' },
  { ibge: '410970815', name: 'Euzébio de Oliveira' },
  { ibge: '410970820', name: 'Vassoural' },
  { ibge: '410970825', name: 'Vila Guay' },
  { ibge: '410975705', name: 'Ibema' },
  { ibge: '410980705', name: 'Ibiporã' },
  { ibge: '410990605', name: 'Icaraíma' },
  { ibge: '410990610', name: 'Porto Camargo' },
  { ibge: '410990615', name: 'Vila Rica do Ivaí' },
  { ibge: '411000305', name: 'Iguaraçu' },
  { ibge: '411005205', name: 'Iguatu' },
  { ibge: '411007805', name: 'Imbaú' },
  { ibge: '411010205', name: 'Imbituva' },
  { ibge: '411010210', name: 'Apiaba' },
  { ibge: '411020105', name: 'Inácio Martins' },
  { ibge: '411030005', name: 'Inajá' },
  { ibge: '411040905', name: 'Indianópolis' },
  { ibge: '411050805', name: 'Ipiranga' },
  { ibge: '411060705', name: 'Iporã' },
  { ibge: '411060714', name: 'Nilza' },
  { ibge: '411060715', name: 'Nova Santa Helena' },
  { ibge: '411065605', name: 'Iracema do Oeste' },
  { ibge: '411070605', name: 'Irati' },
  { ibge: '411070610', name: 'Gonçalves Júnior' },
  { ibge: '411070615', name: 'Guamirim' },
  { ibge: '411070620', name: 'Itapará' },
  { ibge: '411080505', name: 'Iretama' },
  { ibge: '411080506', name: 'Águas de Jurema' },
  { ibge: '411080510', name: 'Marilu' },
  { ibge: '411090405', name: 'Itaguajé' },
  { ibge: '411095305', name: 'Itaipulândia' },
  { ibge: '411095325', name: 'São José do Itavó' },
  { ibge: '411100105', name: 'Itambaracá' },
  { ibge: '411100110', name: 'São Joaquim do Pontal' },
  { ibge: '411110005', name: 'Itambé' },
  { ibge: '411120905', name: "Itapejara d'Oeste" },
  { ibge: '411120910', name: 'Barra Grande' },
  { ibge: '411120915', name: 'Coxilha Rica' },
  { ibge: '411125805', name: 'Itaperuçu' },
  { ibge: '411130805', name: 'Itaúna do Sul' },
  { ibge: '411140705', name: 'Ivaí' },
  { ibge: '411140710', name: 'Bom Jardim do Sul' },
  { ibge: '411150605', name: 'Ivaiporã' },
  { ibge: '411150610', name: 'Alto Porã' },
  { ibge: '411150625', name: 'Jacutinga' },
  { ibge: '411150635', name: 'Santa Bárbara' },
  { ibge: '411155505', name: 'Ivaté' },
  { ibge: '411155515', name: 'Herculândia' },
  { ibge: '411160505', name: 'Ivatuba' },
  { ibge: '411170405', name: 'Jaboti' },
  { ibge: '411180305', name: 'Jacarezinho' },
  { ibge: '411190205', name: 'Jaguapitã' },
  { ibge: '411200905', name: 'Jaguariaíva' },
  { ibge: '411200910', name: 'Eduardo Xavier da Silva' },
  { ibge: '411210805', name: 'Jandaia do Sul' },
  { ibge: '411210810', name: 'São José' },
  { ibge: '411220705', name: 'Janiópolis' },
  { ibge: '411220710', name: 'Arapuan' },
  { ibge: '411230605', name: 'Japira' },
  { ibge: '411230610', name: 'Novo Jardim' },
  { ibge: '411240505', name: 'Japurá' },
  { ibge: '411250405', name: 'Jardim Alegre' },
  { ibge: '411260305', name: 'Jardim Olinda' },
  { ibge: '411270205', name: 'Jataizinho' },
  { ibge: '411270210', name: 'Antônio Brandão de Oliveira' },
  { ibge: '411270215', name: 'Frei Timóteo' },
  { ibge: '411275105', name: 'Jesuítas' },
  { ibge: '411275110', name: 'Carajá' },
  { ibge: '411280105', name: 'Joaquim Távora' },
  { ibge: '411280110', name: 'Joá' },
  { ibge: '411290005', name: 'Jundiaí do Sul' },
  { ibge: '411295905', name: 'Juranda' },
  { ibge: '411295908', name: 'Primavera' },
  { ibge: '411295910', name: 'Rio Verde' },
  { ibge: '411300705', name: 'Jussara' },
  { ibge: '411310605', name: 'Kaloré' },
  { ibge: '411320505', name: 'Lapa' },
  { ibge: '411320510', name: 'Água Azul' },
  { ibge: '411320515', name: 'Mariental' },
  { ibge: '411325405', name: 'Laranjal' },
  { ibge: '411330405', name: 'Laranjeiras do Sul' },
  { ibge: '411330425', name: 'Passo Liso' },
  { ibge: '411340305', name: 'Leópolis' },
  { ibge: '411342905', name: 'Lidianópolis' },
  { ibge: '411345205', name: 'Lindoeste' },
  { ibge: '411350205', name: 'Loanda' },
  { ibge: '411360105', name: 'Lobato' },
  { ibge: '411370005', name: 'Londrina' },
  { ibge: '411370010', name: 'Guaravera' },
  { ibge: '411370015', name: 'Irerê' },
  { ibge: '411370020', name: 'Lerroville' },
  { ibge: '411370025', name: 'Maravilha' },
  { ibge: '411370030', name: 'Paiquerê' },
  { ibge: '411370035', name: 'São Luiz' },
  { ibge: '411370045', name: 'Warta' },
  { ibge: '411373405', name: 'Luiziana' },
  { ibge: '411375905', name: 'Lunardelli' },
  { ibge: '411380905', name: 'Lupionópolis' },
  { ibge: '411380910', name: 'Mairá' },
  { ibge: '411390805', name: 'Mallet' },
  { ibge: '411390810', name: 'Dorizon' },
  { ibge: '411390815', name: 'Rio Claro do Sul' },
  { ibge: '411400505', name: 'Mamborê' },
  { ibge: '411410405', name: 'Mandaguaçu' },
  { ibge: '411420305', name: 'Mandaguari' },
  { ibge: '411430205', name: 'Mandirituba' },
  { ibge: '411430210', name: 'Areia Branca dos Assis' },
  { ibge: '411435105', name: 'Manfrinópolis' },
  { ibge: '411440105', name: 'Mangueirinha' },
  { ibge: '411440110', name: 'Covó' },
  { ibge: '411450005', name: 'Manoel Ribas' },
  { ibge: '411450010', name: 'Barra Santa Salete' },
  { ibge: '411460905', name: 'Marechal Cândido Rondon' },
  { ibge: '411460907', name: 'Bom Jardim' },
  { ibge: '411460915', name: 'Iguiporã' },
  { ibge: '411460920', name: 'Margarida' },
  { ibge: '411460930', name: 'Novo Horizonte' },
  { ibge: '411460935', name: 'Novo Três Passos' },
  { ibge: '411460945', name: 'Porto Mendes' },
  { ibge: '411460955', name: 'São Roque' },
  { ibge: '411470805', name: 'Maria Helena' },
  { ibge: '411470810', name: 'Carbonera' },
  { ibge: '411480705', name: 'Marialva' },
  { ibge: '411480710', name: 'Santa Fé do Pirapó' },
  { ibge: '411480715', name: 'São Luiz' },
  { ibge: '411480720', name: 'São Miguel do Cambuí' },
  { ibge: '411490605', name: 'Marilândia do Sul' },
  { ibge: '411490625', name: 'Nova Amoreira' },
  { ibge: '411500205', name: 'Marilena' },
  { ibge: '411500210', name: 'Ipanema' },
  { ibge: '411510105', name: 'Mariluz' },
  { ibge: '411510110', name: 'São Luiz' },
  { ibge: '411520005', name: 'Maringá' },
  { ibge: '411520010', name: 'Floriano' },
  { ibge: '411520015', name: 'Iguatemi' },
  { ibge: '411530905', name: 'Mariópolis' },
  { ibge: '411530910', name: 'Rio Pinheiro' },
  { ibge: '411530915', name: 'Senhor Bom Jesus dos Gramados' },
  { ibge: '411535805', name: 'Maripá' },
  { ibge: '411535810', name: 'Candeia' },
  { ibge: '411535820', name: 'Pérola Independente' },
  { ibge: '411540805', name: 'Marmeleiro' },
  { ibge: '411545705', name: 'Marquinho' },
  { ibge: '411550705', name: 'Marumbi' },
  { ibge: '411560605', name: 'Matelândia' },
  { ibge: '411560607', name: 'Agro Cafeeira' },
  { ibge: '411570505', name: 'Matinhos' },
  { ibge: '411573905', name: 'Mato Rico' },
  { ibge: '411575405', name: 'Mauá da Serra' },
  { ibge: '411580405', name: 'Medianeira' },
  { ibge: '411580425', name: 'Maralúcia' },
  { ibge: '411585305', name: 'Mercedes' },
  { ibge: '411590305', name: 'Mirador' },
  { ibge: '411600005', name: 'Miraselva' },
  { ibge: '411605905', name: 'Missal' },
  { ibge: '411605910', name: 'Dom Armando' },
  { ibge: '411605920', name: 'Portão Ocoí' },
  { ibge: '411610905', name: 'Moreira Sales' },
  { ibge: '411610910', name: "Paraná d'Oeste" },
  { ibge: '411620805', name: 'Morretes' },
  { ibge: '411620810', name: 'Porto de Cima' },
  { ibge: '411630705', name: 'Munhoz de Melo' },
  { ibge: '411630710', name: 'Fernão Dias' },
  { ibge: '411640605', name: 'Nossa Senhora das Graças' },
  { ibge: '411650505', name: 'Nova Aliança do Ivaí' },
  { ibge: '411660405', name: 'Nova América da Colina' },
  { ibge: '411670305', name: 'Nova Aurora' },
  { ibge: '411670307', name: 'Marajó' },
  { ibge: '411670310', name: 'Palmitópolis' },
  { ibge: '411680205', name: 'Nova Cantu' },
  { ibge: '411680210', name: 'Geremia Lunardelli' },
  { ibge: '411680215', name: 'Santo Rei' },
  { ibge: '411690105', name: 'Nova Esperança' },
  { ibge: '411690110', name: 'Barão de Lucena' },
  { ibge: '411690115', name: 'Ivaitinga' },
  { ibge: '411695005', name: 'Nova Esperança do Sudoeste' },
  { ibge: '411700805', name: 'Nova Fátima' },
  { ibge: '411705705', name: 'Nova Laranjeiras' },
  { ibge: '411705710', name: 'Bananas' },
  { ibge: '411705715', name: 'Guaraí' },
  { ibge: '411705720', name: 'Guarani' },
  { ibge: '411705730', name: 'Rio da Prata' },
  { ibge: '411710705', name: 'Nova Londrina' },
  { ibge: '411710710', name: 'Cintra Pimentel' },
  { ibge: '411720605', name: 'Nova Olímpia' },
  { ibge: '411721405', name: 'Nova Santa Bárbara' },
  { ibge: '411722205', name: 'Nova Santa Rosa' },
  { ibge: '411722210', name: 'Alto Santa Fé' },
  { ibge: '411722220', name: 'Planalto do Oeste' },
  { ibge: '411725505', name: 'Nova Prata do Iguaçu' },
  { ibge: '411727105', name: 'Nova Tebas' },
  { ibge: '411727107', name: 'Catuporanga' },
  { ibge: '411727110', name: 'Poema' },
  { ibge: '411729705', name: 'Novo Itacolomi' },
  { ibge: '411730505', name: 'Ortigueira' },
  { ibge: '411730510', name: 'Barreiro' },
  { ibge: '411730515', name: 'Lajeado Bonito' },
  { ibge: '411730520', name: 'Monjolinho' },
  { ibge: '411730525', name: 'Natingui' },
  { ibge: '411740405', name: 'Ourizona' },
  { ibge: '411745305', name: 'Ouro Verde do Oeste' },
  { ibge: '411750305', name: 'Paiçandu' },
  { ibge: '411750310', name: 'Água Boa' },
  { ibge: '411760205', name: 'Palmas' },
  { ibge: '411760215', name: 'Francisco Frederico Teixeira Guimarães' },
  { ibge: '411760220', name: 'Padre Ponciano' },
  { ibge: '411770105', name: 'Palmeira' },
  { ibge: '411770110', name: 'Papagaios Novos' },
  { ibge: '411780005', name: 'Palmital' },
  { ibge: '411790905', name: 'Palotina' },
  { ibge: '411790915', name: 'São Camilo' },
  { ibge: '411790925', name: 'Vila Floresta' },
  { ibge: '411800605', name: 'Paraíso do Norte' },
  { ibge: '411810505', name: 'Paranacity' },
  { ibge: '411820405', name: 'Paranaguá' },
  { ibge: '411820410', name: 'Alexandra' },
  { ibge: '411830305', name: 'Paranapoema' },
  { ibge: '411840205', name: 'Paranavaí' },
  { ibge: '411840210', name: 'Cristo Rei' },
  { ibge: '411840215', name: 'Deputado José Afonso' },
  { ibge: '411840220', name: 'Graciosa' },
  { ibge: '411840225', name: 'Sumaré' },
  { ibge: '411845105', name: 'Pato Bragado' },
  { ibge: '411850105', name: 'Pato Branco' },
  { ibge: '411850130', name: 'São Roque do Chopim' },
  { ibge: '411860005', name: 'Paula Freitas' },
  { ibge: '411870905', name: 'Paulo Frontin' },
  { ibge: '411870910', name: 'Vera Guarani' },
  { ibge: '411880805', name: 'Peabiru' },
  { ibge: '411885705', name: 'Perobal' },
  { ibge: '411890705', name: 'Pérola' },
  { ibge: '411900405', name: "Pérola d'Oeste" },
  { ibge: '411900415', name: 'Conciolândia' },
  { ibge: '411910305', name: 'Piên' },
  { ibge: '411915205', name: 'Pinhais' },
  { ibge: '411920205', name: 'Pinhalão' },
  { ibge: '411920210', name: 'Lavrinha' },
  { ibge: '411925105', name: 'Pinhal de São Bento' },
  { ibge: '411930105', name: 'Pinhão' },
  { ibge: '411930110', name: 'Bom Retiro' },
  { ibge: '411930115', name: 'Faxinal do Céu' },
  { ibge: '411930125', name: 'Pinhalzinho' },
  { ibge: '411940005', name: 'Piraí do Sul' },
  { ibge: '411950905', name: 'Piraquara' },
  { ibge: '411960805', name: 'Pitanga' },
  { ibge: '411960810', name: 'Barra Bonita' },
  { ibge: '411960837', name: 'Rio XV de Baixo' },
  { ibge: '411960840', name: 'Vila Nova' },
  { ibge: '411965705', name: 'Pitangueiras' },
  { ibge: '411970705', name: 'Planaltina do Paraná' },
  { ibge: '411970710', name: 'Comur' },
  { ibge: '411980605', name: 'Planalto' },
  { ibge: '411980610', name: 'Barra Grande' },
  { ibge: '411980615', name: 'Centro Novo' },
  { ibge: '411980620', name: 'Sagrada Família' },
  { ibge: '411980625', name: 'Valério' },
  { ibge: '411990505', name: 'Ponta Grossa' },
  { ibge: '411990510', name: 'Guaragi' },
  { ibge: '411990515', name: 'Itaiacoca' },
  { ibge: '411990517', name: 'Piriquitos' },
  { ibge: '411990525', name: 'Uvaia' },
  { ibge: '411995405', name: 'Pontal do Paraná' },
  { ibge: '412000205', name: 'Porecatu' },
  { ibge: '412010105', name: 'Porto Amazonas' },
  { ibge: '412015005', name: 'Porto Barreiro' },
  { ibge: '412020005', name: 'Porto Rico' },
  { ibge: '412030905', name: 'Porto Vitória' },
  { ibge: '412033305', name: 'Prado Ferreira' },
  { ibge: '412035805', name: 'Pranchita' },
  { ibge: '412035810', name: 'Canzianópolis' },
  { ibge: '412040805', name: 'Presidente Castelo Branco' },
  { ibge: '412040810', name: 'Boa Vista' },
  { ibge: '412050705', name: 'Primeiro de Maio' },
  { ibge: '412050710', name: 'Ibiaci' },
  { ibge: '412060605', name: 'Prudentópolis' },
  { ibge: '412060610', name: 'Jaciaba' },
  { ibge: '412060615', name: 'Patos Velhos' },
  { ibge: '412065505', name: 'Quarto Centenário' },
  { ibge: '412070505', name: 'Quatiguá' },
  { ibge: '412080405', name: 'Quatro Barras' },
  { ibge: '412080410', name: 'Borda do Campo' },
  { ibge: '412085305', name: 'Quatro Pontes' },
  { ibge: '412090305', name: 'Quedas do Iguaçu' },
  { ibge: '412100005', name: 'Querência do Norte' },
  { ibge: '412100010', name: 'Icatu' },
  { ibge: '412100015', name: 'Porto Brasílio' },
  { ibge: '412110905', name: 'Quinta do Sol' },
  { ibge: '412110910', name: 'Irapuan' },
  { ibge: '412120805', name: 'Quitandinha' },
  { ibge: '412120810', name: 'Doce Grande' },
  { ibge: '412120815', name: 'Pangaré' },
  { ibge: '412125705', name: 'Ramilândia' },
  { ibge: '412130705', name: 'Rancho Alegre' },
  { ibge: '412135605', name: "Rancho Alegre D'Oeste" },
  { ibge: '412140605', name: 'Realeza' },
  { ibge: '412140610', name: 'Maravilha' },
  { ibge: '412140615', name: 'Marmelândia' },
  { ibge: '412140620', name: 'São Roque' },
  { ibge: '412150505', name: 'Rebouças' },
  { ibge: '412160405', name: 'Renascença' },
  { ibge: '412160410', name: 'Baulândia' },
  { ibge: '412160415', name: 'Canela' },
  { ibge: '412170305', name: 'Reserva' },
  { ibge: '412170310', name: 'José Lacerda' },
  { ibge: '412170315', name: 'Rio Novo' },
  { ibge: '412175205', name: 'Reserva do Iguaçu' },
  { ibge: '412180205', name: 'Ribeirão Claro' },
  { ibge: '412180210', name: 'Cachoeira do Espírito Santo' },
  { ibge: '412190105', name: 'Ribeirão do Pinhal' },
  { ibge: '412190115', name: 'Triolândia' },
  { ibge: '412200805', name: 'Rio Azul' },
  { ibge: '412210705', name: 'Rio Bom' },
  { ibge: '412210710', name: 'Santo Antônio do Palmital' },
  { ibge: '412215605', name: 'Rio Bonito do Iguaçu' },
  { ibge: '412215610', name: 'Assentamento Ireno Alves dos Santos' },
  { ibge: '412215615', name: 'Campo do Bugre' },
  { ibge: '412215620', name: 'Linha Rosa' },
  { ibge: '412215625', name: 'Pinhalzinho' },
  { ibge: '412217205', name: 'Rio Branco do Ivaí' },
  { ibge: '412220605', name: 'Rio Branco do Sul' },
  { ibge: '412220610', name: 'Açungui' },
  { ibge: '412230505', name: 'Rio Negro' },
  { ibge: '412240405', name: 'Rolândia' },
  { ibge: '412240410', name: 'Nossa Senhora da Aparecida' },
  { ibge: '412240420', name: 'São Martinho' },
  { ibge: '412250305', name: 'Roncador' },
  { ibge: '412250310', name: 'Alto do São João' },
  { ibge: '412260205', name: 'Rondon' },
  { ibge: '412265105', name: 'Rosário do Ivaí' },
  { ibge: '412270105', name: 'Sabáudia' },
  { ibge: '412280005', name: 'Salgado Filho' },
  { ibge: '412280020', name: 'Tiradentes' },
  { ibge: '412290905', name: 'Salto do Itararé' },
  { ibge: '412300605', name: 'Salto do Lontra' },
  { ibge: '412310505', name: 'Santa Amélia' },
  { ibge: '412320405', name: 'Santa Cecília do Pavão' },
  { ibge: '412330305', name: 'Santa Cruz de Monte Castelo' },
  { ibge: '412330310', name: 'Santa Esmeralda' },
  { ibge: '412340205', name: 'Santa Fé' },
  { ibge: '412350105', name: 'Santa Helena' },
  { ibge: '412350110', name: 'São Clemente' },
  { ibge: '412360005', name: 'Santa Inês' },
  { ibge: '412370905', name: 'Santa Isabel do Ivaí' },
  { ibge: '412370915', name: 'São José do Ivaí' },
  { ibge: '412380805', name: 'Santa Izabel do Oeste' },
  { ibge: '412380810', name: 'Anunciação' },
  { ibge: '412380815', name: 'Jacutinga' },
  { ibge: '412380820', name: 'Rio da Prata' },
  { ibge: '412380825', name: 'Sarandi' },
  { ibge: '412380830', name: 'União do Oeste' },
  { ibge: '412382405', name: 'Santa Lúcia' },
  { ibge: '412385705', name: 'Santa Maria do Oeste' },
  { ibge: '412385720', name: 'Ouro Verde' },
  { ibge: '412385725', name: 'Rio do Tigre' },
  { ibge: '412385730', name: 'São José' },
  { ibge: '412385735', name: 'São Manoel' },
  { ibge: '412390705', name: 'Santa Mariana' },
  { ibge: '412395605', name: 'Santa Mônica' },
  { ibge: '412400405', name: 'Santana do Itararé' },
  { ibge: '412402005', name: 'Santa Tereza do Oeste' },
  { ibge: '412405305', name: 'Santa Terezinha de Itaipu' },
  { ibge: '412410305', name: 'Santo Antônio da Platina' },
  { ibge: '412410312', name: 'Conselheiro Zacarias' },
  { ibge: '412410317', name: 'Monte Real' },
  { ibge: '412420205', name: 'Santo Antônio do Caiuá' },
  { ibge: '412430105', name: 'Santo Antônio do Paraíso' },
  { ibge: '412430110', name: 'São Judas Tadeu' },
  { ibge: '412440005', name: 'Santo Antônio do Sudoeste' },
  { ibge: '412440015', name: 'Marcionópolis' },
  { ibge: '412440030', name: 'São Pedro do Florido' },
  { ibge: '412450905', name: 'Santo Inácio' },
  { ibge: '412460805', name: 'São Carlos do Ivaí' },
  { ibge: '412460810', name: 'Porto São Carlos' },
  { ibge: '412470705', name: 'São Jerônimo da Serra' },
  { ibge: '412470710', name: 'São João do Pinhal' },
  { ibge: '412470715', name: 'Terra Nova' },
  { ibge: '412470720', name: 'Vila Nova de Florença' },
  { ibge: '412480605', name: 'São João' },
  { ibge: '412480610', name: 'Dois Irmãos' },
  { ibge: '412480615', name: 'Nova Lourdes' },
  { ibge: '412480617', name: 'Ouro Verde' },
  { ibge: '412480620', name: 'Vila Paraíso' },
  { ibge: '412490505', name: 'São João do Caiuá' },
  { ibge: '412500105', name: 'São João do Ivaí' },
  { ibge: '412500115', name: 'Ubaúna' },
  { ibge: '412500125', name: 'Luar' },
  { ibge: '412500130', name: 'Santa Luzia da Alvorada' },
  { ibge: '412510005', name: 'São João do Triunfo' },
  { ibge: '412510010', name: 'Palmira' },
  { ibge: '412520905', name: "São Jorge d'Oeste" },
  { ibge: '412520910', name: 'Doutor Antônio Paranhos' },
  { ibge: '412520915', name: 'Iolópolis' },
  { ibge: '412520917', name: 'Lagos do Iguaçu' },
  { ibge: '412520920', name: "Sede Nova Sant'Ana" },
  { ibge: '412530805', name: 'São Jorge do Ivaí' },
  { ibge: '412530810', name: 'Copacabana do Norte' },
  { ibge: '412535705', name: 'São Jorge do Patrocínio' },
  { ibge: '412540705', name: 'São José da Boa Vista' },
  { ibge: '412545605', name: 'São José das Palmeiras' },
  { ibge: '412550605', name: 'São José dos Pinhais' },
  { ibge: '412550606', name: 'Borda do Campo de São Sebastião' },
  { ibge: '412550607', name: 'Cachoeira de São José' },
  { ibge: '412550610', name: 'Campo Largo da Roseira' },
  { ibge: '412550615', name: 'Colônia Murici' },
  { ibge: '412550620', name: 'Marcelino' },
  { ibge: '412550625', name: 'São Marcos' },
  { ibge: '412555505', name: 'São Manoel do Paraná' },
  { ibge: '412560505', name: 'São Mateus do Sul' },
  { ibge: '412560510', name: 'Caitá' },
  { ibge: '412560515', name: 'Fluviópolis' },
  { ibge: '412560520', name: 'Lajeado' },
  { ibge: '412570405', name: 'São Miguel do Iguaçu' },
  { ibge: '412570410', name: 'Aurora do Iguaçu' },
  { ibge: '412570420', name: 'São José do Iguaçu' },
  { ibge: '412575305', name: 'São Pedro do Iguaçu' },
  { ibge: '412580305', name: 'São Pedro do Ivaí' },
  { ibge: '412580310', name: 'Mariza' },
  { ibge: '412590205', name: 'São Pedro do Paraná' },
  { ibge: '412590210', name: 'Porto São José' },
  { ibge: '412600905', name: 'São Sebastião da Amoreira' },
  { ibge: '412610805', name: 'São Tomé' },
  { ibge: '412620705', name: 'Sapopema' },
  { ibge: '412620710', name: 'Lambari' },
  { ibge: '412620715', name: 'Vida Nova' },
  { ibge: '412625605', name: 'Sarandi' },
  { ibge: '412627205', name: 'Saudade do Iguaçu' },
  { ibge: '412630605', name: 'Sengés' },
  { ibge: '412630607', name: 'Ouro Verde' },
  { ibge: '412630610', name: 'Reianópolis' },
  { ibge: '412635505', name: 'Serranópolis do Iguaçu' },
  { ibge: '412640505', name: 'Sertaneja' },
  { ibge: '412640510', name: 'Paranagi' },
  { ibge: '412650405', name: 'Sertanópolis' },
  { ibge: '412660305', name: 'Siqueira Campos' },
  { ibge: '412660310', name: 'Alemoa' },
  { ibge: '412665205', name: 'Sulina' },
  { ibge: '412667805', name: 'Tamarana' },
  { ibge: '412670205', name: 'Tamboara' },
  { ibge: '412680105', name: 'Tapejara' },
  { ibge: '412680110', name: 'Bela Vista do Tapiracuí' },
  { ibge: '412690005', name: 'Tapira' },
  { ibge: '412700705', name: 'Teixeira Soares' },
  { ibge: '412700720', name: 'Guaraúna' },
  { ibge: '412710605', name: 'Telêmaco Borba' },
  { ibge: '412720505', name: 'Terra Boa' },
  { ibge: '412730405', name: 'Terra Rica' },
  { ibge: '412730410', name: 'Adhemar de Barros' },
  { ibge: '412740305', name: 'Terra Roxa' },
  { ibge: '412740310', name: "Santa Rita d'Oeste" },
  { ibge: '412750205', name: 'Tibagi' },
  { ibge: '412750210', name: 'Alto do Amparo' },
  { ibge: '412750220', name: 'Caetano Mendes' },
  { ibge: '412760105', name: 'Tijucas do Sul' },
  { ibge: '412770005', name: 'Toledo' },
  { ibge: '412770008', name: 'Concórdia do Oeste' },
  { ibge: '412770010', name: 'Dez de Maio' },
  { ibge: '412770015', name: 'Dois Irmãos' },
  { ibge: '412770020', name: 'Novo Sarandi' },
  { ibge: '412770025', name: 'São Luiz do Oeste' },
  { ibge: '412770030', name: 'São Miguel' },
  { ibge: '412770033', name: 'Vila Ipiranga' },
  { ibge: '412770035', name: 'Vila Nova' },
  { ibge: '412770040', name: 'Novo Sobradinho' },
  { ibge: '412780905', name: 'Tomazina' },
  { ibge: '412780910', name: 'Sapé' },
  { ibge: '412785805', name: 'Três Barras do Paraná' },
  { ibge: '412788205', name: 'Tunas do Paraná' },
  { ibge: '412790805', name: 'Tuneiras do Oeste' },
  { ibge: '412790810', name: "Aparecida D'Oeste" },
  { ibge: '412790815', name: 'Marabá' },
  { ibge: '412795705', name: 'Tupãssi' },
  { ibge: '412796505', name: 'Turvo' },
  { ibge: '412800505', name: 'Ubiratã' },
  { ibge: '412800510', name: 'Yolanda' },
  { ibge: '412810405', name: 'Umuarama' },
  { ibge: '412810415', name: 'Lovat' },
  { ibge: '412810425', name: 'Roberto Silveira' },
  { ibge: '412810430', name: 'Santa Eliza' },
  { ibge: '412810435', name: 'Serra dos Dourados' },
  { ibge: '412820305', name: 'União da Vitória' },
  { ibge: '412820310', name: 'São Cristóvão' },
  { ibge: '412820315', name: 'São Domingos' },
  { ibge: '412830205', name: 'Uniflor' },
  { ibge: '412840105', name: 'Uraí' },
  { ibge: '412840110', name: 'Cruzeiro do Norte' },
  { ibge: '412850005', name: 'Wenceslau Braz' },
  { ibge: '412853405', name: 'Ventania' },
  { ibge: '412853410', name: 'Novo Barro Preto' },
  { ibge: '412855905', name: 'Vera Cruz do Oeste' },
  { ibge: '412860905', name: 'Verê' },
  { ibge: '412860910', name: 'Presidente Kennedy' },
  { ibge: '412860915', name: 'Sede Progresso' },
  { ibge: '412862505', name: 'Alto Paraíso' },
  { ibge: '412863305', name: 'Doutor Ulysses' },
  { ibge: '412865805', name: 'Virmond' },
  { ibge: '412870805', name: 'Vitorino' },
  { ibge: '412880705', name: 'Xambrê' },
  { ibge: '420005105', name: 'Abdon Batista' },
  { ibge: '420010105', name: 'Abelardo Luz' },
  { ibge: '420020005', name: 'Agrolândia' },
  { ibge: '420030905', name: 'Agronômica' },
  { ibge: '420040805', name: 'Água Doce' },
  { ibge: '420040810', name: 'Herciliópolis' },
  { ibge: '420050705', name: 'Águas de Chapecó' },
  { ibge: '420055605', name: 'Águas Frias' },
  { ibge: '420060605', name: 'Águas Mornas' },
  { ibge: '420070505', name: 'Alfredo Wagner' },
  { ibge: '420070510', name: 'Arnópolis' },
  { ibge: '420070515', name: 'Catuíra' },
  { ibge: '420070520', name: 'São Leonardo' },
  { ibge: '420075405', name: 'Alto Bela Vista' },
  { ibge: '420080405', name: 'Anchieta' },
  { ibge: '420090305', name: 'Angelina' },
  { ibge: '420090310', name: 'Barra Clara' },
  { ibge: '420090315', name: 'Garcia' },
  { ibge: '420100005', name: 'Anita Garibaldi' },
  { ibge: '420100015', name: 'Lagoa da Estiva' },
  { ibge: '420110905', name: 'Anitápolis' },
  { ibge: '420120805', name: 'Antônio Carlos' },
  { ibge: '420125705', name: 'Apiúna' },
  { ibge: '420127305', name: 'Arabutã' },
  { ibge: '420127310', name: 'Nova Estrela' },
  { ibge: '420130705', name: 'Araquari' },
  { ibge: '420130710', name: 'Itapocu' },
  { ibge: '420140605', name: 'Araranguá' },
  { ibge: '420140610', name: 'Hercílio Luz' },
  { ibge: '420140615', name: 'Balneário Morro dos Conventos' },
  { ibge: '420140625', name: 'Sanga da Toca' },
  { ibge: '420150505', name: 'Armazém' },
  { ibge: '420160405', name: 'Arroio Trinta' },
  { ibge: '420165305', name: 'Arvoredo' },
  { ibge: '420170305', name: 'Ascurra' },
  { ibge: '420180205', name: 'Atalanta' },
  { ibge: '420190105', name: 'Aurora' },
  { ibge: '420195005', name: 'Balneário Arroio do Silva' },
  { ibge: '420200805', name: 'Balneário Camboriú' },
  { ibge: '420205705', name: 'Balneário Barra do Sul' },
  { ibge: '420207305', name: 'Balneário Gaivota' },
  { ibge: '420208105', name: 'Bandeirante' },
  { ibge: '420209905', name: 'Barra Bonita' },
  { ibge: '420210705', name: 'Barra Velha' },
  { ibge: '420213105', name: 'Bela Vista do Toldo' },
  { ibge: '420215605', name: 'Belmonte' },
  { ibge: '420220605', name: 'Benedito Novo' },
  { ibge: '420220613', name: 'Barra São João' },
  { ibge: '420220615', name: 'Santa Maria' },
  { ibge: '420230505', name: 'Biguaçu' },
  { ibge: '420230510', name: 'Guaporanga' },
  { ibge: '420230515', name: 'Sorocaba do Sul' },
  { ibge: '420240405', name: 'Blumenau' },
  { ibge: '420240410', name: 'Itoupava' },
  { ibge: '420240415', name: 'Grande Garcia' },
  { ibge: '420243805', name: 'Bocaina do Sul' },
  { ibge: '420245305', name: 'Bombinhas' },
  { ibge: '420250305', name: 'Bom Jardim da Serra' },
  { ibge: '420253705', name: 'Bom Jesus' },
  { ibge: '420257805', name: 'Bom Jesus do Oeste' },
  { ibge: '420260205', name: 'Bom Retiro' },
  { ibge: '420260210', name: 'Canoas' },
  { ibge: '420270105', name: 'Botuverá' },
  { ibge: '420280005', name: 'Braço do Norte' },
  { ibge: '420285905', name: 'Braço do Trombudo' },
  { ibge: '420287505', name: 'Brunópolis' },
  { ibge: '420290905', name: 'Brusque' },
  { ibge: '420300605', name: 'Caçador' },
  { ibge: '420300615', name: 'Taquara Verde' },
  { ibge: '420310505', name: 'Caibi' },
  { ibge: '420315405', name: 'Calmon' },
  { ibge: '420320405', name: 'Camboriú' },
  { ibge: '420320410', name: 'Monte Alegre' },
  { ibge: '420325305', name: 'Capão Alto' },
  { ibge: '420330305', name: 'Campo Alegre' },
  { ibge: '420330310', name: 'Bateias de Baixo' },
  { ibge: '420330315', name: 'Fragosos' },
  { ibge: '420340205', name: 'Campo Belo do Sul' },
  { ibge: '420350105', name: 'Campo Erê' },
  { ibge: '420360005', name: 'Campos Novos' },
  { ibge: '420360010', name: 'Bela Vista' },
  { ibge: '420360015', name: "Dal'Pai" },
  { ibge: '420360017', name: 'Encruzilhada' },
  { ibge: '420360020', name: 'Espinilho' },
  { ibge: '420360023', name: 'Ibicuí' },
  { ibge: '420360026', name: 'Leão' },
  { ibge: '420360045', name: 'Guarani' },
  { ibge: '420370905', name: 'Canelinha' },
  { ibge: '420380805', name: 'Canoinhas' },
  { ibge: '420380812', name: 'Campo da Água Verde' },
  { ibge: '420380815', name: 'Felipe Schmidt' },
  { ibge: '420380820', name: 'Marcílio Dias' },
  { ibge: '420380825', name: 'Paula Pereira' },
  { ibge: '420380830', name: 'Pinheiros' },
  { ibge: '420390705', name: 'Capinzal' },
  { ibge: '420390710', name: 'Alto Alegre' },
  { ibge: '420395605', name: 'Capivari de Baixo' },
  { ibge: '420400405', name: 'Catanduvas' },
  { ibge: '420410305', name: 'Caxambu do Sul' },
  { ibge: '420415205', name: 'Celso Ramos' },
  { ibge: '420417805', name: 'Cerro Negro' },
  { ibge: '420419405', name: 'Chapadão do Lageado' },
  { ibge: '420420205', name: 'Chapecó' },
  { ibge: '420420210', name: 'Alto da Serra' },
  { ibge: '420420220', name: 'Figueira' },
  { ibge: '420420225', name: 'Gôio-En' },
  { ibge: '420420240', name: 'Marechal Bormann' },
  { ibge: '420425105', name: 'Cocal do Sul' },
  { ibge: '420430105', name: 'Concórdia' },
  { ibge: '420430115', name: 'Engenho Velho' },
  { ibge: '420430120', name: 'Planalto' },
  { ibge: '420430125', name: 'Presidente Kennedy' },
  { ibge: '420430135', name: 'Santo Antonio' },
  { ibge: '420430140', name: 'Tamanduá' },
  { ibge: '420435005', name: 'Cordilheira Alta' },
  { ibge: '420440005', name: 'Coronel Freitas' },
  { ibge: '420445905', name: 'Coronel Martins' },
  { ibge: '420450905', name: 'Corupá' },
  { ibge: '420455805', name: 'Correia Pinto' },
  { ibge: '420460805', name: 'Criciúma' },
  { ibge: '420460815', name: 'Rio Maina' },
  { ibge: '420470705', name: 'Cunha Porã' },
  { ibge: '420475605', name: 'Cunhataí' },
  { ibge: '420480605', name: 'Curitibanos' },
  { ibge: '420480613', name: 'Marombas Bossardi' },
  { ibge: '420480617', name: 'Santa Cruz do Pery' },
  { ibge: '420490505', name: 'Descanso' },
  { ibge: '420490515', name: 'Itajubá' },
  { ibge: '420500105', name: 'Dionísio Cerqueira' },
  { ibge: '420500107', name: 'Idamar' },
  { ibge: '420500109', name: 'Jorge Lacerda' },
  { ibge: '420500110', name: 'São Pedro Tobias' },
  { ibge: '420510005', name: 'Dona Emma' },
  { ibge: '420515905', name: 'Doutor Pedrinho' },
  { ibge: '420517505', name: 'Entre Rios' },
  { ibge: '420519105', name: 'Ermo' },
  { ibge: '420520905', name: 'Erval Velho' },
  { ibge: '420520910', name: 'Barra Fria' },
  { ibge: '420530805', name: 'Faxinal dos Guedes' },
  { ibge: '420530810', name: 'Barra Grande' },
  { ibge: '420535705', name: 'Flor do Sertão' },
  { ibge: '420540705', name: 'Florianópolis' },
  { ibge: '420540708', name: 'Barra da Lagoa' },
  { ibge: '420540710', name: 'Cachoeira do Bom Jesus' },
  { ibge: '420540712', name: 'Campeche' },
  { ibge: '420540715', name: 'Canasvieiras' },
  { ibge: '420540720', name: 'Ingleses do Rio Vermelho' },
  { ibge: '420540725', name: 'Lagoa da Conceição' },
  { ibge: '420540730', name: 'Pântano do Sul' },
  { ibge: '420540735', name: 'Ratones' },
  { ibge: '420540740', name: 'Ribeirão da Ilha' },
  { ibge: '420540745', name: 'Santo Antônio de Lisboa' },
  { ibge: '420540750', name: 'São João do Rio Vermelho' },
  { ibge: '420543105', name: 'Formosa do Sul' },
  { ibge: '420545605', name: 'Forquilhinha' },
  { ibge: '420550605', name: 'Fraiburgo' },
  { ibge: '420555505', name: 'Frei Rogério' },
  { ibge: '420555510', name: 'Nucleo Triticola' },
  { ibge: '420560505', name: 'Galvão' },
  { ibge: '420570405', name: 'Garopaba' },
  { ibge: '420580305', name: 'Garuva' },
  { ibge: '420590205', name: 'Gaspar' },
  { ibge: '420600905', name: 'Governador Celso Ramos' },
  { ibge: '420610805', name: 'Grão Pará' },
  { ibge: '420610810', name: 'Aiurê' },
  { ibge: '420610815', name: 'Invernada' },
  { ibge: '420620705', name: 'Gravatal' },
  { ibge: '420630605', name: 'Guabiruba' },
  { ibge: '420640505', name: 'Guaraciaba' },
  { ibge: '420640520', name: 'Ouro Verde' },
  { ibge: '420650405', name: 'Guaramirim' },
  { ibge: '420660305', name: 'Guarujá do Sul' },
  { ibge: '420665205', name: 'Guatambú' },
  { ibge: '420665210', name: 'Fazenda Zandavalli' },
  { ibge: '420670205', name: "Herval d'Oeste" },
  { ibge: '420675105', name: 'Ibiam' },
  { ibge: '420680105', name: 'Ibicaré' },
  { ibge: '420690005', name: 'Ibirama' },
  { ibge: '420690010', name: 'Dalbérgia' },
  { ibge: '420700705', name: 'Içara' },
  { ibge: '420710605', name: 'Ilhota' },
  { ibge: '420720505', name: 'Imaruí' },
  { ibge: '420720510', name: "Rio d'Una" },
  { ibge: '420730405', name: 'Imbituba' },
  { ibge: '420730410', name: 'Mirim' },
  { ibge: '420730415', name: 'Vila Nova' },
  { ibge: '420740305', name: 'Imbuia' },
  { ibge: '420750205', name: 'Indaial' },
  { ibge: '420757705', name: 'Iomerê' },
  { ibge: '420760105', name: 'Ipira' },
  { ibge: '420765005', name: 'Iporã do Oeste' },
  { ibge: '420768405', name: 'Ipuaçu' },
  { ibge: '420770005', name: 'Ipumirim' },
  { ibge: '420775905', name: 'Iraceminha' },
  { ibge: '420775920', name: 'São José do Laranjal' },
  { ibge: '420780905', name: 'Irani' },
  { ibge: '420785805', name: 'Irati' },
  { ibge: '420790805', name: 'Irineópolis' },
  { ibge: '420790810', name: 'Poço Preto' },
  { ibge: '420800505', name: 'Itá' },
  { ibge: '420810405', name: 'Itaiópolis' },
  { ibge: '420810410', name: 'Iraputã' },
  { ibge: '420810415', name: 'Itaió' },
  { ibge: '420810418', name: 'Moema' },
  { ibge: '420820305', name: 'Itajaí' },
  { ibge: '420830205', name: 'Itapema' },
  { ibge: '420840105', name: 'Itapiranga' },
  { ibge: '420845005', name: 'Itapoá' },
  { ibge: '420850005', name: 'Ituporanga' },
  { ibge: '420850010', name: 'Rio Bonito' },
  { ibge: '420860905', name: 'Jaborá' },
  { ibge: '420870805', name: 'Jacinto Machado' },
  { ibge: '420880705', name: 'Jaguaruna' },
  { ibge: '420890605', name: 'Jaraguá do Sul' },
  { ibge: '420895505', name: 'Jardinópolis' },
  { ibge: '420900305', name: 'Joaçaba' },
  { ibge: '420900315', name: 'Nova Petrópolis' },
  { ibge: '420900325', name: 'Santa Helena' },
  { ibge: '420910205', name: 'Joinville' },
  { ibge: '420910210', name: 'Pirabeiraba' },
  { ibge: '420915105', name: 'José Boiteux' },
  { ibge: '420917705', name: 'Jupiá' },
  { ibge: '420920105', name: 'Lacerdópolis' },
  { ibge: '420930005', name: 'Lages' },
  { ibge: '420930025', name: 'Índios' },
  { ibge: '420930045', name: 'Santa Teresinha do Salto' },
  { ibge: '420940905', name: 'Laguna' },
  { ibge: '420940915', name: 'Ribeirão Pequeno' },
  { ibge: '420945805', name: 'Lajeado Grande' },
  { ibge: '420950805', name: 'Laurentino' },
  { ibge: '420960705', name: 'Lauro Muller' },
  { ibge: '420960710', name: 'Barro Branco' },
  { ibge: '420960715', name: 'Guatá' },
  { ibge: '420970605', name: 'Lebon Régis' },
  { ibge: '420970610', name: 'São Sebastião do Sul' },
  { ibge: '420980505', name: 'Leoberto Leal' },
  { ibge: '420985405', name: 'Lindóia do Sul' },
  { ibge: '420990405', name: 'Lontras' },
  { ibge: '421000105', name: 'Luiz Alves' },
  { ibge: '421003505', name: 'Luzerna' },
  { ibge: '421005005', name: 'Macieira' },
  { ibge: '421010005', name: 'Mafra' },
  { ibge: '421010010', name: 'Bela Vista do Sul' },
  { ibge: '421010015', name: 'Rio Preto do Sul' },
  { ibge: '421020905', name: 'Major Gercino' },
  { ibge: '421020910', name: 'Boiteuxburgo' },
  { ibge: '421020915', name: 'Pinheiral' },
  { ibge: '421030805', name: 'Major Vieira' },
  { ibge: '421040705', name: 'Maracajá' },
  { ibge: '421050605', name: 'Maravilha' },
  { ibge: '421055505', name: 'Marema' },
  { ibge: '421060505', name: 'Massaranduba' },
  { ibge: '421070405', name: 'Matos Costa' },
  { ibge: '421080305', name: 'Meleiro' },
  { ibge: '421080315', name: 'Sapiranga' },
  { ibge: '421085205', name: 'Mirim Doce' },
  { ibge: '421090205', name: 'Modelo' },
  { ibge: '421100905', name: 'Mondaí' },
  { ibge: '421105805', name: 'Monte Carlo' },
  { ibge: '421110805', name: 'Monte Castelo' },
  { ibge: '421110820', name: 'Residência Fuck' },
  { ibge: '421120705', name: 'Morro da Fumaça' },
  { ibge: '421120710', name: 'Estação Cocal' },
  { ibge: '421125605', name: 'Morro Grande' },
  { ibge: '421130605', name: 'Navegantes' },
  { ibge: '421140505', name: 'Nova Erechim' },
  { ibge: '421145405', name: 'Nova Itaberaba' },
  { ibge: '421150405', name: 'Nova Trento' },
  { ibge: '421150410', name: 'Aguti' },
  { ibge: '421150415', name: 'Claraíba' },
  { ibge: '421160305', name: 'Nova Veneza' },
  { ibge: '421160307', name: 'Nossa Senhora do Caravagio' },
  { ibge: '421160310', name: 'São Bento Baixo' },
  { ibge: '421165205', name: 'Novo Horizonte' },
  { ibge: '421170205', name: 'Orleans' },
  { ibge: '421170210', name: 'Pindotiba' },
  { ibge: '421170215', name: 'Rio das Furnas' },
  { ibge: '421170220', name: 'Barracão' },
  { ibge: '421170225', name: 'Brusque do Sul' },
  { ibge: '421170230', name: 'Rio Pinheiros' },
  { ibge: '421175105', name: 'Otacílio Costa' },
  { ibge: '421180105', name: 'Ouro' },
  { ibge: '421180115', name: 'Santa Lúcia' },
  { ibge: '421185005', name: 'Ouro Verde' },
  { ibge: '421187605', name: 'Paial' },
  { ibge: '421189205', name: 'Painel' },
  { ibge: '421190005', name: 'Palhoça' },
  { ibge: '421190010', name: 'Enseada de Brito' },
  { ibge: '421200705', name: 'Palma Sola' },
  { ibge: '421205605', name: 'Palmeira' },
  { ibge: '421210605', name: 'Palmitos' },
  { ibge: '421210610', name: 'Diamantina' },
  { ibge: '421210620', name: 'Oldemburg' },
  { ibge: '421210625', name: 'Santa Lúcia' },
  { ibge: '421220505', name: 'Papanduva' },
  { ibge: '421220510', name: 'Nova Cultura' },
  { ibge: '421223905', name: 'Paraíso' },
  { ibge: '421223910', name: 'Grápia' },
  { ibge: '421225405', name: 'Passo de Torres' },
  { ibge: '421225410', name: 'Balneário Rosa do Mar' },
  { ibge: '421227005', name: 'Passos Maia' },
  { ibge: '421230405', name: 'Paulo Lopes' },
  { ibge: '421240305', name: 'Pedras Grandes' },
  { ibge: '421240310', name: 'Azambuja' },
  { ibge: '421250205', name: 'Penha' },
  { ibge: '421260105', name: 'Peritiba' },
  { ibge: '421265005', name: 'Pescaria Brava' },
  { ibge: '421270005', name: 'Petrolândia' },
  { ibge: '421270010', name: 'Rio Antinha' },
  { ibge: '421280905', name: 'Balneário Piçarras' },
  { ibge: '421290805', name: 'Pinhalzinho' },
  { ibge: '421290815', name: 'Machado' },
  { ibge: '421300505', name: 'Pinheiro Preto' },
  { ibge: '421310405', name: 'Piratuba' },
  { ibge: '421310410', name: 'Uruguai' },
  { ibge: '421315305', name: 'Planalto Alegre' },
  { ibge: '421320305', name: 'Pomerode' },
  { ibge: '421330205', name: 'Ponte Alta' },
  { ibge: '421335105', name: 'Ponte Alta do Norte' },
  { ibge: '421340105', name: 'Ponte Serrada' },
  { ibge: '421340108', name: 'Baía Alta' },
  { ibge: '421350005', name: 'Porto Belo' },
  { ibge: '421360905', name: 'Porto União' },
  { ibge: '421360910', name: 'Santa Cruz do Timbó' },
  { ibge: '421360920', name: 'São Miguel da Serra' },
  { ibge: '421370805', name: 'Pouso Redondo' },
  { ibge: '421370810', name: 'Aterrado Torto' },
  { ibge: '421380705', name: 'Praia Grande' },
  { ibge: '421380710', name: 'Cachoeira de Fátima' },
  { ibge: '421390605', name: 'Presidente Castello Branco' },
  { ibge: '421400305', name: 'Presidente Getúlio' },
  { ibge: '421400310', name: 'Mirador' },
  { ibge: '421410205', name: 'Presidente Nereu' },
  { ibge: '421415105', name: 'Princesa' },
  { ibge: '421420105', name: 'Quilombo' },
  { ibge: '421430005', name: 'Rancho Queimado' },
  { ibge: '421430010', name: 'Taquaras' },
  { ibge: '421440905', name: 'Rio das Antas' },
  { ibge: '421440910', name: 'Ipoméia' },
  { ibge: '421450805', name: 'Rio do Campo' },
  { ibge: '421460705', name: 'Rio do Oeste' },
  { ibge: '421470605', name: 'Rio dos Cedros' },
  { ibge: '421470610', name: 'Cedro Alto' },
  { ibge: '421480505', name: 'Rio do Sul' },
  { ibge: '421490405', name: 'Rio Fortuna' },
  { ibge: '421500005', name: 'Rio Negrinho' },
  { ibge: '421500030', name: 'Vila de Volta Grande' },
  { ibge: '421505905', name: 'Rio Rufino' },
  { ibge: '421507505', name: 'Riqueza' },
  { ibge: '421510905', name: 'Rodeio' },
  { ibge: '421520805', name: 'Romelândia' },
  { ibge: '421530705', name: 'Salete' },
  { ibge: '421535605', name: 'Saltinho' },
  { ibge: '421540605', name: 'Salto Veloso' },
  { ibge: '421545505', name: 'Sangão' },
  { ibge: '421545515', name: 'Morro Grande' },
  { ibge: '421550505', name: 'Santa Cecília' },
  { ibge: '421555405', name: 'Santa Helena' },
  { ibge: '421560405', name: 'Santa Rosa de Lima' },
  { ibge: '421565305', name: 'Santa Rosa do Sul' },
  { ibge: '421567905', name: 'Santa Terezinha' },
  { ibge: '421567915', name: 'Craveiro' },
  { ibge: '421567925', name: 'Rio da Anta' },
  { ibge: '421568705', name: 'Santa Terezinha do Progresso' },
  { ibge: '421569505', name: 'Santiago do Sul' },
  { ibge: '421570305', name: 'Santo Amaro da Imperatriz' },
  { ibge: '421575205', name: 'São Bernardino' },
  { ibge: '421580205', name: 'São Bento do Sul' },
  { ibge: '421590105', name: 'São Bonifácio' },
  { ibge: '421600805', name: 'São Carlos' },
  { ibge: '421605705', name: 'São Cristovão do Sul' },
  { ibge: '421610705', name: 'São Domingos' },
  { ibge: '421610715', name: 'Maratá' },
  { ibge: '421610730', name: 'Vila Milani' },
  { ibge: '421620605', name: 'São Francisco do Sul' },
  { ibge: '421620610', name: 'Saí' },
  { ibge: '421625505', name: 'São João do Oeste' },
  { ibge: '421630505', name: 'São João Batista' },
  { ibge: '421630510', name: 'Tijipió' },
  { ibge: '421635405', name: 'São João do Itaperiú' },
  { ibge: '421640405', name: 'São João do Sul' },
  { ibge: '421640415', name: 'Vila Conceição' },
  { ibge: '421640420', name: 'Vila Santa Catarina' },
  { ibge: '421650305', name: 'São Joaquim' },
  { ibge: '421650310', name: 'Pericó' },
  { ibge: '421650313', name: 'Santa Izabel' },
  { ibge: '421650315', name: 'São Sebastião do Arvoredo' },
  { ibge: '421660205', name: 'São José' },
  { ibge: '421660210', name: 'Barreiros' },
  { ibge: '421660213', name: 'Centro Histórico de São José' },
  { ibge: '421670105', name: 'São José do Cedro' },
  { ibge: '421670110', name: 'Mariflor' },
  { ibge: '421670112', name: 'Padre Reus' },
  { ibge: '421680005', name: 'São José do Cerrito' },
  { ibge: '421690905', name: 'São Lourenço do Oeste' },
  { ibge: '421690910', name: 'Frederico Wastner' },
  { ibge: '421690920', name: 'Presidente Juscelino' },
  { ibge: '421690929', name: 'São Roque' },
  { ibge: '421700605', name: 'São Ludgero' },
  { ibge: '421710505', name: 'São Martinho' },
  { ibge: '421710510', name: 'Vargem do Cedro' },
  { ibge: '421715405', name: 'São Miguel da Boa Vista' },
  { ibge: '421720405', name: 'São Miguel do Oeste' },
  { ibge: '421725305', name: 'São Pedro de Alcântara' },
  { ibge: '421730305', name: 'Saudades' },
  { ibge: '421740205', name: 'Schroeder' },
  { ibge: '421750105', name: 'Seara' },
  { ibge: '421750115', name: 'Caraíba' },
  { ibge: '421750120', name: 'Nova Teutônia' },
  { ibge: '421755005', name: 'Serra Alta' },
  { ibge: '421760005', name: 'Siderópolis' },
  { ibge: '421770905', name: 'Sombrio' },
  { ibge: '421770909', name: 'Boa Esperança' },
  { ibge: '421770915', name: 'Nova Guarita' },
  { ibge: '421775805', name: 'Sul Brasil' },
  { ibge: '421780805', name: 'Taió' },
  { ibge: '421780815', name: 'Passo Manso' },
  { ibge: '421790705', name: 'Tangará' },
  { ibge: '421790715', name: 'Irakitan' },
  { ibge: '421790720', name: 'Marari' },
  { ibge: '421795605', name: 'Tigrinhos' },
  { ibge: '421800405', name: 'Tijucas' },
  { ibge: '421810305', name: 'Timbé do Sul' },
  { ibge: '421820205', name: 'Timbó' },
  { ibge: '421825105', name: 'Timbó Grande' },
  { ibge: '421830105', name: 'Três Barras' },
  { ibge: '421830110', name: 'São Cristóvão' },
  { ibge: '421835005', name: 'Treviso' },
  { ibge: '421840005', name: 'Treze de Maio' },
  { ibge: '421840010', name: 'São Gabriel' },
  { ibge: '421850905', name: 'Treze Tílias' },
  { ibge: '421860805', name: 'Trombudo Central' },
  { ibge: '421870705', name: 'Tubarão' },
  { ibge: '421875605', name: 'Tunápolis' },
  { ibge: '421880605', name: 'Turvo' },
  { ibge: '421880620', name: 'Morro Chato' },
  { ibge: '421885505', name: 'União do Oeste' },
  { ibge: '421890505', name: 'Urubici' },
  { ibge: '421890510', name: 'Águas Brancas' },
  { ibge: '421890520', name: 'Santa Teresinha' },
  { ibge: '421895405', name: 'Urupema' },
  { ibge: '421900205', name: 'Urussanga' },
  { ibge: '421910105', name: 'Vargeão' },
  { ibge: '421915005', name: 'Vargem' },
  { ibge: '421917605', name: 'Vargem Bonita' },
  { ibge: '421920005', name: 'Vidal Ramos' },
  { ibge: '421930905', name: 'Videira' },
  { ibge: '421930910', name: 'Anta Gorda' },
  { ibge: '421930925', name: 'Lourdes' },
  { ibge: '421935805', name: 'Vitor Meireles' },
  { ibge: '421935810', name: 'Barra da Prata' },
  { ibge: '421940805', name: 'Witmarsum' },
  { ibge: '421950705', name: 'Xanxerê' },
  { ibge: '421950715', name: 'Cambuinzal' },
  { ibge: '421960605', name: 'Xavantina' },
  { ibge: '421960610', name: 'Linha das Palmeiras' },
  { ibge: '421970505', name: 'Xaxim' },
  { ibge: '421970511', name: 'Anita Garibaldi' },
  { ibge: '421970516', name: 'Diadema' },
  { ibge: '421985305', name: 'Zortéa' },
  { ibge: '422000005', name: 'Balneário Rincão' },
  { ibge: '430003405', name: 'Aceguá' },
  { ibge: '430003415', name: 'Colônia Nova' },
  { ibge: '430003425', name: 'Minuano' },
  { ibge: '430003435', name: 'Rio Negro' },
  { ibge: '430005905', name: 'Água Santa' },
  { ibge: '430005915', name: 'Engenho Grande' },
  { ibge: '430005925', name: 'Santo Antônio dos Pinheirinhos' },
  { ibge: '430005928', name: 'São Caetano' },
  { ibge: '430010905', name: 'Agudo' },
  { ibge: '430020805', name: 'Ajuricaba' },
  { ibge: '430020815', name: 'Medianeira' },
  { ibge: '430030705', name: 'Alecrim' },
  { ibge: '430040605', name: 'Alegrete' },
  { ibge: '430040610', name: 'Passo Novo' },
  { ibge: '430045505', name: 'Alegria' },
  { ibge: '430045510', name: 'Espírito Santo' },
  { ibge: '430047105', name: 'Almirante Tamandaré do Sul' },
  { ibge: '430047115', name: 'Linha Vitória' },
  { ibge: '430047130', name: 'Rincão do Segredo' },
  { ibge: '430050505', name: 'Alpestre' },
  { ibge: '430050510', name: 'Farinhas' },
  { ibge: '430050515', name: 'Sertãozinho' },
  { ibge: '430050520', name: 'Volta Grande' },
  { ibge: '430055405', name: 'Alto Alegre' },
  { ibge: '430055410', name: 'Linha Bonita' },
  { ibge: '430055420', name: 'Santa Lúcia' },
  { ibge: '430055425', name: 'São José' },
  { ibge: '430055430', name: 'Treze de Maio' },
  { ibge: '430057005', name: 'Alto Feliz' },
  { ibge: '430060405', name: 'Alvorada' },
  { ibge: '430060410', name: 'Estância Grande' },
  { ibge: '430063805', name: 'Amaral Ferrador' },
  { ibge: '430064605', name: 'Ametista do Sul' },
  { ibge: '430064625', name: 'São Valentin da Gruta' },
  { ibge: '430066105', name: 'André da Rocha' },
  { ibge: '430070305', name: 'Anta Gorda' },
  { ibge: '430070310', name: 'Itapuca' },
  { ibge: '430080205', name: 'Antônio Prado' },
  { ibge: '430080230', name: 'Santana' },
  { ibge: '430085105', name: 'Arambaré' },
  { ibge: '430085120', name: 'Santa Rita do Sul' },
  { ibge: '430087705', name: 'Araricá' },
  { ibge: '430090105', name: 'Aratiba' },
  { ibge: '430090115', name: 'Dourado' },
  { ibge: '430090118', name: 'Pio X' },
  { ibge: '430090120', name: 'Rio Azul' },
  { ibge: '430090125', name: 'Três Barras' },
  { ibge: '430090130', name: 'Volta Fechada' },
  { ibge: '430100805', name: 'Arroio do Meio' },
  { ibge: '430100816', name: 'Forqueta' },
  { ibge: '430100818', name: 'Palmas' },
  { ibge: '430100830', name: 'Vale do Arroio Grande' },
  { ibge: '430105705', name: 'Arroio do Sal' },
  { ibge: '430107305', name: 'Arroio do Padre' },
  { ibge: '430110705', name: 'Arroio dos Ratos' },
  { ibge: '430120605', name: 'Arroio do Tigre' },
  { ibge: '430120610', name: 'Coloninha' },
  { ibge: '430120618', name: 'Linha Ocidental' },
  { ibge: '430120625', name: 'Vila Progresso' },
  { ibge: '430120635', name: 'Sítio Alto' },
  { ibge: '430120640', name: 'Taboãozinho' },
  { ibge: '430120645', name: 'Taquaral' },
  { ibge: '430130505', name: 'Arroio Grande' },
  { ibge: '430130510', name: 'Mauá' },
  { ibge: '430130515', name: 'Pedreiras' },
  { ibge: '430130520', name: 'Santa Izabel do Sul' },
  { ibge: '430140405', name: 'Arvorezinha' },
  { ibge: '430140420', name: 'Pinhal Queimado' },
  { ibge: '430150305', name: 'Augusto Pestana' },
  { ibge: '430150310', name: 'Rosário' },
  { ibge: '430155205', name: 'Áurea' },
  { ibge: '430160205', name: 'Bagé' },
  { ibge: '430160217', name: 'Joca Tavares' },
  { ibge: '430160220', name: 'José Otávio' },
  { ibge: '430160221', name: 'Palmas' },
  { ibge: '430160222', name: 'Piraí' },
  { ibge: '430163605', name: 'Balneário Pinhal' },
  { ibge: '430163610', name: 'Magistério' },
  { ibge: '430163615', name: 'Túnel Verde' },
  { ibge: '430165105', name: 'Barão' },
  { ibge: '430165110', name: 'Arroio Canoas' },
  { ibge: '430165125', name: 'Francesa Alta' },
  { ibge: '430165127', name: 'Francesa Baixa' },
  { ibge: '430165130', name: 'General Neto' },
  { ibge: '430170105', name: 'Barão de Cotegipe' },
  { ibge: '430175005', name: 'Barão do Triunfo' },
  { ibge: '430180005', name: 'Barracão' },
  { ibge: '430180010', name: 'Espigão Alto' },
  { ibge: '430185905', name: 'Barra do Guarita' },
  { ibge: '430187505', name: 'Barra do Quaraí' },
  { ibge: '430187515', name: 'Francisco Borges' },
  { ibge: '430187518', name: 'Guterrez' },
  { ibge: '430187525', name: 'Passo da Cruz' },
  { ibge: '430190905', name: 'Barra do Ribeiro' },
  { ibge: '430190910', name: 'Douradilho' },
  { ibge: '430192505', name: 'Barra do Rio Azul' },
  { ibge: '430195805', name: 'Barra Funda' },
  { ibge: '430200605', name: 'Barros Cassal' },
  { ibge: '430205505', name: 'Benjamin Constant do Sul' },
  { ibge: '430210505', name: 'Bento Gonçalves' },
  { ibge: '430210507', name: 'São Pedro' },
  { ibge: '430210510', name: 'Faria Lemos' },
  { ibge: '430210530', name: 'Tuiutí' },
  { ibge: '430210540', name: 'Vale dos Vinhedos' },
  { ibge: '430215405', name: 'Boa Vista das Missões' },
  { ibge: '430215430', name: 'São João' },
  { ibge: '430220405', name: 'Boa Vista do Buricá' },
  { ibge: '430222005', name: 'Boa Vista do Cadeado' },
  { ibge: '430222015', name: 'Cadeado' },
  { ibge: '430222020', name: 'Capela' },
  { ibge: '430222025', name: 'Faxinal' },
  { ibge: '430222030', name: 'Formigueiro' },
  { ibge: '430222040', name: 'Ponte Queimada' },
  { ibge: '430222045', name: 'Rincão do Tigre' },
  { ibge: '430223805', name: 'Boa Vista do Incra' },
  { ibge: '430225305', name: 'Boa Vista do Sul' },
  { ibge: '430230305', name: 'Bom Jesus' },
  { ibge: '430230308', name: 'Capão do Tigre' },
  { ibge: '430230311', name: 'Capela São Francisco' },
  { ibge: '430230313', name: 'Casa Branca' },
  { ibge: '430230315', name: 'Itaimbezinho' },
  { ibge: '430230320', name: 'Santo Inácio' },
  { ibge: '430235205', name: 'Bom Princípio' },
  { ibge: '430237805', name: 'Bom Progresso' },
  { ibge: '430240205', name: 'Bom Retiro do Sul' },
  { ibge: '430240215', name: 'Pinhal' },
  { ibge: '430245105', name: 'Boqueirão do Leão' },
  { ibge: '430250105', name: 'Bossoroca' },
  { ibge: '430250107', name: 'Esquina Piratini' },
  { ibge: '430250110', name: 'Ivaí' },
  { ibge: '430250115', name: 'Timbaúva' },
  { ibge: '430258405', name: 'Bozano' },
  { ibge: '430258425', name: 'Salto' },
  { ibge: '430260005', name: 'Braga' },
  { ibge: '430260010', name: 'Pedro Gárcia' },
  { ibge: '430265905', name: 'Brochier' },
  { ibge: '430265920', name: 'Linha Pinheiro Machado' },
  { ibge: '430270905', name: 'Butiá' },
  { ibge: '430270910', name: 'Cerro do Roque' },
  { ibge: '430280805', name: 'Caçapava do Sul' },
  { ibge: '430280810', name: 'Bom Jardim' },
  { ibge: '430280815', name: 'Carajá Seival' },
  { ibge: '430280820', name: 'Cerro do Martins' },
  { ibge: '430280825', name: 'Forninho' },
  { ibge: '430280830', name: 'Santa Bárbara' },
  { ibge: '430290705', name: 'Cacequi' },
  { ibge: '430290710', name: 'Saicã' },
  { ibge: '430290715', name: 'Umbu' },
  { ibge: '430300405', name: 'Cachoeira do Sul' },
  { ibge: '430300410', name: 'Barro Vermelho' },
  { ibge: '430300412', name: 'Bosque' },
  { ibge: '430300415', name: 'Capané' },
  { ibge: '430300425', name: 'Cordilheira' },
  { ibge: '430300430', name: 'Ferreira' },
  { ibge: '430300445', name: 'Três Vendas' },
  { ibge: '430310305', name: 'Cachoeirinha' },
  { ibge: '430320205', name: 'Cacique Doble' },
  { ibge: '430320210', name: 'São Luís Rei' },
  { ibge: '430330105', name: 'Caibaté' },
  { ibge: '430340005', name: 'Caiçara' },
  { ibge: '430340010', name: 'Ipuaçu' },
  { ibge: '430350905', name: 'Camaquã' },
  { ibge: '430350912', name: 'Bandeirinha' },
  { ibge: '430350915', name: 'Banhado do Colégio' },
  { ibge: '430350920', name: 'Bonito' },
  { ibge: '430350922', name: 'Capela Santo Antônio' },
  { ibge: '430350925', name: 'Capela Velha' },
  { ibge: '430350935', name: 'Pacheca' },
  { ibge: '430350940', name: "Sant'Auta" },
  { ibge: '430355805', name: 'Camargo' },
  { ibge: '430360805', name: 'Cambará do Sul' },
  { ibge: '430360810', name: 'Bom Retiro' },
  { ibge: '430360815', name: 'Osvaldo Kroeff' },
  { ibge: '430367305', name: 'Campestre da Serra' },
  { ibge: '430370705', name: 'Campina das Missões' },
  { ibge: '430380605', name: 'Campinas do Sul' },
  { ibge: '430390505', name: 'Campo Bom' },
  { ibge: '430400205', name: 'Campo Novo' },
  { ibge: '430400215', name: 'Vila Turvo' },
  { ibge: '430410105', name: 'Campos Borges' },
  { ibge: '430410115', name: 'Linha Ferrari' },
  { ibge: '430410120', name: 'Mundo Novo' },
  { ibge: '430410125', name: 'Rincão dos Toledos' },
  { ibge: '430410130', name: 'São José dos Campos Borges' },
  { ibge: '430410135', name: 'Varame' },
  { ibge: '430410140', name: 'Volta Vitória' },
  { ibge: '430420005', name: 'Candelária' },
  { ibge: '430420010', name: 'Botucaraí' },
  { ibge: '430420015', name: 'Linha Brasil' },
  { ibge: '430420020', name: 'Linha do Rio' },
  { ibge: '430420025', name: 'Pinheiro' },
  { ibge: '430420030', name: 'Vila União' },
  { ibge: '430430905', name: 'Cândido Godói' },
  { ibge: '430435805', name: 'Candiota' },
  { ibge: '430435810', name: 'Baú' },
  { ibge: '430435820', name: 'Jaguarão Grande' },
  { ibge: '430435825', name: 'Passo Real de Candiota' },
  { ibge: '430435830', name: 'Seival' },
  { ibge: '430440805', name: 'Canela' },
  { ibge: '430450705', name: 'Canguçu' },
  { ibge: '430450710', name: 'Quarto Distrito' },
  { ibge: '430450715', name: 'Quinto Distrito' },
  { ibge: '430450720', name: 'Segundo Distrito' },
  { ibge: '430450725', name: 'Terceiro Distrito' },
  { ibge: '430460605', name: 'Canoas' },
  { ibge: '430461405', name: 'Canudos do Vale' },
  { ibge: '430462205', name: 'Capão Bonito do Sul' },
  { ibge: '430462210', name: 'Barretos' },
  { ibge: '430463005', name: 'Capão da Canoa' },
  { ibge: '430463006', name: 'Arroio Teixeira' },
  { ibge: '430463007', name: 'Capão Novo' },
  { ibge: '430463010', name: 'Curumim' },
  { ibge: '430465505', name: 'Capão do Cipó' },
  { ibge: '430466305', name: 'Capão do Leão' },
  { ibge: '430466310', name: 'Hidráulica' },
  { ibge: '430466315', name: 'Passo das Pedras' },
  { ibge: '430466320', name: 'Pavão' },
  { ibge: '430467105', name: 'Capivari do Sul' },
  { ibge: '430467110', name: 'Rancho Velho' },
  { ibge: '430467115', name: 'Santa Rosa' },
  { ibge: '430468905', name: 'Capela de Santana' },
  { ibge: '430469705', name: 'Capitão' },
  { ibge: '430470505', name: 'Carazinho' },
  { ibge: '430470525', name: 'Pinheiro Marcado' },
  { ibge: '430470533', name: 'São Bento' },
  { ibge: '430471305', name: 'Caraá' },
  { ibge: '430480405', name: 'Carlos Barbosa' },
  { ibge: '430480410', name: 'Arco Verde' },
  { ibge: '430480413', name: 'Cinco da Boa Vista' },
  { ibge: '430480420', name: 'Santa Luiza' },
  { ibge: '430480425', name: 'Santo Antônio de Castro' },
  { ibge: '430485305', name: 'Carlos Gomes' },
  { ibge: '430490305', name: 'Casca' },
  { ibge: '430490310', name: 'Evangelista' },
  { ibge: '430495205', name: 'Caseiros' },
  { ibge: '430500905', name: 'Catuípe' },
  { ibge: '430500910', name: 'Colônia das Almas' },
  { ibge: '430500912', name: 'Esquina Bom Sucesso' },
  { ibge: '430500913', name: 'Esquina Brasil Neves' },
  { ibge: '430500917', name: 'Passo Burmann' },
  { ibge: '430500918', name: 'Pontão Santo Antônio' },
  { ibge: '430500920', name: 'Santa Tereza' },
  { ibge: '430510805', name: 'Caxias do Sul' },
  { ibge: '430510815', name: 'Criúva' },
  { ibge: '430510820', name: 'Fazenda Souza' },
  { ibge: '430510835', name: 'Oliva' },
  { ibge: '430510840', name: 'Santa Lúcia do Piaí' },
  { ibge: '430510845', name: 'Seca' },
  { ibge: '430510850', name: 'Vila Cristina' },
  { ibge: '430511605', name: 'Centenário' },
  { ibge: '430512405', name: 'Cerrito' },
  { ibge: '430512410', name: 'Alto Alegre' },
  { ibge: '430512415', name: 'Vila Freire' },
  { ibge: '430513205', name: 'Cerro Branco' },
  { ibge: '430515705', name: 'Cerro Grande' },
  { ibge: '430517305', name: 'Cerro Grande do Sul' },
  { ibge: '430520705', name: 'Cerro Largo' },
  { ibge: '430520713', name: 'Santo Antônio' },
  { ibge: '430520714', name: 'São Francisco' },
  { ibge: '430530605', name: 'Chapada' },
  { ibge: '430530610', name: 'Boi Preto' },
  { ibge: '430530615', name: 'Santana' },
  { ibge: '430530620', name: 'São Miguel' },
  { ibge: '430530625', name: 'Tesouras' },
  { ibge: '430530630', name: 'Vila Rica' },
  { ibge: '430535505', name: 'Charqueadas' },
  { ibge: '430537105', name: 'Charrua' },
  { ibge: '430540505', name: 'Chiapetta' },
  { ibge: '430543905', name: 'Chuí' },
  { ibge: '430544705', name: 'Chuvisca' },
  { ibge: '430545405', name: 'Cidreira' },
  { ibge: '430545409', name: 'Costa do Sol' },
  { ibge: '430550405', name: 'Ciríaco' },
  { ibge: '430550407', name: 'Cruzaltinha' },
  { ibge: '430550415', name: 'São João Bosco' },
  { ibge: '430558705', name: 'Colinas' },
  { ibge: '430560305', name: 'Colorado' },
  { ibge: '430560310', name: 'Vista Alegre' },
  { ibge: '430570205', name: 'Condor' },
  { ibge: '430580105', name: 'Constantina' },
  { ibge: '430580106', name: 'Barra Curta Baixa' },
  { ibge: '430580107', name: 'Capinzal' },
  { ibge: '430580114', name: 'São Marcos Baixo' },
  { ibge: '430583505', name: 'Coqueiro Baixo' },
  { ibge: '430583510', name: 'Arroio da Laje' },
  { ibge: '430585005', name: 'Coqueiros do Sul' },
  { ibge: '430585015', name: 'Igrejinha' },
  { ibge: '430585020', name: 'Rio Bonito' },
  { ibge: '430585022', name: 'Serra do Pontão' },
  { ibge: '430585025', name: 'Xadrez' },
  { ibge: '430587105', name: 'Coronel Barros' },
  { ibge: '430590005', name: 'Coronel Bicaco' },
  { ibge: '430590010', name: 'Antônio Kerpel' },
  { ibge: '430590015', name: 'Campo Santo' },
  { ibge: '430590020', name: 'Turvinho' },
  { ibge: '430593405', name: 'Coronel Pilar' },
  { ibge: '430595905', name: 'Cotiporã' },
  { ibge: '430595910', name: 'Lageado Bonito' },
  { ibge: '430597505', name: 'Coxilha' },
  { ibge: '430600705', name: 'Crissiumal' },
  { ibge: '430600710', name: 'Esquina Gaúcha' },
  { ibge: '430600715', name: 'Lajeado Grande' },
  { ibge: '430600720', name: 'Planalto' },
  { ibge: '430600730', name: 'Vista Nova' },
  { ibge: '430605605', name: 'Cristal' },
  { ibge: '430605610', name: 'Bom Será' },
  { ibge: '430605615', name: 'Cordeiro' },
  { ibge: '430605625', name: 'Passo do Mendonça' },
  { ibge: '430607205', name: 'Cristal do Sul' },
  { ibge: '430610605', name: 'Cruz Alta' },
  { ibge: '430610613', name: 'Colônia São João' },
  { ibge: '430613005', name: 'Cruzaltense' },
  { ibge: '430620505', name: 'Cruzeiro do Sul' },
  { ibge: '430630405', name: 'David Canabarro' },
  { ibge: '430632005', name: 'Derrubadas' },
  { ibge: '430635305', name: 'Dezesseis de Novembro' },
  { ibge: '430637905', name: 'Dilermando de Aguiar' },
  { ibge: '430640305', name: 'Dois Irmãos' },
  { ibge: '430642905', name: 'Dois Irmãos das Missões' },
  { ibge: '430645205', name: 'Dois Lajeados' },
  { ibge: '430650205', name: 'Dom Feliciano' },
  { ibge: '430655105', name: 'Dom Pedro de Alcântara' },
  { ibge: '430655110', name: 'Arroio dos Mengues' },
  { ibge: '430655115', name: 'Morro dos Leffas' },
  { ibge: '430655120', name: 'Porto Colônia' },
  { ibge: '430660105', name: 'Dom Pedrito' },
  { ibge: '430660110', name: 'Torquato Severo' },
  { ibge: '430670005', name: 'Dona Francisca' },
  { ibge: '430673405', name: 'Doutor Maurício Cardoso' },
  { ibge: '430673415', name: 'Pitanga' },
  { ibge: '430673420', name: 'Pranchada' },
  { ibge: '430675905', name: 'Doutor Ricardo' },
  { ibge: '430676705', name: 'Eldorado do Sul' },
  { ibge: '430676710', name: 'Bom Retiro' },
  { ibge: '430676715', name: 'Parque Eldorado' },
  { ibge: '430680905', name: 'Encantado' },
  { ibge: '430680925', name: 'Valdástico' },
  { ibge: '430690805', name: 'Encruzilhada do Sul' },
  { ibge: '430690809', name: 'Capitão Noronha' },
  { ibge: '430690815', name: 'Cerro Partido' },
  { ibge: '430690820', name: 'Coronel Prestes' },
  { ibge: '430690825', name: 'Maria Santa' },
  { ibge: '430690830', name: 'Pompeu Machado' },
  { ibge: '430690835', name: 'Santa Bárbara' },
  { ibge: '430692405', name: 'Engenho Velho' },
  { ibge: '430693205', name: 'Entre-Ijuís' },
  { ibge: '430695705', name: 'Entre Rios do Sul' },
  { ibge: '430697305', name: 'Erebango' },
  { ibge: '430700505', name: 'Erechim' },
  { ibge: '430700510', name: 'Capo-Erê' },
  { ibge: '430700515', name: 'Jaguaretê' },
  { ibge: '430705405', name: 'Ernestina' },
  { ibge: '430710405', name: 'Herval' },
  { ibge: '430710410', name: 'Basílio' },
  { ibge: '430710415', name: 'Bote' },
  { ibge: '430710420', name: 'Cerro Chato' },
  { ibge: '430710425', name: 'Coxilha do Lageado' },
  { ibge: '430710430', name: 'Jaguarão Chico' },
  { ibge: '430710435', name: 'Mingote' },
  { ibge: '430720305', name: 'Erval Grande' },
  { ibge: '430720310', name: 'Goio-Ên' },
  { ibge: '430720315', name: 'Pinhalzinho' },
  { ibge: '430720320', name: 'Santana' },
  { ibge: '430720325', name: 'Sete de Setembro' },
  { ibge: '430730205', name: 'Erval Seco' },
  { ibge: '430730210', name: 'Arco Íris' },
  { ibge: '430730212', name: 'Capivara' },
  { ibge: '430730215', name: 'Coronel Finzito' },
  { ibge: '430730222', name: 'Fortaleza' },
  { ibge: '430730225', name: 'São Bom Jesus' },
  { ibge: '430730240', name: 'Vista Gaúcha' },
  { ibge: '430740105', name: 'Esmeralda' },
  { ibge: '430740110', name: 'São Sebastião' },
  { ibge: '430745005', name: 'Esperança do Sul' },
  { ibge: '430745007', name: 'Açoita Cavalo' },
  { ibge: '430745008', name: 'Bom Jardim' },
  { ibge: '430745010', name: 'Lara' },
  { ibge: '430745020', name: 'Ismael' },
  { ibge: '430750005', name: 'Espumoso' },
  { ibge: '430750015', name: 'Avelino Paranhos' },
  { ibge: '430750020', name: 'Campina Redonda' },
  { ibge: '430750030', name: 'Depósito' },
  { ibge: '430750050', name: 'Volta Alegre' },
  { ibge: '430755905', name: 'Estação' },
  { ibge: '430760905', name: 'Estância Velha' },
  { ibge: '430770805', name: 'Esteio' },
  { ibge: '430780705', name: 'Estrela' },
  { ibge: '430780723', name: 'Costão' },
  { ibge: '430780726', name: 'Novo Paraíso' },
  { ibge: '430780727', name: 'Delfina' },
  { ibge: '430780735', name: 'Glória' },
  { ibge: '430781505', name: 'Estrela Velha' },
  { ibge: '430781510', name: 'Itaúba' },
  { ibge: '430781515', name: 'Rincão da Estrela' },
  { ibge: '430781520', name: 'São Luiz' },
  { ibge: '430783105', name: 'Eugênio de Castro' },
  { ibge: '430786405', name: 'Fagundes Varela' },
  { ibge: '430790605', name: 'Farroupilha' },
  { ibge: '430790610', name: 'Jansen' },
  { ibge: '430790615', name: 'Nova Milano' },
  { ibge: '430790620', name: 'Nova Sardenha' },
  { ibge: '430800305', name: 'Faxinal do Soturno' },
  { ibge: '430805205', name: 'Faxinalzinho' },
  { ibge: '430807805', name: 'Fazenda Vilanova' },
  { ibge: '430810205', name: 'Feliz' },
  { ibge: '430820105', name: 'Flores da Cunha' },
  { ibge: '430820108', name: 'Mato Perso' },
  { ibge: '430820115', name: 'Otávio Rocha' },
  { ibge: '430825005', name: 'Floriano Peixoto' },
  { ibge: '430830005', name: 'Fontoura Xavier' },
  { ibge: '430830007', name: 'Campo Novo' },
  { ibge: '430830010', name: 'Gramado São Pedro' },
  { ibge: '430830020', name: 'Linha Silveira' },
  { ibge: '430830035', name: 'Três Pinheiros' },
  { ibge: '430840905', name: 'Formigueiro' },
  { ibge: '430843305', name: 'Forquetinha' },
  { ibge: '430845805', name: 'Fortaleza dos Valos' },
  { ibge: '430845815', name: 'Fazenda Colorado' },
  { ibge: '430850805', name: 'Frederico Westphalen' },
  { ibge: '430850810', name: 'Castelinho' },
  { ibge: '430850815', name: 'Osvaldo Cruz' },
  { ibge: '430860705', name: 'Garibaldi' },
  { ibge: '430860725', name: 'Marcorama' },
  { ibge: '430860728', name: 'São José de Costa Real' },
  { ibge: '430865605', name: 'Garruchos' },
  { ibge: '430870605', name: 'Gaurama' },
  { ibge: '430870615', name: 'Baliza' },
  { ibge: '430880505', name: 'General Câmara' },
  { ibge: '430880508', name: 'Boca da Picada' },
  { ibge: '430880510', name: 'Boqueirão' },
  { ibge: '430880525', name: 'Santo Amaro do Sul' },
  { ibge: '430885405', name: 'Gentil' },
  { ibge: '430885410', name: 'Campo do Meio' },
  { ibge: '430890405', name: 'Getúlio Vargas' },
  { ibge: '430890425', name: 'Rio Toldo' },
  { ibge: '430890430', name: 'Souza Ramos' },
  { ibge: '430900105', name: 'Giruá' },
  { ibge: '430900110', name: 'Cândido Freire' },
  { ibge: '430900120', name: 'Mato Grande' },
  { ibge: '430900122', name: 'Quinze de Novembro' },
  { ibge: '430900123', name: 'Rincão dos Mellos' },
  { ibge: '430900124', name: 'Rincão Maciel' },
  { ibge: '430900126', name: 'São Paulo das Tunas' },
  { ibge: '430905005', name: 'Glorinha' },
  { ibge: '430905010', name: 'Capão Grande' },
  { ibge: '430905020', name: 'Maracanã' },
  { ibge: '430905035', name: 'Vila Nova' },
  { ibge: '430910005', name: 'Gramado' },
  { ibge: '430912605', name: 'Gramado dos Loureiros' },
  { ibge: '430915905', name: 'Gramado Xavier' },
  { ibge: '430920905', name: 'Gravataí' },
  { ibge: '430920915', name: 'Barro Vermelho' },
  { ibge: '430920930', name: 'Ipiranga' },
  { ibge: '430920933', name: 'Itacolomi' },
  { ibge: '430920935', name: 'Morungava' },
  { ibge: '430925805', name: 'Guabiju' },
  { ibge: '430930805', name: 'Guaíba' },
  { ibge: '430940705', name: 'Guaporé' },
  { ibge: '430940707', name: 'Colombo' },
  { ibge: '430940735', name: 'Santo Antônio' },
  { ibge: '430950605', name: 'Guarani das Missões' },
  { ibge: '430950609', name: 'Linha Harmonia' },
  { ibge: '430950620', name: 'Linha Porto Alegre' },
  { ibge: '430955505', name: 'Harmonia' },
  { ibge: '430957105', name: 'Herveiras' },
  { ibge: '430960505', name: 'Horizontina' },
  { ibge: '430960510', name: 'Cascata' },
  { ibge: '430965405', name: 'Hulha Negra' },
  { ibge: '430970405', name: 'Humaitá' },
  { ibge: '430975305', name: 'Ibarama' },
  { ibge: '430980305', name: 'Ibiaçá' },
  { ibge: '430980310', name: 'Rio Telha' },
  { ibge: '430980315', name: 'Vitória' },
  { ibge: '430990205', name: 'Ibiraiaras' },
  { ibge: '430990207', name: 'Nossa Senhora da Saúde' },
  { ibge: '430990209', name: 'São Luiz' },
  { ibge: '430990210', name: 'São Sebastião' },
  { ibge: '430995105', name: 'Ibirapuitã' },
  { ibge: '431000905', name: 'Ibirubá' },
  { ibge: '431000910', name: 'Alfredo Brenner' },
  { ibge: '431000920', name: 'Santo Antônio do Bom Retiro' },
  { ibge: '431010805', name: 'Igrejinha' },
  { ibge: '431020705', name: 'Ijuí' },
  { ibge: '431020707', name: 'Alto da União' },
  { ibge: '431020708', name: 'Barreiro' },
  { ibge: '431020710', name: 'Chorão' },
  { ibge: '431020725', name: 'Floresta' },
  { ibge: '431020727', name: 'Itaí' },
  { ibge: '431020730', name: 'Mauá' },
  { ibge: '431020737', name: 'Santana' },
  { ibge: '431020740', name: 'Santo Antônio' },
  { ibge: '431030605', name: 'Ilópolis' },
  { ibge: '431033005', name: 'Imbé' },
  { ibge: '431036305', name: 'Imigrante' },
  { ibge: '431040505', name: 'Independência' },
  { ibge: '431040510', name: 'Colônia Medeiros' },
  { ibge: '431040515', name: 'Esquina Araújo' },
  { ibge: '431040520', name: 'São Valentim' },
  { ibge: '431041305', name: 'Inhacorá' },
  { ibge: '431043905', name: 'Ipê' },
  { ibge: '431043920', name: 'Vila São Paulo' },
  { ibge: '431043925', name: 'Vila Segrêdo' },
  { ibge: '431046205', name: 'Ipiranga do Sul' },
  { ibge: '431050405', name: 'Iraí' },
  { ibge: '431050430', name: 'Vila Salete' },
  { ibge: '431053805', name: 'Itaara' },
  { ibge: '431055305', name: 'Itacurubi' },
  { ibge: '431057905', name: 'Itapuca' },
  { ibge: '431060305', name: 'Itaqui' },
  { ibge: '431060312', name: 'Ibicuí' },
  { ibge: '431060330', name: 'Itaó' },
  { ibge: '431060340', name: 'Pintado' },
  { ibge: '431060345', name: 'Tapuraí' },
  { ibge: '431065205', name: 'Itati' },
  { ibge: '431070205', name: 'Itatiba do Sul' },
  { ibge: '431070210', name: 'Povoado Tozzo' },
  { ibge: '431070215', name: 'Saltinho' },
  { ibge: '431070220', name: 'Sete Lagoas' },
  { ibge: '431075105', name: 'Ivorá' },
  { ibge: '431080105', name: 'Ivoti' },
  { ibge: '431085005', name: 'Jaboticaba' },
  { ibge: '431085020', name: 'Trentin' },
  { ibge: '431087605', name: 'Jacuizinho' },
  { ibge: '431090005', name: 'Jacutinga' },
  { ibge: '431090012', name: 'Barão Hirstch' },
  { ibge: '431100705', name: 'Jaguarão' },
  { ibge: '431110605', name: 'Jaguari' },
  { ibge: '431110610', name: 'Ijucapirama' },
  { ibge: '431110617', name: 'Santo Izidro' },
  { ibge: '431110620', name: 'Taquarichim' },
  { ibge: '431112205', name: 'Jaquirana' },
  { ibge: '431112210', name: 'Chapada' },
  { ibge: '431113005', name: 'Jari' },
  { ibge: '431115505', name: 'Jóia' },
  { ibge: '431120505', name: 'Júlio de Castilhos' },
  { ibge: '431120525', name: 'São João dos Mellos' },
  { ibge: '431120530', name: 'Três Mártires' },
  { ibge: '431123905', name: 'Lagoa Bonita do Sul' },
  { ibge: '431125405', name: 'Lagoão' },
  { ibge: '431127005', name: 'Lagoa dos Três Cantos' },
  { ibge: '431127020', name: 'Linha Glória' },
  { ibge: '431130405', name: 'Lagoa Vermelha' },
  { ibge: '431130408', name: 'Boqueirão' },
  { ibge: '431130411', name: 'Campinas' },
  { ibge: '431130414', name: 'Capão do Cedro' },
  { ibge: '431130425', name: 'Clemente Argolo' },
  { ibge: '431130427', name: 'Nossa Senhora de Fátima' },
  { ibge: '431130430', name: 'Santa Luzia' },
  { ibge: '431130435', name: 'Tupinambá' },
  { ibge: '431140305', name: 'Lajeado' },
  { ibge: '431142905', name: 'Lajeado do Bugre' },
  { ibge: '431150205', name: 'Lavras do Sul' },
  { ibge: '431150210', name: 'Ibaré' },
  { ibge: '431160105', name: 'Liberato Salzano' },
  { ibge: '431160110', name: 'Pinhalzinho' },
  { ibge: '431162705', name: 'Lindolfo Collor' },
  { ibge: '431164305', name: 'Linha Nova' },
  { ibge: '431170005', name: 'Machadinho' },
  { ibge: '431170010', name: 'Bela Vista' },
  { ibge: '431171805', name: 'Maçambará' },
  { ibge: '431171810', name: 'Bororé' },
  { ibge: '431171815', name: 'Encruzilhada' },
  { ibge: '431171820', name: 'Passo do Goulart' },
  { ibge: '431173405', name: 'Mampituba' },
  { ibge: '431175905', name: 'Manoel Viana' },
  { ibge: '431175910', name: 'Barragem do Itú' },
  { ibge: '431175920', name: 'Pirajú' },
  { ibge: '431177505', name: 'Maquiné' },
  { ibge: '431177510', name: 'Barra do Ouro' },
  { ibge: '431177515', name: 'Morro Alto' },
  { ibge: '431179105', name: 'Maratá' },
  { ibge: '431179115', name: 'Esperança' },
  { ibge: '431179120', name: 'Macega' },
  { ibge: '431179125', name: 'São Pedro do Maratá' },
  { ibge: '431180905', name: 'Marau' },
  { ibge: '431180909', name: 'Laranjeira' },
  { ibge: '431180917', name: 'Veado Pardo' },
  { ibge: '431180935', name: 'São Miguel' },
  { ibge: '431190805', name: 'Marcelino Ramos' },
  { ibge: '431190810', name: 'Coronel Teixeira' },
  { ibge: '431190820', name: 'Pinhalzinho' },
  { ibge: '431198105', name: 'Mariana Pimentel' },
  { ibge: '431200505', name: 'Mariano Moro' },
  { ibge: '431205405', name: 'Marques de Souza' },
  { ibge: '431205410', name: 'Bela Vista do Fão' },
  { ibge: '431205415', name: 'Tamanduá' },
  { ibge: '431210405', name: 'Mata' },
  { ibge: '431210410', name: 'Clara' },
  { ibge: '431213805', name: 'Mato Castelhano' },
  { ibge: '431215305', name: 'Mato Leitão' },
  { ibge: '431215315', name: 'Arroio Bonito' },
  { ibge: '431215340', name: 'Sampaio' },
  { ibge: '431215345', name: 'Santo Antônio' },
  { ibge: '431217905', name: 'Mato Queimado' },
  { ibge: '431220305', name: 'Maximiliano de Almeida' },
  { ibge: '431225205', name: 'Minas do Leão' },
  { ibge: '431230205', name: 'Miraguaí' },
  { ibge: '431230210', name: 'Sítio Gabriel' },
  { ibge: '431230215', name: 'Tronqueiras' },
  { ibge: '431235105', name: 'Montauri' },
  { ibge: '431237705', name: 'Monte Alegre dos Campos' },
  { ibge: '431237710', name: 'Capela da Luz' },
  { ibge: '431237715', name: 'Carmo' },
  { ibge: '431237720', name: 'Enxovia' },
  { ibge: '431237725', name: 'Passo do Carro' },
  { ibge: '431237730', name: 'Ranchinho' },
  { ibge: '431237735', name: 'Santo Antonio' },
  { ibge: '431237738', name: 'São Francisco' },
  { ibge: '431237740', name: 'São José' },
  { ibge: '431237745', name: 'São Judas' },
  { ibge: '431237750', name: 'Saúde' },
  { ibge: '431238505', name: 'Monte Belo do Sul' },
  { ibge: '431240105', name: 'Montenegro' },
  { ibge: '431240113', name: 'Costa da Serra' },
  { ibge: '431240118', name: 'Fortaleza' },
  { ibge: '431240127', name: 'Pesqueiro' },
  { ibge: '431240135', name: 'Santos Reis' },
  { ibge: '431240140', name: 'Vendinha' },
  { ibge: '431242705', name: 'Mormaço' },
  { ibge: '431244305', name: 'Morrinhos do Sul' },
  { ibge: '431244310', name: 'Costão' },
  { ibge: '431244315', name: 'Morro de Dentro' },
  { ibge: '431244320', name: 'Morro do Forno' },
  { ibge: '431245005', name: 'Morro Redondo' },
  { ibge: '431247605', name: 'Morro Reuter' },
  { ibge: '431250005', name: 'Mostardas' },
  { ibge: '431250010', name: 'Doutor Edgardo Pereira Velho' },
  { ibge: '431250013', name: 'Rincão do Cristóvão Pereira' },
  { ibge: '431250015', name: 'São Simão' },
  { ibge: '431260905', name: 'Muçum' },
  { ibge: '431261705', name: 'Muitos Capões' },
  { ibge: '431261710', name: 'Bom Retiro' },
  { ibge: '431261715', name: 'Capão Grande' },
  { ibge: '431261720', name: 'Encruzilhada São Sebastião' },
  { ibge: '431261725', name: 'Fazenda Laranjeiras' },
  { ibge: '431261735', name: 'Morro Grande' },
  { ibge: '431261745', name: 'Santa Rita' },
  { ibge: '431261750', name: 'Várzea dos Antunes' },
  { ibge: '431261755', name: 'Vila Ituim' },
  { ibge: '431262505', name: 'Muliterno' },
  { ibge: '431265805', name: 'Não-Me-Toque' },
  { ibge: '431265815', name: 'São José do Centro' },
  { ibge: '431267405', name: 'Nicolau Vergueiro' },
  { ibge: '431270805', name: 'Nonoai' },
  { ibge: '431275705', name: 'Nova Alvorada' },
  { ibge: '431275715', name: 'General Cadorna' },
  { ibge: '431280705', name: 'Nova Araçá' },
  { ibge: '431290605', name: 'Nova Bassano' },
  { ibge: '431295505', name: 'Nova Boa Vista' },
  { ibge: '431300305', name: 'Nova Bréscia' },
  { ibge: '431301105', name: 'Nova Candelária' },
  { ibge: '431303705', name: 'Nova Esperança do Sul' },
  { ibge: '431306005', name: 'Nova Hartz' },
  { ibge: '431308605', name: 'Nova Pádua' },
  { ibge: '431310205', name: 'Nova Palma' },
  { ibge: '431310210', name: 'Canhemborá' },
  { ibge: '431310215', name: 'Vila Cruz' },
  { ibge: '431320105', name: 'Nova Petrópolis' },
  { ibge: '431320107', name: 'Dos Boêmios' },
  { ibge: '431320115', name: 'Pinhal Alto' },
  { ibge: '431320120', name: 'Vale do Rio Caí' },
  { ibge: '431330005', name: 'Nova Prata' },
  { ibge: '431333405', name: 'Nova Ramada' },
  { ibge: '431335905', name: 'Nova Roma do Sul' },
  { ibge: '431337505', name: 'Nova Santa Rita' },
  { ibge: '431339105', name: 'Novo Cabrais' },
  { ibge: '431340905', name: 'Novo Hamburgo' },
  { ibge: '431342505', name: 'Novo Machado' },
  { ibge: '431342515', name: 'Pratos' },
  { ibge: '431344105', name: 'Novo Tiradentes' },
  { ibge: '431346605', name: 'Novo Xingu' },
  { ibge: '431349005', name: 'Novo Barreiro' },
  { ibge: '431350805', name: 'Osório' },
  { ibge: '431350811', name: 'Aguapés' },
  { ibge: '431350827', name: 'Atlântida-Sul' },
  { ibge: '431350828', name: 'Borussia' },
  { ibge: '431350835', name: 'Passinhos' },
  { ibge: '431350845', name: 'Santa Luzia' },
  { ibge: '431360705', name: 'Paim Filho' },
  { ibge: '431365605', name: 'Palmares do Sul' },
  { ibge: '431365615', name: 'Bacupari' },
  { ibge: '431365616', name: 'Butiatuva' },
  { ibge: '431365617', name: 'Casa Velha' },
  { ibge: '431365620', name: 'Granja Getúlio Vargas' },
  { ibge: '431365625', name: 'Frei Sebastião' },
  { ibge: '431365630', name: 'Quintão' },
  { ibge: '431370605', name: 'Palmeira das Missões' },
  { ibge: '431370617', name: 'Quebrado' },
  { ibge: '431370642', name: 'Santa Rosa' },
  { ibge: '431370645', name: 'Santa Teresinha' },
  { ibge: '431370652', name: 'São Bento' },
  { ibge: '431380505', name: 'Palmitinho' },
  { ibge: '431390405', name: 'Panambi' },
  { ibge: '431395305', name: 'Pantano Grande' },
  { ibge: '431395310', name: 'Aroeiras' },
  { ibge: '431395320', name: 'Monte Castelo' },
  { ibge: '431395330', name: 'Várzea' },
  { ibge: '431400105', name: 'Paraí' },
  { ibge: '431402705', name: 'Paraíso do Sul' },
  { ibge: '431403505', name: 'Pareci Novo' },
  { ibge: '431405005', name: 'Parobé' },
  { ibge: '431405012', name: 'Poço Fundo' },
  { ibge: '431405015', name: 'Santa Cristina' },
  { ibge: '431406805', name: 'Passa Sete' },
  { ibge: '431406810', name: 'Campo de Sobradinho' },
  { ibge: '431406820', name: 'Murta' },
  { ibge: '431406830', name: 'Pitingal' },
  { ibge: '431407605', name: 'Passo do Sobrado' },
  { ibge: '431410005', name: 'Passo Fundo' },
  { ibge: '431410010', name: 'Bela Vista' },
  { ibge: '431410017', name: 'Bom Recreio' },
  { ibge: '431410035', name: 'Pulador' },
  { ibge: '431410040', name: 'São Roque' },
  { ibge: '431410045', name: 'Sede Independência' },
  { ibge: '431413405', name: 'Paulo Bento' },
  { ibge: '431415905', name: 'Paverama' },
  { ibge: '431415915', name: 'Fazenda São José' },
  { ibge: '431415920', name: 'Bom Jardim' },
  { ibge: '431417505', name: 'Pedras Altas' },
  { ibge: '431417510', name: 'Arroio Mau' },
  { ibge: '431417515', name: 'Cerro do Baú' },
  { ibge: '431417525', name: 'São Diogo' },
  { ibge: '431420905', name: 'Pedro Osório' },
  { ibge: '431420920', name: 'Matarazzo' },
  { ibge: '431430805', name: 'Pejuçara' },
  { ibge: '431440705', name: 'Pelotas' },
  { ibge: '431440720', name: 'Cascata' },
  { ibge: '431440725', name: 'Cerrito Alegre' },
  { ibge: '431440727', name: 'Z/3' },
  { ibge: '431440735', name: 'Monte Bonito' },
  { ibge: '431440742', name: 'Rincão da Cruz' },
  { ibge: '431440745', name: 'Quilombo' },
  { ibge: '431440750', name: 'Santa Silvana' },
  { ibge: '431440760', name: 'Triunfo' },
  { ibge: '431442305', name: 'Picada Café' },
  { ibge: '431445605', name: 'Pinhal' },
  { ibge: '431446405', name: 'Pinhal da Serra' },
  { ibge: '431447205', name: 'Pinhal Grande' },
  { ibge: '431447220', name: 'Rincão do Appel' },
  { ibge: '431449805', name: 'Pinheirinho do Vale' },
  { ibge: '431450605', name: 'Pinheiro Machado' },
  { ibge: '431450610', name: 'Candiota' },
  { ibge: '431450620', name: 'Torrinhas' },
  { ibge: '431454805', name: 'Pinto Bandeira' },
  { ibge: '431455505', name: 'Pirapó' },
  { ibge: '431455510', name: 'Figueira' },
  { ibge: '431460505', name: 'Piratini' },
  { ibge: '431460510', name: 'Segundo Distrito' },
  { ibge: '431460515', name: 'Terceiro Distrito' },
  { ibge: '431460520', name: 'Quarto Distrito' },
  { ibge: '431460525', name: 'Quinto Distrito' },
  { ibge: '431470405', name: 'Planalto' },
  { ibge: '431470412', name: 'São Luiz' },
  { ibge: '431470415', name: 'Santa Cruz' },
  { ibge: '431470420', name: 'São José' },
  { ibge: '431475305', name: 'Poço das Antas' },
  { ibge: '431477905', name: 'Pontão' },
  { ibge: '431477910', name: 'Sagrisa' },
  { ibge: '431478705', name: 'Ponte Preta' },
  { ibge: '431478730', name: 'Souto Neto' },
  { ibge: '431480305', name: 'Portão' },
  { ibge: '431490205', name: 'Porto Alegre' },
  { ibge: '431500805', name: 'Porto Lucena' },
  { ibge: '431505705', name: 'Porto Mauá' },
  { ibge: '431507305', name: 'Porto Vera Cruz' },
  { ibge: '431510705', name: 'Porto Xavier' },
  { ibge: '431510720', name: 'Rincão Comprido' },
  { ibge: '431513105', name: 'Pouso Novo' },
  { ibge: '431514905', name: 'Presidente Lucena' },
  { ibge: '431515605', name: 'Progresso' },
  { ibge: '431515607', name: 'Batovira' },
  { ibge: '431515610', name: 'Campo Branco' },
  { ibge: '431515612', name: 'Constantino' },
  { ibge: '431515620', name: 'Xaxim' },
  { ibge: '431517205', name: 'Protásio Alves' },
  { ibge: '431520605', name: 'Putinga' },
  { ibge: '431520615', name: 'Xarqueada' },
  { ibge: '431530505', name: 'Quaraí' },
  { ibge: '431531305', name: 'Quatro Irmãos' },
  { ibge: '431532105', name: 'Quevedos' },
  { ibge: '431535405', name: 'Quinze de Novembro' },
  { ibge: '431535420', name: 'Santa Clara do Ingaí' },
  { ibge: '431535425', name: 'Sede Aurora' },
  { ibge: '431540405', name: 'Redentora' },
  { ibge: '431540410', name: 'São João' },
  { ibge: '431540430', name: 'Sítio Cassemiro' },
  { ibge: '431545305', name: 'Relvado' },
  { ibge: '431550305', name: 'Restinga Seca' },
  { ibge: '431555205', name: 'Rio dos Índios' },
  { ibge: '431555210', name: 'Posse dos Linhares' },
  { ibge: '431560205', name: 'Rio Grande' },
  { ibge: '431560215', name: 'Povo Novo' },
  { ibge: '431560220', name: 'Quinta' },
  { ibge: '431560225', name: 'Taim' },
  { ibge: '431560230', name: 'Ilha dos Marinheiros' },
  { ibge: '431570105', name: 'Rio Pardo' },
  { ibge: '431570110', name: 'Albardão' },
  { ibge: '431570115', name: 'Bexiga' },
  { ibge: '431570122', name: 'Iruí' },
  { ibge: '431570123', name: 'João Rodrigues' },
  { ibge: '431570124', name: 'Passo da Areia' },
  { ibge: '431570127', name: 'Passo do Adão' },
  { ibge: '431570130', name: 'Rincão del Rei' },
  { ibge: '431575005', name: 'Riozinho' },
  { ibge: '431580005', name: 'Roca Sales' },
  { ibge: '431580010', name: 'Campinho' },
  { ibge: '431590905', name: 'Rodeio Bonito' },
  { ibge: '431590910', name: 'Saltinho' },
  { ibge: '431595805', name: 'Rolador' },
  { ibge: '431595810', name: 'Igrejinha' },
  { ibge: '431595825', name: 'Passo do Faxinal' },
  { ibge: '431595830', name: 'Passo do Quaresma' },
  { ibge: '431595840', name: 'Serrinha do Rosário' },
  { ibge: '431600605', name: 'Rolante' },
  { ibge: '431600610', name: 'Boa Esperança' },
  { ibge: '431600617', name: 'Rolantinho' },
  { ibge: '431610505', name: 'Ronda Alta' },
  { ibge: '431610510', name: 'Alto Recreio' },
  { ibge: '431620405', name: 'Rondinha' },
  { ibge: '431630305', name: 'Roque Gonzales' },
  { ibge: '431630310', name: 'Dona Otília' },
  { ibge: '431630315', name: 'Rincão Vermelho' },
  { ibge: '431640205', name: 'Rosário do Sul' },
  { ibge: '431640210', name: 'Campo Sêco' },
  { ibge: '431640215', name: 'Caverá' },
  { ibge: '431640220', name: 'Mangueiras' },
  { ibge: '431640225', name: 'São Carlos' },
  { ibge: '431640230', name: 'Touro Passo' },
  { ibge: '431642805', name: 'Sagrada Família' },
  { ibge: '431642815', name: 'Leonel Rocha' },
  { ibge: '431643605', name: 'Saldanha Marinho' },
  { ibge: '431643610', name: 'Campinas' },
  { ibge: '431645105', name: 'Salto do Jacuí' },
  { ibge: '431645107', name: 'Capão Bonito' },
  { ibge: '431645115', name: 'Tabajara' },
  { ibge: '431645121', name: 'Júlio Borges' },
  { ibge: '431645123', name: 'Passo Real' },
  { ibge: '431645124', name: 'Rincão do Ivaí' },
  { ibge: '431647705', name: 'Salvador das Missões' },
  { ibge: '431647720', name: 'Santa Catarina' },
  { ibge: '431650105', name: 'Salvador do Sul' },
  { ibge: '431650109', name: 'Campestre' },
  { ibge: '431650120', name: 'Linha Comprida' },
  { ibge: '431650125', name: 'Linha São João' },
  { ibge: '431660005', name: 'Sananduva' },
  { ibge: '431660010', name: 'Rio Tigre' },
  { ibge: '431670905', name: 'Santa Bárbara do Sul' },
  { ibge: '431670918', name: 'Capão Alto' },
  { ibge: '431670925', name: 'Itaíba' },
  { ibge: '431673305', name: 'Santa Cecília do Sul' },
  { ibge: '431675805', name: 'Santa Clara do Sul' },
  { ibge: '431680805', name: 'Santa Cruz do Sul' },
  { ibge: '431680807', name: 'Alto Paredão' },
  { ibge: '431680810', name: 'Boa Vista' },
  { ibge: '431680830', name: 'Monte Alverne' },
  { ibge: '431680835', name: 'Rio Pardinho' },
  { ibge: '431680838', name: 'Saraiva' },
  { ibge: '431680841', name: 'São José da Reserva' },
  { ibge: '431680842', name: 'São Martinho' },
  { ibge: '431690705', name: 'Santa Maria' },
  { ibge: '431690710', name: 'Arroio do Sol' },
  { ibge: '431690712', name: 'Arroio Grande' },
  { ibge: '431690715', name: 'Boca do Monte' },
  { ibge: '431690720', name: 'Pains' },
  { ibge: '431690723', name: 'Palma' },
  { ibge: '431690733', name: 'Passo do Verde' },
  { ibge: '431690735', name: 'Santa Flora' },
  { ibge: '431690738', name: 'Santo Antão' },
  { ibge: '431690750', name: 'São Valentim' },
  { ibge: '431695605', name: 'Santa Maria do Herval' },
  { ibge: '431697205', name: 'Santa Margarida do Sul' },
  { ibge: '431697210', name: 'Bolso' },
  { ibge: '431697215', name: 'Cambaizinho' },
  { ibge: '431697220', name: 'Canas' },
  { ibge: '431697230', name: 'Serrinha' },
  { ibge: '431700405', name: 'Santana da Boa Vista' },
  { ibge: '431710305', name: "Sant'Ana do Livramento" },
  { ibge: '431710306', name: 'Cati' },
  { ibge: '431710307', name: 'Espinilho' },
  { ibge: '431710308', name: 'Ibicuí' },
  { ibge: '431710310', name: 'Pampeiro' },
  { ibge: '431710320', name: 'São Diogo' },
  { ibge: '431710325', name: 'Upamaroti' },
  { ibge: '431720205', name: 'Santa Rosa' },
  { ibge: '431720210', name: 'Sete de Setembro' },
  { ibge: '431725105', name: 'Santa Tereza' },
  { ibge: '431730105', name: 'Santa Vitória do Palmar' },
  { ibge: '431730107', name: 'Arvore Só' },
  { ibge: '431730109', name: 'Atlântico' },
  { ibge: '431730115', name: 'Curral Alto' },
  { ibge: '431730120', name: 'Mirim' },
  { ibge: '431730130', name: 'Taim' },
  { ibge: '431740005', name: 'Santiago' },
  { ibge: '431740015', name: 'Ernesto Alves' },
  { ibge: '431740020', name: 'Florida' },
  { ibge: '431740030', name: 'Tupantuba' },
  { ibge: '431750905', name: 'Santo Ângelo' },
  { ibge: '431750907', name: 'Atafona' },
  { ibge: '431750910', name: 'Buriti' },
  { ibge: '431750920', name: 'Colônia Municipal' },
  { ibge: '431750925', name: 'Comandaí' },
  { ibge: '431750933', name: 'Cristo Rei' },
  { ibge: '431750941', name: 'Lajeado Cerne' },
  { ibge: '431750942', name: 'Restinga Seca' },
  { ibge: '431750943', name: 'Rincão dos Mendes' },
  { ibge: '431750946', name: 'Rincão dos Meotti' },
  { ibge: '431750947', name: 'Rincão dos Roratos' },
  { ibge: '431750948', name: 'Ressaca Buriti' },
  { ibge: '431750955', name: 'Sossego' },
  { ibge: '431750960', name: 'União' },
  { ibge: '431750970', name: 'Lajeado Micuim' },
  { ibge: '431755805', name: 'Santo Antônio do Palma' },
  { ibge: '431755820', name: 'Montes Carpatos' },
  { ibge: '431755825', name: 'Santa Terezinha' },
  { ibge: '431755830', name: 'São José' },
  { ibge: '431760805', name: 'Santo Antônio da Patrulha' },
  { ibge: '431760811', name: 'Catanduva Grande' },
  { ibge: '431760812', name: 'Chicolomã' },
  { ibge: '431760816', name: 'Evaristo' },
  { ibge: '431760820', name: 'Miraguaia' },
  { ibge: '431760830', name: 'Pinheirinhos' },
  { ibge: '431770705', name: 'Santo Antônio das Missões' },
  { ibge: '431770710', name: 'São José' },
  { ibge: '431775605', name: 'Santo Antônio do Planalto' },
  { ibge: '431775620', name: 'Rincão Doce' },
  { ibge: '431780605', name: 'Santo Augusto' },
  { ibge: '431780609', name: 'Nossa Senhora de Fátima' },
  { ibge: '431780610', name: 'Pedro Paiva' },
  { ibge: '431780613', name: 'Rincão dos Paivas' },
  { ibge: '431780615', name: 'Santo Antônio' },
  { ibge: '431790505', name: 'Santo Cristo' },
  { ibge: '431790510', name: 'Sírio' },
  { ibge: '431790512', name: 'Vila Bom Princípio Baixo' },
  { ibge: '431790515', name: 'Vila Laranjeira' },
  { ibge: '431795405', name: 'Santo Expedito do Sul' },
  { ibge: '431800205', name: 'São Borja' },
  { ibge: '431800225', name: 'Nhú-Porã' },
  { ibge: '431800235', name: 'Samburá' },
  { ibge: '431800240', name: 'Sarandi' },
  { ibge: '431805105', name: 'São Domingos do Sul' },
  { ibge: '431805115', name: 'Santa Gema' },
  { ibge: '431810105', name: 'São Francisco de Assis' },
  { ibge: '431810110', name: 'Beluno' },
  { ibge: '431810118', name: 'Boa Vista' },
  { ibge: '431810120', name: 'Toroquá' },
  { ibge: '431820005', name: 'São Francisco de Paula' },
  { ibge: '431820010', name: 'Cazuza Ferreira' },
  { ibge: '431820020', name: 'Eletra' },
  { ibge: '431820030', name: 'Juá' },
  { ibge: '431820035', name: 'Lajeado Grande' },
  { ibge: '431820040', name: 'Rincão dos Kroeff' },
  { ibge: '431820045', name: 'Tainhas' },
  { ibge: '431830905', name: 'São Gabriel' },
  { ibge: '431830910', name: 'Azevedo Sodré' },
  { ibge: '431830911', name: 'Batovi' },
  { ibge: '431830912', name: 'Catuçaba' },
  { ibge: '431830913', name: 'Cerro do Ouro' },
  { ibge: '431830915', name: 'Suspiro' },
  { ibge: '431830920', name: 'Tiaraju' },
  { ibge: '431830925', name: 'Vacacaí' },
  { ibge: '431840805', name: 'São Jerônimo' },
  { ibge: '431840817', name: 'Gramal' },
  { ibge: '431840820', name: 'Morrinhos' },
  { ibge: '431840825', name: 'Quitéria' },
  { ibge: '431842405', name: 'São João da Urtiga' },
  { ibge: '431843205', name: 'São João do Polêsine' },
  { ibge: '431843225', name: 'Recanto Maestro' },
  { ibge: '431843230', name: 'Vale Vêneto' },
  { ibge: '431844005', name: 'São Jorge' },
  { ibge: '431845705', name: 'São José das Missões' },
  { ibge: '431846505', name: 'São José do Herval' },
  { ibge: '431848105', name: 'São José do Hortêncio' },
  { ibge: '431849905', name: 'São José do Inhacorá' },
  { ibge: '431850705', name: 'São José do Norte' },
  { ibge: '431850710', name: 'Bojuru' },
  { ibge: '431850715', name: 'Estreito' },
  { ibge: '431860605', name: 'São José do Ouro' },
  { ibge: '431860609', name: 'Brugnarotto' },
  { ibge: '431860611', name: 'Cerro Azul' },
  { ibge: '431860614', name: 'Jardim Alegre' },
  { ibge: '431860617', name: 'Marmeleiro' },
  { ibge: '431860619', name: 'São Miguel' },
  { ibge: '431860620', name: 'São Pedro do Iraxim' },
  { ibge: '431860625', name: 'Tanque' },
  { ibge: '431860632', name: 'Vila Souza' },
  { ibge: '431861405', name: 'São José do Sul' },
  { ibge: '431862205', name: 'São José dos Ausentes' },
  { ibge: '431862210', name: 'Faxinal Preto' },
  { ibge: '431862215', name: 'São Gonçalo' },
  { ibge: '431862220', name: 'Silveira' },
  { ibge: '431862225', name: 'Várzea' },
  { ibge: '431870505', name: 'São Leopoldo' },
  { ibge: '431880405', name: 'São Lourenço do Sul' },
  { ibge: '431880408', name: 'Boa Vista' },
  { ibge: '431880410', name: 'Boqueirão' },
  { ibge: '431880415', name: 'Esperança' },
  { ibge: '431880420', name: 'Faxinal' },
  { ibge: '431880425', name: 'Harmonia' },
  { ibge: '431880435', name: 'Prado Novo' },
  { ibge: '431880440', name: 'Taquaral' },
  { ibge: '431890305', name: 'São Luiz Gonzaga' },
  { ibge: '431890310', name: 'Afonso Rodrigues' },
  { ibge: '431890314', name: 'Capela São Paulo' },
  { ibge: '431890320', name: 'Rincão de São Pedro' },
  { ibge: '431890323', name: 'Rincão dos Pintos' },
  { ibge: '431890330', name: 'Santa Inês' },
  { ibge: '431890335', name: 'São Lourenço das Missões' },
  { ibge: '431900005', name: 'São Marcos' },
  { ibge: '431900020', name: 'Pedras Brancas' },
  { ibge: '431910905', name: 'São Martinho' },
  { ibge: '431912505', name: 'São Martinho da Serra' },
  { ibge: '431915805', name: 'São Miguel das Missões' },
  { ibge: '431915810', name: 'Campestre' },
  { ibge: '431915815', name: 'Coimbra' },
  { ibge: '431915820', name: 'Mato Grande' },
  { ibge: '431915827', name: 'Rincão dos Moraes' },
  { ibge: '431915830', name: 'São João das Missões' },
  { ibge: '431915835', name: 'São José' },
  { ibge: '431920805', name: 'São Nicolau' },
  { ibge: '431920820', name: 'Santo Isidro' },
  { ibge: '431930705', name: 'São Paulo das Missões' },
  { ibge: '431930710', name: 'Pinheiro Machado' },
  { ibge: '431935605', name: 'São Pedro da Serra' },
  { ibge: '431936405', name: 'São Pedro das Missões' },
  { ibge: '431937205', name: 'São Pedro do Butiá' },
  { ibge: '431937210', name: 'Butiá Inferior' },
  { ibge: '431940605', name: 'São Pedro do Sul' },
  { ibge: '431940610', name: 'Cerro Claro' },
  { ibge: '431940615', name: 'Guassupi' },
  { ibge: '431940645', name: 'Xiniquá' },
  { ibge: '431950505', name: 'São Sebastião do Caí' },
  { ibge: '431960405', name: 'São Sepé' },
  { ibge: '431960420', name: 'Cerrito do Ouro' },
  { ibge: '431960425', name: 'Jazidas' },
  { ibge: '431960428', name: 'Tupanci' },
  { ibge: '431960430', name: 'Vila Block' },
  { ibge: '431970305', name: 'São Valentim' },
  { ibge: '431971105', name: 'São Valentim do Sul' },
  { ibge: '431971110', name: 'Fazenda Fialho' },
  { ibge: '431971120', name: 'Santa Bárbara' },
  { ibge: '431973705', name: 'São Valério do Sul' },
  { ibge: '431975205', name: 'São Vendelino' },
  { ibge: '431980205', name: 'São Vicente do Sul' },
  { ibge: '431980210', name: 'Cavajuretã' },
  { ibge: '431980215', name: 'Loreto' },
  { ibge: '431990105', name: 'Sapiranga' },
  { ibge: '431990125', name: 'Picada São Jacob' },
  { ibge: '432000805', name: 'Sapucaia do Sul' },
  { ibge: '432010705', name: 'Sarandi' },
  { ibge: '432010710', name: 'Atiaçu' },
  { ibge: '432010720', name: 'Barreirinho' },
  { ibge: '432010730', name: 'Novo Sarandi' },
  { ibge: '432020605', name: 'Seberi' },
  { ibge: '432020610', name: 'Barrinha' },
  { ibge: '432020615', name: 'Lajeado Bonito' },
  { ibge: '432023005', name: 'Sede Nova' },
  { ibge: '432023020', name: 'Monte Belo' },
  { ibge: '432026305', name: 'Segredo' },
  { ibge: '432026310', name: 'Bela Vista' },
  { ibge: '432026318', name: 'Serrinha Velha' },
  { ibge: '432026320', name: 'Tamanduá' },
  { ibge: '432030505', name: 'Selbach' },
  { ibge: '432030510', name: 'Arroio Grande' },
  { ibge: '432030520', name: 'Floresta' },
  { ibge: '432032105', name: 'Senador Salgado Filho' },
  { ibge: '432032110', name: 'Esquina Ipiranga' },
  { ibge: '432032112', name: 'Giruazinho' },
  { ibge: '432032115', name: 'Vila Oito de Agosto' },
  { ibge: '432035405', name: 'Sentinela do Sul' },
  { ibge: '432040405', name: 'Serafina Corrêa' },
  { ibge: '432040415', name: 'Silva Jardim' },
  { ibge: '432045305', name: 'Sério' },
  { ibge: '432050305', name: 'Sertão' },
  { ibge: '432050310', name: 'Engenheiro Luiz Englert' },
  { ibge: '432055205', name: 'Sertão Santana' },
  { ibge: '432057805', name: 'Sete de Setembro' },
  { ibge: '432060205', name: 'Severiano de Almeida' },
  { ibge: '432060210', name: 'Mirim' },
  { ibge: '432065105', name: 'Silveira Martins' },
  { ibge: '432067705', name: 'Sinimbu' },
  { ibge: '432070105', name: 'Sobradinho' },
  { ibge: '432080005', name: 'Soledade' },
  { ibge: '432080012', name: 'Bugre' },
  { ibge: '432080027', name: 'Pinhal' },
  { ibge: '432080035', name: 'Santa Terezinha' },
  { ibge: '432085905', name: 'Tabaí' },
  { ibge: '432090905', name: 'Tapejara' },
  { ibge: '432090930', name: 'Vila Campos' },
  { ibge: '432100605', name: 'Tapera' },
  { ibge: '432100620', name: 'São Pedro' },
  { ibge: '432100625', name: 'Vila Paz' },
  { ibge: '432110505', name: 'Tapes' },
  { ibge: '432120405', name: 'Taquara' },
  { ibge: '432120407', name: 'Entrepelado' },
  { ibge: '432120408', name: 'Fazenda Fialho' },
  { ibge: '432120410', name: 'Padilha' },
  { ibge: '432120413', name: 'Pega Fogo' },
  { ibge: '432120417', name: 'Rio da Ilha' },
  { ibge: '432120420', name: 'Santa Cruz da Concórdia' },
  { ibge: '432130305', name: 'Taquari' },
  { ibge: '432130307', name: 'Amoras' },
  { ibge: '432132905', name: 'Taquaruçu do Sul' },
  { ibge: '432135205', name: 'Tavares' },
  { ibge: '432135210', name: 'Butiás' },
  { ibge: '432135215', name: 'Capão Comprido' },
  { ibge: '432135220', name: 'Tapera' },
  { ibge: '432140205', name: 'Tenente Portela' },
  { ibge: '432140212', name: 'Braço Forte' },
  { ibge: '432140225', name: 'Daltro Filho' },
  { ibge: '432140240', name: 'São Pedro' },
  { ibge: '432143605', name: 'Terra de Areia' },
  { ibge: '432143625', name: 'Sanga Funda' },
  { ibge: '432145105', name: 'Teutônia' },
  { ibge: '432145110', name: 'Pontes Filho' },
  { ibge: '432146905', name: 'Tio Hugo' },
  { ibge: '432147705', name: 'Tiradentes do Sul' },
  { ibge: '432147710', name: 'Alto Uruguai' },
  { ibge: '432147713', name: 'Lajeado Bonito' },
  { ibge: '432147717', name: 'Novo Planalto' },
  { ibge: '432147720', name: 'Porto Soberbo' },
  { ibge: '432149305', name: 'Toropi' },
  { ibge: '432149315', name: 'Linha Bonita' },
  { ibge: '432150105', name: 'Torres' },
  { ibge: '432150110', name: 'Glória' },
  { ibge: '432160005', name: 'Tramandaí' },
  { ibge: '432160015', name: 'Estância Velha' },
  { ibge: '432160025', name: 'Nova Tramandaí' },
  { ibge: '432162605', name: 'Travesseiro' },
  { ibge: '432163405', name: 'Três Arroios' },
  { ibge: '432163412', name: 'Coxilha Seca' },
  { ibge: '432166705', name: 'Três Cachoeiras' },
  { ibge: '432166710', name: 'Morro Azul' },
  { ibge: '432166715', name: 'Rio do Terra' },
  { ibge: '432166720', name: 'Vila Fernando Ferrari' },
  { ibge: '432170905', name: 'Três Coroas' },
  { ibge: '432180805', name: 'Três de Maio' },
  { ibge: '432180811', name: 'Barrinha' },
  { ibge: '432180815', name: 'Consolata' },
  { ibge: '432180820', name: 'Manchinha' },
  { ibge: '432180825', name: 'Progresso' },
  { ibge: '432180830', name: 'Quaraim' },
  { ibge: '432183205', name: 'Três Forquilhas' },
  { ibge: '432185705', name: 'Três Palmeiras' },
  { ibge: '432185710', name: 'Progresso' },
  { ibge: '432190705', name: 'Três Passos' },
  { ibge: '432190708', name: 'Alto Erval Novo' },
  { ibge: '432190715', name: 'Bela Vista' },
  { ibge: '432190722', name: 'Floresta' },
  { ibge: '432190735', name: 'Padre Gonzales' },
  { ibge: '432190745', name: 'Santo Antônio' },
  { ibge: '432195605', name: 'Trindade do Sul' },
  { ibge: '432200405', name: 'Triunfo' },
  { ibge: '432200410', name: 'Costa da Cadeia' },
  { ibge: '432200415', name: 'Passo Raso' },
  { ibge: '432200420', name: 'Porto Batista' },
  { ibge: '432210305', name: 'Tucunduva' },
  { ibge: '432215205', name: 'Tunas' },
  { ibge: '432215210', name: 'Cerro Preto' },
  { ibge: '432215215', name: 'Despraiado' },
  { ibge: '432215220', name: 'Nossa Senhora Aparecida' },
  { ibge: '432215225', name: 'Pedregal' },
  { ibge: '432215230', name: 'Rincão Comprido' },
  { ibge: '432218605', name: 'Tupanci do Sul' },
  { ibge: '432220205', name: 'Tupanciretã' },
  { ibge: '432220207', name: 'Espinilho Grande' },
  { ibge: '432225105', name: 'Tupandi' },
  { ibge: '432230105', name: 'Tuparendi' },
  { ibge: '432230110', name: 'Cerro Alto' },
  { ibge: '432230115', name: 'Cinquentenário' },
  { ibge: '432232705', name: 'Turuçu' },
  { ibge: '432234305', name: 'Ubiretama' },
  { ibge: '432235005', name: 'União da Serra' },
  { ibge: '432235020', name: 'Oeste' },
  { ibge: '432235025', name: 'Pulador' },
  { ibge: '432237605', name: 'Unistalda' },
  { ibge: '432240005', name: 'Uruguaiana' },
  { ibge: '432240020', name: 'João Arregui' },
  { ibge: '432240025', name: 'Plano Alto' },
  { ibge: '432240030', name: 'São Marcos' },
  { ibge: '432240035', name: 'Vertentes' },
  { ibge: '432250905', name: 'Vacaria' },
  { ibge: '432250907', name: 'Bela Vista' },
  { ibge: '432250908', name: 'Capão da Herança' },
  { ibge: '432250910', name: 'Coxilha Grande' },
  { ibge: '432250920', name: 'Estrela' },
  { ibge: '432250940', name: 'Refugiado' },
  { ibge: '432252505', name: 'Vale Verde' },
  { ibge: '432253305', name: 'Vale do Sol' },
  { ibge: '432254105', name: 'Vale Real' },
  { ibge: '432254110', name: 'Arroio do Ouro' },
  { ibge: '432254115', name: 'Canto Krewer' },
  { ibge: '432254120', name: 'Forqueta Baixa' },
  { ibge: '432254125', name: 'Morro Gaúcho' },
  { ibge: '432255805', name: 'Vanini' },
  { ibge: '432260805', name: 'Venâncio Aires' },
  { ibge: '432260807', name: 'Arlindo' },
  { ibge: '432260810', name: 'Centro Linha Brasil' },
  { ibge: '432260815', name: 'Deodoro' },
  { ibge: '432260817', name: 'Estância Nova' },
  { ibge: '432260820', name: 'Mariante' },
  { ibge: '432260822', name: 'Palanque' },
  { ibge: '432260830', name: 'Santa Emília' },
  { ibge: '432260845', name: 'Vale do Sampaio' },
  { ibge: '432270705', name: 'Vera Cruz' },
  { ibge: '432280605', name: 'Veranópolis' },
  { ibge: '432285505', name: 'Vespasiano Correa' },
  { ibge: '432290505', name: 'Viadutos' },
  { ibge: '432300205', name: 'Viamão' },
  { ibge: '432300210', name: 'Águas Claras' },
  { ibge: '432300215', name: 'Capão da Porteira' },
  { ibge: '432300220', name: 'Espigão' },
  { ibge: '432300225', name: 'Itapuã' },
  { ibge: '432300230', name: 'Passo da Areia' },
  { ibge: '432300235', name: 'Passo do Sabão' },
  { ibge: '432300240', name: 'Viamópolis' },
  { ibge: '432310105', name: 'Vicente Dutra' },
  { ibge: '432310110', name: 'Laranjeira' },
  { ibge: '432320005', name: 'Victor Graeff' },
  { ibge: '432320007', name: 'Faxinal' },
  { ibge: '432320015', name: 'São José da Glória' },
  { ibge: '432330905', name: 'Vila Flores' },
  { ibge: '432335805', name: 'Vila Lângaro' },
  { ibge: '432340805', name: 'Vila Maria' },
  { ibge: '432345705', name: 'Vila Nova do Sul' },
  { ibge: '432350705', name: 'Vista Alegre' },
  { ibge: '432360605', name: 'Vista Alegre do Prata' },
  { ibge: '432370505', name: 'Vista Gaúcha' },
  { ibge: '432370510', name: 'Bom Plano' },
  { ibge: '432375405', name: 'Vitória das Missões' },
  { ibge: '432375430', name: 'São João Batista' },
  { ibge: '432377005', name: 'Westfalia' },
  { ibge: '432380405', name: 'Xangri-lá' },
  { ibge: '432380415', name: 'Guará' },
  { ibge: '432380425', name: 'Rainha do Mar' },
  { ibge: '500020305', name: 'Água Clara' },
  { ibge: '500025205', name: 'Alcinópolis' },
  { ibge: '500060905', name: 'Amambai' },
  { ibge: '500070805', name: 'Anastácio' },
  { ibge: '500080705', name: 'Anaurilândia' },
  { ibge: '500085605', name: 'Angélica' },
  { ibge: '500085610', name: 'Ipezal' },
  { ibge: '500090605', name: 'Antônio João' },
  { ibge: '500090610', name: 'Campestre' },
  { ibge: '500100305', name: 'Aparecida do Taboado' },
  { ibge: '500110205', name: 'Aquidauana' },
  { ibge: '500110210', name: 'Camisão' },
  { ibge: '500110215', name: 'Cipolândia' },
  { ibge: '500110220', name: 'Piraputanga' },
  { ibge: '500110225', name: 'Taunay' },
  { ibge: '500124305', name: 'Aral Moreira' },
  { ibge: '500150805', name: 'Bandeirantes' },
  { ibge: '500150810', name: 'Congonha' },
  { ibge: '500190405', name: 'Bataguassu' },
  { ibge: '500190410', name: 'Porto XV de Novembro' },
  { ibge: '500200105', name: 'Batayporã' },
  { ibge: '500210005', name: 'Bela Vista' },
  { ibge: '500210010', name: 'Nossa Senhora de Fátima' },
  { ibge: '500215905', name: 'Bodoquena' },
  { ibge: '500215910', name: 'Morraria do Sul' },
  { ibge: '500220905', name: 'Bonito' },
  { ibge: '500220910', name: 'Águas de Miranda' },
  { ibge: '500230805', name: 'Brasilândia' },
  { ibge: '500230815', name: 'Debrasa' },
  { ibge: '500240705', name: 'Caarapó' },
  { ibge: '500240710', name: 'Cristalina' },
  { ibge: '500240720', name: 'Nova América' },
  { ibge: '500260505', name: 'Camapuã' },
  { ibge: '500260535', name: 'Pontinha do Cocho' },
  { ibge: '500270405', name: 'Campo Grande' },
  { ibge: '500270460', name: 'Anhanduí' },
  { ibge: '500270480', name: 'Rochedinho' },
  { ibge: '500280305', name: 'Caracol' },
  { ibge: '500290205', name: 'Cassilândia' },
  { ibge: '500290215', name: 'Indaiá do Sul' },
  { ibge: '500295105', name: 'Chapadão do Sul' },
  { ibge: '500310805', name: 'Corguinho' },
  { ibge: '500310810', name: 'Baianópolis' },
  { ibge: '500315705', name: 'Coronel Sapucaia' },
  { ibge: '500320705', name: 'Corumbá' },
  { ibge: '500320710', name: 'Albuquerque' },
  { ibge: '500320715', name: 'Amolar' },
  { ibge: '500320720', name: 'Coimbra' },
  { ibge: '500320725', name: 'Nhecolândia' },
  { ibge: '500320730', name: 'Paiaguás' },
  { ibge: '500320735', name: 'Porto Esperança' },
  { ibge: '500325605', name: 'Costa Rica' },
  { ibge: '500325610', name: 'Baús' },
  { ibge: '500325615', name: 'Paraíso' },
  { ibge: '500330605', name: 'Coxim' },
  { ibge: '500330608', name: 'Jauru' },
  { ibge: '500330610', name: 'São Romão' },
  { ibge: '500330615', name: 'Taquari' },
  { ibge: '500345405', name: 'Deodápolis' },
  { ibge: '500345410', name: 'Lagoa Bonita' },
  { ibge: '500345415', name: 'Porto Vilma' },
  { ibge: '500345420', name: 'Presidente Castelo' },
  { ibge: '500345425', name: 'Vila União' },
  { ibge: '500348805', name: 'Dois Irmãos do Buriti' },
  { ibge: '500348810', name: 'Palmeiras' },
  { ibge: '500350405', name: 'Douradina' },
  { ibge: '500350410', name: 'Bocajá' },
  { ibge: '500350415', name: 'Cruzaltina' },
  { ibge: '500370205', name: 'Dourados' },
  { ibge: '500370220', name: 'Guaçu' },
  { ibge: '500370225', name: 'Itaum' },
  { ibge: '500370230', name: 'Panambi' },
  { ibge: '500370235', name: 'Picadinha' },
  { ibge: '500370240', name: 'São Pedro' },
  { ibge: '500370245', name: 'Indápolis' },
  { ibge: '500370248', name: 'Vila Formosa' },
  { ibge: '500370250', name: 'Vila Vargas' },
  { ibge: '500375105', name: 'Eldorado' },
  { ibge: '500375110', name: 'Morumbi' },
  { ibge: '500380105', name: 'Fátima do Sul' },
  { ibge: '500380115', name: 'Culturama' },
  { ibge: '500390005', name: 'Figueirão' },
  { ibge: '500400705', name: 'Glória de Dourados' },
  { ibge: '500400710', name: 'Nova Esperança' },
  { ibge: '500400715', name: 'Guaçulândia' },
  { ibge: '500410605', name: 'Guia Lopes da Laguna' },
  { ibge: '500430405', name: 'Iguatemi' },
  { ibge: '500440305', name: 'Inocência' },
  { ibge: '500440310', name: 'Morangas' },
  { ibge: '500440315', name: 'São José do Sucuriú' },
  { ibge: '500440320', name: 'São Pedro' },
  { ibge: '500450205', name: 'Itaporã' },
  { ibge: '500450210', name: 'Carumbé' },
  { ibge: '500450215', name: 'Montese' },
  { ibge: '500450220', name: 'Piraporã' },
  { ibge: '500450225', name: 'Santa Terezinha' },
  { ibge: '500460105', name: 'Itaquiraí' },
  { ibge: '500470005', name: 'Ivinhema' },
  { ibge: '500470010', name: 'Amandina' },
  { ibge: '500480905', name: 'Japorã' },
  { ibge: '500490805', name: 'Jaraguari' },
  { ibge: '500490810', name: 'Bom Fim' },
  { ibge: '500500405', name: 'Jardim' },
  { ibge: '500500410', name: 'Boqueirão' },
  { ibge: '500510305', name: 'Jateí' },
  { ibge: '500515205', name: 'Juti' },
  { ibge: '500520205', name: 'Ladário' },
  { ibge: '500525105', name: 'Laguna Carapã' },
  { ibge: '500540005', name: 'Maracaju' },
  { ibge: '500540010', name: 'Vista Alegre' },
  { ibge: '500560805', name: 'Miranda' },
  { ibge: '500568105', name: 'Mundo Novo' },
  { ibge: '500570705', name: 'Naviraí' },
  { ibge: '500580605', name: 'Nioaque' },
  { ibge: '500600205', name: 'Nova Alvorada do Sul' },
  { ibge: '500620005', name: 'Nova Andradina' },
  { ibge: '500620020', name: 'Nova Casa Verde' },
  { ibge: '500625905', name: 'Novo Horizonte do Sul' },
  { ibge: '500627505', name: 'Paraíso das Águas' },
  { ibge: '500627510', name: 'Alto Sucuriú' },
  { ibge: '500627515', name: 'Bela Alvorada' },
  { ibge: '500630905', name: 'Paranaíba' },
  { ibge: '500630908', name: 'Alto Santana' },
  { ibge: '500630923', name: 'Raimundo' },
  { ibge: '500630935', name: 'São João do Aporé' },
  { ibge: '500630940', name: 'Tamandaré' },
  { ibge: '500630945', name: 'Velhacaria' },
  { ibge: '500635805', name: 'Paranhos' },
  { ibge: '500640805', name: 'Pedro Gomes' },
  { ibge: '500660605', name: 'Ponta Porã' },
  { ibge: '500660615', name: 'Cabeceira do Apa' },
  { ibge: '500660625', name: 'Sanga Puitã' },
  { ibge: '500690305', name: 'Porto Murtinho' },
  { ibge: '500710905', name: 'Ribas do Rio Pardo' },
  { ibge: '500710910', name: 'Bálsamo' },
  { ibge: '500720805', name: 'Rio Brilhante' },
  { ibge: '500720810', name: 'Prudêncio Thomaz' },
  { ibge: '500730705', name: 'Rio Negro' },
  { ibge: '500730710', name: 'Nova Esperança' },
  { ibge: '500740605', name: 'Rio Verde de Mato Grosso' },
  { ibge: '500740610', name: 'Juscelândia' },
  { ibge: '500750505', name: 'Rochedo' },
  { ibge: '500750510', name: 'Água Boa' },
  { ibge: '500755405', name: 'Santa Rita do Pardo' },
  { ibge: '500769505', name: 'São Gabriel do Oeste' },
  { ibge: '500769510', name: 'Areado' },
  { ibge: '500769515', name: 'Ponte Vermelha' },
  { ibge: '500770305', name: 'Sete Quedas' },
  { ibge: '500780205', name: 'Selvíria' },
  { ibge: '500790105', name: 'Sidrolândia' },
  { ibge: '500790110', name: 'Capão Seco' },
  { ibge: '500790115', name: 'Quebra Côco' },
  { ibge: '500793505', name: 'Sonora' },
  { ibge: '500795005', name: 'Tacuru' },
  { ibge: '500797605', name: 'Taquarussu' },
  { ibge: '500800805', name: 'Terenos' },
  { ibge: '500830505', name: 'Três Lagoas' },
  { ibge: '500830510', name: 'Arapuá' },
  { ibge: '500830515', name: 'Garcias' },
  { ibge: '500830520', name: 'Ilha Comprida' },
  { ibge: '500830525', name: 'Guadalupe do Alto Paraná' },
  { ibge: '500840405', name: 'Vicentina' },
  { ibge: '500840440', name: 'São José' },
  { ibge: '500840445', name: 'Vila Rica' },
  { ibge: '510010205', name: 'Acorizal' },
  { ibge: '510010207', name: 'Baús' },
  { ibge: '510010210', name: 'Aldeia' },
  { ibge: '510020105', name: 'Água Boa' },
  { ibge: '510020115', name: 'Jaraguá' },
  { ibge: '510020125', name: 'Serrinha' },
  { ibge: '510025005', name: 'Alta Floresta' },
  { ibge: '510030005', name: 'Alto Araguaia' },
  { ibge: '510030007', name: 'Buriti' },
  { ibge: '510035905', name: 'Alto Boa Vista' },
  { ibge: '510040905', name: 'Alto Garças' },
  { ibge: '510050805', name: 'Alto Paraguai' },
  { ibge: '510050807', name: 'Capão Verde' },
  { ibge: '510050810', name: 'Lavouras' },
  { ibge: '510060705', name: 'Alto Taquari' },
  { ibge: '510080505', name: 'Apiacás' },
  { ibge: '510100105', name: 'Araguaiana' },
  { ibge: '510120905', name: 'Araguainha' },
  { ibge: '510125805', name: 'Araputanga' },
  { ibge: '510130805', name: 'Arenápolis' },
  { ibge: '510140705', name: 'Aripuanã' },
  { ibge: '510160505', name: 'Barão de Melgaço' },
  { ibge: '510160510', name: 'Joselândia' },
  { ibge: '510170405', name: 'Barra do Bugres' },
  { ibge: '510170406', name: 'Assari' },
  { ibge: '510170422', name: 'Tapirapuã' },
  { ibge: '510180305', name: 'Barra do Garças' },
  { ibge: '510180315', name: 'Indianópolis' },
  { ibge: '510180330', name: 'Toricueije' },
  { ibge: '510180335', name: 'Vale dos Sonhos' },
  { ibge: '510185205', name: 'Bom Jesus do Araguaia' },
  { ibge: '510190205', name: 'Brasnorte' },
  { ibge: '510190210', name: 'Água da Prata' },
  { ibge: '510250405', name: 'Cáceres' },
  { ibge: '510250407', name: 'Bezerro Branco' },
  { ibge: '510250415', name: 'Caramujo' },
  { ibge: '510250425', name: 'Horizonte do Oeste' },
  { ibge: '510250430', name: 'Nova Cáceres' },
  { ibge: '510260305', name: 'Campinápolis' },
  { ibge: '510260315', name: 'São José do Couto' },
  { ibge: '510263705', name: 'Campo Novo do Parecis' },
  { ibge: '510263715', name: 'Itanorte' },
  { ibge: '510263720', name: 'Marechal Rondon' },
  { ibge: '510267805', name: 'Campo Verde' },
  { ibge: '510267810', name: 'Coronel Ponce' },
  { ibge: '510268605', name: 'Campos de Júlio' },
  { ibge: '510269405', name: 'Canabrava do Norte' },
  { ibge: '510270205', name: 'Canarana' },
  { ibge: '510279305', name: 'Carlinda' },
  { ibge: '510285005', name: 'Castanheira' },
  { ibge: '510300705', name: 'Chapada dos Guimarães' },
  { ibge: '510300710', name: 'Água Fria' },
  { ibge: '510300720', name: 'Rio da Casca' },
  { ibge: '510305605', name: 'Cláudia' },
  { ibge: '510310605', name: 'Cocalinho' },
  { ibge: '510320505', name: 'Colíder' },
  { ibge: '510325405', name: 'Colniza' },
  { ibge: '510325410', name: 'Guariba' },
  { ibge: '510330405', name: 'Comodoro' },
  { ibge: '510330409', name: 'Colônia dos Mineiros' },
  { ibge: '510330415', name: 'Nova Alvorada' },
  { ibge: '510330417', name: 'Noroagro' },
  { ibge: '510330420', name: 'Padronal' },
  { ibge: '510335305', name: 'Confresa' },
  { ibge: '510335320', name: 'Veranópolis' },
  { ibge: '510336105', name: "Conquista D'Oeste" },
  { ibge: '510337905', name: 'Cotriguaçu' },
  { ibge: '510340305', name: 'Cuiabá' },
  { ibge: '510340310', name: 'Coxipó da Ponte' },
  { ibge: '510340315', name: 'Coxipó do Ouro' },
  { ibge: '510340320', name: 'Guia' },
  { ibge: '510343705', name: 'Curvelândia' },
  { ibge: '510345205', name: 'Denise' },
  { ibge: '510350205', name: 'Diamantino' },
  { ibge: '510360105', name: 'Dom Aquino' },
  { ibge: '510360120', name: 'Entre Rios' },
  { ibge: '510370005', name: 'Feliz Natal' },
  { ibge: '510380905', name: "Figueirópolis D'Oeste" },
  { ibge: '510385805', name: 'Gaúcha do Norte' },
  { ibge: '510390805', name: 'General Carneiro' },
  { ibge: '510390820', name: 'Paredão Grande' },
  { ibge: '510395705', name: "Glória D'Oeste" },
  { ibge: '510395716', name: "Monte Castelo D'Oeste" },
  { ibge: '510410405', name: 'Guarantã do Norte' },
  { ibge: '510420305', name: 'Guiratinga' },
  { ibge: '510420310', name: 'Alcantilado' },
  { ibge: '510420325', name: 'Vale Rico' },
  { ibge: '510450005', name: 'Indiavaí' },
  { ibge: '510452605', name: 'Ipiranga do Norte' },
  { ibge: '510454205', name: 'Itanhangá' },
  { ibge: '510455905', name: 'Itaúba' },
  { ibge: '510460905', name: 'Itiquira' },
  { ibge: '510480705', name: 'Jaciara' },
  { ibge: '510480720', name: 'Celma' },
  { ibge: '510490605', name: 'Jangada' },
  { ibge: '510500205', name: 'Jauru' },
  { ibge: '510500215', name: 'Lucialva' },
  { ibge: '510510105', name: 'Juara' },
  { ibge: '510510110', name: 'Águas Claras' },
  { ibge: '510510115', name: 'Catuaí' },
  { ibge: '510510120', name: 'Paranorte' },
  { ibge: '510515005', name: 'Juína' },
  { ibge: '510515009', name: 'Filadélfia' },
  { ibge: '510515010', name: 'Fontanillas' },
  { ibge: '510515030', name: 'Terra Roxa' },
  { ibge: '510517605', name: 'Juruena' },
  { ibge: '510520005', name: 'Juscimeira' },
  { ibge: '510520010', name: 'Irenópolis' },
  { ibge: '510520015', name: 'Santa Elvira' },
  { ibge: '510520020', name: 'São Lourenço de Fátima' },
  { ibge: '510523405', name: "Lambari D'Oeste" },
  { ibge: '510525905', name: 'Lucas do Rio Verde' },
  { ibge: '510525910', name: 'Groslândia' },
  { ibge: '510530905', name: 'Luciara' },
  { ibge: '510550705', name: 'Vila Bela da Santíssima Trindade' },
  { ibge: '510550710', name: 'Aguapeí' },
  { ibge: '510558005', name: 'Marcelândia' },
  { ibge: '510560605', name: 'Matupá' },
  { ibge: '510562205', name: "Mirassol d'Oeste" },
  { ibge: '510562230', name: 'Sonho Azul' },
  { ibge: '510590305', name: 'Nobres' },
  { ibge: '510590306', name: 'Bom Jardim' },
  { ibge: '510590307', name: 'Coqueiral' },
  { ibge: '510600005', name: 'Nortelândia' },
  { ibge: '510610905', name: 'Nossa Senhora do Livramento' },
  { ibge: '510610910', name: 'Pirizal' },
  { ibge: '510610913', name: 'Ribeirão dos Cocais' },
  { ibge: '510610915', name: 'Seco' },
  { ibge: '510615805', name: 'Nova Bandeirantes' },
  { ibge: '510617405', name: 'Nova Nazaré' },
  { ibge: '510618205', name: 'Nova Lacerda' },
  { ibge: '510619005', name: 'Nova Santa Helena' },
  { ibge: '510620805', name: 'Nova Brasilândia' },
  { ibge: '510620810', name: 'Riolândia' },
  { ibge: '510621605', name: 'Nova Canaã do Norte' },
  { ibge: '510621610', name: 'Colorado do Norte' },
  { ibge: '510621615', name: 'Ouro Branco' },
  { ibge: '510622405', name: 'Nova Mutum' },
  { ibge: '510623205', name: 'Nova Olímpia' },
  { ibge: '510624005', name: 'Nova Ubiratã' },
  { ibge: '510624010', name: 'Entre Rios' },
  { ibge: '510624015', name: 'Novo Mato Grosso' },
  { ibge: '510624017', name: 'Parque Água Limpa' },
  { ibge: '510624020', name: 'Piratininga' },
  { ibge: '510624026', name: 'Santa Terezinha do Rio Ferro' },
  { ibge: '510624030', name: 'Santo Antônio do Rio Bonito' },
  { ibge: '510625705', name: 'Nova Xavantina' },
  { ibge: '510625725', name: 'União do Leste' },
  { ibge: '510626505', name: 'Novo Mundo' },
  { ibge: '510627305', name: 'Novo Horizonte do Norte' },
  { ibge: '510628105', name: 'Novo São Joaquim' },
  { ibge: '510628115', name: 'Itaquerê' },
  { ibge: '510629905', name: 'Paranaíta' },
  { ibge: '510630705', name: 'Paranatinga' },
  { ibge: '510631505', name: 'Novo Santo Antônio' },
  { ibge: '510637205', name: 'Pedra Preta' },
  { ibge: '510637210', name: 'São José do Planalto' },
  { ibge: '510642205', name: 'Peixoto de Azevedo' },
  { ibge: '510645505', name: 'Planalto da Serra' },
  { ibge: '510650505', name: 'Poconé' },
  { ibge: '510650510', name: 'Cangas' },
  { ibge: '510650515', name: 'Fazenda de Cima' },
  { ibge: '510665305', name: 'Pontal do Araguaia' },
  { ibge: '510670305', name: 'Ponte Branca' },
  { ibge: '510675205', name: 'Pontes e Lacerda' },
  { ibge: '510677805', name: 'Porto Alegre do Norte' },
  { ibge: '510680205', name: 'Porto dos Gaúchos' },
  { ibge: '510682805', name: 'Porto Esperidião' },
  { ibge: '510685105', name: 'Porto Estrela' },
  { ibge: '510700805', name: 'Poxoréo' },
  { ibge: '510700810', name: 'Alto Coité' },
  { ibge: '510700815', name: 'Jarudore' },
  { ibge: '510700820', name: 'Paraíso do Leste' },
  { ibge: '510704005', name: 'Primavera do Leste' },
  { ibge: '510706505', name: 'Querência' },
  { ibge: '510706515', name: 'Coutinho União' },
  { ibge: '510710705', name: 'São José dos Quatro Marcos' },
  { ibge: '510710710', name: 'Santa Fé' },
  { ibge: '510715605', name: 'Reserva do Cabaçal' },
  { ibge: '510718005', name: 'Ribeirão Cascalheira' },
  { ibge: '510719805', name: 'Ribeirãozinho' },
  { ibge: '510720605', name: 'Rio Branco' },
  { ibge: '510724805', name: 'Santa Carmem' },
  { ibge: '510726305', name: 'Santo Afonso' },
  { ibge: '510729705', name: 'São José do Povo' },
  { ibge: '510729715', name: 'Nova Catanduva' },
  { ibge: '510730505', name: 'São José do Rio Claro' },
  { ibge: '510735405', name: 'São José do Xingu' },
  { ibge: '510735425', name: 'Santo Antônio do Fontoura' },
  { ibge: '510740405', name: 'São Pedro da Cipa' },
  { ibge: '510757805', name: 'Rondolândia' },
  { ibge: '510760205', name: 'Rondonópolis' },
  { ibge: '510760210', name: 'Anhumas' },
  { ibge: '510760215', name: 'Nova Galiléia' },
  { ibge: '510760220', name: 'Boa Vista' },
  { ibge: '510760230', name: 'Vila Operária' },
  { ibge: '510770105', name: 'Rosário Oeste' },
  { ibge: '510770110', name: 'Arruda' },
  { ibge: '510770115', name: 'Bauxi' },
  { ibge: '510770120', name: 'Marzagão' },
  { ibge: '510774305', name: 'Santa Cruz do Xingu' },
  { ibge: '510775005', name: 'Salto do Céu' },
  { ibge: '510775010', name: 'Cristinópolis' },
  { ibge: '510775015', name: 'Vila Progresso' },
  { ibge: '510776805', name: 'Santa Rita do Trivelato' },
  { ibge: '510777605', name: 'Santa Terezinha' },
  { ibge: '510779205', name: 'Santo Antônio do Leste' },
  { ibge: '510780005', name: 'Santo Antônio do Leverger' },
  { ibge: '510780010', name: 'Engenho Velho' },
  { ibge: '510780015', name: 'Mimoso' },
  { ibge: '510780017', name: 'Caité' },
  { ibge: '510780025', name: 'Varginha' },
  { ibge: '510785905', name: 'São Félix do Araguaia' },
  { ibge: '510785915', name: 'Espigão do Leste' },
  { ibge: '510787505', name: 'Sapezal' },
  { ibge: '510788305', name: 'Serra Nova Dourada' },
  { ibge: '510790905', name: 'Sinop' },
  { ibge: '510792505', name: 'Sorriso' },
  { ibge: '510792510', name: 'Boa Esperança' },
  { ibge: '510792515', name: 'Caravágio' },
  { ibge: '510792530', name: 'Primavera' },
  { ibge: '510794105', name: 'Tabaporã' },
  { ibge: '510795805', name: 'Tangará da Serra' },
  { ibge: '510795815', name: 'Progresso' },
  { ibge: '510795820', name: 'São Joaquim' },
  { ibge: '510795825', name: 'São Jorge' },
  { ibge: '510800605', name: 'Tapurah' },
  { ibge: '510800630', name: 'Novo Eldorado' },
  { ibge: '510805505', name: 'Terra Nova do Norte' },
  { ibge: '510805515', name: 'Miraguaí do Norte' },
  { ibge: '510805520', name: 'Nonoaí do Norte' },
  { ibge: '510805530', name: 'Nona Agrovila' },
  { ibge: '510810505', name: 'Tesouro' },
  { ibge: '510810510', name: 'Batovi' },
  { ibge: '510810515', name: 'Cassununga' },
  { ibge: '510820405', name: 'Torixoréu' },
  { ibge: '510830305', name: 'União do Sul' },
  { ibge: '510835205', name: 'Vale de São Domingos' },
  { ibge: '510840205', name: 'Várzea Grande' },
  { ibge: '510840210', name: 'Bom Sucesso' },
  { ibge: '510840215', name: 'Passagem da Conceição' },
  { ibge: '510840220', name: 'Porto Velho' },
  { ibge: '510840225', name: 'Capão Grande' },
  { ibge: '510850105', name: 'Vera' },
  { ibge: '510860005', name: 'Vila Rica' },
  { ibge: '510880805', name: 'Nova Guarita' },
  { ibge: '510885705', name: 'Nova Marilândia' },
  { ibge: '510890705', name: 'Nova Maringá' },
  { ibge: '510895605', name: 'Nova Monte Verde' },
  { ibge: '520005005', name: 'Abadia de Goiás' },
  { ibge: '520010005', name: 'Abadiânia' },
  { ibge: '520010010', name: "Posse d'Abadia" },
  { ibge: '520013405', name: 'Acreúna' },
  { ibge: '520015905', name: 'Adelândia' },
  { ibge: '520017505', name: 'Água Fria de Goiás' },
  { ibge: '520020905', name: 'Água Limpa' },
  { ibge: '520025805', name: 'Águas Lindas de Goiás' },
  { ibge: '520030805', name: 'Alexânia' },
  { ibge: '520030810', name: "Olhos D'Água" },
  { ibge: '520050605', name: 'Aloândia' },
  { ibge: '520055505', name: 'Alto Horizonte' },
  { ibge: '520060505', name: 'Alto Paraíso de Goiás' },
  { ibge: '520060525', name: 'São Jorge' },
  { ibge: '520080305', name: 'Alvorada do Norte' },
  { ibge: '520082905', name: 'Amaralina' },
  { ibge: '520085205', name: 'Americano do Brasil' },
  { ibge: '520090205', name: 'Amorinópolis' },
  { ibge: '520110805', name: 'Anápolis' },
  { ibge: '520110810', name: 'Goialândia' },
  { ibge: '520110815', name: 'Interlândia' },
  { ibge: '520110820', name: 'Joanápolis' },
  { ibge: '520110830', name: 'Sousânia' },
  { ibge: '520120705', name: 'Anhanguera' },
  { ibge: '520130605', name: 'Anicuns' },
  { ibge: '520130615', name: 'Capelinha' },
  { ibge: '520130620', name: 'Choupana' },
  { ibge: '520140505', name: 'Aparecida de Goiânia' },
  { ibge: '520140510', name: 'Nova Brasília' },
  { ibge: '520145405', name: 'Aparecida do Rio Doce' },
  { ibge: '520150405', name: 'Aporé' },
  { ibge: '520160305', name: 'Araçu' },
  { ibge: '520170205', name: 'Aragarças' },
  { ibge: '520180105', name: 'Aragoiânia' },
  { ibge: '520215505', name: 'Araguapaz' },
  { ibge: '520235305', name: 'Arenópolis' },
  { ibge: '520250205', name: 'Aruanã' },
  { ibge: '520260105', name: 'Aurilândia' },
  { ibge: '520280905', name: 'Avelinópolis' },
  { ibge: '520310405', name: 'Baliza' },
  { ibge: '520320305', name: 'Barro Alto' },
  { ibge: '520330205', name: 'Bela Vista de Goiás' },
  { ibge: '520340105', name: 'Bom Jardim de Goiás' },
  { ibge: '520350005', name: 'Bom Jesus de Goiás' },
  { ibge: '520355905', name: 'Bonfinópolis' },
  { ibge: '520357505', name: 'Bonópolis' },
  { ibge: '520360905', name: 'Brazabrantes' },
  { ibge: '520380705', name: 'Britânia' },
  { ibge: '520390605', name: 'Buriti Alegre' },
  { ibge: '520393905', name: 'Buriti de Goiás' },
  { ibge: '520396205', name: 'Buritinópolis' },
  { ibge: '520400305', name: 'Cabeceiras' },
  { ibge: '520410205', name: 'Cachoeira Alta' },
  { ibge: '520420105', name: 'Cachoeira de Goiás' },
  { ibge: '520425005', name: 'Cachoeira Dourada' },
  { ibge: '520430005', name: 'Caçu' },
  { ibge: '520440905', name: 'Caiapônia' },
  { ibge: '520450805', name: 'Caldas Novas' },
  { ibge: '520455705', name: 'Caldazinha' },
  { ibge: '520460705', name: 'Campestre de Goiás' },
  { ibge: '520465605', name: 'Campinaçu' },
  { ibge: '520470605', name: 'Campinorte' },
  { ibge: '520470610', name: 'Colinaçu' },
  { ibge: '520480505', name: 'Campo Alegre de Goiás' },
  { ibge: '520485405', name: 'Campo Limpo de Goiás' },
  { ibge: '520490405', name: 'Campos Belos' },
  { ibge: '520495305', name: 'Campos Verdes' },
  { ibge: '520500005', name: 'Carmo do Rio Verde' },
  { ibge: '520505905', name: 'Castelândia' },
  { ibge: '520510905', name: 'Catalão' },
  { ibge: '520510910', name: 'Santo Antônio do Rio Verde' },
  { ibge: '520520805', name: 'Caturaí' },
  { ibge: '520530705', name: 'Cavalcante' },
  { ibge: '520540605', name: 'Ceres' },
  { ibge: '520545505', name: 'Cezarina' },
  { ibge: '520547105', name: 'Chapadão do Céu' },
  { ibge: '520549705', name: 'Cidade Ocidental' },
  { ibge: '520551305', name: 'Cocalzinho de Goiás' },
  { ibge: '520552105', name: 'Colinas do Sul' },
  { ibge: '520552130', name: 'Vila Borba' },
  { ibge: '520570305', name: 'Córrego do Ouro' },
  { ibge: '520580205', name: 'Corumbá de Goiás' },
  { ibge: '520590105', name: 'Corumbaíba' },
  { ibge: '520620605', name: 'Cristalina' },
  { ibge: '520620610', name: 'Campos Lindos' },
  { ibge: '520630505', name: 'Cristianópolis' },
  { ibge: '520640405', name: 'Crixás' },
  { ibge: '520640407', name: 'Auriverde' },
  { ibge: '520650305', name: 'Cromínia' },
  { ibge: '520660205', name: 'Cumari' },
  { ibge: '520670105', name: 'Damianópolis' },
  { ibge: '520680005', name: 'Damolândia' },
  { ibge: '520690905', name: 'Davinópolis' },
  { ibge: '520710505', name: 'Diorama' },
  { ibge: '520725305', name: 'Doverlândia' },
  { ibge: '520735205', name: 'Edealina' },
  { ibge: '520740205', name: 'Edéia' },
  { ibge: '520750105', name: 'Estrela do Norte' },
  { ibge: '520753505', name: 'Faina' },
  { ibge: '520753510', name: 'Caiçara' },
  { ibge: '520753515', name: 'Jeroaquara' },
  { ibge: '520760005', name: 'Fazenda Nova' },
  { ibge: '520760010', name: 'Bacilândia' },
  { ibge: '520760015', name: 'Serra Dourada' },
  { ibge: '520780805', name: 'Firminópolis' },
  { ibge: '520790705', name: 'Flores de Goiás' },
  { ibge: '520800405', name: 'Formosa' },
  { ibge: '520800407', name: 'Bezerra' },
  { ibge: '520800410', name: 'Santa Rosa' },
  { ibge: '520800415', name: 'Juscelino Kubitschek' },
  { ibge: '520810305', name: 'Formoso' },
  { ibge: '520815205', name: 'Gameleira de Goiás' },
  { ibge: '520830105', name: 'Divinópolis de Goiás' },
  { ibge: '520840005', name: 'Goianápolis' },
  { ibge: '520850905', name: 'Goiandira' },
  { ibge: '520860805', name: 'Goianésia' },
  { ibge: '520860810', name: 'Natinópolis' },
  { ibge: '520870705', name: 'Goiânia' },
  { ibge: '520870715', name: 'Vila Rica' },
  { ibge: '520880605', name: 'Goianira' },
  { ibge: '520890505', name: 'Goiás' },
  { ibge: '520890515', name: 'Buenolândia' },
  { ibge: '520890525', name: 'Calcilândia' },
  { ibge: '520890530', name: 'Davidópolis' },
  { ibge: '520890540', name: 'São João' },
  { ibge: '520890545', name: 'Uvá' },
  { ibge: '520910105', name: 'Goiatuba' },
  { ibge: '520910107', name: 'Marcianópolis' },
  { ibge: '520915005', name: 'Gouvelândia' },
  { ibge: '520920005', name: 'Guapó' },
  { ibge: '520929105', name: 'Guaraíta' },
  { ibge: '520940805', name: 'Guarani de Goiás' },
  { ibge: '520945705', name: 'Guarinos' },
  { ibge: '520960605', name: 'Heitoraí' },
  { ibge: '520970505', name: 'Hidrolândia' },
  { ibge: '520980405', name: 'Hidrolina' },
  { ibge: '520990305', name: 'Iaciara' },
  { ibge: '520993705', name: 'Inaciolândia' },
  { ibge: '520995205', name: 'Indiara' },
  { ibge: '521000005', name: 'Inhumas' },
  { ibge: '521010905', name: 'Ipameri' },
  { ibge: '521010910', name: 'Cavalheiro' },
  { ibge: '521010915', name: 'Domiciano Ribeiro' },
  { ibge: '521015805', name: 'Ipiranga de Goiás' },
  { ibge: '521020805', name: 'Iporá' },
  { ibge: '521030705', name: 'Israelândia' },
  { ibge: '521030715', name: 'Piloândia' },
  { ibge: '521040605', name: 'Itaberaí' },
  { ibge: '521056205', name: 'Itaguari' },
  { ibge: '521060405', name: 'Itaguaru' },
  { ibge: '521080205', name: 'Itajá' },
  { ibge: '521090105', name: 'Itapaci' },
  { ibge: '521090110', name: 'Aparecida de Goiás' },
  { ibge: '521100805', name: 'Itapirapuã' },
  { ibge: '521100810', name: 'Jacilândia' },
  { ibge: '521120605', name: 'Itapuranga' },
  { ibge: '521120610', name: 'Cibele' },
  { ibge: '521120615', name: 'Diolândia' },
  { ibge: '521130505', name: 'Itarumã' },
  { ibge: '521130515', name: 'Olaria do Angico' },
  { ibge: '521140405', name: 'Itauçu' },
  { ibge: '521150305', name: 'Itumbiara' },
  { ibge: '521160205', name: 'Ivolândia' },
  { ibge: '521160210', name: 'Campolândia' },
  { ibge: '521170105', name: 'Jandaia' },
  { ibge: '521180005', name: 'Jaraguá' },
  { ibge: '521190905', name: 'Jataí' },
  { ibge: '521200605', name: 'Jaupaci' },
  { ibge: '521205505', name: 'Jesúpolis' },
  { ibge: '521210505', name: 'Joviânia' },
  { ibge: '521220405', name: 'Jussara' },
  { ibge: '521220410', name: 'Canadá' },
  { ibge: '521220415', name: 'Juscelândia' },
  { ibge: '521220425', name: 'São Sebastião do Rio Claro' },
  { ibge: '521225305', name: 'Lagoa Santa' },
  { ibge: '521230305', name: 'Leopoldo de Bulhões' },
  { ibge: '521250105', name: 'Luziânia' },
  { ibge: '521250106', name: 'Jardim Ingá' },
  { ibge: '521260005', name: 'Mairipotaba' },
  { ibge: '521270905', name: 'Mambaí' },
  { ibge: '521280805', name: 'Mara Rosa' },
  { ibge: '521290705', name: 'Marzagão' },
  { ibge: '521295605', name: 'Matrinchã' },
  { ibge: '521295610', name: 'Lua Nova' },
  { ibge: '521300405', name: 'Maurilândia' },
  { ibge: '521305305', name: 'Mimoso de Goiás' },
  { ibge: '521308705', name: 'Minaçu' },
  { ibge: '521308710', name: 'Cana Brava' },
  { ibge: '521310305', name: 'Mineiros' },
  { ibge: '521340005', name: 'Moiporá' },
  { ibge: '521340010', name: 'Messianópolis' },
  { ibge: '521350905', name: 'Monte Alegre de Goiás' },
  { ibge: '521370705', name: 'Montes Claros de Goiás' },
  { ibge: '521370710', name: 'Lucilândia' },
  { ibge: '521370715', name: 'Registro do Araguaia' },
  { ibge: '521370720', name: 'Aparecida do Rio Claro' },
  { ibge: '521370726', name: 'Ponte Alta do Araguaia' },
  { ibge: '521375605', name: 'Montividiu' },
  { ibge: '521377205', name: 'Montividiu do Norte' },
  { ibge: '521380605', name: 'Morrinhos' },
  { ibge: '521385505', name: 'Morro Agudo de Goiás' },
  { ibge: '521390505', name: 'Mossâmedes' },
  { ibge: '521400205', name: 'Mozarlândia' },
  { ibge: '521405105', name: 'Mundo Novo' },
  { ibge: '521410105', name: 'Mutunópolis' },
  { ibge: '521440805', name: 'Nazário' },
  { ibge: '521450705', name: 'Nerópolis' },
  { ibge: '521460605', name: 'Niquelândia' },
  { ibge: '521460610', name: 'São Luiz do Tocantins' },
  { ibge: '521460615', name: 'Tupiraçaba' },
  { ibge: '521460625', name: 'Vila Taveira' },
  { ibge: '521470505', name: 'Nova América' },
  { ibge: '521480405', name: 'Nova Aurora' },
  { ibge: '521483805', name: 'Nova Crixás' },
  { ibge: '521483810', name: 'Bandeirantes' },
  { ibge: '521486105', name: 'Nova Glória' },
  { ibge: '521487905', name: 'Nova Iguaçu de Goiás' },
  { ibge: '521490305', name: 'Nova Roma' },
  { ibge: '521500905', name: 'Nova Veneza' },
  { ibge: '521520705', name: 'Novo Brasil' },
  { ibge: '521523105', name: 'Novo Gama' },
  { ibge: '521525605', name: 'Novo Planalto' },
  { ibge: '521530605', name: 'Orizona' },
  { ibge: '521530610', name: 'Alto Alvorada' },
  { ibge: '521540505', name: 'Ouro Verde de Goiás' },
  { ibge: '521550405', name: 'Ouvidor' },
  { ibge: '521560305', name: 'Padre Bernardo' },
  { ibge: '521565205', name: 'Palestina de Goiás' },
  { ibge: '521570205', name: 'Palmeiras de Goiás' },
  { ibge: '521580105', name: 'Palmelo' },
  { ibge: '521590005', name: 'Palminópolis' },
  { ibge: '521600705', name: 'Panamá' },
  { ibge: '521630405', name: 'Paranaiguara' },
  { ibge: '521640305', name: 'Paraúna' },
  { ibge: '521645205', name: 'Perolândia' },
  { ibge: '521680905', name: 'Petrolina de Goiás' },
  { ibge: '521690805', name: 'Pilar de Goiás' },
  { ibge: '521710405', name: 'Piracanjuba' },
  { ibge: '521720305', name: 'Piranhas' },
  { ibge: '521730205', name: 'Pirenópolis' },
  { ibge: '521730210', name: 'Lagolândia' },
  { ibge: '521740105', name: 'Pires do Rio' },
  { ibge: '521760905', name: 'Planaltina' },
  { ibge: '521760910', name: 'Córrego Rico' },
  { ibge: '521760915', name: 'São Gabriel de Goiás' },
  { ibge: '521770805', name: 'Pontalina' },
  { ibge: '521800305', name: 'Porangatu' },
  { ibge: '521805205', name: 'Porteirão' },
  { ibge: '521810205', name: 'Portelândia' },
  { ibge: '521830005', name: 'Posse' },
  { ibge: '521839105', name: 'Professor Jamil' },
  { ibge: '521850805', name: 'Quirinópolis' },
  { ibge: '521860705', name: 'Rialma' },
  { ibge: '521860710', name: 'Castrinópolis' },
  { ibge: '521870605', name: 'Rianápolis' },
  { ibge: '521878905', name: 'Rio Quente' },
  { ibge: '521880505', name: 'Rio Verde' },
  { ibge: '521880512', name: 'Lagoa do Bauzinho' },
  { ibge: '521880515', name: 'Ouroana' },
  { ibge: '521880520', name: 'Riverlândia' },
  { ibge: '521890405', name: 'Rubiataba' },
  { ibge: '521890415', name: 'Valdelândia' },
  { ibge: '521900105', name: 'Sanclerlândia' },
  { ibge: '521910005', name: 'Santa Bárbara de Goiás' },
  { ibge: '521920905', name: 'Santa Cruz de Goiás' },
  { ibge: '521925805', name: 'Santa Fé de Goiás' },
  { ibge: '521930805', name: 'Santa Helena de Goiás' },
  { ibge: '521935705', name: 'Santa Isabel' },
  { ibge: '521940705', name: 'Santa Rita do Araguaia' },
  { ibge: '521945605', name: 'Santa Rita do Novo Destino' },
  { ibge: '521950605', name: 'Santa Rosa de Goiás' },
  { ibge: '521960505', name: 'Santa Tereza de Goiás' },
  { ibge: '521970405', name: 'Santa Terezinha de Goiás' },
  { ibge: '521971205', name: 'Santo Antônio da Barra' },
  { ibge: '521973805', name: 'Santo Antônio de Goiás' },
  { ibge: '521975305', name: 'Santo Antônio do Descoberto' },
  { ibge: '521980305', name: 'São Domingos' },
  { ibge: '521990205', name: 'São Francisco de Goiás' },
  { ibge: '522000905', name: "São João d'Aliança" },
  { ibge: '522000910', name: 'Forte' },
  { ibge: '522005805', name: 'São João da Paraúna' },
  { ibge: '522010805', name: 'São Luís de Montes Belos' },
  { ibge: '522010810', name: 'Rosalândia' },
  { ibge: '522015705', name: 'São Luíz do Norte' },
  { ibge: '522020705', name: 'São Miguel do Araguaia' },
  { ibge: '522026405', name: 'São Miguel do Passa Quatro' },
  { ibge: '522028005', name: 'São Patrício' },
  { ibge: '522040505', name: 'São Simão' },
  { ibge: '522040510', name: 'Itaguaçu' },
  { ibge: '522045405', name: 'Senador Canedo' },
  { ibge: '522050405', name: 'Serranópolis' },
  { ibge: '522060305', name: 'Silvânia' },
  { ibge: '522068605', name: 'Simolândia' },
  { ibge: '522070205', name: "Sítio d'Abadia" },
  { ibge: '522100705', name: 'Taquaral de Goiás' },
  { ibge: '522108005', name: 'Teresina de Goiás' },
  { ibge: '522119705', name: 'Terezópolis de Goiás' },
  { ibge: '522130405', name: 'Três Ranchos' },
  { ibge: '522140305', name: 'Trindade' },
  { ibge: '522145205', name: 'Trombas' },
  { ibge: '522150205', name: 'Turvânia' },
  { ibge: '522155105', name: 'Turvelândia' },
  { ibge: '522157705', name: 'Uirapuru' },
  { ibge: '522160105', name: 'Uruaçu' },
  { ibge: '522160115', name: 'Geriaçu' },
  { ibge: '522160120', name: 'Pau Terra' },
  { ibge: '522170005', name: 'Uruana' },
  { ibge: '522170010', name: 'Uruíta' },
  { ibge: '522170020', name: 'Uruceres' },
  { ibge: '522180905', name: 'Urutaí' },
  { ibge: '522185805', name: 'Valparaíso de Goiás' },
  { ibge: '522190805', name: 'Varjão' },
  { ibge: '522200505', name: 'Vianópolis' },
  { ibge: '522200510', name: 'Caraíba' },
  { ibge: '522205405', name: 'Vicentinópolis' },
  { ibge: '522220305', name: 'Vila Boa' },
  { ibge: '522230205', name: 'Vila Propício' },
  { ibge: '530010805', name: 'Brasília' },
];

export default { listOfDistricts };
