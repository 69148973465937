import { HTMLAttributes, ReactNode } from 'react';
import Wrapper from '../../components/Wrapper';

interface IContainerProps extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode;
  reverse?: boolean;
}

function Container({ children, reverse }: IContainerProps) {
  return (
    <div
      className={`flex mb-8 last:mb-0 ${reverse ? 'bg-gray-200' : 'bg-white'}`}
    >
      <Wrapper className="flex-col flex">
        <div
          className={`flex ${
            reverse ? 'flex-row-reverse py-8' : 'flex-row'
          } justify-between items-start content-start flex-wrap sm:flex-nowrap`}
        >
          {children}
        </div>
      </Wrapper>
    </div>
  );
}

Container.defaultProps = {
  reverse: false,
};

export default Container;
