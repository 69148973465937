import { ReactNode } from 'react';

interface ITitle {
  children: ReactNode;
  className?: string;
  textColorClassName?: string;
  borderColorful?: boolean;
}

function Title({
  children,
  className,
  textColorClassName,
  borderColorful,
}: ITitle) {
  const borderClass = `${
    borderColorful ? 'after:bg-green-600' : 'after:bg-white'
  } after:block after:w-16 after:h-0.5 after:mt-2 after:mx-auto`;

  return (
    <h2
      className={`text-center text-xl md:text-2xl font-light mb-10 ${textColorClassName} ${borderClass} ${className}`}
    >
      {children}
    </h2>
  );
}

Title.defaultProps = {
  className: '',
  textColorClassName: 'text-text',
  borderColorful: true,
};

export default Title;
