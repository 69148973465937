import axios from 'axios';
import { IBudgetForm } from '../interfaces/budgets';
import { axiosErrorMessage, validateRequiredFields } from '../utils';

export async function fetchSendBudget({
  buffetId,
  name,
  email,
  phone,
  quantity,
  partyDate,
}: IBudgetForm) {
  validateRequiredFields([
    { name: 'name', value: name },
    { name: 'email', value: email },
    { name: 'phone', value: phone },
    { name: 'quantity', value: quantity },
    { name: 'buffetId', value: buffetId },
    { name: 'partyDate', value: partyDate },
  ]);

  const { REACT_APP_API_URL, REACT_APP_ORIGIN_URL } = process.env;

  return axios
    .post(
      `${REACT_APP_API_URL}/budget`,
      {
        name,
        email,
        partyDate,
        quantity,
        phone,
        buffetId,
      },
      {
        headers: {
          'Access-Control-Allow-Origin': REACT_APP_ORIGIN_URL ?? '',
        },
      }
    )
    .then(({ data }) => data)
    .catch(axiosErrorMessage);
}

export default {
  fetchSendBudget,
};
