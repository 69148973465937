import axios from 'axios';
import { IBuffetRegister } from '../interfaces/buffets';
import { ISearchPageDetails } from '../interfaces/search';
import { axiosErrorMessage } from '../utils';

export async function fetchFeaturedBuffets() {
  const { REACT_APP_API_URL, REACT_APP_ORIGIN_URL } = process.env;

  return axios
    .get(`${REACT_APP_API_URL}/featuredBuffets`, {
      headers: {
        'Access-Control-Allow-Origin': REACT_APP_ORIGIN_URL ?? '',
      },
    })
    .then(({ data }) => data.data)
    .catch(axiosErrorMessage);
}

export async function fetchPopularBuffets() {
  const { REACT_APP_API_URL, REACT_APP_ORIGIN_URL } = process.env;

  return axios
    .get(`${REACT_APP_API_URL}/popularBuffets`, {
      headers: {
        'Access-Control-Allow-Origin': REACT_APP_ORIGIN_URL ?? '',
      },
    })
    .then(({ data }) => data.data)
    .catch(axiosErrorMessage);
}

export async function fetchBuffetDetails(slug: string) {
  const { REACT_APP_API_URL, REACT_APP_ORIGIN_URL } = process.env;

  return axios
    .get(`${REACT_APP_API_URL}/buffet?name=${slug}`, {
      headers: {
        'Access-Control-Allow-Origin': REACT_APP_ORIGIN_URL ?? '',
      },
    })
    .then(({ data }) => data.data)
    .catch(axiosErrorMessage);
}

export async function fetchRegisterBuffet(body: IBuffetRegister) {
  const { REACT_APP_API_URL, REACT_APP_ORIGIN_URL } = process.env;

  return axios
    .post(
      `${REACT_APP_API_URL}/buffet`,
      {
        ...body,
      },
      {
        headers: {
          'Access-Control-Allow-Origin': REACT_APP_ORIGIN_URL ?? '',
        },
      }
    )
    .then(({ data }) => data)
    .catch(axiosErrorMessage);
}

export async function fetchBuffets(query: ISearchPageDetails) {
  const { REACT_APP_API_URL, REACT_APP_ORIGIN_URL } = process.env;

  return axios
    .get(`${REACT_APP_API_URL}/buffets`, {
      headers: {
        'Access-Control-Allow-Origin': REACT_APP_ORIGIN_URL ?? '',
      },
      params: query,
    })
    .then(({ data }) => data)
    .catch(axiosErrorMessage);
}

export default {
  fetchFeaturedBuffets,
  fetchPopularBuffets,
  fetchBuffetDetails,
  fetchRegisterBuffet,
  fetchBuffets,
};
