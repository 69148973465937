import { InputHTMLAttributes } from 'react';

interface IProps extends InputHTMLAttributes<HTMLInputElement> {
  className?: string;
}

function Checkbox({ className, ...rest }: IProps) {
  return <input type="checkbox" className={`w-5 h-5 inline-flex mr-3 ${className}`} {...rest} />;
}

Checkbox.defaultProps = {
  className: '',
};

export default Checkbox;
