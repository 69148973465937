import { ChangeEvent, FormEvent, HTMLAttributes, useState } from 'react';
import { useMutation } from 'react-query';
import { fetchSendBudget } from '../../api/budget';
import { IBudgetForm } from '../../interfaces/budgets';
import AlertMessages from '../AlertMessages';
import Button from '../Button';
import Label from '../Label';
import Loader from '../Loader';
import Subtitle from '../Subtitle';
import Textfield from '../Textfield';

interface IProps {
  buffetId: string;
}

const emptyState: IBudgetForm = {
  name: '',
  email: '',
  partyDate: '',
  quantity: '',
  phone: '',
  buffetId: '',
};

function Fieldset({ children }: HTMLAttributes<HTMLFieldSetElement>) {
  return <fieldset className="w-full mb-5">{children}</fieldset>;
}

function BudgetForm({ buffetId }: IProps) {
  const [state, setState] = useState(emptyState);

  const { mutate, isLoading, error, data } = useMutation(fetchSendBudget, {
    onSettled: () => {
      setState(emptyState);
    },
  });

  const onHandleChange = ({ target }: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    setState((prevState) => ({
      ...prevState,
      [target.name]: target.value,
    }));
  };

  const onHandleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    mutate({
      ...state,
      buffetId,
    });
  };

  return (
    <div className="w-full flex flex-col bg-gray-250 rounded-lg box-border p-6 lg:max-w-79 min-w-79">
      <Subtitle>Solicite um orçamento</Subtitle>

      <AlertMessages
        show={!!error}
        type="error"
        message={typeof error === 'string' ? error : JSON.stringify(error)}
      />

      {isLoading ? (
        <Loader fullScreen={false} />
      ) : (
        <div className="w-full">
          {data?.message ? (
            <AlertMessages type="success" message={data?.message} />
          ) : (
            <form onSubmit={onHandleSubmit} className="w-full flex flex-col">
              <Fieldset>
                <Label htmlFor="txtName">Nome:*</Label>
                <Textfield
                  type="text"
                  name="name"
                  id="txtName"
                  value={state.name}
                  onChange={onHandleChange}
                  placeholder="digite seu nome..."
                  minLength={3}
                  maxLength={60}
                  required
                />
              </Fieldset>

              <Fieldset>
                <Label htmlFor="txtEmail">E-mail:*</Label>
                <Textfield
                  type="email"
                  name="email"
                  id="txtEmail"
                  value={state.email}
                  onChange={onHandleChange}
                  placeholder="digite seu e-mail..."
                  required
                />
              </Fieldset>

              <Fieldset>
                <Label htmlFor="txtPhone">Telefone:*</Label>
                <Textfield
                  type="text"
                  mask="(99) 99999-9999"
                  placeholder="digite seu telefone..."
                  name="phone"
                  id="txtPhone"
                  value={state.phone}
                  onChange={onHandleChange}
                  required
                />
              </Fieldset>

              <Fieldset>
                <Label htmlFor="txtQuantity">Nº aproximado de convidados:*</Label>
                <Textfield
                  type="number"
                  name="quantity"
                  id="txtQuantity"
                  value={state.quantity}
                  placeholder="100"
                  onChange={onHandleChange}
                  minLength={1}
                  maxLength={3}
                  min={25}
                  max={400}
                  required
                />
              </Fieldset>

              <Fieldset>
                <Label htmlFor="txtPartyDate">Data da festa:*</Label>
                <Textfield
                  type="text"
                  mask="99/99/9999"
                  placeholder={`01/11/${new Date().getFullYear() + 1}`}
                  name="partyDate"
                  id="txtPartyDate"
                  value={state.partyDate}
                  onChange={onHandleChange}
                  required
                />
              </Fieldset>

              <div className="inline-flex justify-end">
                <Button type="submit" theme="purple" size="regular">
                  Enviar
                </Button>
              </div>
            </form>
          )}
        </div>
      )}
    </div>
  );
}

export default BudgetForm;
