import MasterPage from '../../components/MasterPage';
import Paragraph from '../../components/Paragraph';
import Subtitle from '../../components/Subtitle';
import IMG_01 from '../../assets/images/about-1.jpg';
import IMG_02 from '../../assets/images/about-2.jpg';
import IMG_03 from '../../assets/images/about-3.png';
import Container from './container';

function QuemSomos() {
  const contentStyle = 'sm:w-2/4 w-full mb-6 sm:mb-0';
  const imgContainerStyle = 'rounded-3xl overflow-hidden w-full sm:w-2/4 flex justify-center';
  const imgStyle = 'object-contain w-full h-full inline-flex';

  return (
    <MasterPage
      title="Quem Somos"
      description="Desenvolver um comportamento empresarial de qualidade oferecendo
    excelência em atendimento e obtenção de resultados, de modo que,
    sejamos referência não só nas relações estabelecidas com nossos
    clientes mas em todo o mercado de busca e pesquisas direcionadas
    para buffets infantis do Brasil, estando a frente dos concorrentes
    e nos tornando o maior e mais completo site de buscas entre os mais
    procurados no ambiente digital."
    >
      <div className="w-full flex-col flex">
        <Container>
          <div className={contentStyle}>
            <Subtitle>Missão</Subtitle>
            <Paragraph>
              Organizar todos os buffets infantis do Brasil e torná-los nacionalmente acessíveis e
              úteis, dando a oportunidade de se destacarem entre os demais obtendo um crescimento
              acima do que por eles é estimado, oferecendo serviço de melhor qualidade, melhor
              atendimento e um melhor relacionamento entre cliente/empresa.
            </Paragraph>
          </div>

          <div className={`${imgContainerStyle} sm:ml-8`}>
            <img src={IMG_01} alt="" className={imgStyle} />
          </div>
        </Container>

        <Container reverse>
          <div className={contentStyle}>
            <Subtitle>Visão</Subtitle>
            <Paragraph>
              Desenvolver um comportamento empresarial de qualidade oferecendo excelência em
              atendimento e obtenção de resultados, de modo que, sejamos referência não só nas
              relações estabelecidas com nossos clientes mas em todo o mercado de busca e pesquisas
              direcionadas para buffets infantis do Brasil, estando a frente dos concorrentes e nos
              tornando o maior e mais completo site de buscas entre os mais procurados no ambiente
              digital.
            </Paragraph>
          </div>

          <div className={`${imgContainerStyle} sm:mr-8`}>
            <img src={IMG_02} alt="" className={imgStyle} />
          </div>
        </Container>

        <Container>
          <div className={contentStyle}>
            <Subtitle>Valores</Subtitle>
            <Paragraph>
              - Lealdade e ética nas relações <br />
              - Qualidade superior <br />
              - Honestidade <br />
              - Comprometimento <br />
              - Respeito <br />
              - Reconhecimento <br />
              - Inovação <br />- Excelência na obtenção de resultados.
            </Paragraph>
          </div>

          <div className={`${imgContainerStyle} sm:ml-8`}>
            <img src={IMG_03} alt="" className={imgStyle} />
          </div>
        </Container>
      </div>
    </MasterPage>
  );
}

export default QuemSomos;
