import React, { InputHTMLAttributes } from 'react';
import InputMask from 'react-input-mask';

interface IProps extends InputHTMLAttributes<HTMLInputElement> {
  mask?: string;
}

const Textfield = React.forwardRef<HTMLInputElement, IProps>((props, ref) => {
  const { mask, ...rest } = props;

  const inputStyle =
    'w-full inline-flex text-lg font-light md:font-light box-border text-text outline-none rounded-3xl border-2 border-gray-250 bg-white transition-all hover:border-blue-450 disabled:bg-disabled py-3 px-6 placeholder:italic placeholder:lowercase disabled:hover:bg-disabled disabled:hover:border-disabled disabled:border-disabled disabled:cursor-not-allowed';

  return (
    <div className="w-full">
      {mask ? (
        <InputMask inputRef={ref} mask={mask} className={inputStyle} {...rest} />
      ) : (
        <input ref={ref} className={inputStyle} {...rest} />
      )}
    </div>
  );
});

Textfield.defaultProps = {
  mask: '',
};

export default Textfield;
