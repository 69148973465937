import { FC } from 'react';
import Anuncie from '../containers/Anuncie';
import BuffetsInfantis from '../containers/BuffetsInfantis';
import ConfirmacaoCadastro from '../containers/ConfirmacaoCadastro';
import DetalheBuffet from '../containers/DetalheBuffet';
import FaleConosco from '../containers/FaleConosco';
import FAQ from '../containers/FAQ';
import Home from '../containers/Home';
import NotFound from '../containers/NotFound';
import PlanosInvestimentos from '../containers/PlanosInvestimentos';
import PoliticaPrivacidade from '../containers/PoliticaPrivacidade';
import QuemSomos from '../containers/QuemSomos';
import TermosUso from '../containers/TermosUso';

interface IContainerProps {
  [key: string]: string | number;
}

interface IRoutes {
  path: string;
  active: boolean;
  private: boolean;
  containerProps: IContainerProps;
  container: FC;
}

export const arrRoutes: IRoutes[] = [
  {
    path: '/',
    active: true,
    private: false,
    containerProps: {},
    container: Home,
  },
  {
    path: '/quem-somos',
    active: true,
    private: false,
    containerProps: {},
    container: QuemSomos,
  },
  {
    path: '/fale-conosco',
    active: true,
    private: false,
    containerProps: {},
    container: FaleConosco,
  },
  {
    path: '/faq',
    active: true,
    private: false,
    containerProps: {},
    container: FAQ,
  },
  {
    path: '/planos-e-investimentos',
    active: true,
    private: false,
    containerProps: {},
    container: PlanosInvestimentos,
  },
  {
    path: '/anuncie-seu-buffet-infantil',
    active: true,
    private: false,
    containerProps: {},
    container: Anuncie,
  },
  {
    path: '/buffets-infantis/:city',
    active: true,
    private: false,
    containerProps: {},
    container: BuffetsInfantis,
  },
  {
    path: '/buffets-infantis/:city/:district',
    active: true,
    private: false,
    containerProps: {},
    container: BuffetsInfantis,
  },
  {
    path: '/buffets-infantis',
    active: true,
    private: false,
    containerProps: {},
    container: BuffetsInfantis,
  },
  {
    path: '/buffet-infantil/:slug',
    active: true,
    private: false,
    containerProps: {},
    container: DetalheBuffet,
  },
  {
    path: '/politica-de-privacidade',
    active: true,
    private: false,
    containerProps: {},
    container: PoliticaPrivacidade,
  },
  {
    path: '/termos-de-uso',
    active: true,
    private: false,
    containerProps: {},
    container: TermosUso,
  },
  {
    path: '/confirmacao-de-conta',
    active: true,
    private: false,
    containerProps: {},
    container: ConfirmacaoCadastro,
  },
  {
    path: '/pagina-nao-encontrada',
    active: true,
    private: false,
    containerProps: {},
    container: NotFound,
  },
];

export default { arrRoutes };
export type { IRoutes };
