import { List } from 'phosphor-react';
import { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { useWindowSize } from '../../hooks/useWindowSize';
import Button from '../Button';
import NavJSON from './nav.json';

function Nav() {
  const { nav } = NavJSON;
  const [showMobileNav, setshowMobileNav] = useState(false);

  const { REACT_APP_ADMIN_URL } = process.env;

  const [windowWidth] = useWindowSize();

  const activeClassName = 'decoration-none text-blue-450 font-semibold';

  useEffect(() => {
    if (showMobileNav && windowWidth < 1024) {
      document.body.classList.add('overflow-hidden');
    } else {
      document.body.classList.remove('overflow-hidden');
    }
  }, [showMobileNav, windowWidth]);

  return (
    <>
      <button
        type="button"
        className={`lg:hidden bg-transparent absolute overflow-hidden cursor-pointer border-0 left-0 top-1/2 -translate-y-1/2 z-50 -ml-0.5 ${
          showMobileNav ? 'text-white' : 'text-text'
        }`}
        onClick={() => setshowMobileNav(!showMobileNav)}
      >
        <List size={28} weight="bold" />
        <span className="-indent-96 absolute">menu</span>
      </button>

      <nav
        className={`lg:inline-flex lg:relative lg:w-auto h-screen lg:h-auto ${
          showMobileNav ? 'fixed left-0 top-0 z-40 bg-black lg:bg-transparent w-screen' : 'hidden'
        }`}
      >
        <ul className="w-full flex flex-col lg:flex-row mt-14 lg:mt-0">
          {nav.map((item) => {
            const hasChildren = item.children.length > 0;

            return (
              <li
                key={uuidv4()}
                className={`inline-flex w-full items-center font-normal flex-wrap text-sg relative first:ml-0 whitespace-nowrap mb-2 lg:mb-0 ${
                  hasChildren ? 'group' : ''
                }`}
              >
                <NavLink
                  to={item.url}
                  className={({ isActive }) =>
                    `w-full box-border px-4 lg:px-1 mx-0 lg:mx-2 py-2 lg:py-5 border-none transition-all ${
                      hasChildren ? 'hidden lg:block' : 'block'
                    } ${
                      isActive && !hasChildren
                        ? activeClassName
                        : 'text-white lg:text-text lg:hover:text-blue-450'
                    }`
                  }
                >
                  {item.text}
                </NavLink>

                {hasChildren && (
                  <ul className="nav-children lg:hidden group-hover:block w-[calc(100%+30px)] lg:absolute z-20 lg:bg-white rounded-2xl lg:py-4 shadow-outer lg:top-20 lg:-left-4 before:content-[''] before:h-5 before:bg-transparent before:absolute before:-top-5 before:left-0 before:z-20 before:w-full before:block after:hidden after:lg:block">
                    {item.children.map((chil) => (
                      <li
                        key={uuidv4()}
                        className="w-full mb-2 last:mb-0 lg:mb-0 whitespace-nowrap first:lg:rounded-t-2xl last:lg:rounded-b-2xl"
                      >
                        <NavLink
                          to={chil.url}
                          className={({ isActive }) =>
                            `w-full block lg:px-7 px-4 py-2 m-0 box-border decoration-0 border-b-0 transition-all lg:hover:text-blue-450 ${
                              isActive ? activeClassName : 'lg:text-text text-white'
                            }`
                          }
                        >
                          {chil.text}
                        </NavLink>
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            );
          })}

          <li className="inline-flex items-center box-border px-4 lg:px-0 lg:ml-2 mt-2 lg:mt-0">
            <Button
              type="button"
              onClick={() => {
                window.location.href = REACT_APP_ADMIN_URL ?? '';
              }}
            >
              Login
            </Button>
          </li>
        </ul>
      </nav>
    </>
  );
}

export default Nav;
