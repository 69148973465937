import QuestionsContainer from '../../../components/QuestionsContainer';
import Title from '../../../components/Title';
import Wrapper from '../../../components/Wrapper';

function FAQSection() {
  return (
    <section>
      <Wrapper>
        <Title>Perguntas Frequentes</Title>
        <QuestionsContainer />
      </Wrapper>
    </section>
  );
}

export default FAQSection;
