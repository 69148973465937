import ContactForm from '../../components/ContactForm';
import MasterPage from '../../components/MasterPage';
import Wrapper from '../../components/Wrapper';

function FaleConosco() {
  return (
    <MasterPage
      title="Fale Conosco"
      description="Acesse nosso formulário de contato e tire todas as suas dúvidas."
    >
      <Wrapper>
        <ContactForm />
      </Wrapper>
    </MasterPage>
  );
}

export default FaleConosco;
