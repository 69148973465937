import axios from 'axios';
import { ICommentsForm } from '../interfaces/comments';
import { axiosErrorMessage, validateRequiredFields } from '../utils';

export async function fetchSendComment({ buffetId, name, email, message, rating }: ICommentsForm) {
  validateRequiredFields([
    { name: 'name', value: name },
    { name: 'email', value: email },
    { name: 'message', value: message },
    { name: 'rating', value: rating },
    { name: 'buffetId', value: buffetId },
  ]);

  const { REACT_APP_API_URL, REACT_APP_ORIGIN_URL } = process.env;

  return axios
    .post(
      `${REACT_APP_API_URL}/comment`,
      {
        name,
        email,
        message,
        rating,
        buffetId,
      },
      {
        headers: {
          'Access-Control-Allow-Origin': REACT_APP_ORIGIN_URL ?? '',
        },
      }
    )
    .then(({ data }) => data)
    .catch(axiosErrorMessage);
}

export async function fetchComments(buffetId: string) {
  validateRequiredFields([{ name: 'buffetId', value: buffetId }]);

  const { REACT_APP_API_URL, REACT_APP_ORIGIN_URL } = process.env;

  return axios
    .get(`${REACT_APP_API_URL}/comments`, {
      headers: {
        'Access-Control-Allow-Origin': REACT_APP_ORIGIN_URL ?? '',
      },
      params: {
        buffetId,
      },
    })
    .then(({ data }) => data.data)
    .catch(axiosErrorMessage);
}

export default {
  fetchSendComment,
  fetchComments,
};
