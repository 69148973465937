import axios from 'axios';
import { axiosErrorMessage } from '../utils';

export async function fetchCities() {
  const { REACT_APP_API_URL, REACT_APP_ORIGIN_URL } = process.env;

  return axios
    .get(`${REACT_APP_API_URL}/cities`, {
      headers: {
        'Access-Control-Allow-Origin': REACT_APP_ORIGIN_URL ?? '',
      },
    })
    .then(({ data }) => data.data)
    .catch(axiosErrorMessage);
}

export default {
  fetchCities,
};
