import IMG_Loader from '../../assets/images/loader.svg';

interface IProps {
  fullScreen?: boolean;
  description?: string;
}

function Loader({ fullScreen, description }: IProps) {
  if (fullScreen)
    return (
      <div className="w-full h-full fixed top-0 left-0 z-50 overflow-hidden">
        <div className="w-full h-full absolute left-0 top-0 z-30 bg-white opacity-95" />

        <div className="w-full h-full absolute left-0 top-0 z-40 flex items-center content-center justify-center flex-wrap">
          <img src={IMG_Loader} alt="Carregando..." className="h-12 inline-flex" />
          {description && (
            <span className="w-full justify-center inline-flex text-lg text-text font-light md:font-light mt-4">
              {description}
            </span>
          )}
        </div>
      </div>
    );

  return (
    <div className="w-full flex justify-center items-center">
      <img src={IMG_Loader} alt="Carregando..." className="h-8 inline-flex" />
    </div>
  );
}

Loader.defaultProps = {
  fullScreen: true,
  description: '',
};

export default Loader;
