import { ChangeEvent, FormEvent, useCallback, useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { Link } from 'react-router-dom';
import { fetchRegisterBuffet } from '../../api/buffets';
import { fetchZipCode } from '../../api/zipCode';
import AlertMessages from '../../components/AlertMessages';
import Button from '../../components/Button';
import Checkbox from '../../components/Checkbox';
import Label from '../../components/Label';
import Loader from '../../components/Loader';
import MasterPage from '../../components/MasterPage';
import Paragraph from '../../components/Paragraph';
import Select from '../../components/Select';
import Subtitle from '../../components/Subtitle';
import Textfield from '../../components/Textfield';
import Wrapper from '../../components/Wrapper';
import { IBuffetRegister } from '../../interfaces/buffets';
import { IListOfDistricts, listOfDistricts } from '../../mocks/districts';
import { orderBy } from '../../utils';

const emptyState: IBuffetRegister = {
  username: '',
  password: '',
  confirmPassword: '',
  name: '',
  zipCode: '',
  address: '',
  neighborhood: '',
  city: '',
  district: '',
  state: '',
  number: '',
  ibge: '',
};

function Anuncie() {
  const [isLoadingAddress, setIsLoadingAddress] = useState(false);
  const [error, setError] = useState('');
  const [state, setState] = useState<IBuffetRegister>(emptyState);
  const [terms, setTerms] = useState(false);
  const [allDistricts, setAllDistricts] = useState<IListOfDistricts[]>([]);

  const {
    mutate,
    isLoading,
    data: submitData,
  } = useMutation(['register'], fetchRegisterBuffet, {
    onError(err) {
      const errorMessage = typeof err === 'string' ? err : JSON.stringify(err);
      setError(errorMessage);
    },
    onSuccess() {
      setState(emptyState);
      setAllDistricts([]);
      setTerms(false);
    },
  });

  const clearMessages = () => {
    setError('');
  };

  const onHandleChange = ({
    target,
  }: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    setState((prevState) => ({
      ...prevState,
      [target.name]: target.value,
    }));
  };

  const onHandleBlurZipCode = async ({ target }: any) => {
    const val: string = target.value;

    clearMessages();

    if (val.indexOf('_') === -1) {
      setIsLoadingAddress(true);

      try {
        const res = await fetchZipCode(val);

        const list: IListOfDistricts[] = listOfDistricts.filter((item: IListOfDistricts) =>
          item.ibge.includes(res.ibge)
        );

        const listOrderedByName: IListOfDistricts[] = list.length
          ? orderBy({ propName: 'name', order: 'ASC', arr: list })
          : list;

        setAllDistricts(listOrderedByName);

        setState((prevState) => ({
          ...prevState,
          address: res.logradouro,
          state: res.uf,
          city: res.localidade,
          neighborhood: res.bairro,
          ibge: res.ibge,
        }));
      } catch (err) {
        const errorMessage = typeof err === 'string' ? err : JSON.stringify(err);

        setAllDistricts([]);
        setState((prevState) => ({
          ...prevState,
          address: '',
          state: '',
          city: '',
          neighborhood: '',
          ibge: '',
        }));

        setError(errorMessage);
      } finally {
        setIsLoadingAddress(false);
      }
    }
  };

  const onHandleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    clearMessages();

    mutate({
      ...state,
      terms,
    });
  };

  const onClosingTab = useCallback(
    (e: any) => {
      if (state.username) {
        e.preventDefault();
        // registrationRecovery(state.username);
        e.returnValue = 'Você realmente deseja sair? Suas alterações não serão salvas.';
      }
    },
    [state.username]
  );

  useEffect(() => {
    window.addEventListener('beforeunload', onClosingTab);

    return () => {
      window.removeEventListener('beforeunload', onClosingTab);
    };
  }, [onClosingTab]);

  return (
    <MasterPage
      title="Anuncie seu Buffet Infantil"
      description="Milhares de pessoas buscam por Buffets Infantís diariamente. Anuncie seu Buffet Infantil e expanda seus negócios do jeito que você nunca imaginou."
    >
      <Wrapper>
        <Paragraph>
          Preencha o formulário abaixo e você receberá um e-mail com as instruções necessárias para
          completar seu anúncio. Se você já possui ao menos um buffet infantil cadastrado e deseja
          cadastrar um novo anúncio, faça seu login{' '}
          <a href="https://admin.kidsbuffets.com.br" className="link">
            aqui
          </a>
          .
        </Paragraph>

        <AlertMessages
          show={!!submitData?.message}
          type="success"
          message="Obrigado por fazer parte do KidsBuffets.com.br! Em alguns instantes você receberá um e-mail de confirmação e com todas as instruções necessárias para completar seu anúncio."
        />
        <AlertMessages show={!!error} type="error" message={error} />
      </Wrapper>

      {(isLoadingAddress || isLoading) && <Loader fullScreen />}

      <form className="w-full" onSubmit={onHandleSubmit}>
        <section className="w-full bg-gray-250 py-10 my-8">
          <Wrapper>
            <Subtitle>Dados de acesso</Subtitle>

            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
              <fieldset>
                <Label className="pl-6" htmlFor="txtEmail">
                  E-mail:*
                </Label>
                <Textfield
                  type="email"
                  placeholder="digite seu email..."
                  name="username"
                  autoComplete="off"
                  id="txtEmail"
                  onChange={onHandleChange}
                  value={state.username}
                  maxLength={60}
                  required
                />
              </fieldset>

              <fieldset>
                <Label className="pl-6" htmlFor="txtPassword">
                  Senha:*
                </Label>
                <Textfield
                  type="password"
                  placeholder="digite sua senha..."
                  autoComplete="off"
                  name="password"
                  id="txtPassword"
                  onChange={onHandleChange}
                  value={state.password}
                  minLength={8}
                  maxLength={60}
                  required
                />
              </fieldset>

              <fieldset>
                <Label className="pl-6" htmlFor="txtPasswordConfirm">
                  Confirmar senha:*
                </Label>
                <Textfield
                  type="password"
                  placeholder="confirme sua senha..."
                  autoComplete="off"
                  name="confirmPassword"
                  id="txtPasswordConfirm"
                  onChange={onHandleChange}
                  value={state.confirmPassword}
                  minLength={8}
                  maxLength={60}
                  pattern={state.password === state.confirmPassword ? undefined : '/^(true)$/'}
                  required
                />
              </fieldset>
            </div>
          </Wrapper>
        </section>

        <section>
          <Wrapper>
            <Subtitle>Dados do seu Buffet Infantil</Subtitle>

            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-6">
              <fieldset className="sm:col-span-2">
                <Label className="pl-6" htmlFor="txtName">
                  Nome do Buffet Infantil:*
                </Label>
                <Textfield
                  type="text"
                  placeholder="digite o nome do seu buffet infantil..."
                  name="name"
                  id="txtName"
                  autoComplete="off"
                  onChange={onHandleChange}
                  value={state.name}
                  minLength={3}
                  maxLength={60}
                  required
                />
              </fieldset>

              <fieldset className="sm:col-span-2">
                <Label className="pl-6">Plano:*</Label>
                <Select className="w-full" name="plan">
                  <option value="">Grátis por tempo limitado</option>
                </Select>
              </fieldset>

              <fieldset>
                <Label className="pl-6" htmlFor="txtCEP">
                  CEP:*
                </Label>
                <Textfield
                  type="text"
                  placeholder="01452-000"
                  mask="99999-999"
                  name="zipCode"
                  autoComplete="off"
                  id="txtCEP"
                  onChange={onHandleChange}
                  onBlur={onHandleBlurZipCode}
                  value={state.zipCode}
                  required
                />
              </fieldset>

              {state.address && (
                <fieldset className="sm:col-span-3">
                  <Label className="pl-6" htmlFor="txtAddress">
                    Endereço:*
                  </Label>
                  <Textfield
                    type="text"
                    placeholder=""
                    name="address"
                    id="txtAddress"
                    value={state.address}
                    required
                    disabled
                  />
                </fieldset>
              )}

              <fieldset>
                <Label className="pl-6" htmlFor="txtNumber">
                  Número:*
                </Label>
                <Textfield
                  type="text"
                  placeholder="123"
                  name="number"
                  id="txtNumber"
                  autoComplete="off"
                  value={state.number}
                  onChange={onHandleChange}
                  maxLength={10}
                  minLength={1}
                  required
                />
              </fieldset>

              {state.neighborhood && (
                <fieldset className="sm:col-span-3">
                  <Label className="pl-6" htmlFor="txtNeighborhood">
                    Bairro:*
                  </Label>
                  <Textfield
                    type="text"
                    placeholder=""
                    name="neighborhood"
                    id="txtNeighborhood"
                    value={state.neighborhood}
                    maxLength={60}
                    required
                    disabled
                  />
                </fieldset>
              )}

              {allDistricts.length > 0 && (
                <fieldset className="sm:col-span-2 lg:col-span-1">
                  <Label className="pl-6" htmlFor="txtDistrict">
                    Distrito:*
                  </Label>
                  <Select
                    className="w-full"
                    name="district"
                    id="txtDistrict"
                    value={state.district}
                    onChange={onHandleChange}
                    required
                  >
                    <option value="" hidden disabled>
                      Selecione sua região
                    </option>
                    {allDistricts.map((item: IListOfDistricts) => (
                      <option key={item.ibge} value={item.name}>
                        {item.name}
                      </option>
                    ))}
                  </Select>
                </fieldset>
              )}

              {state.city && (
                <fieldset className="sm:col-span-2">
                  <Label className="pl-6" htmlFor="txtCity">
                    Cidade:*
                  </Label>
                  <Textfield
                    type="text"
                    placeholder=""
                    name="city"
                    id="txtCity"
                    value={state.city}
                    maxLength={60}
                    required
                    disabled
                  />
                </fieldset>
              )}

              {state.state && (
                <fieldset className="lg:col-span-1">
                  <Label className="pl-6" htmlFor="txtState">
                    Estado:*
                  </Label>
                  <Textfield
                    type="text"
                    placeholder=""
                    name="state"
                    id="txtState"
                    value={state.state}
                    minLength={2}
                    maxLength={2}
                    required
                    disabled
                  />
                </fieldset>
              )}

              <fieldset className="sm:col-span-4 mt-4">
                <Label className="w-full items-center">
                  <Checkbox
                    name="terms"
                    id="txtTerms"
                    checked={state.terms}
                    onChange={() => setTerms(!terms)}
                    required
                  />

                  <span className="inline-block">
                    Li e aceito os{' '}
                    <Link to="/termos-de-uso" className="link">
                      termos de uso
                    </Link>{' '}
                    e a{' '}
                    <Link to="/politica-de-privacidade" className="link">
                      política de privacidade
                    </Link>{' '}
                    de KidsBuffets.com.br
                  </span>
                </Label>
              </fieldset>
            </div>

            <Button type="submit" theme="green" size="large" className="mt-8">
              Confirmar Cadastro
            </Button>
          </Wrapper>
        </section>
      </form>
    </MasterPage>
  );
}

export default Anuncie;
