import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { useEffect, useMemo, useState } from 'react';
import AlertMessages from '../../components/AlertMessages';
import Loader from '../../components/Loader';
import MasterPage from '../../components/MasterPage';
import Wrapper from '../../components/Wrapper';
import { fetchAccountConfirmation } from '../../api/account';
import { validEmailAddress } from '../../utils';
import Button from '../../components/Button';

function ConfirmacaoCadastro() {
  const { search } = useLocation();
  const q: any = useMemo(() => (search ? queryString.parse(search) : {}), [search]);

  const { REACT_APP_ADMIN_URL } = process.env;

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const fetchValidation = async () => {
    try {
      const resp = await fetchAccountConfirmation(q.email, q.token);
      setSuccess(resp);
      setError('');
    } catch (err) {
      const message = typeof err === 'string' ? err : JSON.stringify(err);
      setError(message);
      setSuccess('');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (q && q.email && q.token && validEmailAddress(q.email).result) {
      fetchValidation();
    } else {
      setSuccess('');
      setError('Ops! Token de confirmação ou e-mail inválidos');
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [q]);

  return (
    <MasterPage
      title="Confirmação de conta"
      description="Confirme sua conta para ter acesso ao nosso painel administrativo."
    >
      <Wrapper>
        {loading ? (
          <Loader fullScreen={false} />
        ) : (
          <>
            <AlertMessages show={!!error} type="error" message={error} />

            {!!success && (
              <div className="w-full">
                <AlertMessages type="success" message={success} />

                <Button
                  type="button"
                  onClick={() => {
                    window.location.href = REACT_APP_ADMIN_URL ?? '';
                  }}
                  theme="purple"
                  className="mt-10"
                >
                  Acessar painel administrativo
                </Button>
              </div>
            )}
          </>
        )}
      </Wrapper>
    </MasterPage>
  );
}

export default ConfirmacaoCadastro;
