interface IProps {
  address: string;
}

function GoogleMaps({ address }: IProps) {
  const { REACT_APP_MAPS_KEY } = process.env;

  return (
    <iframe
      title="Google Maps"
      src={`https://www.google.com/maps/embed/v1/search?key=${REACT_APP_MAPS_KEY}&q=${address}`}
      width="100%"
      height="395"
      frameBorder="0"
      style={{ border: 0 }}
      allowFullScreen
    />
  );
}

export default GoogleMaps;
