import { CheckCircle, Info, Warning, XCircle } from 'phosphor-react';
import { ReactElement, useLayoutEffect, useState } from 'react';

interface IProps {
  type: 'success' | 'error' | 'info' | 'warning';
  message: string;
  show?: boolean;
}

function AlertMessages({ type, message, show }: IProps) {
  const [theme, setTheme] = useState('');
  const [icon, setIcon] = useState<ReactElement>();

  useLayoutEffect(() => {
    const size = 32;
    const style = 'mr-4 text-lg';

    switch (type) {
      case 'info':
        setTheme('bg-blue-200 border-blue-500 text-text');
        setIcon(<Info size={size} weight="fill" className={`${style} text-text`} />);
        break;

      case 'warning':
        setTheme('bg-yellow-200 border-yellow-400 text-text');
        setIcon(<Warning size={size} weight="fill" className={`${style} text-text`} />);
        break;

      case 'error':
        setTheme('bg-red-500 border-red-700 text-white');
        setIcon(<XCircle size={size} weight="fill" className={`${style} text-white`} />);
        break;

      default:
        setTheme('bg-green-500 border-green-700 text-white');
        setIcon(<CheckCircle size={size} weight="fill" className={`${style} text-white`} />);
        break;
    }
  }, [type]);

  if (show) {
    return (
      <div
        className={`flex rounded-xs border-l-6 mb-4 overflow-hidden rounded-lg ${theme}`}
        data-testid="alertMessages"
      >
        <div className="w-full p-4 box-border flex">
          <div className="inline-flex min-w-9 h-8 box-border items-center">{icon}</div>

          <p className="inline-flex items-center">{message}</p>
        </div>
      </div>
    );
  }

  return <> </>;
}

AlertMessages.defaultProps = {
  show: true,
};

export default AlertMessages;
