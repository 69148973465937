import { ShareNetwork } from 'phosphor-react';
import { useState } from 'react';
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
  LinkedinShareButton,
  LinkedinIcon,
  EmailShareButton,
  EmailIcon,
} from 'react-share';
import Button from '../Button';
import Lightbox from '../Lightbox';

interface IProps {
  path: string;
  buffetName: string;
}

function SharePage({ path, buffetName }: IProps) {
  const { REACT_APP_ORIGIN_URL } = process.env;

  const [showLightbox, setShowLightbox] = useState(false);

  const shareTitle = `${buffetName} | KidsBuffets`;
  const sharePath = `${REACT_APP_ORIGIN_URL}${path}`;

  return (
    <>
      <Button type="button" theme="green" onClick={() => setShowLightbox(true)}>
        <ShareNetwork size={20} className="text-white mr-2" weight="fill" />
        Compartilhar página
      </Button>

      {showLightbox && (
        <Lightbox
          title="Compartilhar página"
          onClose={() => setShowLightbox(false)}
          height={260}
          width={580}
        >
          <ul className="flex w-full">
            <li>
              <EmailShareButton url={sharePath} subject={shareTitle}>
                <EmailIcon size={48} round />
              </EmailShareButton>
            </li>

            <li className="ml-2">
              <FacebookShareButton url={sharePath} quote={shareTitle}>
                <FacebookIcon size={48} round />
              </FacebookShareButton>
            </li>

            <li className="ml-2">
              <TwitterShareButton url={sharePath} title={shareTitle}>
                <TwitterIcon size={48} round />
              </TwitterShareButton>
            </li>

            <li className="ml-2">
              <WhatsappShareButton url={sharePath} title={shareTitle} separator=" ">
                <WhatsappIcon size={48} round />
              </WhatsappShareButton>
            </li>

            <li className="ml-2">
              <LinkedinShareButton url={sharePath}>
                <LinkedinIcon size={48} round />
              </LinkedinShareButton>
            </li>
          </ul>

          <div className="w-full bg-black p-4 rounded-lg box-border mt-4">
            <span className="text-white text-base italic font-light md:font-light">
              {sharePath}
            </span>
          </div>
        </Lightbox>
      )}
    </>
  );
}

export default SharePage;
