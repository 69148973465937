import { ReactElement } from 'react';

interface ISocialMediaProps {
  icon: ReactElement;
  className?: string;
  text: string;
  url: string;
}

function SocialMedia({ className, icon, text, url }: ISocialMediaProps) {
  return (
    <div className={`inline-flex items-center ${className}`}>
      {icon}

      <span className="text-white font-normal text-sm ml-2">
        <a
          className="no-underline hover:no-underline hover:text-orange-450 transition-all"
          href={url}
          target="_blank"
          rel="noopener noreferrer"
        >
          {text}
        </a>
      </span>
    </div>
  );
}

SocialMedia.defaultProps = {
  className: '',
};

export default SocialMedia;
