import { v4 as uuidv4 } from 'uuid';
import Questions from '../Questions';
import { faqQuestions, IFaqQuestions } from './questions';

function QuestionsContainer() {
  return (
    <>
      {faqQuestions.map((item: IFaqQuestions) => (
        <Questions key={uuidv4()} item={item} />
      ))}
    </>
  );
}

export default QuestionsContainer;
