import { ReactNode, ButtonHTMLAttributes } from 'react';

interface IProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: ReactNode;
  size?: 'small' | 'regular' | 'large';
  theme?: 'none' | 'blue' | 'green' | 'orange' | 'purple' | 'white';
}

interface IObj {
  [key: string]: string;
}

function Button({
  className,
  children,
  theme = 'blue',
  size = 'regular',
  ...rest
}: IProps) {
  const themes: IObj = {
    purple: 'text-white bg-purple-950 border-purple-950 hover:saturate-50',
    orange: 'text-white bg-orange-450 border-orange-450 hover:saturate-50',
    green: 'text-white bg-green-650 border-green-650 hover:saturate-50',
    blue: 'text-white bg-blue-450 border-blue-450 hover:saturate-50',
    white: 'text-white bg-transparent border-white hover:saturate-50',
    none: 'text-text bg-transparent border-transparent',
  };

  const sizes: IObj = {
    small: 'text-sm px-6 py-1',
    regular: 'text-sg px-8 py-2',
    large: 'text-lg px-11 py-4 max-h-14',
  };

  return (
    <button
      className={`border rounded-3xl cursor-pointer font-normal flex justify-center items-center box-border px-7 py-2 transition-all duration-300 ${sizes[size]} ${themes[theme]} ${className}`}
      {...rest}
    >
      {children}
    </button>
  );
}

Button.defaultProps = {
  theme: 'blue',
  size: 'regular',
};

export default Button;
