import { HTMLAttributes } from 'react';
import { Link } from 'react-router-dom';
import MasterPage from '../../components/MasterPage';
import Paragraph from '../../components/Paragraph';
import Subtitle from '../../components/Subtitle';
import Wrapper from '../../components/Wrapper';

function Container({ children }: HTMLAttributes<HTMLDivElement>) {
  return <div className="w-full mt-10">{children}</div>;
}

function TermosUso() {
  const listStyle = 'w-full flex flex-col list-disc box-border pl-10';

  return (
    <MasterPage
      title="Termos de uso"
      description="Antes de acessar ou utilizar qualquer parte do KidsBuffets.com.br, o usuário/anunciante deverá ler com atenção estes Termos."
    >
      <Wrapper>
        <Paragraph>
          Antes de acessar ou utilizar qualquer parte do KidsBuffets.com.br, o usuário/anunciante
          deverá ler com atenção estes Termos.
        </Paragraph>

        <Paragraph>
          O uso do KidsBuffets.com.br, ou acesso a este, implicará que o usuário/anunciante leu,
          concordou e aceitou cumprir com os termos, condições e advertências aqui estabelecidos.
        </Paragraph>

        <Paragraph>
          Estes Termos de Uso podem ser modificados a qualquer momento e sem a necessidade de aviso
          prévio, estando o usuário/anunciante ciente de que será de sua exclusiva responsabilidade
          verificá-los periodicamente. Caso o usuário/anunciante não deseje vincular-se a estes
          Termos de Uso, por favor, não acesse ou utilize qualquer parte do KidsBuffets.com.br.
        </Paragraph>

        <Container>
          <Subtitle>1. Limitações e uso adequado</Subtitle>
          <Paragraph>
            O acesso e a utilização do KidsBuffets.com.br são oferecidos aos usuários/anunciantes
            unicamente para o seu uso pessoal e não comercial. O usuário/anunciante concorda
            expressamente que apenas acessará ou utilizará o KidsBuffets.com.br para os propósitos a
            que ele se destina, assim entendidos aqueles que são permitidos por estes Termos de Uso
            e pela legislação vigente. Entre outros fatores, o usuário/anunciante concorda que não
            poderá:
          </Paragraph>

          <ul className={listStyle}>
            <li className="mb-6">
              <Paragraph className="inline-flex">
                Exceto com autorização expressa por escrito do KidsBuffets.com.br, modificar,
                copiar, distribuir, transmitir, exibir, realizar, reproduzir, publicar, licenciar,
                criar trabalhos derivados, exibir em outra webpage, utilizar em qualquer outro
                website, transferir ou vender qualquer informação, software, listas de usuários,
                banco de dados ou outras listas, produtos ou serviços prestados por ou obtidos pelo
                KidsBuffets.com.br, incluindo, mas não limitado a, práticas envolvendo “screen
                scraping”, “database scraping”, ou qualquer outra atividade com o propósito de obter
                listas de usuários ou outras informações;
              </Paragraph>
            </li>

            <li className="mb-6">
              <Paragraph>
                Enviar ou transmitir quaisquer informações que possam induzir outros a erro; incitem
                a violência ou intolerância de qualquer natureza; ofensivas ou que contrariem a lei
                e/ou a ordem pública;
              </Paragraph>
            </li>

            <li className="mb-6">
              <Paragraph>
                Efetuar cadastro no KidsBuffets.com.br utilizando-se de informações falsas ou de
                propriedade de terceiros;
              </Paragraph>
            </li>

            <li className="mb-6">
              <Paragraph>
                Enviar ou transmitir informações de terceiros, ou de qualquer forma alterar, remover
                ou corromper informações de terceiros;
              </Paragraph>
            </li>

            <li className="mb-6">
              <Paragraph>
                Enviar ou transmitir quaisquer formas de códigos maliciosos, vírus eletrônicos ou
                conteúdo destrutivo que possam vir a expor informações do KidsBuffets.com.br, de
                terceiros ou causem qualquer tipo de dano aos equipamentos do destinatário;
              </Paragraph>
            </li>

            <li className="mb-6">
              <Paragraph>
                Exceto com a permissão prévia e escrita do KidsBuffets.com.br, acessar ou tentar
                acessar senhas protegidas, áreas restritas, áreas seguras ou não públicas do
                KidsBuffets.com.br;
              </Paragraph>
            </li>

            <li className="mb-6">
              <Paragraph>
                Realizar qualquer atividade no KidsBuffets.com.br que viole direitos de propriedade
                intelectual;
              </Paragraph>
            </li>

            <li>
              <Paragraph>
                Danificar, desabilitar, sobrecarregar, prejudicar o KidsBuffets.com.br ou interferir
                com o uso de qualquer parte integrada de ativa do KidsBuffets.com.br; e/ou obter ou
                tentar obter qualquer material ou informação de qualquer forma que não seja
                acessível por outras formas e que não sejam disponibilizados prontamente pelo
                KidsBuffets.com.br.
              </Paragraph>
            </li>
          </ul>
        </Container>

        <Container>
          <Subtitle>2. Links eletrônicos e referências ao KidsBuffets.com.br</Subtitle>
          <Paragraph>
            O usuário/anunciante poderá criar um link direto para qualquer website ou webpage do
            KidsBuffets.com.br.
          </Paragraph>

          <Paragraph>
            A origem de qualquer link para qualquer website ou webpage do KidsBuffets.com.br deve
            claramente identificar que o link está conectado a um website ou webpage do
            KidsBuffets.com.br.
          </Paragraph>

          <Paragraph>
            Ao criar um link para o KidsBuffets.com.br, o usuário/anunciante concorda expressamente
            que:
          </Paragraph>

          <ul className={listStyle}>
            <li className="mb-6">
              <Paragraph className="inline-flex">
                não usará nenhuma tecnologia que resulte no deslocamento de conteúdo do
                KidsBuffets.com.br para um frame e/ou uma janela pop-up reduzida e/ou quaisquer
                outros mecanismos de exibição que mudem a aparência do KidsBuffets.com.br tal qual
                este se apresenta em acesso direto;
              </Paragraph>
            </li>

            <li className="mb-6">
              <Paragraph className="inline-flex">
                seu site não deverá exibir conteúdo ou link para outros web sites que contenham
                conteúdo ilegal, obsceno, indecente, depreciativo, discriminatório ou de outra forma
                ofensivo, nos termos da lei;
              </Paragraph>
            </li>

            <li className="mb-6">
              <Paragraph className="inline-flex">
                possui o domínio do seu site devidamente registrado, bem como todos os direitos
                necessários para usar o mesmo;
              </Paragraph>
            </li>

            <li className="mb-6">
              <Paragraph className="inline-flex">
                não deverá de nenhuma forma acessar, coletar, armazenar, divulgar, transferir ou
                usar quaisquer informações obtidas ou derivadas de um acesso de usuário ou
                utilização do KidsBuffets.com.br.
              </Paragraph>
            </li>
          </ul>

          <Paragraph className="inline-flex">
            O KidsBuffets.com.br se reserva o direito de revogar sua permissão de criar tal link a
            qualquer momento, de acordo com seus próprios critérios, e o usuário/anunciante concorda
            em cessar imediatamente a utilização do link a qualquer momento que lhe seja solicitado
            pelo KidsBuffets.com.br. O usuário/anunciante concorda que o KidsBuffets.com.br pode, de
            acordo com seus próprios critérios e a qualquer momento, rescindir o seu acesso e uso do
            KidsBuffets.com.br, ou qualquer parte do mesmo, com ou sem advertência prévia. O
            usuário/anunciante também concorda que o uso do KidsBuffets.com.br e quaisquer de seus
            serviços serão imediatamente rescindidos se o usuário/anunciante violar estes Termos de
            Uso. Além disso, o KidsBuffets.com.br se reserva o direito, de acordo com seus próprios
            critérios, de modificar ou descontinuar qualquer parte do mesmo, com ou sem advertência,
            e sem qualquer responsabilidade para com o usuário/anunciante ou terceiros
          </Paragraph>
        </Container>

        <Container>
          <Subtitle>3. Facilidades oferecidas</Subtitle>
          <Paragraph>
            KidsBuffets.com.br destina-se a fornecer espaço para veiculação de anúncios de diversos
            anunciantes, servindo como mero veículo de exposição destes anúncios, não atuando como
            prestador de serviços de consultoria, intermediação comercial, agenciamento, ou
            participante direto ou indireto de quaisquer negócios ou transações eventualmente
            efetuadas entre o(s) usuário(s) e o(s) anunciante(s). Estas facilidades fornecidas pelo
            KidsBuffets.com.br podem ser localizadas em websites de terceiros ou aplicativos, sob a
            forma de um link, propaganda na qual se pode clicar, ou de qualquer outra maneira
            tecnologicamente viável.
          </Paragraph>

          <Paragraph>
            KidsBuffets.com.br não é responsável pela precisão, suficiência, veracidade,
            exaustividade ou pontualidade contida nos anúncios que veicula, não se podendo
            considerar que o KidsBuffets.com.br esteja fazendo uma oferta, prometendo vender um
            produto específico por um preço específico, ou garantindo que qualquer anunciante venda
            seu produto ou serviço sob quaisquer termos específicos. Além disso, o
            KidsBuffets.com.br não dá qualquer garantia a respeito dos serviços de terceiros. O
            KidsBuffets.com.br expressamente recomenda que o usuário/anunciante verifique e confirme
            a suficiência e confiabilidade destes serviços ou oferta de terceiros.
          </Paragraph>

          <Paragraph>
            As informações contidas no KidsBuffets.com.br poderão conter erros tipográficos ou
            imprecisões de outras natureza. Verificação e ajustes de tais informações são realizados
            periodicamente.
          </Paragraph>
        </Container>

        <Container>
          <Subtitle>4. Responsabilidades</Subtitle>
          <Paragraph>
            KidsBuffets.com.br será responsável apenas e tão somente pelo conteúdo e funcionalidade
            das ferramentas eletrônicas e facilidades que disponibilizar diretamente ao
            usuário/anunciante.
          </Paragraph>

          <Paragraph>
            Assim, nas hipóteses em que o usuário/anunciante ou algum terceiro ajuíze qualquer tipo
            de reclamação ou ação legal contra o(s) anunciante(s), todos os envolvidos nestas
            reclamações ou ações expressamente eximirão KidsBuffets.com.br, seus sócios, empregados
            e contratados a qualquer título de toda e qualquer responsabilidade.
          </Paragraph>

          <Paragraph>
            KidsBuffets.com.br também não se responsabiliza pelo cumprimento devido pelo usuário,
            por anunciantes ou quaisquer terceiros, das respectivas obrigações tributárias que
            venham a incidir, nos termos da lei vigente, sobre quaisquer atividades, operações ou
            negócios que tenham sua origem nos anúncios veiculados ao KidsBuffets.com.br. Portanto,
            KidsBuffets.com.br recomenda expressamente que o usuário/anunciante fique atento a estes
            pontos e, ao adquirir algum bem, exija todos os documentos fiscais e legais pertinentes.
          </Paragraph>

          <Paragraph>
            KidsBuffets.com.br disponibiliza determinadas facilidades interativas, tais como, mas
            não se limitando a, ferramentas financeiras ou de hipoteca que fornecem dados e
            informações customizadas baseadas em dados inseridos pelo usuário ou pelos anunciantes.
            Estas ferramentas têm como propósito facilitar a realização de cálculos e não são
            vinculativas, não devendo ser interpretadas como uma oferta para empréstimos, pois tem
            apenas propósito demonstrativo e podem variar. KidsBuffets.com.br recomenda que o
            usuário/anunciante consulte sempre uma instituição financeira de sua confiança.
          </Paragraph>

          <Paragraph>
            O usuário/anunciante pode estar sujeito a termos, condições, e política de privacidade
            adicionais e/ou diferentes quando o usuário/anunciante utiliza serviços, conteúdo (como
            definido abaixo), software ou sites de terceiros aos quais o usuário/anunciante pode ser
            conduzido a partir do KidsBuffets.com.br, mas os quais o KidsBuffets.com.br não opera e
            não tem controle. O KidsBuffets.com.br não se responsabiliza por estes serviços,
            conteúdo, software ou sites de terceiros, sendo que se o usuário/anunciante o fará por
            sua conta e risco se escolher utiliza-los.
          </Paragraph>
        </Container>

        <Container>
          <Subtitle>5. Conteúdo</Subtitle>
          <Paragraph>
            Todo o conteúdo encontrado no KidsBuffets.com.br incluindo, mas não se limitando a, toda
            informação, texto, mensagens, imagens, fotos, ilustrações, design, ícones, clipes de
            vídeo, sons, arquivos, marcas, materiais de direito autoral, adornos comerciais,
            software, especificações, catálogos, literatura, informação técnica, propaganda e outro
            conteúdo ou materiais (coletivamente, “Conteúdo”) é de propriedade e/ou posse do
            KidsBuffets.com.br ou seus usuários, salvo indicação em contrário. KidsBuffets.com.br
            concede ao usuário uma licença limitada, a título precário e não exclusiva, para acessar
            e utilizar o KidsBuffets.com.br e seu conteúdo unicamente para propósitos informativos,
            pessoais e não comerciais (incluindo a impressão de páginas individuais do
            KidsBuffets.com.br desde que o usuário/anunciante mantenha a menção aos direitos
            autorais e outros avisos relacionados à propriedade intelectual contidos no mesmo).
          </Paragraph>

          <Paragraph>
            É expressamente proibido fazer downloads (exceto para cache das páginas), modificar ou
            fazer qualquer outro uso do KidsBuffets.com.br ou conteúdo, exceto com o prévio e
            expresso consentimento por escrito do KidsBuffets.com.br. O usuário/anunciante entende
            que o conteúdo de terceiros veiculado, transmitido por, ou ligado ao KidsBuffets.com.br,
            é de exclusiva responsabilidade do originador de tal conteúdo. O conteúdo é fornecido
            pelo KidsBuffets.com.br da mesma forma em que foi disponibilizado pelo seu autor. O
            usuário/anunciante concorda que o uso de qualquer conteúdo será feito por sua conta e
            risco e que sob nenhuma circunstância o KidsBuffets.com.br será responsável por qualquer
            conteúdo ou qualquer perda ou dano de qualquer natureza, incorridos como resultado do
            uso de qualquer conteúdo disponibilizado por meio do KidsBuffets.com.br.
          </Paragraph>

          <Paragraph>
            O usuário/anunciante está ciente de que o KidsBuffets.com.br não filtra ou aprova o
            conteúdo de terceiros, e que o KidsBuffets.com.br possui o direito (mas não a
            obrigação), de acordo com seus próprios critérios, de recusar, modificar ou apagar
            conteúdo do KidsBuffets.com.br, por qualquer razão.
          </Paragraph>
        </Container>

        <Container>
          <Subtitle>6. Conteúdo incluído pelo usuário/anunciante</Subtitle>
          <Paragraph>
            Na medida em que o usuário/anunciante divulga, faz upload, insere, envia ou transmite de
            qualquer forma conteúdo no KidsBuffets.com.br (uma &quot;Transmissão&quot;), o
            usuário/anunciante está ciente de que será de sua exclusiva responsabilidade fornecer
            informações verdadeiras, precisas e completas, bem como se abster de assumir
            personalidade fictícia, representar falsamente terceiros ou afirmar afiliação
            inexistente junto de qualquer pessoa ou entidade.
          </Paragraph>

          <Paragraph>
            O usuário/anunciante é inteiramente responsável por todo conteúdo que disponibilizar
            através do KidsBuffets.com.br. Ao disponibilizar conteúdo, o usuário/anunciante afirma e
            garante que possui todos os direitos, ou autorização por escrito dos seus respectivos
            proprietários, sobre as informações, materiais e dados que transmitir ao
            KidsBuffets.com.br.
          </Paragraph>

          <Paragraph>
            Nesse sentido, o usuário/anunciante não poderá, sob qualquer pretexto, transmitir
            qualquer conteúdo:
          </Paragraph>

          <ul className={listStyle}>
            <li className="mb-6">
              <Paragraph>Não relacionado a assuntos apropriados ao KidsBuffets.com.br;</Paragraph>
            </li>

            <li className="mb-6">
              <Paragraph>Que induza quaisquer pessoas a erro;</Paragraph>
            </li>

            <li className="mb-6">
              <Paragraph>
                Que seja ilegal, nocivo, ameaçador, abusivo, opressivo, difamatório, vulgar,
                obsceno, desonesto, que invada a privacidade de terceiros, que incite o ódio ou a
                discriminação racial, étnica, religiosa ou qualquer outra;
              </Paragraph>
            </li>

            <li className="mb-6">
              <Paragraph>
                Que não possua o direito de divulgar ou transmitir sob qualquer lei ou condição
                contratual firmada com terceiros;
              </Paragraph>
            </li>

            <li className="mb-6">
              <Paragraph>
                Que constitua uma infração de qualquer direito de propriedade intelectual,
                incluindo, mas não se limitando a, marca, patente, segredo comercial, direitos
                autorais ou outro direito relacionados à propriedade intelectual de qualquer parte;
              </Paragraph>
            </li>

            <li>
              <Paragraph>
                Que contenham vírus ou qualquer outro código malicioso, arquivos e programas de
                computador desenvolvidos para interromper, destruir ou limitar a funcionalidade de
                qualquer software de computador ou hardware ou equipamento de telecomunicações do
                KidsBuffets.com.br ou de terceiros; ou que violar intencionalmente ou não qualquer
                lei aplicável local, estadual, nacional ou internacional, ou quaisquer
                regulamentações que tenham a força da lei.
              </Paragraph>
            </li>
          </ul>
        </Container>

        <Container>
          <Subtitle>7. Direitos autorais, propriedade intelectual e marcas:</Subtitle>
          <Paragraph>
            Ao Transmitir conteúdo ao KidsBuffets.com.br, o usuário/anunciante afirma e garante que
            tem o direito de ceder e transferir ao KidsBuffets.com.br, como de fato cede e
            transfere, uma licença irrevogável, perpétua, não exclusiva, gratuita e mundial para que
            o KidsBuffets.com.br possa usar, copiar, exibir e distribuir o conteúdo, bem como
            preparar trabalhos derivados dele, ou incorporar em outros trabalhos ao conteúdo, bem
            como deles livremente dispor.
          </Paragraph>

          <Paragraph>
            Además, ao divulgar conteúdo em qualquer área pública do KidsBuffets.com.br, o
            usuário/anunciante concede ao KidsBuffets.com.br todos os direitos necessários para
            proibir qualquer agregação, exibição, cópia, duplicação, reprodução, ou exploração
            subsequente do conteúdo no KidsBuffets.com.br por qualquer terceiro para qualquer
            propósito.
          </Paragraph>

          <Paragraph>
            Todos os materiais disponibilizados pelo KidsBuffets.com.br, incluindo, mas não se
            limitando a, marcas, logotipos, layouts, designs, recursos, sistemas , bases de dados e
            imagens, cuja propriedade seja do KidsBuffets.com.br ou que este esteja autorizado
            contratualmente a veicular pelos terceiros titulares, estão protegidos pela legislação
            que trata sobre propriedade intelectual, sendo os mesmos de titularidade ou estando os
            mesmos sob a posse do KidsBuffets.com.br ou seus usuários. A reprodução não autorizada
            de tais materiais somente será possível mediante prévia e expressa autorização do
            KidsBuffets.com.br nesse sentido.
          </Paragraph>

          <Paragraph>
            O uso indevido destes materiais protegidos configurará flagrante infração à legislação
            pertinente, ficando ao usuário/anunciante ou quaisquer terceiros que fizerem tal uso
            sujeitos às devidas medidas judiciais e/ou extrajudiciais que se fizerem necessárias,
            sem prejuízos de indenização por perdas e danos causados e honorários advocatícios
            eventualmente incorridos.
          </Paragraph>
        </Container>

        <Container>
          <Subtitle>8. Políticas de privacidade</Subtitle>
          <Paragraph>
            KidsBuffets.com.br está comprometido em manter a privacidade e segurança de todas as
            informações não publicáveis, entendidas assim, mas não se limitando a, aquelas
            relacionadas aos seus dados e informações pessoais, que o usuário/anunciante fornecer ao
            KidsBuffets.com.br, conforme sua Política de Privacidade. KidsBuffets.com.br recomenda
            expressamente que o usuário{' '}
            <Link className="link" to="/politica-de-privacidade">
              clique aqui
            </Link>{' '}
            para rever a Política de Privacidade do KidsBuffets.com.br.
          </Paragraph>
        </Container>

        <Container>
          <Subtitle>9. Termos gerais</Subtitle>
          <Paragraph>
            KidsBuffets.com.br, de tempos em tempos, poderá modificar estes Termos de Uso, sem aviso
            prévio. Estas modificações serão incorporadas aos Termos de Uso imediatamente, a partir
            de sua publicação no KidsBuffets.com.br. O usuário/anunciante entende e aceita que
            deverá rever estes Termos de Uso periodicamente, e que ao usar e acessar o
            KidsBuffets.com.br o usuário/anunciante está tacitamente aceitando os termos que
            estiverem em vigor.
          </Paragraph>

          <Paragraph>
            Se qualquer parte dos Termos de Uso for determinada inválida ou inexequível de acordo
            com o direito aplicável, incluindo, mas não se limitando, a renúncia da garantia e
            limitações de responsabilidade aqui contidas, então a provisão inválida ou inexequível
            será considerada substituída por uma provisão válida e executável que se aproxime o
            máximo possível da intenção da provisão original, e as disposições remanescente dos
            Termos de Uso permanecerão em vigor. Salvo indicação em contrário, especificada aqui (ou
            em qualquer outro acordo escrito e firmado entre o usuário/anunciante e o
            KidsBuffets.com.br), os Termos de Uso constituem um acordo entre o usuário/anunciante e
            o KidsBuffets.com.br no que diz respeito à utilização do KidsBuffets.com.br.
          </Paragraph>
        </Container>

        <Container>
          <Subtitle>10. Riscos tecnológicos</Subtitle>
          <Paragraph>
            O usuário/anunciante declara estar plenamente ciente dos riscos a que está exposto pelo
            fato de estar conectado à rede mundial de computadores, tais como, mas não se limitando
            a, vírus, códigos maliciosos e invasões em seu sistema. Assim sendo, todos os riscos
            advindos da utilização do KidsBuffets.com.br são de exclusiva responsabilidade sua. Se o
            uso do KidsBuffets.com.br eventualmente resultar em quaisquer danos ao seus sistema ou
            hardware, o KidsBuffets.com.br não terá qualquer responsabilidade sobre tal evento.
          </Paragraph>

          <Paragraph>
            Em nenhuma hipótese o KidsBuffets.com.br poderá ser responsabilizado por qualquer dano
            ou prejuízo, direto ou indireto, advindos de fatos resultantes do uso do
            KidsBuffets.com.br ou da inabilidade de uso deste, ou, ainda, por quaisquer informações,
            produtos ou serviços obtidos através dele ou em decorrência do seu uso.
          </Paragraph>
        </Container>

        <Container>
          <Subtitle>11. Indenização</Subtitle>
          <Paragraph>
            O usuário/anunciante concorda em indenizar o KidsBuffets.com.br, seus anunciantes,
            afiliados, diretores, empregados e contratados de qualquer espécie, e mantê-los indenes
            de quaisquer e todas as reclamações e demandas, incluindo, mas não se limitando a,
            honorários razoáveis de advogados, feitas por terceiros ou decorrentes de qualquer
            conteúdo enviado, divulgado ou providenciado de qualquer outra forma pelo usuário ao
            KidsBuffets.com.br e/ou seus anunciantes, e qualquer violação destes Termos de Uso
            cometida pelo usuário/anunciante ou, se houver, seus afiliados, diretores, empregados e
            contratados de qualquer espécie.
          </Paragraph>

          <Paragraph>
            Se o usuário/anunciante não concordar com qualquer parte dos presentes Termos de Uso, o
            KidsBuffets.com.br recomenda expressamente que o usuário/anunciante não faça qualquer
            uso do KidsBuffets.com.br, pois, ao fazê-lo, sob qualquer forma ou pretexto, estará
            afirmando a sua integral concordância com estes Termos de Uso.
          </Paragraph>
        </Container>
      </Wrapper>
    </MasterPage>
  );
}

export default TermosUso;
