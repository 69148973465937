import Wrapper from '../Wrapper';
import Title from '../Title';

import IMG_Mirandinha from '../../assets/images/partners/mirandinha-miniaturas.png';
import IMG_Outline from '../../assets/images/partners/agencia-outline.png';
import IMG_Animae from '../../assets/images/partners/animae.png';
import IMG_Gabriela from '../../assets/images/partners/gabriela-fotografia.png';

interface IProps {
  className?: string;
}

const partnersArr = [
  {
    id: 1,
    title: 'Mirandinha Miniaturas',
    url: 'https://mirandinhaminiaturas.com.br',
    image: IMG_Mirandinha,
  },
  {
    id: 2,
    title: 'Agência Outline',
    url: 'https://www.instagram.com/agenciaoutline/',
    image: IMG_Outline,
  },
  {
    id: 3,
    title: 'Festas Animaê',
    url: 'https://www.instagram.com/festasanimae/',
    image: IMG_Animae,
  },
  {
    id: 4,
    title: 'Gabriela Rocha Fotografia',
    url: 'https://www.instagram.com/gabrielarochafotografia/',
    image: IMG_Gabriela,
  },
];

function Partners({ className }: IProps) {
  return (
    <div
      className={`w-full py-8 bg-repeat-x bg-center bg-partners overflow-x-auto md:overflow-x-hidden ${className}`}
    >
      <Wrapper>
        <Title textColorClassName="text-white" borderColorful={false}>
          Conheça alguns de nossos{' '}
          <strong className="font-semibold">parceiros</strong>
        </Title>

        <ul className="w-full flex md:justify-between">
          {partnersArr.map((partner) => (
            <li
              key={partner.id}
              className="inline-flex items-center content-center mx-4 first:ml-0 last:mr-0"
            >
              <a
                href={partner.url}
                target="_blank"
                rel="noopener noreferrer"
                title={partner.title}
              >
                <figure className="p-0 m-0">
                  <img
                    src={partner.image}
                    alt={partner.title}
                    height={136}
                    className="w-full h-full"
                  />
                </figure>
              </a>
            </li>
          ))}
        </ul>
      </Wrapper>
    </div>
  );
}

Partners.defaultProps = {
  className: '',
};

export default Partners;
