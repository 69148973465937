/* eslint-disable jsx-a11y/label-has-associated-control */
import { LabelHTMLAttributes, ReactNode } from 'react';

interface IProps extends LabelHTMLAttributes<HTMLLabelElement> {
  children: ReactNode;
  className?: string;
}

function Label({ children, className, ...rest }: IProps) {
  return (
    <label
      className={`inline-flex text-sm text-text font-light md:font-light box-border ${className}`}
      {...rest}
    >
      {children}
    </label>
  );
}

Label.defaultProps = {
  className: '',
};

export default Label;
