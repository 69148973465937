import { FormEvent, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import IMG_Banner from '../../assets/images/kids-banner-new.jpg';
import AppContext from '../../context';
import { ICities } from '../../interfaces/cities';
import Button from '../Button';
import Select from '../Select';
import Wrapper from '../Wrapper';

function MainBanner() {
  const navigate = useNavigate();

  const { cities, isLoadingCities } = useContext(AppContext);

  const [city, setCity] = useState('');

  const onHandleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (city) {
      navigate(`/buffets-infantis/${city}`);
    } else {
      navigate('/buffets-infantis');
    }
  };

  return (
    <article
      className="w-full flex bg-blue-50 bg-no-repeat bg-main-banner bg-mobile md:bg-auto
      min-h-102.5 md:min-h-130"
      style={{ backgroundImage: `url('${IMG_Banner}')` }}
    >
      <Wrapper>
        <h1 className="w-full flex flex-col items-center mb-5 mt-6 md:mt-8 md:mb-5">
          <span className="font-semibold text-xl sm:text-3xl md:text-4xl sm:mb-1 lg:mb-2 text-center">
            Encontre o melhor Buffet Infantil
          </span>

          <span className="font-light text-lg sm:text-2xl md:text-3xl">
            Para a festa da sua criança
          </span>
        </h1>

        <form
          method="POST"
          onSubmit={onHandleSubmit}
          className="flex w-full justify-center sm:flex-nowrap flex-wrap"
        >
          <Select
            containerClassName="md:w-2/4"
            className="md:py-0"
            value={city}
            onChange={({ target }) => setCity(target.value)}
          >
            <option value="">Buscar em todas as cidades</option>

            {!isLoadingCities &&
              cities.length > 0 &&
              cities.map((item: ICities) => (
                <option key={item.value} value={item.value}>
                  {item.label}
                </option>
              ))}
          </Select>

          <Button
            type="submit"
            size="large"
            className="w-full sm:w-auto rounded-3xl sm:ml-3 max-h-12 sm:max-h-max sm:mt-0 mt-2"
          >
            Buscar
          </Button>
        </form>
      </Wrapper>
    </article>
  );
}

export default MainBanner;
